import ReportDataColumn from '@/pages/report/wizard-report/date-column-report';
import ReportDataView from '@/pages/report/wizard-report/default-report-view';

export default function DataReportRoutes(commonComponents) {
  return [
    {
      path: '/data-report/:menuId',
      name: 'ReportDataView',
      components: { default: ReportDataView, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/date-column-report/:menuId',
      name: 'ReportDataColumn',
      components: { default: ReportDataColumn, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
