<!--
  PACKAGE_NAME : src/pages/esp/monitoring/redis
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 25. 2. 12.
  DESCRIPTION :
-->
<template>
  <div class="page-sub-box">
    <redis-info />
  </div>
</template>
<script>
  import RedisInfo from './redis-info.vue';

  export default {
    name: 'ESPRedisList',
    components: {
      RedisInfo,
    },
    data() {
      return {
        stylingMode: 'outlined',
      };
    },
    watch: {},
    computed: {},
    methods: {},
    mounted() {},
  };
</script>
