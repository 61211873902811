import ESPLogin from '@/pages/esp/user/login.vue';
import ESPPasswordChange from '@/pages/esp/user/password-change.vue';
import ESPUserManagementList from '@/pages/esp/user/management/list.vue';
import ESPUserManagementConfig from '@/pages/esp/user/management/config.vue';

export default function UserRoutes(commonComponents) {
  return [
    {
      path: '/login',
      name: 'ESPLogin',
      components: { singlePage: ESPLogin },
      meta: { requiresAuth: false, noTrack: true },
    },
    {
      path: '/password-change',
      name: 'ESPPasswordChange',
      components: { singlePage: ESPPasswordChange },
      meta: { requiresAuth: true, noTrack: true },
    },
    {
      path: '/esp/user/management/list',
      name: 'ESPUserManagementList',
      components: { default: ESPUserManagementList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/user/management/config',
      name: 'ESPUserManagementConfig',
      components: { default: ESPUserManagementConfig, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
  ];
}
