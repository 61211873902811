<template>
  <div class="main locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="herHistoryGrid"/>
  </div>
</template>
<script>
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  name: 'EUCHerHistory',
  components: {
    EspDxDataGrid,
  },
  data() {
    return {
      stylingMode: 'outlined', //outlined, underlined, filled
      dataGrid: {
        callApi: 'CALL_EUC_API',
        excel: {
          title: '호폭주 이력',
        },
        refName: 'herHistoryGrid',
        dataSourceDefaultSortColumn: '-regDt',
        showActionButtons: {
          excel: true,
        },
        apiActionNm: {
          select: 'EUC_CURRICALLSURGE_SELECT',
          delete: 'EUC_CURRICALLSURGE_DELETE'
        },
        searchParams: {
          curriType: 'CALLSURGE'
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: 'Server ID',
            dataField: 'serverId',
            allowEditing: false,
          },
          {
            caption: '발신번호',
            dataField: 'callingNum',
            allowEditing: false,
          },
          {
            caption: '수신번호',
            dataField: 'calledNum',
            allowEditing: false,
          },
          {
            caption: '수신번호명',
            dataField: 'calledNm',
            allowEditing: false,
          },
          {
            caption: '전환번호',
            dataField: 'dndortransno',
            allowEditing: false,
          },
          {
            caption: '전환번호명',
            dataField: 'dndortransnoNm',
            allowEditing: false,
          },
          {
            caption: '표시내용',
            dataField: 'display',
            allowEditing: false,
          },

          {
            caption: '등록자',
            dataField: 'regId',
            allowEditing: false,
          },
          {
            caption: '등록일',
            dataField: 'regDt',
            allowEditing: false,
            calculateCellValue: this.formatDt,
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(rowData) {
      if (rowData?.regDt) {
        return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
  },
};
</script>
