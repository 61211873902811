<!--
  PACKAGE_NAME : src\pages\ewm\hr\agent\appointment\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-07-15
  DESCRIPTION : 발령관리 탭 메인 화면
-->
<template>
  <div class="container">
    <Tabs ref="tabs">
      <Tab title="인사 발령 관리">
        <Hr ref="hrtRef" />
      </Tab>
      <Tab title="퇴사 관리">
        <Resign ref="resignRef" />
      </Tab>
      <Tab title="휴직 관리">
        <Leave ref="leaveRef" />
      </Tab>
      <Tab title="입사 관리">
        <Join ref="joinRef" />
      </Tab>
    </Tabs>
  </div>
</template>

<script>
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import Hr from '@/pages/ewm/hr/agent/appointment/hr.vue';
  import Resign from '@/pages/ewm/hr/agent/appointment/resign.vue';
  import Leave from '@/pages/ewm/hr/agent/appointment/leave.vue';
  import Join from '@/pages/ewm/hr/agent/appointment/join.vue';

  export default {
    name: 'EWMAgentAppointmentList',
    components: { Hr, Resign, Leave, Tabs, Join, Tab },
    props: {},
    data() {
      return {};
    },
    computed: {},
    methods: {},
    created() {},
    mounted() {},
  };
</script>

<style lang="scss" scoped></style>
