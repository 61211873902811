<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준년월</div>
              <DxDateBox
                :styling-mode="config.stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.frScheYmd"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy-MM"
                date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
                :max="searchType.customTypes.toScheYmd"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayStartChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="mt-1">~</div>
              <DxDateBox
                :styling-mode="config.stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.toScheYmd"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy-MM"
                date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
                :min="searchType.customTypes.frScheYmd"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'year',
                  minZoomLevel: 'year',
                  maxZoomLevel: 'year',
                }"
                @value-changed="onDayEndChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년월은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
      </div>

      <div class="flex gap-x-6">
        <div class="w-5/12">
          <esp-dx-data-grid :data-grid="kpiScheGrid" ref="kpiScheGrid" @row-click="onScheRowClick" />
        </div>
        <div class="w-7/12 flex flex-col gap-y-4">
          <esp-dx-data-grid :data-grid="kpiScheStdGrid" ref="kpiScheStdGrid" @saving="e => onStandardSave(e)" />
          <esp-dx-data-grid
            :data-grid="kpiScheTargetGrid"
            ref="kpiScheTargetGrid"
            @row-click="onRowClick"
            @cell-hover-changed="onCellHoverChanged"
            @saving="e => onTargetSave(e)"
          />
        </div>
      </div>
    </div>

    <!-- Agent Modal -->
    <modal-add-agent
      :isOpen="modal.agent.popupVisible"
      :showModalTitle="true"
      :selectedIdList="modal.agent.selectedIdList"
      @closeModal="onClose(false)"
      @saveModal="onSave"
    />
    <!-- Agent Modal -->

    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : false
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.height
              : 40
            : 40,
          onClick: () => {
            onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.height
              : 40
            : 40,
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import { getPastFromToday, isSuccess } from '@/utils/common-lib';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import ModalEwmAdd from '@/pages/ewm/productivity/implementation/evaluation-schedule/modal-ewm-add';
  import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
  import ModalKpiScheStd from '@/pages/ewm/productivity/implementation/evaluation-schedule/modal-kpi-sche-std';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = null;

  export default {
    name: 'EWMEvaluationSchedule',
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxCheckBox,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
      DxPopup,
      DxToolbarItem,
      ModalEwmAdd,
      ModalKpiScheStd,
      ModalAddAgent,
    },
    data() {
      return {
        config: {
          stylingMode: 'outlined',
        },
        codes: {
          productionTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            frScheYmd: getPastFromToday(5, 'months'),
            toScheYmd: getPastFromToday(-5, 'months'),
          },
          paramsData: {},
        },
        modal: {
          isOpened: false,
          initData: {},
          contentData: [],
          componentName: '',
          stdSelectedIdList: [],
          agent: {
            popupVisible: false,
            selectedIdList: [],
            targetSelectedIdList: [],
          },
        },
        kpiScheGrid: {
          keyExpr: 'id',
          refName: 'kpiScheGrid',
          allowColumnResizing: true,
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            initNewRow: true,
            saving: true,
            rowClick: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '복사생성',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 70,
                  height: 30,
                  onClick() {
                    vm.onScheCopy();
                  },
                },
                sortIndex: 10,
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onAddSche();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onScheDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '기준년월',
              dataField: 'scheYmd',
              alignment: 'center',
              sortOrder: 'desc', // acs desc none
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              dataType: 'date',
              format: 'yyyy.MM',
              editorOptions: {
                dataType: 'date',
                calendarOptions: {
                  zoomLevel: 'year',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'year',
                },
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '생산성평가계획명',
              dataField: 'scheNm',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
              cellTemplate: (container, options) => {
                if (options.data.useFl === this.$_enums.common.stringUsedFlag.NO.value) {
                  let aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    vm.onUpdateData(options.data);
                  });
                  container.append(aTag);
                } else {
                  container.append(options.value);
                }
              },
            },
            {
              caption: '스케줄적용여부',
              dataField: 'agtScheApply',
              alignment: 'center',
              width: '110',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.agtScheApply === e.value).label;
              },
              cellTemplate: (container, options) => {
                const checkbox = new DxCheckBox({
                  propsData: {
                    value: options.data.agtScheApply === this.$_enums.common.stringUsedFlag.YES.value,
                    disabled: true,
                    onValueChanged: e => {
                      const scheApply = e.value
                        ? this.$_enums.common.stringUsedFlag.YES.value
                        : this.$_enums.common.stringUsedFlag.NO.value;

                      this.$refs.kpiScheGrid.getInstance.cellValue(options.rowIndex, 'agtScheApply', scheApply);
                    },
                  },
                });
                checkbox.$mount();
                container.append(checkbox.$el);
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '사용여부',
              dataField: 'useFl',
              alignment: 'center',
              width: '100',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.useFl === e.value).label;
              },
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
        kpiScheStdGrid: {
          keyExpr: 'id',
          refName: 'kpiScheStdGrid',
          allowColumnResizing: true,
          columnResizingMode: 'nextColumn',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          height: '350', // 주석처리시 100%
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            saving: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '기준추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 90,
                  height: 30,
                  onClick() {
                    vm.onAddStd();
                  },
                },
                sortIndex: 10,
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onStdDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'id',
              dataField: 'id',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              allowHeaderFiltering: true,
            },
            {
              caption: '구분',
              dataField: 'productionTypeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
            },
            {
              caption: '평가기준명',
              dataField: 'productionName',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: 'IB(%)',
              dataField: 'inCntWeight',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: 'OB(%)',
              dataField: 'outCntWeight',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              multiHeaderNm: '가중치',
              columns: [
                {
                  caption: 'IB(%)',
                  dataField: 'inTimeWeight',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                  calculateDisplayCellValue: rowData => {
                    // FIXME : 하드코딩, EWM U_CODE 하드코딩
                    if (rowData.productionTypeCd === 1274) {
                      return `${rowData.inCntWeight} %`;
                    } else {
                      return `${rowData.inTimeWeight} %`;
                    }
                  },
                },
                {
                  caption: 'OB(%)',
                  dataField: 'outTimeWeight',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                  calculateDisplayCellValue: rowData => {
                    // FIXME : 하드코딩, EWM U_CODE 하드코딩
                    if (rowData.productionTypeCd === 1274) {
                      return `${rowData.outCntWeight} %`;
                    } else {
                      return `${rowData.outTimeWeight} %`;
                    }
                  },
                },
              ],
            },
            {
              caption: '평가방식',
              dataField: 'evaluationMethod',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '배점',
              dataField: 'productionScore',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
          ],
        },
        kpiScheTargetGrid: {
          keyExpr: 'id',
          refName: 'kpiScheTargetGrid',
          allowColumnResizing: true,
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          width: '500', // 주석처리시 100%
          height: 'calc(100vh - 660px)', // 주석처리시 100%
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            cellHoverChanged: true,
            rowClick: true,
            saving: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '상담사추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 100,
                  height: 30,
                  onClick() {
                    vm.onAddTarget();
                  },
                },
                sortIndex: 10,
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onTargetDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '부서명',
              dataField: 'deptNmPath',
              alignment: 'left',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
            },
            {
              caption: '상담사',
              dataField: 'agtId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateSortValue: data => `${data.agtNm} [${data.agtId}]`,
              calculateDisplayCellValue: rowData => {
                return `${rowData.agtNm} [${rowData.agtId}]`;
              },
            },
            {
              caption: '상담사명',
              dataField: 'agtNm',
              alignment: 'center',
              visible: false,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
          ],
        },
        isSelectionStopped: true,
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      /** @description : 상담사 셀 호버 이벤트  */
      onCellHoverChanged(e) {
        const event = e.event;
        if (event.buttons === 1) {
          if (this.isSelectionStopped) {
            this.isSelectionStopped = false;
            this.selectedRange = {};
          }

          if (this.selectedRange.startRowIndex === undefined) {
            this.selectedRange.startRowIndex = e.rowIndex;
          }

          if (!this.selectedRange.startColumnIndex) {
            this.selectedRange.startColumnIndex = e.columnIndex;
          }

          this.selectedRange.endRowIndex = e.rowIndex;
          this.selectedRange.endColumnIndex = e.columnIndex;

          let start = Math.min(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);
          let end = Math.max(this.selectedRange.startRowIndex, this.selectedRange.endRowIndex);

          let indexes = [];
          for (let i = start; i <= end; i++) {
            indexes.push(i);
          }
          e.component.selectRowsByIndexes(indexes);
        } else {
          this.isSelectionStopped = true;
        }
      },
      /** @description : 상담사 클릭 이벤트  */
      onRowClick(e) {
        let keys = e.component.getSelectedRowKeys();
        let index = keys.indexOf(e.key);

        if (index > -1) {
          keys.splice(index, 1);
        } else {
          keys.push(e.key);
        }

        e.component.selectRows(keys);
      },
      /** @description : 1그리드 데이터 검색 메서드 */
      async selectDataList() {
        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        this.$refs.kpiScheGrid.getInstance.selectRows([], false);
        this.kpiScheStdGrid.dataSource = [];
        this.modal.stdSelectedIdList = [];
        this.kpiScheTargetGrid.dataSource = [];
        this.modal.agent.targetSelectedIdList = [];

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_LIST',
          data: {
            frScheYmd: vm.searchType.customTypes.frScheYmd,
            toScheYmd: vm.searchType.customTypes.toScheYmd,
          },
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.kpiScheGrid.dataSource = res.data.data;
        }
      },
      /** @description : 1그리드 데이터 복사 메서드 */
      async onScheCopy() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DUPLICATE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = selectedRowsData.map(x => x.id);

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_DUPLICATE',
          data: sendData,
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onAddSche() {
        this.onOpenModal(
          'ModalEwmAdd',
          {
            title: '생산성평가계획 추가',
            buttons: {
              save: { text: '저장' },
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '400',
          },
          {
            columns: this.kpiScheGrid.columns,
          },
        );
      },
      onUpdateData(data) {
        this.onOpenModal(
          'ModalEwmAdd',
          {
            title: '생산성평가계획 수정',
            buttons: {
              save: { text: '수정' },
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '400',
          },
          {
            columns: this.kpiScheGrid.columns,
            formData: {
              id: data.id,
              ymd: data.scheYmd,
              name: data.scheNm,
              check: data.agtScheApply === 'Y' ? true : false,
            },
          },
        );
      },
      /** @description : 1그리드 데이터 저장 메서드 */
      async onScheSave(e) {
        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_SAVE',
          data: { data: e },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 1그리드 데이터 삭제 메서드 */
      async onScheDeleteData() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (selectedRowsData.findIndex(f => f.useFl === this.$_enums.common.stringUsedFlag.YES.value) > -1) {
          this.$_Msg(`사용중인 계획이 있습니다.`);
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = selectedRowsData.map(x => x.id);

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_DELETE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          await this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 1그리드 row 선택 메서드 */
      async onScheRowClick(row) {
        let scheId = typeof row == 'number' ? row : row.data.id;
        this.$refs.kpiScheGrid.getInstance.selectRows([scheId], false);

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_DETAIL_LIST',
          data: {
            scheId: scheId,
          },
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.kpiScheStdGrid.dataSource = res.data.data[0].scheStds;
          this.modal.stdSelectedIdList = res.data.data[0].scheStds.map(item => item.kpiProStdId);
          this.kpiScheTargetGrid.dataSource = res.data.data[0].scheTargets;
          this.modal.agent.targetSelectedIdList = res.data.data[0].scheTargets.map(item => item.agtId);
        }
      },
      /** @description : 2그리드 추가 메서드 */
      onAddStd() {
        const selectedRowsData = this.$refs.kpiScheGrid.selectedRowsData;

        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        } else if (selectedRowsData.length > 1) {
          this.$_Msg('계획 대상을 1개만 선택해 주세요');
          return;
        }

        if (selectedRowsData[0].useFl === 'Y') {
          this.$_Msg(`사용중입니다.`);
          return;
        }

        this.onOpenModal(
          'ModalKpiScheStd',
          {
            title: '생산성평가기준',
            buttons: {
              save: { text: '선택' },
              cancel: { text: '닫기' },
            },
            width: '80%',
            height: '600',
          },
          //this.modal.stdSelectedIdList,
          this.kpiScheStdGrid.dataSource.map(m => m.kpiProStdId),
        );
      },
      onOpenModal(componentNm, componentInitData, data) {
        this.modal.currentComponent = componentNm;
        this.modal.initData = componentInitData;
        this.modal.contentData = data;
        this.isOpenModal(true);
      },
      /** @description: 팝업 오픈 체크 메서드 */
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description: 강의 row 추가 */
      onConfirmModal() {
        if (
          this.modal.currentComponent === 'ModalEwmAdd' &&
          (this.modal.contentData.columns || this.modal.contentData.ymd == null || this.modal.contentData.name == null)
        ) {
          return;
        }

        if (this.modal.currentComponent === 'ModalKpiScheStd' && this.modal.contentData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (this.modal.currentComponent === 'ModalKpiScheStd') {
          let obejctArray = [];

          this.modal.contentData.forEach(i => {
            if (!this.modal.stdSelectedIdList.includes(i.id)) {
              obejctArray.push(i);
            }
          });
          obejctArray.forEach((obj, index) => {
            this.$refs.kpiScheStdGrid.getInstance.addRow();
          });

          obejctArray.forEach((obj, index) => {
            Object.entries(obj).forEach(([key, value]) => {
              this.$refs.kpiScheStdGrid.getInstance.cellValue(index, key, value);
            });
          });

          this.modal.stdSelectedIdList = this.modal.contentData.map(item => item.id);
        } else {
          let data = [];
          data.push({
            id: this.modal.contentData.id,
            scheNm: this.modal.contentData.name,
            scheYmd: this.modal.contentData.ymd,
            agtScheApply: this.modal.contentData.check ? 'Y' : 'N',
            useFl: this.$_enums.common.stringUsedFlag.NO.value,
          });
          this.onScheSave(data);
        }

        this.isOpenModal(false);
      },
      /** @description : 2그리드 저장 메서드 */
      async onStandardSave(e) {
        e.cancel = true;

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        let data = [];
        const selectedRowsData = this.$refs.kpiScheGrid.selectedRowsData;

        if (e.changes.length) {
          if (e.changes && e.changes.length > 0) {
            e.changes.forEach(d => {
              data.push({
                id: null,
                scheId: selectedRowsData[0].id,
                kpiProStdId: d.data.id,
                delFl: this.$_enums.common.stringUsedFlag.NO.value,
              });
            });
          }
        }

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_STD_SAVE',
          data: { dataStd: data },
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          await this.onScheRowClick(selectedRowsData[0].id);
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 2그리드 삭제 메서드 */
      async onStdDeleteData() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();
        const selectedRowsDataStd = this.$refs.kpiScheStdGrid.getInstance.getSelectedRowsData();

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (selectedRowsData[0].useFl === 'Y') {
          this.$_Msg(`사용중입니다.`);
          return;
        }

        if (!selectedRowsDataStd?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = selectedRowsDataStd.map(x => x.id);

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_STD_DELETE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          this.onScheRowClick(selectedRowsData[0].id);
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 3그리드 추가 메서드 */
      onAddTarget() {
        const selectedRowsData = this.$refs.kpiScheGrid.selectedRowsData;

        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        } else if (selectedRowsData.length > 1) {
          this.$_Msg('계획 대상을 1개만 선택해 주세요');
          return;
        }

        if (selectedRowsData[0].useFl === 'Y') {
          this.$_Msg(`사용중입니다.`);
          return;
        }

        this.modal.agent.popupVisible = true;
        this.modal.agent.selectedIdList = this.kpiScheTargetGrid.dataSource.map(m => m.agtId);
      },
      /** @description : 대상자 닫기 */
      onClose() {
        this.modal.agent.popupVisible = false;
        this.modal.agent.selectedIdList = [];
      },
      /** @description : 3그리드 row 추가 */
      onSave(value) {
        if (!value.length) {
          return;
        }

        let obejctArray = [];

        value.forEach(i => {
          if (!this.modal.agent.targetSelectedIdList.includes(i.agtid)) {
            i.agtId = i.agtid;
            obejctArray.push(i);
          }
        });

        obejctArray.forEach((obj, index) => {
          this.$refs.kpiScheTargetGrid.getInstance.addRow();
        });

        obejctArray.forEach((obj, index) => {
          Object.entries(obj).forEach(([key, value]) => {
            this.$refs.kpiScheTargetGrid.getInstance.cellValue(index, key, value);
          });
        });

        this.modal.agent.targetSelectedIdList = value.map(item => item.agtid);

        this.onClose();
      },
      /** @description : 3그리드 저장 메서드 */
      async onTargetSave(e) {
        e.cancel = true;

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        let data = [];
        const selectedRowsData = this.$refs.kpiScheGrid.selectedRowsData;

        if (e.changes.length) {
          if (e.changes && e.changes.length > 0) {
            e.changes.forEach(d => {
              console.log(d);
              data.push({
                id: null,
                scheId: selectedRowsData[0].id,
                agtId: d.data.agtId,
                delFl: this.$_enums.common.stringUsedFlag.NO.value,
              });
            });
          }
        }

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_TARGET_SAVE',
          data: { dataTarget: data },
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          await this.onScheRowClick(selectedRowsData[0].id);
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 3그리드 삭제 메서드 */
      async onTargetDeleteData() {
        const selectedRowsData = this.$refs.kpiScheGrid.getInstance.getSelectedRowsData();
        const selectedRowsDataTarget = this.$refs.kpiScheTargetGrid.getInstance.getSelectedRowsData();

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (selectedRowsData[0].useFl === 'Y') {
          this.$_Msg(`사용중입니다.`);
          return;
        }

        if (!selectedRowsDataTarget?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = selectedRowsDataTarget.map(x => x.id);

        const payload = {
          actionName: 'EWM_PRODUCTIVITY_SCHEDULE_TARGET_DELETE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          this.onScheRowClick(selectedRowsData[0].id);
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onDayStartChanged(e) {
        this.searchType.customTypes.frScheYmd = e.value.slice(0, 6) + '01';
      },
      onDayEndChanged(e) {
        this.searchType.customTypes.toScheYmd = e.value.slice(0, 6) + '29';
      },
      /** @description : 코드 로드 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_production_type');
      },
    },
    async mounted() {
      vm = this;

      await this.initCodeMap().then(() => {
        this.codes.productionTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_production_type'], 2);

        this.$refs.kpiScheStdGrid.getInstance.columnOption('productionTypeCd', 'lookup', this.codes.productionTypeCd);
      });
      this.selectDataList();
    },
  };
</script>
