import EUCCiscoDevice from '@/pages/euc/device/cisco/index.vue';
import EUCCiscoDeviceDetail from '@/pages/euc/device/cisco/detail.vue';
import EUCCiscoSuperCopy from '@/pages/euc/device/cisco/supercopy.vue';

export default function CiscoRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/cisco',
            name: 'EUCCiscoDevice',
            components: { default: EUCCiscoDevice, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/device/cisco/detail',
            name: 'EUCCiscoDeviceDetail',
            components: { default: EUCCiscoDeviceDetail, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/device/cisco/supercopy',
            name: 'EUCCiscoSuperCopy',
            components: { default: EUCCiscoSuperCopy, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}