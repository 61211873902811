<!--
  PACKAGE_NAME : src\pages\op\ivr\dnis\list.vue
  FILE_NAME : list
  AUTHOR : hmlee
  DATE : 2024-05-22
  DESCRIPTION : 대표번호 관리 리스트
-->
<template>
  <div class="w100 h100">
    <div class="main">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="handleInitNewRow"/>
    </div>

    <!-- 공지사항 설정 -->
    <modal-notice
      v-if="modal.notice.visible"
      :is-open="modal.notice.visible"
      :notice-data="modal.notice.data"
      @saveModal="handleSaveModal('notice')"
      @closeModal="handleCloseModal('notice')"
    />

    <!-- 인입그룹 설정 -->
    <modal-ibg
      v-if="modal.ibg.visible"
      :is-open="modal.ibg.visible"
      :ibg-data="modal.ibg.data"
      @saveModal="handleSaveModal('ibg')"
      @closeModal="handleCloseModal('ibg')"
    />

    <!-- 환경설정 설정 -->
    <modal-setting
      v-if="modal.setting.visible"
      :is-open="modal.setting.visible"
      :setting-data="modal.setting.data"
      @saveModal="handleSaveModal('setting')"
      @closeModal="handleCloseModal('setting')"
    />
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { formatDate, isSuccess } from '@/utils/common-lib';
import ModalNotice from './modal-notice.vue';
import ModalIbg from './modal-ibg.vue';
import ModalSetting from './modal-setting.vue';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
import CustomStore from "devextreme/data/custom_store";
import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
import { mountComponent } from "@/utils/devextreme-util";

export default {
  name: 'CCDNISList',
  components: {
    EspDxDataGrid,
    ModalNotice,
    ModalIbg,
    ModalSetting,
    DxButton,
  },
  props: {},
  watch: {},
  data() {
    return {
      config: {
        ivrSvctpList: [], // 서비스코드 템플릿 리스트
        scheduleList: [], // 운영스케줄 리스트
      },
      modal: {
        notice: {
          visible: false,
          data: {},
        },
        ibg: {
          visible: false,
          data: {},
        },
        setting: {
          visible: false,
          data: {},
        },
      },
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'ivrDnisGrid',
        dataSource: [], //dataSource
        dataSourceDefaultSortColumn: '+ivrDnisOrd',
        showColumnLines: true,
        apiActionNm: {
          update: 'CC_IVR_DNIS_MERGE',
          delete: 'CC_IVR_DNIS_DELETE',
        },
        showActionButtons: {
          select: true,
        },
        customEvent: {
          initNewRow: true,
        },
        loadPanel: { // 로딩바 설정
          enabled: false, // 로딩바 표시 유무
        },
        columns: [
          {
            caption: '사이트',
            i18n: 'COMMON.WORD.SITE',
            dataField: 'siteId',
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: 'siteNm',
              valueExpr: 'id',
            },
            requiredRule: {},
          },
          {
            caption: '센터',
            i18n: 'COMMON.WORD.TENANT',
            dataField: 'tenantId',
            lookup: {
              dataSource: this.$store.getters.getTenantList,
              displayExpr: 'tenantNm',
              valueExpr: 'id',
            },
            requiredRule: {},
          },
          {
            caption: 'DNIS',
            dataField: 'dnis',
            requiredRule: {},
          },
          {
            caption: 'DNIS명',
            i18n: 'CC.WORD.DNIS_TITLE',
            dataField: 'dnisNm',
            requiredRule: {},
          },
          {
            caption: '운영스케줄',
            i18n: 'CC.WORD.OP_SCHEDULE',
            dataField: 'scheduleId',
            lookup: {
              dataSource: [],
              displayExpr: 'scheduleNm',
              valueExpr: 'id',
            },
          },
          {
            caption: '서비스코드',
            i18n: 'CC.WORD.SERVICECODE',
            dataField: 'svctpId',
            lookup: {
              dataSource: [],
              displayExpr: 'svctpNm',
              valueExpr: 'id',
            },
          },
          {
            caption: '공지사항',
            i18n: 'CC.WORD.NOTICE',
            dataField: 'noticeNm',
            allowEditing: false,
            cellTemplate: (container, options) => {
              // 새로 추가된 행인 경우 버튼 비활성화
              if (options.row.isNewRow || !options.data.id) {  // ID가 없거나 새로운 행인 경우
                return false;
              }

              if (options.value) {
                const aTag = document.createElement('a');
                aTag.innerText = options.value;
                aTag.addEventListener('click', () => {
                  this.handleOpenModal('notice', options.data); //공지사항 설정 팝업 오픈
                });
                container.append(aTag);
              } else {
                mountComponent(
                  container,
                  EspDxButton,
                  {
                    i18n: 'COMPONENTS.SETTING',
                    color: 'white',
                    mode: 'outlined',
                    width: '45',
                    height: '25',
                    value: options.data.id
                  },
                  {
                    handleClick: () => this.handleOpenModal('notice', options.data)
                  },
                  this // Vue 인스턴스 컨텍스트 전달
                );
              }
            },
          },
          {
            caption: '인입그룹',
            i18n: 'CC.WORD.IBG',
            dataField: 'dnisIbgs',
            allowEditing: false,
            allowFiltering: false,
            calculateCellValue: (rowData) => {
              if (!rowData.dnisIbgs) return 0;
              return rowData.dnisIbgs.length;
            },
            cellTemplate: (container, options) => {
              // 새로 추가된 행인 경우 버튼 비활성화
              if (options.row.isNewRow || !options.data.id) {  // ID가 없거나 새로운 행인 경우
                return false;
              }

              if( options.value > 0 ){
                const aTag = document.createElement('a');
                aTag.innerText = `${options.value}개`;
                aTag.addEventListener('click', () => {
                  this.handleOpenModal('ibg', options.data); //인입그룹 설정 팝업 오픈
                });

                container.append(aTag);
              }else {
                mountComponent(
                  container,
                  EspDxButton,
                  {
                    i18n: 'COMPONENTS.SETTING',
                    color: 'white',
                    mode: 'outlined',
                    width: '45',
                    height: '25',
                    value: options.data.id
                  },
                  {
                    handleClick: () => this.handleOpenModal('ibg', options.data)
                  },
                  this // Vue 인스턴스 컨텍스트 전달
                );
              }
            },
          },
          {
            caption: '환경설정',
            i18n: 'CC.WORD.CONFIG_SETTING',
            dataField: 'dnisSettings',
            allowEditing: false,
            allowFiltering: false,
            calculateCellValue: (rowData) => {
              if (!rowData.dnisSettings) return 0;
              return rowData.dnisSettings.length;
            },
            cellTemplate: (container, options) => {
              // 새로 추가된 행인 경우 버튼 비활성화
              if (options.row.isNewRow || !options.data.id) {  // ID가 없거나 새로운 행인 경우
                return false;
              }

              if( options.value > 0 ){
                //링크 클릭시 팝업
                const aTag = document.createElement('a');
                aTag.innerText = `${options.value}개`;
                aTag.addEventListener('click', () => {
                  this.handleOpenModal('setting', options.data); //환경설정 설정 팝업 오픈
                });

                container.append(aTag);
              }else {
                mountComponent(
                  container,
                  EspDxButton,
                  {
                    i18n: 'COMPONENTS.SETTING',
                    color: 'white',
                    mode: 'outlined',
                    width: '45',
                    height: '25',
                    value: options.data.id
                  },
                  {
                    handleClick: () => this.handleOpenModal('setting', options.data)
                  },
                  this // Vue 인스턴스 컨텍스트 전달
                );
              }
            },
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'ivrDnisOrd',
            visible: false,
          },
          {
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
            dataField: 'editId',
            visible: false,
          },
          {
            caption: '수정일시',
            i18n: 'COMPONENTS.MODIFY_DATE_TIME',
            dataField: 'editDt',
            visible: false,
            allowEditing: false,
            calculateCellValue: this.formatEditDt,
          },
          {
            caption: '등록자',
            i18n: 'COMPONENTS.REGISTRANT',
            dataField: 'regId',
            visible: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
            dataField: 'regDt',
            visible: false,
            allowEditing: false,
            calculateCellValue: this.formatRegDt,
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    /** @description: 팝업 열기
     * @param settingType 설정 타입(notice, ibg, setting)
     * @param settingData 모달로 넘길 설정 데이터 */
    handleOpenModal(settingType, settingData) {
      this.modal[settingType].visible = true;
      this.modal[settingType].data = settingData;
    },
    /**
     * @description : 팝업 저장
     * @param settingType : 설정 타입(notice, ibg, setting)
     */
    async handleSaveModal(settingType) {
      this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

      if (settingType === 'ibg') {
        await this.selectDnisIbgList();
      }

      await this.$refs[this.dataGrid.refName].refreshData();

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /**
     * @description : 팝업 닫기
     * @param settingType : 설정 타입(notice, ibg, setting)
     */
    async handleCloseModal(settingType) {
      if (settingType === 'setting') {
        await this.selectDnisSettingList();
      }

      await this.$refs[this.dataGrid.refName].refreshData();

      this.modal[settingType].visible = false;
      this.modal[settingType].data = {};
    },
    /** @description: 그리드 행 추가시 초기 설정 */
    handleInitNewRow(e) {
      e.data.viewFl = 'Y';
      e.data.ivrDnisOrd = this.$refs[this.dataGrid.refName].getItems.length + 1;
    },
    /** @description: 대표번호 리스트 조회 */
    async selectDataList(sort = '+ivrDnisOrd') {
      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        load: async(loadOptions) => {
          const params = this.$_getDxDataGridParam(loadOptions);

          if (!params.sort) {
            params.sort = sort;
          }

          const payload = {
            actionName: 'CC_IVR_DNIS_LIST',
            data: params,
          };

          const res = await this.CALL_CC_API(payload);
          const rtnData = {
            data: [],
            totalCount: 0,
          };
          if(isSuccess(res)) {
            let dnisList = res.data.data;
            dnisList.forEach(dnis => {
              const dnisIbgs = this.dnisIbgList.filter(dnisIbg => dnisIbg.dnisId === dnis.id);
              const dnisSettings = this.dnisSettingList.filter(dnisSetting => dnisSetting.dnisId === dnis.id);
              if( dnisIbgs.length > 0 ) { //DNIS 인입그룹
                dnis.dnisIbgs = dnisIbgs;
              }
              if( dnisSettings.length > 0 ) { //DNIS 환경설정
                dnis.dnisSettings = dnisSettings;
              }
            });

            rtnData.data = dnisList;
            rtnData.totalCount = res.data.header.totalCount,

            this.$refs[this.dataGrid.refName].totalCount = rtnData.totalCount;
          }
          return rtnData;
        },
      });
    },
    /** @description: 운영스케줄 리스트 가져오기 */
    async selectScheduleList() {
      const payload = {
        actionName: 'CC_SCHEDULE_LIST',
        data: { sort: '+id' },
      };

      const res = await this.CALL_CC_API(payload);
      let rstData = [];
      if (isSuccess(res)) {
        rstData = res.data.data;
      }
      return rstData;
    },
    /** @description: 서비스코드 템플릿 리스트 가져오기 */
    async selectIvrSvctpList() {
      const payload = {
        actionName: 'CC_IVR_SVC_TP_LIST',
        data: { sort: '+svctpOrd', viewFl: 'Y' },
      };

      const res = await this.CALL_CC_API(payload);
      let rstData = [];
      if (isSuccess(res)) {
        rstData = res.data.data[0].content;
      }
      return rstData;
    },
    /** @description : DNIS 인입그룹 조회 메서드 */
    async selectDnisIbgList() {
      const payload = {
        actionName: 'CC_IVR_DNIS_IBG_LIST',
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.dnisIbgList = res.data.data;
      }
    },
    /** @description : DNIS 환경설정 조회 메서드 */
    async selectDnisSettingList() {
      const payload = {
        actionName: 'CC_IVR_DNIS_SETTING_LIST',
      };

      const res = await this.CALL_CC_API(payload);
      if (isSuccess(res)) {
        this.dnisSettingList = res.data.data;
      }
    },
    /** @description: 등록일시 데이터 출력 */
    formatRegDt(rowData) {
      if (rowData?.regDt) {
        return formatDate(rowData?.regDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    /** @description: 수정일시 데이터 출력 */
    formatEditDt(rowData) {
      if (rowData?.editDt) {
        return formatDate(rowData?.editDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    /** @description: 데이터 초기화 */
    async initData() {
      this.dataGrid.columns[4].lookup.dataSource = await this.selectScheduleList();
      this.dataGrid.columns[5].lookup.dataSource = await this.selectIvrSvctpList();
      await this.selectDnisIbgList();
      await this.selectDnisSettingList();
      await this.selectDataList();
    }
  },
  async created() {
    this.initData();
  },
};
</script>

