import EWMPerformSchedule from '@/pages/ewm/kpi/implementation/perform-sche/index.vue';
import EWMPerformStandard from '@/pages/ewm/kpi/implementation/perform-std/index.vue';
import EWMMetricsResult from '@/pages/ewm/kpi/report/metrics-result/index.vue';
import EWMAddScore from '@/pages/ewm/kpi/setting/addscore/index.vue';
import EWMAddScoreTarget from '@/pages/ewm/kpi/setting/addscore-target/index.vue';
import EWMPerformStandardSetting from '@/pages/ewm/kpi/setting/perform-std/index.vue';

export default function KpiRoutes(commonComponents) {
  return [
    {
      path: '/ewm/kpi/implementation/perform-sche',
      name: 'EWMPerformSchedule',
      components: { default: EWMPerformSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/implementation/perform-std',
      name: 'EWMPerformStandard',
      components: { default: EWMPerformStandard, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/report/metrics-result',
      name: 'EWMMetricsResult',
      components: { default: EWMMetricsResult, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/setting/addscore',
      name: 'EWMAddScore',
      components: { default: EWMAddScore, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/setting/addscore-target',
      name: 'EWMAddScoreTarget',
      components: { default: EWMAddScoreTarget, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/kpi/setting/perform-std',
      name: 'EWMPerformStandardSetting',
      components: { default: EWMPerformStandardSetting, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}