<!--
  PACKAGE_NAME : src\pages\report\wizard\list.vue
  FILE_NAME : list
  AUTHOR : kwmoon
  DATE : 2024-04-22
  DESCRIPTION : 보고서 위자드 리스트 페이지
-->
<template>
  <div>
    <div class="page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
      </div>
    </div>
    <DxPopup :isOpen="state.isOpenXmlModal" :option="xmlModalOption" @saveModal="saveXmlModal" @closeModal="toggleXmlModal(false)">
      <template #content>
        <XmlEditor ref="xmlEditor" :isOn="state.isOpenXmlModal" :options="editorOption" />
      </template>
    </DxPopup>
  </div>
</template>

<script>
  import DxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import XmlEditor from '@/pages/report/config/modal-xml-editor-create';
  import { downloadBlobFile, formatDate, getResData, isEmpty, isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    name: 'ReportWizardReportList',
    components: {
      EspDxDataGrid,
      DxPopup,
      XmlEditor,
    },
    props: {},
    watch: {},
    data() {
      const vm = this;
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        state: {
          isOpenXmlModal: false,
          currentComponent: null,
        },
        xmlModalOption: {
          title: 'XML 보고서 등록',
          width: '80%',
          height: '90%',
          minWidth: null,
          minHeight: null,
        },
        editorOption: {
          useHeader: true,
          useRight: true,
          rsWidth: '30%', // 우측 섹션 넓이
        },
        reportMenu: {
          depth2: [],
          depth3: [],
        },
        dataGrid: {
          refName: 'wizardMenuGrid',
          keyExpr: 'id',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          repaintChangesOnly: false,
          dataSourceDefaultSortColumn: '+id',
          dataSource: [],
          //height:'calc(100vh - 350px)',    // 주석처리시 100%
          customEvent: {
            saving: false,
            reorder: false,
          },
          showActionButtons: {
            select: false,
            copy: false,
            delete: false,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '보고서 추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 100,
                  height: 30,
                  onClick: () => vm.routeDetailPage(),
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => vm.handleClickDeleteBtn(),
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: 'XML',
                  elementAttr: { class: 'btn_XS white light_filled add2' },
                  width: 60,
                  height: 30,
                  onClick: () => vm.toggleXmlModal(),
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: 'XML',
                  elementAttr: { class: 'btn_XS white light_filled download' },
                  width: 60,
                  height: 30,
                  onClick: () => vm.handleClickXMLDownloadBtn(),
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling:{ // 미사용시 주석처리
          //     mode: 'infinite' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10, //page.size,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            changes: [], //null값으로 두면 에러남
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '솔루션',
              dataField: 'solution',
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // asc desc none
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true, // 컬럼 fix 시 사용
              calculateCellValue: rowData => rowData?.solution?.toUpperCase(),
            },
            {
              caption: '구분',
              dataField: 'subPath',
              alignment: 'center', // left center right
              visible: true,
              allowEditing: false,
              sortOrder: 'none', // asc desc none
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true, // 컬럼 fix 시 사용
              calculateCellValue: rowData => rowData?.subPath?.toUpperCase(),
            },
            {
              caption: '소메뉴',
              dataField: 'parentMenuId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true,
              lookup: {
                dataSource: [],
                displayExpr: 'menuNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '보고서명',
              dataField: 'menuNm',
              alignment: 'left',
              width: '25%',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true,
              cellTemplate: (container, data) => {
                const html = document.createElement('a');
                html.innerText = data.value;
                html.addEventListener('click', e => {
                  e.preventDefault();
                  vm.routeDetailPage(data?.data?.id);
                });
                container.append(html);
              },
            },
            {
              caption: '메뉴 노출',
              dataField: 'viewFl',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true,
              lookup: {
                dataSource: this.$_enums.common.stringViewFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '최근 수정자',
              dataField: 'editId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true,
            },
            {
              caption: '최근 수정일시',
              dataField: 'editDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true,
              calculateCellValue: rowData => formatDate(rowData?.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss'),
            },
            {
              caption: '등록자',
              dataField: 'regId',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true,
            },
            {
              caption: '등록일시',
              dataField: 'regDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: true,
              allowGrouping: false,
              fixed: true,
              calculateCellValue: rowData => formatDate(rowData?.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss'),
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 위자드 생성 페이지로 이동 */
      routeDetailPage(id) {
        if (isEmpty(id)) {
          this.$router.push(`/report/config/wizard/detail`);
          return;
        }
        this.$router.push(`/report/config/wizard/detail/${id}`);
      },
      /** @description: 위자드 보고서 페이지 데이터 조회 */
      async selectDataList(sort = '-regDt') {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);
            if (!params.sort) params.sort = sort;

            // DB에 소문자로 저장되기 때문에 소문자로 변환해서 셋팅
            if (params.solution) params.solution = params.solution.toLowerCase();
            if (params.subPath) params.subPath = params.subPath.toLowerCase();
            if (params.viewFl === undefined) params.viewFl = 'Y';
            if (params.delFl === undefined) params.delFl = 'N';

            const res = await vm.CALL_REPORT_API({
              actionName: 'REPORT_WIZARD_MENU_LIST',
              data: { ...params },
              loading: false,
            });

            if (isSuccess(res)) {
              const result = getResData(res).map(item => {
                const targetMenu = vm.reportMenu.depth3.find(menu => menu.id === item.menuId);
                const parentId = targetMenu?.parentId ?? null;
                return { ...item, parentMenuId: parentId };
              });

              const gridData = {
                data: result,
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.wizardMenuGrid.totalCount = gridData.totalCount;
              return gridData;
            }
          },
          totalCount: data => {
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /** 화면 인입 데이터 세팅 영역 */
      async createdData() {
        // 보고서 메뉴
        this.reportMenu.depth3 = this.getReportMenu();
        // (소메뉴) 보고서 메뉴를 포함하는 상위 메뉴
        this.reportMenu.depth2 = this.getMidMenuIncludeReportMenu(this.reportMenu.depth3);
        // 소메뉴 셀렉트박스 데이터 셋팅
        this.dataGrid.columns[2].lookup.dataSource = this.reportMenu.depth2;
        // 데이터 조회
        this.selectDataList();
      },
      /** XML 보고서 등록 기능 보고서 조회 시 사용하는 XML 편집 기능 */
      toggleXmlModal(toggle = true) {
        this.state.currentComponent = toggle ? 'XmlEditor' : null;
        this.state.isOpenXmlModal = toggle;
      },
      validateForm(datas) {
        if (datas) {
          const errorTypes = ['', null, undefined];
          const errorDatas = Object.values(datas).filter(v => errorTypes.includes(v));
          return errorDatas.length > 0;
        }
        return true;
      },
      /** 보고서 삭제 */
      async handleClickDeleteBtn() {
        const selectedItems = this.$refs[this.dataGrid.refName].selectedRowsData;
        if (selectedItems.length === 0) {
          return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        }

        if (
          await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', { defaultValue: '해당 보고서를 삭제하시겠습니까?' }))
        ) {
          const itemKeys = selectedItems.map(item => item.id);
          const payload = {
            actionName: 'REPORT_WIZARD_MENU_DELETE',
            data: { itemKeys },
            loading: true,
            useErrorPopup: true,
          };

          const res = await this.CALL_REPORT_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다.' }));
            this.$refs[this.dataGrid.refName].refreshData();
          }
        }
      },
      /** XML 다운로드 */
      async handleClickXMLDownloadBtn() {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_WIZARD_MENU_DOWNLOAD',
          responseType: 'arraybuffer',
          loading: true,
          data: {},
        });

        if (res.status === 200 && res.data) {
          downloadBlobFile(res);
          return;
        }

        this.$_Msg('다운로드 실패');
      },
      async saveXmlModal() {
        const params = this.$refs.xmlEditor.getCreateParam();
        if (this.validateForm(params)) {
          return this.$_Msg('필수 값을 확인해주시기 바랍니다.');
        }

        params.isValidationPassed = false;
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_WIZARD_MENU_ADD_XML',
          data: params,
          loading: true,
          useErrorPopup: false,
        });

        const { resCode, resMsg } = res.data.header;
        if (isSuccess(res) && (await this.$_Msg('보고서가 등록되었습니다.'))) {
          this.refreshGrid();
          this.toggleXmlModal(false);
        } else if (resCode !== 'XML012') {
          this.$_Msg(resMsg);
        } else if (resCode === 'XML012' && (await this.$_Confirm(`${resMsg} 강제로 저장시키겠습니까?`))) {
          const forceRes = await this.CALL_REPORT_API({
            actionName: 'REPORT_XML_INSERT',
            data: params,
            loading: true,
          });
          if (isSuccess(forceRes) && (await this.$_Msg('보고서가 등록되었습니다.'))) {
            this.refreshGrid();
            this.toggleXmlModal(false);
          }
        }
      },
      refreshGrid() {
        this.$refs.wizardMenuGrid.getInstance.refresh();
      },
      /** 보고서 메뉴를 포함하는 중메뉴 조회 */
      getMidMenuIncludeReportMenu(reportMenuList) {
        const parentIdSet = new Set(reportMenuList.map(item => item.parentId));
        return this.$store.getters.getMenuList.filter(menu => parentIdSet.has(menu.id));
      },
      /** 보고서 메뉴 정보 조회 */
      getReportMenu() {
        return this.$store.getters.getMenuList.filter(menu => menu.menuTypeCd === 'REPORT');
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
  };
</script>

<style scoped></style>
