import ESPAlarmConfig from '@/pages/esp/system/alarm/config.vue';
import ESPCodeList from '@/pages/esp/system/code/list.vue';
import ESPHolidayList from '@/pages/esp/system/holiday/list.vue';
import ESPMenuList from '@/pages/esp/system/menu/list.vue';
import ESPAllowedIPList from '@/pages/esp/system/security/allowed-ip.vue';
import ESPServerRoutingList from '@/pages/esp/system/server/routing.vue';
import ESPSystemSettingList from '@/pages/esp/system/setting/list.vue';
import ESPSystemSettingOperationList from '@/pages/esp/system/setting/operation.vue';
import ESPSiteList from '@/pages/esp/system/site/list.vue';
import ESPTenantList from '@/pages/esp/system/tenant/list.vue';
import ESPThemeConfig from '@/pages/esp/system/theme/config.vue';

export default function SystemRoutes(commonComponents) {
  return [
    {
      path: '/esp/system/alarm/config',
      name: 'ESPAlarmConfig',
      components: { default: ESPAlarmConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/code/list',
      name: 'ESPCodeList',
      components: { default: ESPCodeList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/holiday/list',
      name: 'ESPHolidayList',
      components: { default: ESPHolidayList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/menu/list',
      name: 'ESPMenuList',
      components: { default: ESPMenuList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/security/allowed-ip',
      name: 'ESPAllowedIPList',
      components: { default: ESPAllowedIPList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/server/routing',
      name: 'ESPServerRoutingList',
      components: { default: ESPServerRoutingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/setting/list',
      name: 'ESPSystemSettingList',
      components: { default: ESPSystemSettingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/setting/operation',
      name: 'ESPSystemSettingOperationList',
      components: { default: ESPSystemSettingOperationList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/site/list',
      name: 'ESPSiteList',
      components: { default: ESPSiteList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/tenant/list',
      name: 'ESPTenantList',
      components: { default: ESPTenantList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/system/theme/config',
      name: 'ESPThemeConfig',
      components: { default: ESPThemeConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
