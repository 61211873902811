<!--
  PACKAGE_NAME : src\pages\esp\system\server\routing.vue
  FILE_NAME : routing
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 라우팅 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @init-new-row="setInitNewRow" />
    <routing-host :is-open="popup.visible" @closeModal="handleClosePopup" />
  </div>
</template>

<script>
  import { isSuccess } from '@/utils/common-lib';
  import ArrayStore from 'devextreme/data/array_store';
  import RoutingHost from '@/pages/esp/system/server/host.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    name: 'ESPServerRoutingList',
    components: { EspDxDataGrid, RoutingHost },
    props: {},
    data() {
      return {
        popup: {
          visible: false,
        },
        dataGrid: {
          keyExpr: 'id', // 그리드 키값
          refName: 'refRoutingDataGrid', // 그리드 컴포넌트 참조명
          dataSource: [], // 그리드 데이터
          apiActionNm: {
            select: 'ROUTING_ACTIONS_LIST',
            update: 'ROUTING_ACTIONS_MERGE',
            delete: 'ROUTING_ACTIONS_DELETE',
          },
          showActionButtons: {
            excel: true, // 엑셀 다운로드 버튼
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  text: '호스트 추가',
                  elementAttr: { class: 'btn_XS default filled add1' },
                  width: 100,
                  height: 30,
                  onClick: this.handleOpenPopup,
                },
                location: 'before',
              },
            ], // 커스텀 버튼
          },
          headerFilter: {
            visible: true, // 헤더 필터 표시 여부
          },
          editing: {
            selectTextOnEditStart: false, // 편집 시작시 텍스트 선택 여부
          },
          columns: [
            {
              caption: '호스트 별칭',
              i18n: 'COMPONENTS.ROUTE_HOST_ALIAS',
              dataField: 'alias',
              allowHeaderFiltering: false,
              width: 120,
              lookup: {
                dataSource: [],
                displayExpr: 'label',
                valueExpr: 'value',
              },
              requiredRule: {},
            },
            {
              caption: '액션명',
              i18n: 'COMPONENTS.ROUTE_ACTION_NAME',
              dataField: 'actionNm',
              alignment: 'left',
              allowHeaderFiltering: false,
              validationRules: [
                {
                  type: 'required',
                  message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                    value: this.$_lang('COMPONENTS.ROUTE_ACTION_NAME', '액션명'),
                    defaultValue: '[액션명] 은/는 필수값 입니다.',
                  }),
                },
                {
                  type: 'custom',
                  validationCallback: e => {
                    console.log(e);

                    // esp 라우팅 별칭 외에는 액션명이 별칭으로 시작하지 않으면 안됨
                    const upperAlias = e.data.alias.toUpperCase();
                    if (upperAlias !== 'ESP' && !e.value.startsWith(upperAlias + "_")) {
                      e.rule.message = this.$_lang('COMMON.MESSAGE.ENTER_ROUTE_ALIAS_VALID', {
                        defaultValue: '\'별칭_\' 형식으로 입력해주세요. (\'esp\' 별칭 제외)',
                      });
                      return false;
                    }
                    return true;
                  },
                  message: this.$_lang('COMMON.MESSAGE.VALIDATION_ERROR', { defaultValue: '유효성 검사 오류.' }),
                },
              ],
            },
            {
              caption: 'METHOD',
              dataField: 'method',
              width: 120,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: [{ method: 'GET' }, { method: 'POST' }, { method: 'PUT' }, { method: 'DELETE' }],
                displayExpr: 'method',
                valueExpr: 'method',
              },
              requiredRule: {},
            },
            {
              caption: 'PATH',
              dataField: 'path',
              alignment: 'left',
              allowHeaderFiltering: false,
              requiredRule: {},
            },
            {
              i18n: 'COMPONENTS.ROUTE_TIMEOUT_MS',
              caption: '타임아웃(ms)',
              width: 120,
              dataField: 'timeout',
              alignment: 'right',
              allowHeaderFiltering: false,
              requiredRule: {},
            },
            {
              i18n: 'COMPONENTS.DESCRIPTION',
              caption: '설명',
              dataField: 'description',
              alignment: 'left',
              allowHeaderFiltering: false,
            },
            {
              i18n: 'COMPONENTS.ROUTE_WORK_LOG_FL',
              caption: '작업이력 저장 여부',
              dataField: 'workLogFl',
              width: 150,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ], // 컬럼 정보
        },
      };
    },
    computed: {},
    methods: {
      /**
       * 라우팅 별칭 목록 조회
       * @returns {Promise<void>}
       */
      async setRoutingAliases() {
        const payload = {
          actionName: 'ROUTING_ALIAS_LIST',
          loading: false,
          useErrorPopup: true,
        };
        const res = await this.CALL_API(payload);
        let aliases = [];
        if (isSuccess(res)) {
          res.data.data.forEach(item => {
            aliases.push({
              label: item.alias,
              value: item.alias,
            });
          });
        } else {
          aliases = [];
        }

        this.dataGrid.columns[0].lookup.dataSource = new ArrayStore({
          data: aliases,
          key: 'value',
        });
      },
      /**
       * 신규 행 추가 이벤트
       * @param e
       */
      setInitNewRow(e) {
        e.data.alias = 'esp';
        e.data.method = 'GET';
        e.data.path = '/';
        e.data.timeout = 5000;
        e.data.workLogFl = 'N';
      },
      /**
       * 팝업 닫기
       */
      handleClosePopup() {
        this.popup.visible = false;
        this.$refs[this.dataGrid.refName].getInstance.refresh();
      },
      /**
       * 팝업 열기
       */
      handleOpenPopup() {
        this.popup.visible = true;
      },
    },
    created() {},
    async mounted() {
      await this.setRoutingAliases();
    },
  };
</script>

<style lang="scss" scoped></style>
