<!--
  PACKAGE_NAME : src\pages\esp\history\excel-history-detail.vue
  FILE_NAME : excel-history-detail
  AUTHOR : sskim
  DATE : 2023-05-04
  DESCRIPTION : 보고서 이력 -> 엑셀 다운로드 팝업 컴포넌트
-->
y<template>
	<div id="excel-history-detail">
		<DxDataGrid
			:data-source="contentData"
			:allow-column-resizing="true"
			:allow-column-reordering="true"
			:show-borders="false"
			:show-column-headers="true"
			:show-column-lines="false"
			:no-data-text="noDataText(contentData.length)"
			:selected-row-keys="grid.selectedItemKeys"
			:width="grid.gridWidth"
			:height="grid.gridHeight"
			:visible="true"
		>
			<DxColumn
				caption="사용자"
				data-field="loginIdNmfmt"
				:width="200"
				:height="40"
				:fixed="true"
				:allow-editing="false"
				alignment="center"
				:visible="true"
			>
			</DxColumn>
			<DxColumn
				caption="다운로드 일시"
				data-field="downloadTmfmt"
				template="dateTemplate"
				data-type="string"
				:fixed="false"
				:width="400"
				alignment="center"
				:visible="true"
			>
			</DxColumn>
			<DxScrolling mode="virtual" />
		</DxDataGrid>
	</div>
</template>

<script>
import { DxDataGrid, DxColumn, DxScrolling } from 'devextreme-vue/data-grid';

export default {
  name: 'ESPExcelHistoryDetail',
	components: {
		DxDataGrid,
		DxColumn,
		DxScrolling,
	},
	props: {
		contentData: Array,
	},
	data() {
		return {
			grid: {
				gridWidth: 610,
				gridHeight: 400,
			},
		};
	},
	computed: {},
	methods: {
		createdData() {
			this.contentData.forEach(item => {
				item.loginIdNmfmt = this.formantIdNm(item);
				item.downloadTmfmt = this.formatDate(item.downloadTm);
			});
		},
		noDataText() {
			return `해당 데이터가 없습니다.`;
		},
		formantIdNm(rowData) {
			return `${rowData.loginNm} (${rowData.loginId})`;
		},

		formatDate(date) {
			return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
		},
	},
	beforeCreate() {},
	created() {},
	beforeMount() {},
	mounted() {
		this.createdData();
	},
	beforeUpdate() {},
	updated() {},
	beforeDestroy() {},
	destroyed() {},
};
</script>

<style>
div#excel-history-detail tr[role='presentation'] {
	display: none !important;
}
</style>
