import ESPDownloadFile from '@/pages/esp/history/download-file.vue';
import ESPExcelLookupHistory from '@/pages/esp/history/excel-lookup-history.vue';
import ESPLoginHistory from '@/pages/esp/history/login.vue';
import ESPWorkHistory from '@/pages/esp/history/work.vue';
import ESPExcelHistoryDetail from '@/pages/esp/history/excel-history-detail.vue';
import ESPExcelHistoryTargetDetail from "@/pages/esp/history/excel-history-target-detail.vue";

export default function HistoryRoutes(commonComponents) {
  return [
    {
      path: '/esp/history/download-file',
      name: 'ESPDownloadFile',
      components: { default: ESPDownloadFile, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/excel-lookup-history',
      name: 'ESPExcelLookupHistory',
      components: { default: ESPExcelLookupHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/login',
      name: 'ESPLoginHistory',
      components: { default: ESPLoginHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/work',
      name: 'ESPWorkHistory',
      components: { default: ESPWorkHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/excel-history-detail',
      name: 'ESPExcelHistoryDetail',
      components: { default: ESPExcelHistoryDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/esp/history/excel-history-target-detail',
      name: 'ESPExcelHistoryTargetDetail',
      components: { default: ESPExcelHistoryTargetDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
