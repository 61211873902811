<template>
	<div>
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px">
					<DxValidationGroup ref="searchValidationGroup">
						<div class="flex space-x-2 inner">
							<div class="mt-2">
								기준년월
							</div>
							<DxDateBox
								:edit-enabled="false"
								:styling-mode="stylingMode"
								width="100"
								height="30"
								v-model="searchType.customTypes.dayStart"
								type="date"
								display-format="yyyy-MM"
								dateSerializationFormat="yyyyMMdd"
								dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
								:max="searchType.customTypes.dayEnd"
								maxLength="7"
								invalid-date-message="입력 데이터가 유효하지 않습니다."
								:calendar-options="{
									zoomLevel: 'year',
									minZoomLevel: 'year',
									maxZoomLevel: 'year',
								}"
								@value-changed="onDayStartChanged"
							>
								<DxValidator>
									<DxRequiredRule message="기준년월은 필수입니다." />
								</DxValidator>
							</DxDateBox>
							<div class="mt-1">
								~
							</div>
							<DxDateBox
								:styling-mode="stylingMode"
								width="100"
								height="30"
								v-model="searchType.customTypes.dayEnd"
								type="date"
								display-format="yyyy-MM"
								dateSerializationFormat="yyyyMMdd"
								dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
								maxLength="7"
								:min="searchType.customTypes.dayStart"
								invalid-date-message="입력 데이터가 유효하지 않습니다."
								:calendar-options="{
									zoomLevel: 'year',
									minZoomLevel: 'year',
									maxZoomLevel: 'year',
								}"
								@value-changed="onDayEndChanged"
							>
								<DxValidator>
									<DxRequiredRule message="기준년월은 필수입니다." />
								</DxValidator>
							</DxDateBox>
							<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectExamScheData" />
						</div>
					</DxValidationGroup>
				</div>
			</div>

			<!-- Main Layer -->
			<div class="flex gap-x-6 items-stretch">
				<!-- 1차 -->
				<div class="w-1/3">
					<div class="mt-4">
						<DxDataGrid
							key-expr="id"
							ref="scheMenu"
							:show-borders="false"
							:data-source="sche.scheGrid"
							:no-data-text="this.$_lang('CMN_NO_DATA')"
							height="calc(100vh - 350px)"
							:selected-row-keys="sche.scheSelectedRowKeys"
							@selection-changed="selectionChangedData"
						>
							<DxFilterRow :visible="true" />
							<DxSelection mode="single" />
							<DxColumn caption="시험계획명" data-field="scheNm" alignment="center" />
							<DxColumn
								caption="진행상태"
								data-field="examProcess"
								alignment="center"
								:width="120"
								:calculate-sort-value="data => calculateSortValue(data, 'examProcess')"
							>
								<DxLookup :data-source="codes.examProcess.dataSource" value-expr="value" display-expr="label" />
							</DxColumn>
						</DxDataGrid>
					</div>
				</div>
				<!-- 1차 -->

				<!-- 2차 -->
				<div class="w-2/3 flex flex-col gap-y-6 divide-y">
					<div>
						<h3 class="text-xl font-medium my-4">시험 정보</h3>
						<table class="table_form line-bin min-w-max max-w-screen-xl">
							<colgroup>
								<col style="width: 100px" />
								<col style="width: 300px" />
								<col style="width: 120px" />
								<col style="width: auto" />
							</colgroup>
							<tbody>
								<tr>
									<th scope="row">
										<label for="label01">시험계획:</label>
									</th>
									<td>
										{{ formData.scheNm }}
									</td>
									<th scope="row">
										<label for="label01">진행상태:</label>
									</th>
									<td>
										{{ formData.examProcessNm }}
									</td>
								</tr>

								<tr>
									<th scope="row">
										<label for="label01">시험일시:</label>
									</th>
									<td>
										<div v-if="formData.examStartDt">
											{{ formatDate(formData.examStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD') }}
											&nbsp;{{ formatTime(formData.examStartTime) }} ~ {{ formatTime(formData.examEndTime) }}
										</div>
									</td>
									<th scope="row">
										<label for="label01">이의제기 기간:</label>
									</th>
									<td>
										<div v-if="formData.examFeedbackStartDt">
											{{
												formatDate(
													formData.examFeedbackStartDt,
													'YYYY-MM-DDTHH:mm:ss',
													'YYYY-MM-DD',
												)
											}}
											~
											{{
												formatDate(formData.examFeedbackEndDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')
											}}
										</div>
									</td>
								</tr>

								<tr>
									<th scope="row">
										<label for="label01">시험지명:</label>
									</th>
									<td colspan="3">
										<div class="flex">
											<DxTextBox
												v-model="formData.examTypeNm"
												:styling-mode="textStylingMode"
												:read-only="true"
												width="120"
												class="mar_ri10"
											>
											</DxTextBox>
											<DxTextBox
												v-model="formData.examNm"
												:styling-mode="textStylingMode"
												:read-only="true"
												width="150"
												class="mar_ri10"
											>
											</DxTextBox>
											<DxButton class="btn_XS default filled" text="시험지보기" :height="20" @click="onShowSheet" />
										</div>
									</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div>
						<h3 class="text-xl font-medium absolute mt-3">대상자 정보</h3>
						<div class="mt-4">
							<div class="flex justify-end">
								<DxCheckBox
									class="checkbox"
									text="피드백만 보기"
									:value="this.checkBoxValue"
									@value-changed="onCheckFeedback"
									@initialized="setDefaultCheck"
								/>
							</div>
							<div class="mt-4">
								<DxDataGrid
									key-expr="scheId"
									:show-borders="false"
									:data-source="sche.targetGrid"
									:no-data-text="this.$_lang('CMN_NO_DATA')"
									height="calc(100vh - 600px)"
								>
									<DxFilterRow :visible="true" />
									<DxColumn caption="부서정보" data-field="deptNmPath" alignment="center" :allowFiltering="false">
									</DxColumn>
									<DxColumn
										caption="시험대상자"
										data-field="agtNm"
										cell-template="targetTemplate"
										alignment="center"
										:allowFiltering="false"
									/>
									<DxColumn
										caption="시험점수"
										data-field="totalScore"
										alignment="center"
										:width="120"
										:allowFiltering="false"
										cell-template="scoreTemplate"
									/>
									<DxColumn caption="진행상태" data-field="processCd" alignment="center" :width="120">
										<DxLookup :data-source="this.columnDataSource.processCd" value-expr="id" display-expr="codeNm" />
									</DxColumn>
									<DxColumn
										caption="시험지"
										data-field="feedback"
										cell-template="feedbackTemplate"
										alignment="center"
										:width="100"
										:allowFiltering="false"
									/>

									<template #targetTemplate="{ data }">
										{{ data.row.data.agtNm }}
										[{{ data.row.data.agtId }}]
									</template>
									<template #scoreTemplate="{ data }">
										<div v-if="data.row.data.totalScore">
											{{ data.row.data.totalScore }}
										</div>
										<div v-else>
											{{ '-' }}
										</div>
									</template>
									<template #feedbackTemplate="{ data }">
										<div v-if="data.row.data.processCd !== 'N' && formData.examProcessNm !== '시험진행'">
											<DxButton class="btn_XS white filled" text="시험지" @click="onClickFeedback(data.row.data)" />
										</div>
										<div v-else>
											{{ '-' }}
										</div>
									</template>
								</DxDataGrid>
							</div>
						</div>
					</div>
				</div>
				<!-- 2차 -->
			</div>
			<!-- /Main Layer -->
		</div>
	</div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxDataGrid, DxColumn, DxSelection, DxFilterRow, DxLookup } from 'devextreme-vue/data-grid';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import {cloneObj, formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';

let vm = this;

export default {
  name: 'EWMExamStatus',
	components: {
		DxDateBox,
		DxButton,
		DxTextBox,
		DxCheckBox,
		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxLookup,

		DxValidator,
		DxRequiredRule,
		DxValidationGroup,
	},
	props: {},
	watch: {},
	data() {
		return {
			codes: {
				examProcess: {
					dataSource: [],
					displayExpr: 'label',
					valueExpr: 'value',
				},
			},
			textStylingMode: 'filled',
			stylingMode: 'outlined',
			checkBoxValue: null,
			searchType: {
				customTypes: {
					dayStart: getPastFromToday(11, 'months'),
					dayEnd: getPastFromToday(0, 'days'),
				},
				paramsData: null,
			},
			columnDataSource: {
				examProcessCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				processCd: [
					{ id: 'N', codeNm: '미참석' },
					{ id: 'Y', codeNm: '시험완료' },
					{ id: 'S', codeNm: '채점완료' },
					{ id: 'F', codeNm: '피드백완료' },
					{ id: 'E', codeNm: '마감완료' },
				],
			},
			formData: {},
			sche: {
				scheGrid: [],
				targetGrid: [],
				originTargetGrid: [], //원본
				scheSelectedRowKeys: [],
			},
		};
	},
	computed: {
		searchValidationGroup: function() {
			return this.$refs['searchValidationGroup'].instance;
		},
		ewmCodes() {
			return this.$_enums.ewm;
		},
	},
	methods: {
    formatDate,
		/** @description : 소트설정 */
		calculateSortValue(data, clsCd) {
			if (clsCd == 'examProcess') {
				return this.codes.examProcess.dataSource.find(e => data.examProcess == e.value).label;
			}
		},
		/** @description : 시험지 미리보기 */
		onShowSheet() {
			if (this.formData.sheetId) {
				let params = { data: this.formData, loc: 'status', select: this.sche.scheSelectedRowKeys };
				this.$store.commit('setDetailParams', params);
				// this.$router.push('/ewm/exam/implementation/exam-status/sheet-preview');
				this.$router.push({ path: '/ewm/exam/implementation/exam-schedule/exam-sheet' });
			} else {
				this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
			}
		},
		/** @description : 대상자 시험지 열람 */
		onClickFeedback(data) {
			if (this.formData.examProcessNm == '채점진행') {
				let params = { sheetId: this.formData, targetData: data, select: this.sche.scheSelectedRowKeys };
				this.$store.commit('setDetailParams', params);
				this.$router.push('/ewm/exam/implementation/exam-status/sheet-manager');
			} else {
				let params = { sheetId: this.formData, targetData: data, select: this.sche.scheSelectedRowKeys };
				this.$store.commit('setDetailParams', params);
				this.$router.push('/ewm/exam/implementation/exam-status/sheet-target');
			}
		},
		/** @description : 피드백 확인 */
		onCheckFeedback(e) {
			this.checkBoxValue = e.value;
			if (e.value) {
				this.sche.targetGrid = this.sche.targetGrid.filter(d => d.feedbackCnt > 0 && d.sheetId !== null);
			} else {
				this.sche.targetGrid = this.sche.originTargetGrid;
			}
		},
		/** @description: 체크박스 디폴트값 true로 세팅*/
		setDefaultCheck() {
			this.checkBoxValue = false;
		},
		/** @description : 날짜 검색 */
		onChangeSearchDay() {
			const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
			this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

			this.$_setSearchHistsCustomType(this.searchType.paramsData);
		},
		/** @description : 날짜 형식 변환 메서드 */
		formatDt(rowData) {
			if (rowData?.surDt) {
				return formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
			}
		},
		formatTime(time) {
			return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
		},
		/** @description : 데이터 검색 메서드 */
		async selectExamScheData() {
			if (!vm.searchValidationGroup.validate().isValid) {
				return;
			}
			let params = {
				frScheYmd: vm.searchType.customTypes.dayStart,
				toScheYmd: vm.searchType.customTypes.dayEnd,
			};
			let payload = {
				actionName: 'EWM_EXAM_STATUS_SCHEDULE_LIST',
				data: params,
				loading: false,
			};
			let res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.sche.scheGrid = res.data.data;
				let selected = this.reqParams.select ? this.reqParams.select[0] : res.data.data[0].id;
				this.sche.scheSelectedRowKeys = [selected];
			}
		},
		/** @description : 로우 클릭 이벤트 */
		selectionChangedData(e) {
			this.sche.scheSelectedRowKeys = e.selectedRowKeys;
			let params = {
				scheId: e.selectedRowKeys,
			};
			this.setDefaultCheck();
			this.selectDataList(params);
		},
		async selectDataList(params) {
			let payload1 = {
				actionName: 'EWM_EXAM_STATUS_INFO_LIST',
				data: params,
				loading: false,
			};
			let res1 = await this.CALL_EWM_API(payload1);
			if (isSuccess(res1)) {
				this.formData = cloneObj(res1.data.data[0]);
				this.formData.examProcessNm = this.$_enums.ewm.examProcess.values.find(c => c.value === this.formData.examProcess).label;
				this.formData.examTypeNm = this.$_enums.ewm.examSheetType.values.find(c => c.value === this.formData.examSheetType).label;
			}
			let payload2 = {
				actionName: 'EWM_EXAM_STATUS_TARGET_LIST',
				data: params,
				loading: false,
			};
			let res2 = await this.CALL_EWM_API(payload2);
			if (isSuccess(res2)) {
				this.sche.targetGrid = res2.data.data;
				this.sche.originTargetGrid = res2.data.data;
				this.sche.targetGrid.forEach(d => {
					if (d.logScore > 0) {
						d.processCd = 'Y'; //시험완료
					} else {
						d.processCd = 'N'; //미참석
					}
					if (d.totalScore > 0) {
						d.processCd = 'S'; //채점완료
					}
					if (d.feedbackScore > 0) {
						d.processCd = 'F'; //피드백완료
					}
					if (this.formData.examProcessNm == '마감' && d.totalScore > 0) {
						d.processCd = 'E'; //마감완료
					}
				});
			}
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.dayStart = changed;
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.dayEnd = changed;
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_edu_exam_process,root_edu_exam_type');
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			vm = this;

			this.reqParams = this.$store.getters.getDetailParams;

			this.initCodeMap().then(() => {
				this.columnDataSource.examProcessCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_edu_exam_process'], 2);

				this.codes.examProcess.dataSource = this.$_enums.ewm.examProcess.values;
				//this.$refs.sheetGrid.getInstance.columnOption('examProcess', 'lookup', this.codes.examProcess);
			});
		},
		/** @description : 라이프사이클 mounted시 호출되는 메서드 */
		mountData() {
			this.searchType.paramsData = null;
			this.selectExamScheData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountData();
	},
};
</script>

<style lang="scss" scoped>
.page_search_box .inner div {
	display: inline-block;
}
.page_search_box .inner > div:not(.box-btn-search) {
	vertical-align: middle;
	padding-right: 10px;
}
.page_search_box .spec-type-box {
	display: inline-block;
}
.page_search_box #searchTooltip {
  padding-right: 5px;
	font-size: 22px;
	color: #808080;
}
</style>
