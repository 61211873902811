import ESPUserList from '@/pages/esp/hr/user/list.vue';

export default function HRRoutes(commonComponents) {
  return [
    {
      path: '/esp/hr/user/list',
      name: 'ESPUserList',
      components: { default: ESPUserList, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
