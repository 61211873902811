<template>
	<div class="main">
		<div class="locker_setting_list sub_new_style01 sub_ui_box1">
			<div class="page_search_box border0 mar_b10">
				<div class="inner clearfix">
					<span class="fl text-type01">조회기간</span>
					<div class="ui-datepicker period fl mar_ri10">
						<div class="ui-datepicker-item">
							<DxDateBox
									:edit-enabled=false
									:styling-mode="stylingMode"
									:width="120"
									v-model="dayStart"
									type="date"
									display-format="yyyy.MM.dd"
									dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
									:height="34"
                  dateSerializationFormat="yyyyMMdd"
							>
							</DxDateBox>
						</div>
						<div class="dash">
							~
						</div>
						<div class="ui-datepicker-item">
							<DxDateBox
									:styling-mode="stylingMode"
									:width="120"
									v-model="dayEnd"
									type="date" display-format="yyyy.MM.dd"
									dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
									:height="34"
                  dateSerializationFormat="yyyyMMdd"
							>

							</DxDateBox>
						</div>
					</div>
					<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="34" @click="onSearch" />
				</div>
			</div>
		</div>
		<InfopushChart ref="chartRef" :search-data="searchData" :day-start="dayStart" :day-end="dayEnd" />
	</div>
</template>

<script>
import Search from '@/components/common/search.vue';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxDateBox } from 'devextreme-vue/date-box';
import DxButton from 'devextreme-vue/button';
import { getPastFromToday } from '@/utils/common-lib';
import InfopushChart from "@/pages/euc/info-push/history/infopush-chart.vue";

let vm = this;

export default {
  name: 'EUCInfoPushHistoryChart',
	components: {
		DxSelectBox,
		DxDateBox,
		Search,
		DxButton,
		InfopushChart
	},
	computed: {},
	data() {
		return {
			stylingMode: 'outlined', //outlined, underlined, filled
			dayStart: getPastFromToday(30, 'days'),
			dayEnd: getPastFromToday(0, 'days'),
			dataSource: null,
			resultList: [],
			searchData: {}
		};
	},
	methods: {
		formatDt(date) {
			if(date) {
				return this.formatDate(date, 'YYYYMMDD', 'YYYY-MM-DD');
			}
		},
		onSearch(){
			this.$refs.chartRef.searchData = this.searchData;
			this.$refs.chartRef.selectDataList();
		},
		mountedData() {
			vm = this;
		},
		createdData() {
			this.searchData = {
				chartMode: 'DYNAMIC',
				dateFormat: 'YYYY-MM-DD',
				stylingMode: this.stylingMode
			}
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
