import EWMExamGroupReport from '@/pages/ewm/exam/exam-report/group-report/index.vue';
import EWMExamQuestionReport from '@/pages/ewm/exam/exam-report/question-report/index.vue';
import EWMExamScoreReport from '@/pages/ewm/exam/exam-report/score-report/index.vue';
import EWMExamScheduleConfig from '@/pages/ewm/exam/implementation/exam-schedule/config.vue';
import EWMExamScheduleExamSheet from '@/pages/ewm/exam/implementation/exam-schedule/exam-sheet.vue';
import EWMExamScheduleList from '@/pages/ewm/exam/implementation/exam-schedule/list.vue';
import EWMExamStatus from '@/pages/ewm/exam/implementation/exam-status/index.vue';
import EWMExamStatusSheetManager from '@/pages/ewm/exam/implementation/exam-status/sheet-manager.vue';
import EWMExamStatusSheetTarget from '@/pages/ewm/exam/implementation/exam-status/sheet-target.vue';
import EWMExamQuestion from '@/pages/ewm/exam/question/index.vue';
import EWMExamSheetSettingConfig from '@/pages/ewm/exam/setting/sheet/config.vue';
import EWMExamSheetSettingList from '@/pages/ewm/exam/setting/sheet/list.vue';
import EWMExamSheetSettingPreview from '@/pages/ewm/exam/setting/sheet/preview.vue';

export default function ExamRoutes(commonComponents) {
  return [
    {
      path: '/ewm/exam/exam-report/group-report',
      name: 'EWMExamGroupReport',
      components: { default: EWMExamGroupReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/exam-report/question-report',
      name: 'EWMExamQuestionReport',
      components: { default: EWMExamQuestionReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/exam-report/score-report',
      name: 'EWMExamScoreReport',
      components: { default: EWMExamScoreReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-schedule/config',
      name: 'EWMExamScheduleConfig',
      components: { default: EWMExamScheduleConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-schedule/exam-sheet',
      name: 'EWMExamScheduleExamSheet',
      components: { default: EWMExamScheduleExamSheet, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-schedule/list',
      name: 'EWMExamScheduleList',
      components: { default: EWMExamScheduleList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-status',
      name: 'EWMExamStatus',
      components: { default: EWMExamStatus, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-status/sheet-manager',
      name: 'EWMExamStatusSheetManager',
      components: { default: EWMExamStatusSheetManager, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/implementation/exam-status/sheet-target',
      name: 'EWMExamStatusSheetTarget',
      components: { default: EWMExamStatusSheetTarget, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/question',
      name: 'EWMExamQuestion',
      components: { default: EWMExamQuestion, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/setting/sheet/config',
      name: 'EWMExamSheetSettingConfig',
      components: { default: EWMExamSheetSettingConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/setting/sheet/list',
      name: 'EWMExamSheetSettingList',
      components: { default: EWMExamSheetSettingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/exam/setting/sheet/preview',
      name: 'EWMExamSheetSettingPreview',
      components: { default: EWMExamSheetSettingPreview, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}