import EWMApprovalIntegrationIndex from '@/pages/ewm/approval/integration/index.vue';
import EWMApprovalProcessIndex from '@/pages/ewm/approval/process/index.vue';

export default function ApprovalRoutes(commonComponents) {
  return [
    {
      path: '/ewm/approval/integration/index',
      name: 'EWMApprovalIntegrationIndex',
      components: { default: EWMApprovalIntegrationIndex, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/approval/process/index',
      name: 'EWMApprovalProcessIndex',
      components: { default: EWMApprovalProcessIndex, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
