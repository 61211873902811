<!--
  PACKAGE_NAME : src/pages/setting/history/excel-lookup-history.vue
  FILE_NAME : excel-lookup-history
  AUTHOR : sskim
  DATE : 2023-05-04
  DESCRIPTION : 보고서 이력
-->
<template>
  <div>
    <div class="page-sub-box">
      <div class="page_search_box line_bottom_1px">
        <div class="inner alL">
          <date-range-box ref="dateRangeBox" label="검색 일자" :start-dt="dayStart" :end-dt="dayEnd" />
          <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
        </div>
      </div>
      <esp-dx-data-grid :dataGrid="dataGrid" :ref="dataGrid.refName" />
    </div>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"> </component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>

    <DxPopup
      :show-title="true"
      :title="targetModal.initData ? targetModal.initData.title : null"
      :min-width="targetModal.initData ? targetModal.initData.width : null"
      :width="targetModal.initData ? targetModal.initData.width : null"
      :min-height="targetModal.initData ? targetModal.initData.height : null"
      :height="targetModal.initData ? targetModal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      :visible="targetModal.isOpened"
      @hiding="isOpenTargetModal(false)"
    >
      <template #content>
        <div>
          <component
            :is="targetModal.currentComponent"
            :contentData="targetModal.contentData"
            :contentHeader="targetModal.contentHeader"
            :totalCount="targetModal.totalCount"
            v-model="targetModal.contentData"
          >
          </component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          targetModal.initData.hasOwnProperty('buttons')
            ? targetModal.initData.buttons.hasOwnProperty('cancel')
              ? targetModal.initData.buttons.hasOwnProperty('cancel')
              : !targetModal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: targetModal.initData.hasOwnProperty('buttons')
            ? targetModal.initData.buttons.hasOwnProperty('cancel')
              ? targetModal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenTargetModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxCheckBox } from 'devextreme-vue/check-box';

  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { DxSelection, DxFilterRow, DxScrolling, DxColumn } from 'devextreme-vue/data-grid';
  import Search from '@/components/common/search.vue';
  import { isSuccess, downloadBlobFile, getResData, getPastFromToday, isEmpty } from '@/utils/common-lib';
  import ExcelHistoryDetail from '@/pages/esp/history/excel-history-detail.vue';
  import ExcelHistoryTargetDetail from '@/pages/esp/history/excel-history-target-detail.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxTextBox } from 'devextreme-vue/text-box';

  import moment from 'moment/moment';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';

  export default {
    name: 'ESPExcelLookupHistory',
    components: {
      DateRangeBox,
      EspDxDataGrid,
      DxDateBox,
      DxPopup,
      DxToolbarItem,
      DxDropDownBox,
      DxSelection,
      DxFilterRow,
      DxScrolling,
      DxColumn,
      DxCheckBox,
      Search,
      ExcelHistoryDetail,
      ExcelHistoryTargetDetail,
      DxSelectBox,
      DxTextBox,
      DxButton,
    },
    props: {},
    watch: {},
    data() {
      return {
        menu: {
          depth1: [],
          depth2: [],
          depth3: [],
        },
        downloadStatus: 0,
        downloadPercentage: 0,
        modal: {
          isOpened: false,
          currentComponent: 'null',
          initData: {
            name: 'viewContent',
            title: '다운로드 전체 이력',
            buttons: {
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '550',
          },
          contentData: null,
        },
        targetModal: {
          isOpened: false,
          currentComponent: 'null',
          initData: {
            name: 'viewContent',
            title: '보고서 조회 대상',
            buttons: {
              cancel: { text: '닫기' },
            },
            width: '650',
            height: '570',
          },
          contentHeader: null,
          contentData: null,
          totalCount: 0,
        },
        dayStart: getPastFromToday(7, 'days'),
        dayEnd: moment().format('YYYYMMDD'),
        dataGrid: {
          refName: 'reportHistGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: true, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          dataSource: [],
          showActionButtons: {
            excel: true,
            delete: false,
            customButtons: [],
          },
          export: {
            title: '보고서 이력',
          },
          remoteOperations: {
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              visible: false,
            },
            {
              caption: '대메뉴',
              dataField: 'menu1',
              allowFiltering: false,
              calculateCellValue: row => {
                const menu = this.getMenuById(row.parentMenuId);
                const parentMenu = this.getMenuById(menu.parentId);
                return parentMenu?.menuNm;
              },
            },
            {
              caption: '중메뉴',
              dataField: 'parentMenuId',
              allowFiltering: false,
              calculateCellValue: row => this.getMenuById(row.parentMenuId)?.menuNm,
            },
            {
              caption: '소메뉴',
              dataField: 'reportMenuNm',
              allowSorting: true,
              calculateCellValue: row => this.getMenuById(row.menuId)?.menuNm,
            },
            {
              caption: '조회기간',
              dataField: 'reportSearchPeriod',
              allowSorting: true,
              allowFiltering: false,
              calculateCellValue: row => this.getReportSearchPeriod(row),
              cellTemplate: (container, option) => {
                const div = document.createElement('div');
                div.className = 'p-1';
                div.innerHTML = this.getReportSearchPeriod(option.data).replaceAll('\n', '<br>');
                container.append(div);
              },
            },
            {
              caption: '조회 대상',
              dataField: 'reportSearchData',
              allowFiltering: false,
              cellTemplate: (container, option) => {
                let tag = document.createElement('span');
                try {
                  //조회대상 값
                  const { reportSearchData } = option.data;
                  const parsedData = JSON.parse(reportSearchData);
                  let parsedValue = parsedData.obj;

                  //조회대상 헤더
                  const objHeaderValue = parsedData.objHeader;
                  if (!parsedValue) {
                    tag.innerHTML = '-';
                  } else if (parsedValue[0] === '') {
                    parsedValue = JSON.parse(parsedData[0].customObj);
                    tag = document.createElement('a');
                    tag.innerText = parsedValue.length;
                    tag.addEventListener('click', () => {
                      this.onOpenTargetPopup(parsedValue, reportSearchData, objHeaderValue);
                    });
                  } else {
                    //체크 대상
                    tag = document.createElement('a');
                    tag.innerText = parsedValue.length;
                    tag.addEventListener('click', () => {
                      this.onOpenTargetPopup(option.data.reportSearchObj, reportSearchData, objHeaderValue);
                    });
                  }
                } catch (e) {
                  tag.innerHTML = '-';
                }
                container.append(tag);
              },
            },
            {
              caption: '보고서 유형',
              dataField: 'reportSearchType',
              allowSorting: false,
              lookup: {
                dataSource: this.$_enums.report.reportType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, option) => {
                const reportTypes = this.$_enums.report.reportType.values;
                const selectedType = reportTypes.find(type => type.value === option.value);

                const div = document.createElement('div');
                div.innerHTML = selectedType?.label ?? '-';
                container.append(div);
              },
            },
            {
              caption: '작업 구분',
              dataField: 'workType',
              allowSorting: false,
              lookup: {
                dataSource: this.$_enums.report.workType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              cellTemplate: (container, option) => {
                const workType = this.$_enums.report.workType.findByValue(option.value);
                if (!workType) {
                  container.append('-');
                  return;
                }

                const div = document.createElement('div');
                div.innerHTML = workType.label;
                container.append(div);
              },
            },
            {
              caption: '상태',
              dataField: 'excelStatus',
              allowSorting: false,
              allowFiltering: false,
              cssClass: 'statusBgColor',
              calculateCellValue: row => {
                const checkValue = row.excelStatus;
                if (checkValue === 'complete' || checkValue === '0') {
                  return '완료';
                }
                return this.downloadPercentage + '%';
              },
            },
            {
              caption: '엑셀 다운로드',
              allowSorting: false,
              allowFiltering: false,
              calculateCellValue: row => {
                const value = row.workType;
                if (this.$_enums.report.workType.SEARCH.equals(value)) {
                  return '-';
                }

                const workType = this.$_enums.report.workType.findByValue(value);
                return isEmpty(workType) ? '-' : '다운로드';
              },
              cellTemplate: (container, option) => {
                const workType = option.data.workType;
                const { SEARCH, TARGET_EXCEL, LARGE_DATA_EXCEL } = this.$_enums.report.workType;

                // 작업구분 별 컨테이너 생성
                if (SEARCH.equals(workType)) {
                  container.append('-');
                  return;
                }

                // 다운로드 버튼이 필요한 작업구분
                let buttonLabel = '기본';
                let buttonWidth = 55;
                if (TARGET_EXCEL.equals(workType)) {
                  buttonLabel = '대상별';
                  buttonWidth = 70;
                } else if (LARGE_DATA_EXCEL.equals(workType)) {
                  buttonLabel = '대용량';
                  buttonWidth = 70;
                }

                // 버튼 추가
                container.append(
                  new DxButton({
                    propsData: {
                      text: buttonLabel,
                      width: buttonWidth,
                      height: 30,
                      elementAttr: { class: 'btn_XS green light_filled excel' },
                      onClick: async () => await this.onClickExcelButton(workType, option),
                    },
                  }).$mount().$el,
                );
              },
            },
            {
              caption: '다운로드 횟수',
              dataField: 'excelDownloadCnt',
              allowSorting: false,
              allowFiltering: false,
              cellTemplate: (container, option) => {
                const workType = option.data.workType;
                if (this.$_enums.report.workType.SEARCH.equals(workType)) {
                  container.append('-');
                  return;
                }

                const value = option.data.excelDownloadCnt;
                const tag = document.createElement('a');
                tag.innerText = isEmpty(value) ? 0 : value;
                tag.addEventListener('click', () => {
                  this.onOpenDownloadCountPopup(option.data.id);
                });
                container.append(tag);
              },
            },
            {
              caption: '사용자ID',
              dataField: 'loginId',
            },
            {
              caption: '사용자명',
              dataField: 'loginNm',
            },
            {
              caption: '등록일시',
              dataField: 'executeTm',
              sortOrder: 'desc',
              allowFiltering: false,
              calculateCellValue: row => this.formatDefaultDate(row.executeTm),
            },
          ],
        },
        weekdayDict: [
          { id: 'mon', alt: '월', value: '2', checked: true },
          { id: 'tue', alt: '화', value: '3', checked: true },
          { id: 'wed', alt: '수', value: '4', checked: true },
          { id: 'thu', alt: '목', value: '5', checked: true },
          { id: 'fri', alt: '금', value: '6', checked: true },
          { id: 'sut', alt: '토', value: '7', checked: true },
          { id: 'sun', alt: '일', value: '1', checked: true },
          { id: 'holi', alt: '공휴일', value: 'Y', checked: true },
        ],
      };
    },
    computed: {
      dataGridInstance() {
        return this.$refs[this.dataGrid.refName].getInstance;
      },
    },
    methods: {
      getMenuById(id) {
        return this.$store.getters.getMenuList.find(menu => menu.id === id);
      },
      formatDefaultDate(date) {
        if (!date) return '-';
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      },
      isOpenModal(data) {
        this.modal.isOpened = data;
        if (!data) {
          this.modal.currentComponent = null;
          this.modal.contentData = [];
        }
      },
      isOpenTargetModal(data) {
        this.targetModal.isOpened = data;
        if (!data) {
          this.targetModal.currentComponent = null;
          this.targetModal.contentHeader = [];
          this.targetModal.contentData = [];
          this.targetModal.totalCount = 0;
        }
      },
      /**
       * 대용량 다운로드
       * @param option
       * @returns {Promise<void>}
       */
      async checkAndDownloadLargeExcelFile(option) {
        if (await this.checkPrepareDownload(option, true)) {
          const payload = {
            actionName: 'REPORT_HISTORY_FILE_DOWNLOAD',
            path: `/${option.data.id}`,
            loading: true,
            responseType: 'arraybuffer',
            onDownloadProgress: progressEvent => {
              this.downloadPercentage = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            },
          };

          let res = await this.CALL_REPORT_API(payload);
          if (res.status == '200') {
            if (!res.data || res.data?.header?.resCode == 'fail') {
              this.$_Msg(`다운로드 실패 \n ${res.data?.header?.resMsg ? `Cause :  ${res.data.header.resMsg}` : ''}`);
            } else {
              await downloadBlobFile(res);
              this.downloadStatus = 1;
              this.onSearch();
            }
          } else {
            this.$_Msg('다운로드 실패');
          }
        }
        this.$_Msg('다운로드 실패');
      },
      /**
       * 작업 구분 별 엑셀 다운로드 실행 이벤트 함수
       * @param workType
       * @param option
       * @returns {Promise<void>}
       */
      async onClickExcelButton(workType, option) {
        if (this.$_enums.report.workType.LARGE_DATA_EXCEL.equals(workType)) {
          await this.checkAndDownloadLargeExcelFile(option); // 대용량
        } else {
          // 대용량 다운로드 외 (일반, 대상별)
          await this.checkAndDownloadExcelFile(option, workType);
        }

        if (this.downloadStatus === 1) {
          this.dataGridInstance.cellValue(option.rowIndex, 'excelDownloadCnt', 'complete');
          this.downloadStatus = 0;
        }
      },
      /** 엑셀 다운로드(엑셀다운: 일반, 기본) */
      async checkAndDownloadExcelFile(option, workType) {
        if (await this.checkPrepareDownload(option, false)) {
          if (this.$_enums.report.workType.DEFAULT_EXCEL.equals(workType)) {
            await this.getDownloadExcelFile(option, 'REPORT_EXCEL_DOWNLOAD');
          } else if (this.$_enums.report.workType.TARGET_EXCEL.equals(workType)) {
            await this.getDownloadExcelFile(option, 'REPORT_EXCEL_TARGET_SHEET_DOWNLOAD');
          }

          return this.onSearch();
        }
        this.$_Msg('다운로드 실패');
      },
      async checkPrepareDownload(option, isLargeData) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_HISTORY_UPDATE_CNT',
          data: { id: option.data.id, isLargeData },
          loading: true,
        });

        return isSuccess(res);
      },
      /**
       * 기본 보고서 다운로드 이벤트 함수
       * @param option
       * @param actionName
       * @returns {Promise<void>}
       */
      async getDownloadExcelFile(option, actionName = 'REPORT_EXCEL_DOWNLOAD') {
        const params = JSON.parse(option.data.reportSearchData);
        params.historyId = option.data.id;
        const res = await this.CALL_REPORT_API({
          actionName: actionName,
          data: { data: params },
          loading: true,
          responseType: 'arraybuffer',
        });

        if (res.status === 200 && res.data) {
          await downloadBlobFile(res);
          this.onSearch();
        } else {
          this.$_Msg('다운로드 실패');
        }
      },

      async onOpenDownloadCountPopup(value) {
        try {
          await this.selectDetailInfo(value);
          this.modal.currentComponent = 'ExcelHistoryDetail';
          this.isOpenModal(true);
        } catch (e) {
          this.$_Msg('다운로드 상세내역 조회에 실패하였습니다.');
          this.isOpenModal(false);
        }
      },

      async onOpenTargetPopup(reportSearchObj, reportSearchData, objHeaderValue) {
        try {
          await this.selectTargetDetail(reportSearchObj, reportSearchData, objHeaderValue);
          this.targetModal.currentComponent = 'ExcelHistoryTargetDetail';
          this.isOpenTargetModal(true);
        } catch (err) {
          console.log(err);
          this.$_Msg('조회 대상 조회에 실패하였습니다.');
          this.isOpenTargetModal(false);
        }
      },

      async selectDetailInfo(value) {
        this.modal.contentData = [];
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_HISTORY_DETAIL',
          path: `/${value}`,
          loading: true,
        });
        if (isSuccess(res)) {
          this.modal.contentData = getResData(res);
        } else {
          throw 'Data Loading Error';
        }
      },

      async selectTargetDetail(reportSearchObj, reportSearchData) {
        this.targetModal.contentHeader = [{ caption: '조회 대상', dataField: 'name', width: 400 }];
        this.targetModal.contentData = [];
        this.targetModal.totalCount = 0;
        // 개별
        if (Array.isArray(reportSearchObj)) {
          let objHeader = null;
          this.targetModal.contentData = reportSearchObj.map((item, index) => {
            let value = [];
            if (objHeader === null) {
              //TODO: 헤더 정상적으로 넘어가는 로직 정리되면 해당 if 지울 예정
              value = Object.values(item);
            } else {
              value = objHeader
                .map((header, i) => {
                  const itemData = item[`item${i + 1}`];
                  if (itemData) {
                    return `${header}: ${itemData}`;
                  }
                  return '';
                })
                .filter(v => v !== '');
            }
            return { key: index, name: value?.join(',   ') };
          });
          this.targetModal.totalCount = reportSearchObj.length;
          return;
        }

        //TODO: 조회대상 상세 이력을 보여주는게 맞을지 고민 필요, 그냥 JSON 형태의 파라미터를 보여주는 게 더 나을 수도 있겠다 샘각
        const checkModeTargetDataList = await this.getCheckModeTargetDataList(reportSearchObj, reportSearchData);
        this.targetModal.contentData = checkModeTargetDataList;
        this.targetModal.totalCount = checkModeTargetDataList.length;
      },
      /**
       * @description 보고서 조회대상 상세 리스트 조회 (체크모드)
       * @param {String} idList 조회대상 ID 리스트
       * @param {String} reportSearchData 조회대상 검색조건
       * @returns {Array} 조회대상 상세 리스트
       * */
      async getCheckModeTargetDataList(idList, reportSearchData) {
        const parsedValue = JSON.parse(reportSearchData);
        const objType = parsedValue.objType;
        const solution = parsedValue.solution;
        const subPath = parsedValue.subPath;
        const queryGroupList = await this.setQueryGroupList(objType, solution, subPath);
        return idList
          .slice(1, -1)
          .split(',')
          .map(item => {
            const result = queryGroupList.find(obj => obj.VALUE.toString() === item.replaceAll('"', ''));
            const name = result ? (result.NAMETREE ? result.NAMETREE.replaceAll('|', ' > ') : result.TEXT) : 'N/A';
            return { key: item, name: name };
          });
      },
      async setQueryGroupList(objType, solution, subPath) {
        const res = await this.getReportObjectList(objType, solution, subPath);
        if (isSuccess(res)) {
          return this.$_commonlib.cloneObj(getResData(res));
        } else {
          return () => {
            throw 'Data Loading Error';
          };
        }
      },
      async getReportObjectList(objType, solution, subPath) {
        return await this.asyncGetMasterQueryResult({
          name: objType,
          solution: solution,
          subPath: subPath,
          loginId: this.$store.getters.getLoginId,
        });
      },
      async asyncGetMasterQueryResult(data, useLoading = true) {
        return this.CALL_REPORT_API({
          actionName: 'REPORT_TARGET_RESULT_LIST',
          data: { data },
          loading: useLoading,
        });
      },
      /** 조회기간 조회 */
      getReportSearchPeriod(row) {
        const date = row.reportSearchPeriod;
        const weekdayList = JSON.parse(row.reportSearchWeek);
        const holi = JSON.parse(row.reportSearchData).holi;
        weekdayList.push(holi);
        const weekdaysArray = weekdayList.reduce((acc, value) => {
          const dayObj = this.weekdayDict.find(item => item.value === value);
          if (dayObj !== undefined) acc.push(dayObj.alt);
          return acc;
        }, []);

        if (date === '-') {
          return '-';
        }

        // date 존재 시
        const startDt = moment(date.split('~')[0]).format('YYYY-MM-DD');
        const endDt = moment(date.split('~')[1]).format('YYYY-MM-DD');
        const htmlArr = [];
        htmlArr.push(`${startDt} ~ ${endDt}`); // 기간
        htmlArr.push(weekdaysArray.join(', ')); // 요일 및 공휴일
        htmlArr.push(row.reportSearchTime); // 시간
        return htmlArr.join('\n');
      },
      /** 그리드 데이터 조회 */
      onSearch() {
        const vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'id',
          async load(loadOptions) {
            const params = vm.$_getDxDataGridParam(loadOptions);

            const res = await vm.CALL_REPORT_API({
              actionName: 'REPORT_HISTORY_LIST',
              data: { ...params, executeTm: vm.$refs.dateRangeBox.getStringDateTime() },
              loading: true,
            });

            if (isSuccess(res)) {
              return {
                data: getResData(res).map(row => {
                  const parentMenuId = vm.$store.getters.getMenuList?.find(menu => menu.id === row.menuId).parentId;
                  return { ...row, parentMenuId };
                }),
                totalCount: res.data.header.totalCount,
              };
            }
          },
        });
      },
    },
    mounted() {
      this.onSearch();
    },
  };
</script>
<style scoped>
  ::v-deep .excel .dx-button-text {
    color: white;
  }
  ::v-deep .dx-button.green.light_filled {
    background: #1d6b40;
  }
</style>
