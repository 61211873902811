<!--
  PACKAGE_NAME : src/pages/setting/history/excel-history-target-detail.vue
  FILE_NAME : excel-history-target-detail.vue
  AUTHOR : owook
  DATE : 2024-01-30
  DESCRIPTION : 보고서 이력 -> 조회 대상 팝업 컴포넌트
-->
<template>
	<div id="excel-history-target-detail">
		<esp-dx-data-grid :data-grid="this.grid.historyData" :data-source="contentData" ref="historyData"></esp-dx-data-grid>
	</div>
</template>

<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
  name: 'ESPExcelHistoryTargetDetail',
	components: {
    EspDxDataGrid,
	},
	props: {
		contentHeader: Array,
		contentData: Array,
		totalCount: Number,
	},
	data() {
		return {
			grid: {
				historyData: {
					refName: 'excelHistoryRefName',
					allowColumnResizing: true, //컬럼 사이즈 허용
					showBorders: false, //border 유무
					showColumnHeaders: true, //컬럼 헤더 유무
					showColumnLines: false, //컬럼 세로선 유무
					showRowLines: true, //컬럼 가로선 유무
					rowAlternationEnabled: false,
					repaintChangesOnly: true,
					hoverStateEnabled: true,
					dataSource: [],
					// width:'200',     // 주석처리시 100%
					height: '430', // 주석처리시 100%
					showActionButtons: {
						//툴바 버튼 / 순서(sortIndex) 설정 default : [ 복사: 10, 조회 20, 추가: 30, 저장: 40, 취소: 50, 삭제: 60, 커스텀 버튼: 70번대(71, 72, 73..), 엑셀다운로드: 100 ]
						excel: false,
						save: false,
						cancel: false,
					},
					customEvent: {
						exporting: true,
					},
					columnChooser: {
						enabled: false, // 컬럼 Chooser 버튼 사용유무
					},
					loadPanel: {
						enabled: false, // 로딩바 표시 유무
					},
					sorting: {
						mode: 'multiple', // single multiple
					},
					// scrolling: {
					// 	// 미사용시 주석처리
					// 	mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
					// },
					remoteOperations: {
						filtering: false,
						sorting: true,
						grouping: false,
						paging: true,
					},
					paging: {
						// scrolling 미사용시만 적용됨
						enabled: true,
						pageSize: 10,
						pageIndex: 0, // 시작페이지
					},
					pager: {
						visible: true, //페이저 표시 여부
						showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
						allowedPageSizes: [], //페이지 사이즈 선택 박스
						displayMode: 'compact', //표시 모드 : ['full', 'compact']
						showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
						showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
					},
					filterRow: {
						visible: true,
					},
					headerFilter: {
						visible: true,
					},
					editing: {
						allowUpdating: false,
						allowDeleting: false,
						allowAdding: false,
						changes: [],
						mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
						//startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
						//selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
					},
					selecting: {
						mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
						selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
						showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
					},
					excel: {
						title: '보고서 이력',
					},
					columns: [
						{
							dataField: 'name',
							caption: '조회 대상',
							dataType: 'string',
							alignment: 'center',
							visible: true,
						},
					],
				},
			},
			/*
			grid: {
				gridWidth: 610,
				gridHeight: 400,
				paging: {
					enabled: true,
					pageSize: 12,
					pageIndex: 0,
				},
			},
			*/
		};
	},
	computed: {},
	methods: {
		createdData() {},
		noDataText() {
			return `해당 데이터가 없습니다.`;
		},
	},
	beforeCreate() {},
	created() {
		this.grid.historyData.dataSource = this.contentData;
	},
	beforeMount() {},
	mounted() {
		// contentHeader가 존재하는지 확인
		if (this.contentHeader && this.contentHeader.length > 0) {
			// contentHeader를 기반으로 동적으로 컬럼 추가
			this.grid.historyData.columns = [];
			this.contentHeader.forEach(header => {
				this.grid.historyData.columns.push({
					key: 'i',
					dataField: header.dataField,
					caption: header.caption,
					dataType: header.dataType || 'string',
					alignment: header.alignment || 'center',
					visible: true,
				});
			});
		}
	},
	beforeUpdate() {},
	updated() {},
	beforeDestroy() {},
	destroyed() {},
};
</script>

<style>
div#excel-history-target-detail tr[role='presentation'] {
	display: none !important;
}

/* page size selectbox */
div#excel-history-target-detail .dx-toolbar-items-container .dx-toolbar-after .dx-toolbar-button .page-size-item {
	display: none !important;
}
</style>
