<!--
  PACKAGE_NAME : src\pages\ai\llm-tester
  FILE_NAME : local-model-config
  AUTHOR : hpmoon
  DATE : 2025-04-08
  DESCRIPTION : AI > LLM > 로컬 모델 관리
-->
<template>
  <div>
    <div class="main">
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @saving="saveData" @init-new-row="handleInitNewRow" />
    </div>
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import DxTextArea from "devextreme-vue/text-area";
  import { cloneObj, isEmpty } from "@/utils/common-lib";
  import { DxDropDownBox } from "devextreme-vue/drop-down-box";
  import { DxDataGrid } from "devextreme-vue/data-grid";

  export default {
    name: 'AILocalModelConfig',
    components: {
      EspDxDataGrid,
    },

    data() {
      return {
        dataGrid: {
          refName: 'dataGrid',
          keyExpr: 'id',
          showBorders: true, //border 유무
          // height: '',
          // width: '',
          editing: {
            allowUpdating: true,
            allowDeleting: false,
            allowAdding: true,
            mode: 'batch',
          },
          excel: {
            autoFilterEnabled: false, // 엑셀 필터 사용 유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          filterRow: { visible: false }, // 행 검색 필터
          page: { enabled: false }, // paging, pager 설정(하나라 합침)
          allowColumnResizing: false,
          scrolling: {
            scrollByContent: false,
          },
          selecting: { // 로우 선택 설정
            mode: 'multiple', // 행 단일/멀티 선택 타입 : ['single', 'multiple']
          },
          selectedRowKeys: [],
          disableTotalCount: true, // 상단 조회 건수 노출 설정값
          showActionButtons: { // 상단 버튼 노출 설정값
            update: true, // 추가/저장/취소 한번에 설정 / true이거나 생략시 add, save, cancel 개별 설정 사용 가능 / true가 기본
            add: true, // 추가 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            save: true, // 저장 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            cancel: true, // 취소 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
            delete: false, // 삭제 / true가 기본
            customButtons: [ // 커스텀 버튼 / []가 기본
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: this.$_lang('COMPONENTS.DELETE', { defaultValue: '삭제' }),
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick: () => {
                    this.deleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          dataSource: [], //dataSource 설정
          columns: [
            {
              caption: '로컬 모델명',
              i18n: 'LLM_TESTER.WORD.LOCAL_MODEL_NAME',
              dataField: 'name',
              allowEditing: true,
              requiredRule: {
                message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                  value: this.$_lang('LLM_TESTER.WORD.LOCAL_MODEL_NAME', { defaultValue: '로컬 모델명' }),
                  defaultValue: '[로컬 모델명] 은/는 필수값 입니다'
                }),
              },
            },
            {
              caption: 'URL',
              dataField: 'url',
              allowEditing: true,
              requiredRule: {
                message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                  value: 'URL',
                  defaultValue: '[URL] 은/는 필수값 입니다'
                }),
              },
            },
            {
              caption: '토큰',
              i18n: 'LLM_TESTER.WORD.TOKEN',
              alignment: 'left',
              dataField: 'token',
              allowEditing: true,
              editCellTemplate: (container, options) => {
                const textBox = new DxTextBox({
                  propsData: {
                    value: options.value,
                    mode: 'password',
                    valueChangeEvent: 'input',
                    onValueChanged: (e) => {
                      options.setValue((e.value));
                    },
                  },
                });

                textBox.$mount();
                container.append(textBox.$el);
              },
              cellTemplate(container, options) {
                container.textContent = '*'.repeat((options.value || '').length);
              },
              requiredRule: {
                message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                  value: this.$_lang('LLM_TESTER.WORD.TOKEN', { defaultValue: '토큰' }),
                  defaultValue: '[토큰] 은/는 필수값 입니다'
                }),
              },
            },
            {
              caption: '초매개 변수',
              i18n: 'LLM_TESTER.WORD.HYPERPARAMETERS',
              alignment: 'left',
              dataField: 'available_hyperparameters',
              allowEditing: true,
              editCellTemplate: (container, options) => {
                const dropDownBox = new DxDropDownBox({
                  propsData: {
                    dataSource: this.hyperparameters,
                    deferRendering: false,
                    showClearButton: false,
                    displayExpr: 'name',
                    valueExpr: 'value',
                    value: options.value,
                    opened: false,
                    contentTemplate: (dropBox, dropDownBoxContainer) => {
                      this.dropBoxDataGrid.selectedRowKeys = options.value;
                      const dataGrid = new DxDataGrid({
                        propsData: {
                          ...this.dropBoxDataGrid,
                          onSelectionChanged: e => {
                            options.setValue(e.selectedRowsData.map(item => item.value).sort((a, b) => a - b));
                            container.textContent = this.dropBoxDataGrid.dataSource.filter(item => options.value.includes(item.value)).map(item => item.name).join(', ');
                          },
                        },
                      });
                      dataGrid.$mount();
                      dropDownBoxContainer.append(dataGrid.$el);
                    },
                  },
                });
                dropDownBox.$mount();
                container.append(dropDownBox.$el);
              },
              cellTemplate: (container, options) => {
                container.textContent = isEmpty(options.value) ? '' : this.dropBoxDataGrid.dataSource.filter(item => options.value.includes(item.value)).map(item => item.name).join(', ');
              },
              requiredRule: {
                message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                  value: this.$_lang('', { defaultValue: '초매개 변수' }),
                  defaultValue: '[초매개 변수] 은/는 필수값 입니다'
                }),
              },
            },
            {
              caption: '응답 구조',
              i18n: 'LLM_TESTER.WORD.RESPONSE_STRUCTURE',
              alignment: 'left',
              dataField: 'response_structure',
              allowEditing: true,
              editCellTemplate: (container, options) => {
                const textArea = new DxTextArea({
                  propsData: {
                    value: options.value,
                    height: '120',
                    valueChangeEvent: 'input',
                    onValueChanged: (e) => {
                      options.setValue((e.value));
                    },
                    onKeyDown: this.handleTextAreaKeyDown,
                  },
                });

                textArea.$mount();
                container.append(textArea.$el);
              },
              validationRules: [
                {
                  type: 'required',
                  message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                    value: this.$_lang('LLM_TESTER.WORD.RESPONSE_STRUCTURE', '응답 구조'),
                    defaultValue: '[응답 구조] 은/는 필수값 입니다.',
                  }),
                },
                {
                  type: 'custom',
                  validationCallback: (e) => this.validateJsonData(e, 'RESPONSE_STRUCTURE'),
                },
              ],
            },
            {
              caption: '기타 매개 변수',
              i18n: 'LLM_TESTER.WORD.KEYWORD_ARGUMENTS',
              alignment: 'left',
              dataField: 'keyword_arguments',
              allowEditing: true,
              editCellTemplate: (container, options) => {
                const textArea = new DxTextArea({
                  propsData: {
                    value: options.value,
                    height: '120',
                    valueChangeEvent: 'input',
                    onValueChanged: (e) => {
                      options.setValue((e.value));
                    },
                    onKeyDown: this.handleTextAreaKeyDown,
                  },
                });

                textArea.$mount();
                container.append(textArea.$el);
              },
              validationRules: [
                {
                  type: 'custom',
                  validationCallback: (e) => this.validateJsonData(e, 'KEYWORD_ARGUMENTS'),
                },
              ],
            },
          ]
        },

        dropBoxDataGrid: {
          refName: 'dropBoxDataGrid',
          keyExpr:'value',
          showColumnHeaders: false, //컬럼 헤더 유무
          dataSource: [],
          width: '100%',
          height: null,
          selection: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          },
          selectedRowKeys: [],
          columns: [
            {
              dataField: 'name',
              alignment: 'left',
            },
          ],
        },

        hyperparameters: null,
      };
    },

    computed: {},

    methods: {
      /** @description 데이터 조회 메서드 */
      async getData() {
        const payload = {
          actionName: 'LLM_TESTER_LOCAL_MODEL_LIST',
          loading: false,
        };
        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.dataGrid.dataSource = res.data.map(data => ({
            ...data,
            response_structure: JSON.stringify(data.response_structure, null, 2),
            keyword_arguments: JSON.stringify(data.keyword_arguments, null, 2),
            available_hyperparameters: this.hyperparameters.filter(param => (data.available_hyperparameters & param.value) === param.value).map(param => param.value)
          }));
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 데이터 저장 메서드 */
      async saveData(e) {
        e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

        // 변경된 값이 없으면 메시지 출력
        if (e?.changes.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
          return;
        }

        const saveData = [];

        e.changes.forEach(d => {
          const dataKey = d.key;
          let dataMap = d.data;

          if (d.type === 'update') {
            e.component
              .byKey(dataKey)
              .then(data => {
                dataMap = Object.assign(data, d.data); // Object.assign() 으로 기존 데이터에 변경된 데이터를 덮어씌움
              })
              .catch(error => {
                this.$log.error(error);
              });
          }

          dataMap = cloneObj(dataMap);
          dataMap.response_structure = JSON.parse(dataMap.response_structure);
          dataMap.keyword_arguments = isEmpty(dataMap.keyword_arguments) ? {} : JSON.parse(dataMap.keyword_arguments);
          dataMap.available_hyperparameters = dataMap.available_hyperparameters.reduce((acc, cur) => acc + cur, 0);

          saveData.push(dataMap);
        });

        let payload = {
          actionName: 'LLM_TESTER_LOCAL_MODEL_SAVE',
          data: saveData,
          loading: false,
        };

        let res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          await this.getData();
        } else if (res.data.detail === 'Model is not found') {
          this.$_Msg(this.$_lang('LLM_TESTER.MESSAGE.INVALID_MODEL', { defaultValue: '유효하지 않은 모델입니다.' }));
          return false;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 데이터 삭제 메서드 */
      async deleteData() {
        let selectedRows = this.$refs.dataGrid.getInstance.getSelectedRowsData();

        if (selectedRows.length <= 0) {
          this.$_Msg(this.$_lang('CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다' }));
          return;
        }

        if (
          !(await this.$_Confirm(
            this.$_lang('LLM_TESTER.MESSAGE.DELETE_LOCAL_MODEL_ALERT', { defaultValue: '로컬 모델 삭제 시, 해당 모델을 사용하고 있는 프로젝트의 모델도 함께 삭제 됩니다.<br/> 삭제 하시겠습니까?' }),
          ))
        ) {
          return;
        }

        const payload = {
          actionName: 'LLM_TESTER_LOCAL_MODEL_DELETE',
          data: selectedRows,
          loading: false,
        };

        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE', { defaultValue: '정상적으로 삭제되었습니다' }), { icon: 'success' });
          await this.getData();
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description 새로운 행 추가 메서드 */
      handleInitNewRow(e) {
        e.data.response_structure = JSON.stringify({
          content: [],
          input_token_number: [],
          output_token_number: []
        }, null, 2);
      },

      /** @description TextArea KeyDown 이벤트 처리 메서드 */
      handleTextAreaKeyDown(e) {
        // Shift + Enter > 줄바꿈
        if (e.event.key === 'Enter' && e.event.shiftKey) {
          e.event.stopPropagation();
        }
      },

      /** @description Json 객체 검증 메서드 */
      validateJsonData(e, column) {
        try {
          if(e.value) {
            JSON.parse(e.value);
          }
          return true;
        } catch (err) {
          let position = null;

          const matchPos = err.message.match(/position (\d+)/);
          if (matchPos) {
            position = parseInt(matchPos[1], 10);
          } else {
            const matchCol = err.message.match(/column (\d+)/i);
            if (matchCol) {
              position = parseInt(matchCol[1], 10);
            } else {
              const invalidCharMatch = err.message.match(/Unexpected token '(.*?)'/);
              if (invalidCharMatch) {
                const char = invalidCharMatch[1];
                position = e.value.indexOf(char);
              }
            }
          }

          let preview = '';
          if (position !== null) {
            const start = Math.max(0, position - 10);
            const end = Math.min(e.value.length, position + 10);
            preview = e.value.slice(start, end);
          }

          e.rule.message = this.$_lang('LLM_TESTER.MESSAGE.INVALID_JSON', {
            value: this.$_lang(`LLM_TESTER.WORD.${column}`, e.column.caption),
            preview,
            defaultValue: `[${e.column.caption}]의 값은 Json 형식만 가능합니다.(“…${preview}…”)`,
          });

          return false;
        }
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    created() {
      this.hyperparameters =
        this.$_getCode('llm_tester_hyperparameters').map(({ codeNm, codeValue }) => ({
          name: codeNm,
          value: Number(codeValue),
        }));
      this.dropBoxDataGrid.dataSource = this.hyperparameters;
      this.dropBoxDataGrid.height = (this.hyperparameters.length * 31) + 1;
      this.getData();
    },
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .dx-editor-cell .dx-texteditor .dx-texteditor-input {
      height: unset !important;
      line-height: unset !important;
    }
  }
</style>
