import CCDNISList from '@/pages/cc/ivr/dnis/list';
import CCNoticeList from '@/pages/cc/ivr/notice/list';
import CCNoticeConfig from '@/pages/cc/ivr/notice/config';
import CCScheduleList from '@/pages/cc/ivr/schedule/list';
import CCScheduleConfig from '@/pages/cc/ivr/schedule/config/index';
import CCServiceTemplatesList from '@/pages/cc/ivr/service-templates/list';
import CCIVRServerConfig from '@/pages/cc/ivr/ivrsvr-config';
import CCIVRMent from '@/pages/cc/ivr/ment/ivr-ment';

export default function IVRRoutes(commonComponents) {
  return [
    {
      path: '/cc/ivr/dnis/list',
      name: 'CCDNISList',
      components: { default: CCDNISList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/notice/list',
      name: 'CCNoticeList',
      components: { default: CCNoticeList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/notice/config/:id',
      name: 'CCNoticeConfig',
      components: { default: CCNoticeConfig, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/cc/ivr/schedule/list',
      name: 'CCScheduleList',
      components: { default: CCScheduleList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/schedule/config/index/:id',
      name: 'CCScheduleConfig',
      components: { default: CCScheduleConfig, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/cc/ivr/service-templates/list',
      name: 'CCServiceTemplatesList',
      components: { default: CCServiceTemplatesList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/ivrsvr-config',
      name: 'CCIVRServerConfig',
      components: { default: CCIVRServerConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/ivr/ment/ivr-ment',
      name: 'CCIVRMent',
      components: { default: CCIVRMent, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
