import EUCHunt from '@/pages/euc/device/hunt';

export default function HuntRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/hunt',
            name: 'EUCHunt',
            components: { default: EUCHunt, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}