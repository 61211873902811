<!--
  PACKAGE_NAME : src\pages\esp\system\setting\list.vue
  FILE_NAME : list.vue
  AUTHOR : supark
  DATE : 2021-07-06
  DESCRIPTION : 시스템 설정 목록
-->
<template>
  <div class="page-container">
    <tabs @selectedIndex="tabSelectedIndex" tab-css-class="tab-custom-css">
      <tab :title="tabName" :key="tabName" v-for="tabName in tabList">
        <esp-dx-data-grid
          :data-grid="getDataGridProp(tabName)"
          :ref="getDataGridPropRefName(tabName)"
          @init-new-row="handleInitNewRow"
          @saving="saveSystemSetting"
        />
      </tab>
    </tabs>
  </div>
</template>

<script>
  import Tab from '@/components/common/tab.vue';
  import Tabs from '@/components/common/tabs.vue';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    name: 'ESPSystemSettingList',
    components: {
      EspDxDataGrid,
      Tab,
      Tabs,
    },
    data() {
      return {
        dataGrid: {},
        tabList: [],
        selectTabIndex: '',
        selectTabValue: '',
        defaultColumns: [
          {
            dataField: 'configCategory',
            alignment: 'left',
            visible: false,
          },
          {
            i18n: 'COMPONENTS.KEY',
            caption: '키',
            dataField: 'configKey',
            width: '250',
            alignment: 'left',
            allowAdding: true,
            allowUpdating: false,
            allowEditing: true,
            allowHeaderFiltering: false,
            tooltip: this.$_lang('COMMON.MESSAGE.IMMUTABLE_KEY_WARNING', {
              defaultValue: '키 값은 변경할 수 없습니다. 삭제 후 재등록해주세요.',
            }),
            validationRules: [
              {
                type: 'required',
                message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {
                  value: this.$_lang('COMPONENTS.KEY', '키'),
                  defaultValue: '[키] 은/는 필수값 입니다.',
                }),
              },
              {
                type: 'pattern',
                pattern: /^[a-z_]+$/,
                message: this.$_lang('COMMON.MESSAGE.LOWER_CASE_AND_UNDERSCORE_ONLY', {
                  defaultValue: '소문자와 언더스코어(_)만 입력 가능합니다.',
                }),
              },
            ],
          },
          {
            i18n: 'COMPONENTS.VALUE',
            caption: '값',
            dataField: 'configValue',
            alignment: 'left',
            allowHeaderFiltering: false,
            allowGrouping: false,
            requiredRule: {},
          },
          {
            i18n: 'COMPONENTS.DESCRIPTION',
            caption: '설명',
            dataField: 'description',
            alignment: 'left',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
        ],
      };
    },
    methods: {
      /**
       * @description: 데이터 그리드 prop 조회
       * @param tabName
       * @return {*}
       */
      getDataGridProp(tabName) {
        return this.dataGrid[`dataGrid_${tabName}`];
      },
      /**
       * @description: 데이터 그리드 refName 조회
       * @param tabName
       * @return {*}
       */
      getDataGridPropRefName(tabName) {
        return this.dataGrid[`dataGrid_${tabName}`].refName;
      },
      /**
       * @description 탭 선택 이벤트
       * @param index
       */
      tabSelectedIndex(index) {
        const tabName = this.tabList[index];

        if (this.$refs[`dataGrid_${tabName}`][0].totalCount === 0 || this.selectTabIndex !== index) {
          this.selectDataList(tabName);
        }
        this.selectTabIndex = index;
        this.selectTabValue = tabName;
      },
      /**
       * 시스템 설정 값 조회
       * @param tabName
       * @returns {(function(): never)|*|{data, totalCount}}
       */
      selectDataList(tabName) {
        const vm = this;
        this.dataGrid[`dataGrid_${tabName}`].dataSource = new CustomStore({
          key: 'configKey',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params, ...{ configCategory: tabName } };

            const payload = {
              actionName: 'SYSTEM_SETTING_LIST',
              data: params,
              useErrorPopup: true,
            };

            let rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            }
            return rtnData;
          },
        });
      },
      /**
       * 신규 행 추가 이벤트
       * @param e
       */
      handleInitNewRow(e) {
        e.data.configCategory = this.tabList[this.selectTabIndex]; // 현재 선택된 탭기준으로 설정 카테고리 설정
      },
      /**
       * @description 저장 이벤트
       * @param e
       * @return {Promise<void>}
       */
      async saveSystemSetting(e) {
        e.cancel = true;

        if (e?.changes.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
          return;
        }

        const payload = {
          actionName: 'SYSTEM_SETTING_MERGE',
          data: this.setChangesData(e),
          useErrorPopup: true,
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          e.component.refresh();
        }
      },
      /**
       * 변경된 데이터 설정
       * @param e
       * @return {*[]}
       */
      setChangesData(e) {
        return this.$_dxUtil.convertGridChangeEventToMergedData(e, 'configKey', true);
      },
      /**
       * 데이터 초기화
       */
      initData() {
        this.dataGrid = {};
        this.tabList = [];
        this.selectTabIndex = '';
        this.selectTabValue = '';

        const addPackages = process.env.VUE_APP_ESP_ADD_PACKAGES;
        this.tabList = ['ESP', ...addPackages.split(',')];
        this.tabList.forEach(tabName => {
          this.$set(this.dataGrid, `dataGrid_${tabName}`, {
            keyExpr: 'configKey', // 그리드 키값
            refName: `dataGrid_${tabName}`,
            showBorders: false, //border 유무
            showColumnLines: false, //컬럼 세로선 유무
            rowAlternationEnabled: false,
            dataSource: [],
            apiActionNm: {
              delete: 'SYSTEM_SETTING_DELETE',
            },
            columns: this.defaultColumns,
          });
        });
      },
    },
    created() {
      this.initData();
    },
    mounted() {},
  };
</script>
<style scoped>
  .page-container {
    background: #fff;
    padding: 20px 20px 0 20px;
    border-radius: 10px 10px 0 0;
    z-index: 3;
  }
</style>
