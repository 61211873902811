<!--
  PACKAGE_NAME : src\pages\esp\system\theme\config.vue
  FILE_NAME : config.vue
  AUTHOR : hjsim
  DATE : 2023-07-20
  DESCRIPTION : 테마 설정
-->
<template>
  <div class="page-sub-box clearfix ui-glid-box">
    <div class="fl per50">
      <table class="table_form line-bin">
        <caption>
          <strong>테마설정</strong>
        </caption>
        <colgroup>
          <col style="width: 130px" />
          <col style="width: auto" />
        </colgroup>
        <thead class="sub_title_txt">
        <tr>
          <td colspan="2"><h2>기본 설정</h2></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              시스템 로고<br />
              (200*30)
            </label>
          </th>
          <td>
            <div class="system-container">
              <div
                id="dropzone-external-logo"
                class="dropzone-external-logo flex-box"
                :class="[dragDrop.isLogoDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color']"
              >
                <img v-if="imageSource.logoFile" id="dropzone-image" :src="imageSource.logoFile" alt="" style="height: 300px" />
                <div v-if="textVisible.logo" id="dropzone-text" class="flex-box">
                  <span style="font-weight: bold; font-size: 30px">+</span>
                  <span v-if="!textVisible.isErrorLogo" style="font-size: 15px">클릭 또는 파일을 드래그하세요.</span>
                  <span v-if="textVisible.isErrorLogo" style="font-size: 15px">파일을 찾을 수 없습니다.</span>
                </div>
              </div>
              <DxFileUploader
                ref="logoImg"
                accept="image/*"
                :visible="false"
                :active-state-enabled="false"
                dialog-trigger="#dropzone-external-logo"
                drop-zone="#dropzone-external-logo"
                @drop-zone-enter="onDropZoneEnter"
                @drop-zone-leave="onDropZoneLeave"
                @value-changed="e => onFileChanged(e, 'logo')"
                upload-mode="useButton"
                :allowed-file-extensions="allowedFileExtensions"
              />
            </div>
            <DxButton
              v-if="imageSource.logoFile"
              class="close-btn"
              icon="close"
              :stylingMode="stylingMode"
              :onClick="() => onFileClear('logo')"
            />
            <DxTextBox
              v-model="formData.logoFileNm"
              :disabled="true"
              :show-clear-button="true"
              :styling-mode="stylingMode"
              width="80%"
              :height="30"
              class="mar_ri10"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01"> 고객사 로고 </label>
          </th>
          <td>
            <div class="customerLogo-container">
              <div
                id="dropzone-external-customerLogo"
                class="dropzone-external-customerLogo flex-box"
                :class="[
                      dragDrop.isCustomerLogoDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color',
                    ]"
              >
                <img
                  v-if="imageSource.customerLogoFile"
                  id="dropzone-image"
                  :src="imageSource.customerLogoFile"
                  alt=""
                  style="height: 100px"
                />
                <div v-if="textVisible.customerLogo" id="dropzone-text" class="flex-box">
                  <span style="font-weight: bold; font-size: 20px">+</span>
                  <span v-if="!textVisible.isErrorCustomerLogo" style="font-size: 15px">클릭 또는 파일을 드래그하세요.</span>
                  <span v-if="textVisible.isErrorCustomerLogo" style="font-size: 15px">파일을 찾을 수 없습니다.</span>
                </div>
              </div>
              <DxFileUploader
                ref="customerLogo"
                accept="image/*"
                :visible="false"
                :active-state-enabled="false"
                dialog-trigger="#dropzone-external-customerLogo"
                drop-zone="#dropzone-external-customerLogo"
                @drop-zone-enter="onDropZoneEnter"
                @drop-zone-leave="onDropZoneEnter"
                @value-changed="e => onFileChanged(e, 'customerLogo')"
                upload-mode="useButton"
                :allowed-file-extensions="allowedFileExtensions"
              />
            </div>
            <DxButton
              v-if="imageSource.customerLogoFile"
              class="close-btn-small"
              icon="close"
              :stylingMode="stylingMode"
              :onClick="() => onFileClear('customerLogo')"
            />
            <DxTextBox
              :disabled="true"
              v-model="formData.customerLogoFileNm"
              :show-clear-button="true"
              :styling-mode="stylingMode"
              width="80%"
              :height="30"
              class="mar_ri10"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01"> 고객사명 <br />(웹사이트 타이틀) </label>
          </th>
          <td class="clearfix">
            <DxTextBox
              placeholder="Text box"
              v-model="formData.customerNm"
              :show-clear-button="true"
              :max-length="limitNumberTexts.maxLengths.customerNm"
              :styling-mode="stylingMode"
              width="65%"
              :height="30"
              class="mar_ri10"
              style="vertical-align: middle"
              @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'btmText')"
            >
            </DxTextBox>
            {{
              limitNumberTexts.textLengths.customerNm
                ? limitNumberTexts.textLengths.customerNm
                : formData.btmText
                  ? formData.customerNm.length
                  : 0
            }}/{{ limitNumberTexts.maxLengths.customerNm }}자
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01"> 파비콘(16*16) </label>
          </th>
          <td>
            <div class="favicon-container">
              <div
                id="dropzone-external-favicon"
                class="dropzone-external-favicon flex-box"
                :class="[
                      dragDrop.isFaviconDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color',
                    ]"
              >
                <img v-if="imageSource.faviconFile" id="dropzone-image" :src="imageSource.faviconFile" alt="" style="height: 150px" />
                <div v-if="textVisible.favicon" id="dropzone-text" class="flex-box">
                  <span style="font-weight: bold; font-size: 20px">+</span>
                  <span v-if="!textVisible.isErrorFavicon" style="font-size: 15px">클릭 또는 파일을 드래그하세요.</span>
                  <span v-if="textVisible.isErrorFavicon" style="font-size: 15px">파일을 찾을 수 없습니다.</span>
                </div>
              </div>
              <DxFileUploader
                ref="faviconImg"
                accept="image/*"
                :visible="false"
                :active-state-enabled="false"
                dialog-trigger="#dropzone-external-favicon"
                drop-zone="#dropzone-external-favicon"
                @drop-zone-enter="onDropZoneEnter"
                @drop-zone-leave="onDropZoneEnter"
                @value-changed="e => onFileChanged(e, 'favicon')"
                upload-mode="useButton"
                :allowed-file-extensions="allowedFileExtensions"
              />
            </div>
            <DxButton
              v-if="imageSource.faviconFile"
              class="close-btn-small"
              icon="close"
              :stylingMode="stylingMode"
              :onClick="() => onFileClear('favicon')"
            />
            <DxTextBox
              :disabled="true"
              v-model="formData.faviconFileNm"
              :show-clear-button="true"
              :styling-mode="stylingMode"
              width="80%"
              :height="30"
              class="mar_ri10"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01"> 테마 컬러 </label>
          </th>
          <td class="clearfix">
            <DxSelectBox
              :data-source="themeColors"
              display-expr="key"
              value-expr="value"
              :input-attr="{ 'aria-label': 'Templated Product' }"
              :stylingMode="stylingMode"
              field-template="colorField"
              item-template="colorItem"
              v-model="formData.themeColor"
              :width="300"
            >
              <template #colorField="{ data }">
                <ColorField :field-data="data" />
              </template>
              <template #colorItem="{ data }">
                <ColorItem :item-data="data" />
              </template>
            </DxSelectBox>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div class="fl per50">
      <table class="table_form line-bin">
        <caption>
          <strong>테마설정</strong>
        </caption>
        <colgroup>
          <col style="width: 130px" />
          <col style="width: auto" />
        </colgroup>
        <thead class="sub_title_txt">
        <tr>
          <td colspan="2"><h2>로그인 화면 설정</h2></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <th scope="row" class="alT">
            <label for="label01"> 로그인 배너 이미지 (1152*1082) </label>
          </th>
          <td>
            <div class="banner-container">
              <div
                id="dropzone-external-banner"
                class="dropzone-external-banner flex-box"
                :class="[dragDrop.isBannerDropZoneActive ? 'dx-theme-accent-as-border-color dropzone-active' : 'dx-theme-border-color']"
              >
                <img v-if="imageSource.bannerFile" id="dropzone-image" :src="imageSource.bannerFile" alt="" style="height: 300px" />
                <div v-if="textVisible.banner" id="dropzone-text" class="flex-box">
                  <span style="font-weight: bold; font-size: 30px">+</span>
                  <span v-if="!textVisible.isErrorBanner" style="font-size: 15px">클릭 또는 파일을 드래그하세요.</span>
                  <span v-if="textVisible.isErrorBanner" style="font-size: 15px">파일을 찾을 수 없습니다.</span>
                </div>
              </div>
              <DxFileUploader
                ref="bannerImg"
                accept="image/*"
                :visible="false"
                :active-state-enabled="false"
                dialog-trigger="#dropzone-external-banner"
                drop-zone="#dropzone-external-banner"
                @drop-zone-enter="onDropZoneEnter"
                @drop-zone-leave="onDropZoneLeave"
                @value-changed="e => onFileChanged(e, 'banner')"
                upload-mode="useButton"
                :allowed-file-extensions="allowedFileExtensions"
              />
            </div>
            <DxButton
              v-if="imageSource.bannerFile"
              class="close-btn"
              icon="close"
              :stylingMode="stylingMode"
              :onClick="() => onFileClear('banner')"
            />
            <DxTextBox
              :disabled="true"
              v-model="formData.bannerFileNm"
              :show-clear-button="true"
              :styling-mode="stylingMode"
              width="80%"
              :height="30"
              class="mar_ri10"
            />
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              상단 폰트<br />
              크기/컬러
            </label>
          </th>
          <td class="clearfix flex">
            <DxSelectBox
              placeholder="size"
              :data-source="fontSizes"
              v-model="formData.topFontSize"
              :styling-mode="stylingMode"
              width="90px"
              :height="30"
              class="mar_ri10"
            >
            </DxSelectBox>
            <DxColorBox v-model="formData.topFontColor" :stylingMode="stylingMode" :width="199" />
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01"> 하단 텍스트 </label>
          </th>
          <td class="clearfix">
            <DxTextBox
              placeholder="Text box"
              v-model="formData.btmText"
              :show-clear-button="true"
              :max-length="limitNumberTexts.maxLengths.btmText"
              :styling-mode="stylingMode"
              width="65%"
              :height="30"
              class="mar_ri10"
              style="vertical-align: middle"
              @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'btmText')"
            >
            </DxTextBox>
            {{
              limitNumberTexts.textLengths.btmText
                ? limitNumberTexts.textLengths.btmText
                : formData.btmText
                  ? formData.btmText.length
                  : 0
            }}/{{ limitNumberTexts.maxLengths.btmText }}자
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              하단 폰트<br />
              크기/컬러
            </label>
          </th>
          <td class="clearfix flex">
            <DxSelectBox
              placeholder="size"
              :data-source="fontSizes"
              v-model="formData.btmFontSize"
              :styling-mode="stylingMode"
              width="90px"
              :height="30"
              class="mar_ri10"
            >
            </DxSelectBox>
            <DxColorBox v-model="formData.btmFontColor" :stylingMode="stylingMode" :width="199" />
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01"> 소개글 위치 </label>
          </th>
          <td class="clearfix">
            <div class="dx-field leftTxt">TOP</div>
            <DxSelectBox
              placeholder="50"
              :data-source="this.getPercent()"
              v-model="formData.topTextLoca"
              :styling-mode="stylingMode"
              width="88px"
              :height="30"
              class="mar_ri10 delete"
            >
            </DxSelectBox>
            <div class="dx-field rightTxt">%</div>

            <div class="dx-field leftTxt">LEFT</div>
            <DxSelectBox
              placeholder="50"
              :data-source="this.getPercent()"
              v-model="formData.leftTextLoca"
              :styling-mode="stylingMode"
              width="88px"
              :height="30"
              class="mar_ri10 delete"
            >
            </DxSelectBox>
            <div class="dx-field rightTxt">%</div>
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">저작권 문구<span class="icon_require">필수항목</span></label>
          </th>
          <td class="clearfix">
            <DxTextBox
              v-model="formData.cpRightText"
              :placeholder="theme.copyright"
              :show-clear-button="true"
              :max-length="limitNumberTexts.maxLengths.cpRightText"
              :styling-mode="stylingMode"
              width="65%"
              :height="30"
              class="mar_ri10"
              @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'cpRightText')"
            >
            </DxTextBox>
            {{
              limitNumberTexts.textLengths.cpRightText
                ? limitNumberTexts.textLengths.cpRightText
                : formData.cpRightText
                  ? formData.cpRightText.length
                  : 0
            }}/{{ limitNumberTexts.maxLengths.cpRightText }}자
          </td>
        </tr>
        <tr>
          <th scope="row" class="alT">
            <label for="label01">
              저작권 문구<br />
              폰트 컬러
              <span class="icon_require">필수항목</span>
            </label>
          </th>
          <td class="clearfix">
            <DxColorBox v-model="formData.cpRightFontColor" :stylingMode="stylingMode" :width="300" />
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <div class="bottom-btn-wrap">
          <DxButton
            :text="msgContents('COMPONENTS.SAVE', { defaultValue: '저장' })"
            :width="120"
            :height="40"
            class="default filled txt_S medium"
            @click="onSave"
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { DxFileUploader } from 'devextreme-vue/file-uploader';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import DxColorBox from 'devextreme-vue/color-box';
  import ColorField from './color-field.vue';
  import ColorItem from './color-item.vue';
  import { isSuccess } from '@/utils/common-lib';
  import { espApi } from '@/api';

  export default {
    name: 'ESPThemeConfig',
    components: {
      DxFileUploader,
      DxTextBox,
      DxSelectBox,
      DxButton,
      DxColorBox,
      ColorField,
      ColorItem,
    },
    watch: {
      'files.logo': {
        handler(val) {
          if (typeof val === 'object' && val !== null) {
            this.formData.logoFileNm = val.name;
            this.onFilePreview(val, 'logo');
          }
        },
        deep: false,
      },
      'formData.logoFileNm': {
        handler(val) {
          if (typeof val === 'string') {
            this.formData.logoFileNm = val;
          }
        },
        deep: false,
      },
      'files.favicon': {
        handler(val) {
          if (typeof val === 'object' && val !== null) {
            this.formData.faviconFileNm = val.name;
            this.onFilePreview(val, 'favicon');
          }
        },
        deep: false,
      },
      'formData.faviconFileNm': {
        handler(val) {
          if (typeof val === 'string') {
            this.formData.faviconFileNm = val;
          }
        },
        deep: false,
      },
      'files.banner': {
        handler(val) {
          if (typeof val === 'object' && val !== null) {
            this.formData.bannerFileNm = val.name;
            this.onFilePreview(val, 'banner');
          }
        },
        deep: false,
      },
      'formData.bannerFileNm': {
        handler(val) {
          if (typeof val === 'string') {
            this.formData.bannerFileNm = val;
          }
        },
        deep: false,
      },
      'files.customerLogo': {
        handler(val) {
          if (typeof val === 'object' && val !== null) {
            this.formData.customerLogoFileNm = val.name;
            this.onFilePreview(val, 'customerLogo');
          }
        },
        deep: false,
      },
      'formData.customerLogoFileNm': {
        handler(val) {
          if (typeof val === 'string') {
            this.formData.customerLogoFileNm = val;
          }
        },
        deep: false,
      },
    },
    data() {
      return {
        themeId: null,
        imageSource: {
          logoFile: null,
          faviconFile: null,
          bannerFile: null,
          customerLogoFile: null,
        },
        files: {
          logo: '',
          favicon: '',
          banner: '',
          customerLogo: '',
        },
        stylingMode: 'outlined',
        id: null,
        formData: {
          logoImg: null,
          logoFileNm: '',
          faviconImg: null,
          faviconFileNm: '',
          bannerImg: null,
          bannerFileNm: null,
          customerLogo: null,
          customerLogoFileNm: null,
          customerNm: null,
          themeColor: '#164371',
          topText: '',
          topFontColor: '#fff',
          topFontSize: 16,
          btmText: '',
          btmFontSize: 16,
          btmFontColor: '#fff',
          topTextLoca: 50,
          leftTextLoca: 50,
          cpRightText: this.$_theme.copyright,
          cpRightFontColor: '#fff',
        },
        textVisible: {
          logo: true,
          isErrorLogo: false,
          favicon: true,
          isErrorFavicon: false,
          banner: true,
          isErrorBanner: false,
          customerLogo: true,
          isErrorCustomerLogo: false,
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            loadText: 50,
            topText: 50,
            btmText: 50,
            cpRightText: 50,
            customerNm: 50,
          },
        },
        dragDrop: {
          isLogoDropZoneActive: false,
          isFaviconDropZoneActive: false,
          isBannerDropZoneActive: false,
          isCustomerLogoDropZoneActive: false,
        },
        fontSizes: this.$_theme.fontSizes,
        allowedFileExtensions: this.$_theme.imageFileExtensions,
        themeColors: [],
      };
    },
    computed: {
      theme() {
        return this.$_theme;
      },
    },
    methods: {
      msgContents(messageKey, options) {
        return this.$_lang(messageKey, options);
      },
      /** @description : 퍼센트 배열 요소 만드는 메서드 */
      getPercent() {
        let perc = [];
        for (let per = 0; per <= 100; per += 5) {
          perc.push(per);
        }
        return perc;
      },
      /** @description: 데이터 저장 메서드 */
      async onSave() {
        let validation = true;

        if (!this.formData.customerLogoFileNm) {
          this.$_Msg('고객사 로고는 필수입니다.');
          validation = false;
        }
        if (!this.formData.customerNm) {
          this.$_Msg('고객사명은 필수입니다.');
          validation = false;
        }
        if (!this.formData.faviconFileNm) {
          this.$_Msg('파비콘은 필수입니다.');
          validation = false;
        }
        if (!this.formData.themeColor) {
          this.$_Msg('테마컬러는 필수입니다.');
          validation = false;
        }
        if (!this.formData.bannerFileNm) {
          this.$_Msg('로그인 배너는 필수입니다.');
          validation = false;
        }

        if (validation) {
          await this.uploadFileToFromData('logoImg', this.files.logo);
          await this.uploadFileToFromData('customerLogo', this.files.customerLogo);
          await this.uploadFileToFromData('faviconImg', this.files.favicon);
          await this.uploadFileToFromData('bannerImg', this.files.banner);
          let themeData = {};
          themeData = this.formData;
          if (this.themeId) {
            themeData.themeId = this.themeId;
          }

          const payload = {
            actionName: 'THEME_SETTINGS_UPDATE',
            data: themeData,
          };
          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            location.reload();
          }
        }
      },
      /**
       * @description: 파일 업로드 메서드
       * @param formName
       * @param uploadFile
       * @return {Promise<void>}
       */
      async uploadFileToFromData(formName, uploadFile) {
        // uploadFile 타입이 File이 아닐 경우 return, preview 이미지는 업로드 대상 제외(Blob)
        if (!(uploadFile instanceof File)) {
          return;
        }

        const res = await espApi.fileUpload([uploadFile]);
        if (isSuccess(res)) {
          this.formData[formName] = res.data.data[0].fileName;
        }
      },
      /** @description: 이미지 영역에 이미지를 끌어올 때 메서드 */
      onDropZoneEnter(e) {
        if (e.dropZoneElement.id === 'dropzone-external-logo') {
          this.dragDrop.isBannerDropZoneActive = true;
        } else if (e.dropZoneElement.id === 'dropzone-external-favicon') {
          this.dragDrop.isFaviconDropZoneActive = true;
        } else if (e.dropZoneElement.id === 'dropzone-external-banner') {
          this.dragDrop.isBannerDropZoneActive = true;
        } else if (e.dropZoneElement.id === 'dropzone-external-customerLogo') {
          this.dragDrop.isCustomerLogoDropZoneActive = true;
        }
      },
      /** @description: 이미지 영역에 이미지를 끌어온 마우스가 영역을 벗어나는 메서드 */
      onDropZoneLeave(e) {
        if (e.dropZoneElement.id === 'dropzone-external-logo') {
          this.dragDrop.isBannerDropZoneActive = false;
        } else if (e.dropZoneElement.id === 'dropzone-external-favicon') {
          this.dragDrop.isFaviconDropZoneActive = false;
        } else if (e.dropZoneElement.id === 'dropzone-external-banner') {
          this.dragDrop.isBannerDropZoneActive = false;
        } else if (e.dropZoneElement.id === 'dropzone-external-customerLogo') {
          this.dragDrop.isBannerDropZoneActive = false;
        }
      },
      /** @description: 업로드 한 파일을 읽어 미리보기로 표현 */
      onFilePreview(file, name) {
        if (name === 'logo') {
          //TODO: object 판별하는 코드 수정 예정
          if (typeof file !== 'object') {
            return;
          } else {
            const logoFileReader = new FileReader();
            logoFileReader.onload = () => {
              this.dragDrop.isBannerDropZoneActive = false;
              this.imageSource.logoFile = logoFileReader.result;
              this.textVisible.logo = false;
            };
            logoFileReader.readAsDataURL(file);
          }
        } else if (name === 'favicon') {
          const faviconFileReader = new FileReader();
          if (typeof file !== 'object') {
            return;
          } else {
            faviconFileReader.onload = () => {
              this.dragDrop.isFaviconDropZoneActive = false;
              this.imageSource.faviconFile = faviconFileReader.result;
              this.textVisible.favicon = false;
            };
            faviconFileReader.readAsDataURL(file);
          }
        } else if (name === 'banner') {
          const bannerFileReader = new FileReader();
          if (typeof file !== 'object') {
            return;
          } else {
            bannerFileReader.onload = () => {
              this.dragDrop.isBannerDropZoneActive = false;
              this.imageSource.bannerFile = bannerFileReader.result;
              this.textVisible.banner = false;
            };
            bannerFileReader.readAsDataURL(file);
          }
        } else if (name === 'customerLogo') {
          const customerLogoFileReader = new FileReader();
          if (typeof file !== 'object') {
            return;
          } else {
            customerLogoFileReader.onload = () => {
              this.dragDrop.isCustomerLogoDropZoneActive = false;
              this.imageSource.customerLogoFile = customerLogoFileReader.result;
              this.textVisible.customerLogo = false;
            };
            customerLogoFileReader.readAsDataURL(file);
          }
        }
      },
      /** @description: 파일 변경 시 동작하는 메서드 */
      onFileChanged(e, name) {
        if (e.value.length > 1) {
          this.$_Msg('한 개의 이미지 파일을 올려주세요.');
          return false;
        }
        if (name === 'logo') {
          this.files.logo = e.value[0];
        } else if (name === 'favicon') {
          this.files.favicon = e.value[0];
        } else if (name === 'banner') {
          this.files.banner = e.value[0];
        } else if (name === 'customerLogo') {
          this.files.customerLogo = e.value[0];
        }
      },
      /** @description: 이미지 우측 상단에 Close 버튼 동작 메서드 */
      onFileClear(name) {
        if (name === 'logo') {
          this.imageSource.logoFile = null;
          this.textVisible.logo = true;
          this.files.logo = null;
          this.formData.logoFileNm = '';
        } else if (name === 'favicon') {
          this.imageSource.faviconFile = null;
          this.textVisible.favicon = true;
          this.files.favicon = null;
          this.formData.faviconFileNm = '';
        } else if (name === 'banner') {
          this.imageSource.bannerFile = null;
          this.textVisible.banner = true;
          this.files.banner = null;
          this.formData.bannerFileNm = '';
        } else if (name === 'customerLogo') {
          this.imageSource.customerLogoFile = null;
          this.textVisible.customerLogo = true;
          this.files.customerLogo = null;
          this.formData.customerLogoFileNm = '';
        }
      },
      /**
       * 테마 설정 조회
       *
       * @return {Promise<{}>}
       */
      async getThemeSetting() {
        const payload = {
          actionName: 'THEME_SETTINGS_LIST',
          data: {
            includeBase64: true,
          },
          useErrorPopup: true,
        };

        let rtnData = {};
        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        return rtnData;
      },
      async setFormData() {
        const selectData = await this.getThemeSetting();
        if (!selectData) {
          return;
        }

        this.themeId = selectData.themeId;
        this.formData = selectData;
        this.files.logo = selectData.logoImg;
        this.files.favicon = selectData.faviconImg;
        this.files.customerLogo = selectData.customerLogo;
        this.files.banner = selectData.bannerImg;

        if (selectData.logoFile) {
          this.imageSource.logoFile = selectData.logoFile;
          this.textVisible.logo = false;
        } else {
          this.textVisible.logo = true;
          this.textVisible.isErrorLogo = true;
        }

        if (selectData.faviconFile) {
          this.imageSource.faviconFile = selectData.faviconFile;
          this.textVisible.favicon = false;
        } else {
          this.textVisible.favicon = true;
          this.textVisible.isErrorFavicon = true;
        }

        if (selectData.bannerFile) {
          this.imageSource.bannerFile = selectData.bannerFile;
          this.textVisible.banner = false;
        } else {
          this.textVisible.banner = true;
          this.textVisible.isErrorBanner = true;
        }

        if (selectData.customerLogoFile) {
          this.imageSource.customerLogoFile = selectData.customerLogoFile;
          this.textVisible.customerLogo = false;
        } else {
          this.textVisible.customerLogo = true;
          this.textVisible.isErrorCustomerLogo = true;
        }
      },
    },
    async created() {
      Object.entries(this.$_theme.defaultColors).forEach(color => {
        this.themeColors.push({
          key: color[0],
          value: color[1],
        });
      });
      await this.setFormData();
    },
  };
</script>
<style scoped>
  table tr.empty-row {
    height: 56px;
  }

  .ui-glid-box > div.fl,
  .ui-glid-box > div.fr {
    border-right: 0;
  }

  .table_form td > div {
    display: inline-block;
    vertical-align: middle;
  }

  .table_form td .empty-box {
    width: 10px;
  }

  /* drag & drop css */
  .dropzone-external-logo {
    width: 300px;
    height: 70px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
  }

  .dropzone-external-customerLogo {
    width: 300px;
    height: 70px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
  }

  .dropzone-external-banner {
    width: 300px;
    height: 180px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
  }

  .dropzone-external-favicon {
    width: 300px;
    height: 100px;
    background-color: rgba(183, 183, 183, 0.1);
    border-width: 2px;
    border-style: dashed;
    padding: 10px;
  }

  .dropzone-external > * {
    pointer-events: none;
  }

  .dropzone-external.dropzone-active {
    border-style: solid;
  }

  .widget-container > span {
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 16px;
  }

  #dropzone-image {
    max-width: 100%;
    max-height: 100%;
  }

  #dropzone-text > span {
    font-weight: 100;
    opacity: 0.5;
  }

  #upload-progress {
    display: flex;
    margin-top: 10px;
  }

  .flex-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 0px 12px 0px;
    /* width: 80%; */
  }

  .system-container {
    width: 100%;
    text-align: center;
  }

  .customerLogo-container {
    width: 100%;
    text-align: center;
  }

  .favicon-container {
    width: 100%;
    height: 30%;
    text-align: center;
  }

  .banner-container {
    width: 100%;
    text-align: center;
  }

  .mar_ri10 {
    max-width: 300px;
  }

  .colorBox {
    max-width: 100px;
    min-width: 100px;
  }

  .dx-field {
    border: none !important;
    color: rgb(146, 142, 142) !important;
    font-size: 13px;
  }

  .rightTxt {
    margin: 0px 20px 0px 3px !important;
  }

  .leftTxt {
    margin: 0px 10px 0px 0px !important;
  }

  .clear {
    clear: left;
  }

  .delete {
    margin: 0px !important;
  }

  .close-btn {
    top: 5px;
    left: 150px;
    position: absolute;
    display: flex;
    border: 1px solid;
  }

  .close-btn-small {
    top: 5px;
    left: 101px;
    position: absolute;
    display: flex;
    border: 1px solid;
  }
</style>
