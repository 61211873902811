import EWMAgentAppointmentList from '@/pages/ewm/hr/agent/appointment/list.vue';
import EWMAgentManagementList from '@/pages/ewm/hr/agent/management/list.vue';
import EWMHRComplaint from '@/pages/ewm/hr/complaint/index.vue';
import EWMManagementInterview from '@/pages/ewm/hr/management/interview/index.vue';
import EWMReportAttendance from '@/pages/ewm/hr/management/report/attendance/index.vue';
import EWMReportResignation from '@/pages/ewm/hr/management/report/resignation/index.vue';
import EWMReportWorkStats from '@/pages/ewm/hr/management/report/work-stats/index.vue';
import EWMReportWorkTimeEdit from '@/pages/ewm/hr/management/report/worktime-edit/index.vue';
import EWMReportWorkTimeSchedule from '@/pages/ewm/hr/management/report/worktime-schedule/index.vue';
import EWMVacationSetting from '@/pages/ewm/hr/management/vacation-setting/index.vue';
import EWMVacationType from '@/pages/ewm/hr/management/vacation-type/index.vue';
import EWMWorkInfoList from '@/pages/ewm/hr/work/info/list.vue';
import EWMWorkScheduleList from '@/pages/ewm/hr/work/schedule/list.vue';
import EWMWorkTypeList from '@/pages/ewm/hr/work/type/list.vue';

export default function EwmHRRoutes(commonComponents) {
  return [
    {
      path: '/ewm/hr/agent/appointment/list',
      name: 'EWMAgentAppointmentList',
      components: { default: EWMAgentAppointmentList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/agent/management/list',
      name: 'EWMAgentManagementList',
      components: { default: EWMAgentManagementList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/complaint',
      name: 'EWMHRComplaint',
      components: { default: EWMHRComplaint, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/interview',
      name: 'EWMManagementInterview',
      components: { default: EWMManagementInterview, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/attendance',
      name: 'EWMReportAttendance',
      components: { default: EWMReportAttendance, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/resignation',
      name: 'EWMReportResignation',
      components: { default: EWMReportResignation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/work-stats',
      name: 'EWMReportWorkStats',
      components: { default: EWMReportWorkStats, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/worktime-edit',
      name: 'EWMReportWorkTimeEdit',
      components: { default: EWMReportWorkTimeEdit, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/report/worktime-schedule',
      name: 'EWMReportWorkTimeSchedule',
      components: { default: EWMReportWorkTimeSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/vacation-setting',
      name: 'EWMVacationSetting',
      components: { default: EWMVacationSetting, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/management/vacation-type',
      name: 'EWMVacationType',
      components: { default: EWMVacationType, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/work/info/list',
      name: 'EWMWorkInfoList',
      components: { default: EWMWorkInfoList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/work/schedule/list',
      name: 'EWMWorkScheduleList',
      components: { default: EWMWorkScheduleList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/hr/work/type/list',
      name: 'EWMWorkTypeList',
      components: { default: EWMWorkTypeList, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}