<!--
  PACKAGE_NAME : src/pages/euc/device/setting/
  FILE_NAME : index.vue
  AUTHOR : jhsim
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <template v-for="(item, index) in getComponent">
        <Tab :title="item.title" :key="`Tab${index}`">
          <div v-if="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
            <div class="cusmain-table-wrap">
              <component :is="item.component" :cm-list="cmList"/>
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import EndUserList from '@/pages/euc/device/jabber/end-user-list.vue';
import PCJabberList from '@/pages/euc/device/jabber/pc-jabber-list.vue';
import {isSuccess} from '@/utils/common-lib';

export default {
  name: 'EUCJabber',
  components: {
    Tabs,
    Tab,
    EndUserList,
    PCJabberList,
  },
  data() {
    return {
      tabIndex: 0,
      cmList: [],
      tabs: {
        selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
      },
      tabItems: [
        {
          title: this.$_lang('UC.WORD.END_USER_MANAGEMENT', { defaultValue: '최종 사용자 관리' }),
          component: EndUserList,
          vender: ['CISCO'],
        },
        {
          title: this.$_lang('UC.WORD.PC_JABBER_MANAGEMENT', { defaultValue: 'PC Jabber 관리' }),
          component: PCJabberList,
          vender: ['CISCO'],
        },
      ],
    };
  },
  computed: {
    getVender() {
      return this.$_getSystemData('vender')?.configValue || 'CISCO';
    },
    getComponent() {
      return this.tabItems.filter(item => item.vender.includes(this.getVender));
    },
  },
  methods: {
    async tabSelectedIndex(index) {
      // cm data 변경 시 aes, svr에서 반영할 수 있도록 하기 위해 v-if 사용
      this.tabIndex = index;
    },
    async selectCmList(sort = '+cmOrd') {
      const payload = {
        actionName: 'EUC_CM_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.cmList = res.data.data.map((item) => {
          return {
            cmNm: item.cmNm,
            cmOrd: item.cmOrd,
            description: item.description,
            editId: item.editId,
            editDt: item.editDt,
            regId: item.regId,
          };
        });
      }
    },
  },
  async created() {
    await this.selectCmList();
  },
};
</script>

<style scoped></style>