<!--
  PACKAGE_NAME : src\pages\esp\report\dashboard\index.vue
  FILE_NAME : index
  AUTHOR : kwmoon
  DATE : 2025-02-04
  DESCRIPTION : 대시보드 페이지
-->
<template>
  <div class="page-container">
    <div ref="dragArea">
      <div id="content">
        <grid-layout
          ref="gridlayout"
          :layout.sync="gridLayout"
          :col-num="colNum"
          :row-height="10"
          :vertical-compact="true"
          :use-css-transforms="true"
        >
          <grid-item
            v-for="item in gridLayout"
            :static="item.static"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            :minW="19"
            :minH="8"
          >
            <Widget
              :mode="mode"
              :ref="`widget${item.i}`"
              :widget="item.obj"
              :data="table[item.obj.tableName]"
              :key="item.i"
              @initDashboard="initDashboard"
              @onRemoveWidgetLayout="() => {}"
            />
          </grid-item>
        </grid-layout>
      </div>
    </div>
  </div>
</template>
<script>
  import { GridItem, GridLayout } from 'vue-grid-layout';
  import Widget from '@/components/report/dashboard/widget.vue';
  import { getResData, isEmpty, isSuccess } from '@/utils/common-lib';

  export default {
    name: 'ReportDashboard',
    components: {
      GridLayout,
      GridItem,
      Widget,
    },
    watch: {
      '$route.path': async function (path) {
        if (this.isUsingKeepAlive(path)) {
          return;
        }

        // 대시보드 템플릿 ID 조회
        const menuId = this.$route.params.menuId;
        this.templateId = await this.getTemplateIdByMenuId(menuId);

        // 대시보드 초기화
        await this.initDashboard();
      },
    },
    data() {
      return {
        templateId: 0, // 대시보드 템플릿 ID
        widgetList: [], // 현재 페이지에서 사용 중인 위젯 리스트
        // 스케쥴러 데이터명
        table: {
          // H_AGT_IBG_D: [],
          // H_AGT_IBG_H: [],
          H_IBG_D: [],
          H_IBG_H: [],
          H_AGT_D: [],
          H_PIE_D: [],
        },
        // 소켓 관리
        subscription: null, // 웹소켓 구독 객체
        reconnectAttempt: false, // 재시도 상태

        // 우선 뺌
        mode: '',
        item: null,
        widgetProperty: null,
        colNum: 100, // ??
        gridLayout: [],
        rotated: false,
        widget: {
          cellSize: {
            w: 10, //widget.layout에서 width에 대한 pixel값
            h: 10, //widget.layout에서 height에 대한 pixel값
          },
          maxRowCount: Infinity,
          bubbleUp: true,
          margin: 15,
          outerMargin: 0,
          layout: [],
          fixLayoutOnLoad: true,
        },
        // 날짜/시간 형식
        dateFormat: {
          daily: 210,
          hour: 213,
        },
      };
    },
    methods: {
      /**
       * 현재 접속한 메뉴(페이지)가 keepAlive 로 관리되고 있는지 체크하는 함수
       * @param path 현재 URL
       * @returns {boolean}
       */
      isUsingKeepAlive(path) {
        return this.$vnode.data.keepAlive || path.indexOf('/dashboard') === -1;
      },

      /** @description : 두 날짜의 차이를 조회 후 일자로 반환*/
      getDateDiff(d1, d2) {
        const dateDiff = this.getYmdDiff(d1, d2);
        return Math.abs(dateDiff / (1000 * 60 * 60 * 24)); // 밀리세컨 * 초 * 분 * 시 = 일
      },

      /** @description :  두 날짜의 차이 조회*/
      getYmdDiff(d1, d2) {
        const sy = d1.substring(0, 4);
        const sm = d1.substring(4, 6);
        const sd = d1.substring(6);
        const ey = d2.substring(0, 4);
        const em = d2.substring(4, 6);
        const ed = d2.substring(6);
        const sDate = new Date(sy, sm, sd);
        const eDate = new Date(ey, em, ed);
        const diffDate = sDate.getTime() - eDate.getTime();
        return diffDate;
      },

      /** @description : 대시보드 페이지 진입 이벤트*/
      async initDashboard() {
        this.gridLayout = []; // girdLayout 초기화
        this.widgetList = await this.getWidgetsAsTemplateId();
        if (!isEmpty(this.widgetList)) {
          this.updateWidgetsInfo(this.widgetList);
        }
      },

      /**
       * dutaionType 이 recent 인지 체크
       * @param durationType
       * @returns {boolean}
       */
      isRecentDurationType(durationType) {
        // recent(1130), fixeDuration(1128), cumulativeDuration(1129),
        return durationType === '1130';
      },

      /** @description : 위젯 정보 업데이트 */
      updateWidgetsInfo(widgets) {
        if (widgets.length > 0) {
          widgets.forEach(widget => {
            if (this.isRecentDurationType(widget.durationType)) {
              this.changeDate(widget);
            }

            this.makeWidgetProperty(widget);
            this.setDashboard();
          });
        }
      },

      /** @description : templateId에 따른 위젯 리스트 조회*/
      async getWidgetsAsTemplateId() {
        const res = await this.CALL_REPORT_API({
          actionName: 'WIDGET_LIST_ALL',
          loading: true,
          data: { templateId: this.templateId },
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
      },

      /** @description : 최근 조회시 기간을 계산*/
      changeDate(widget) {
        //최근조회일 경우
        let startDt = widget.period.split('~')[0];
        let endDt = widget.period.split('~')[1];
        //템플릿으로 부터 온 최근 __일|__개월|__년
        let date = new Date();
        const dateGroupCode = widget.dateGroupCode;

        if (dateGroupCode === this.dateFormat.daily || this.dateFormat.hour) {
          const recent = this.getDateDiff(startDt, endDt);
          endDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
          const daysAgo = date.getDate() - recent;
          date.setDate(daysAgo);
          startDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmss', 'YYYYMMDD');
        }

        widget.period = startDt + '~' + endDt;
        return widget;
      },

      /**
       * 문자열 자르는 함수
       * @param str
       * @param unit
       * @returns {*|*[]}
       */
      strToList(str, unit = ',') {
        if (isEmpty(str)) {
          return [];
        }
        return str.split(unit).map(v => v.trim());
      },

      /**
       * 스코어 카드인 경우 endDt 를 오늘 날짜로 변경하는 함수
       * @param widget
       */
      updateScoreCardEndDateToToday(widget) {
        if (widget.chartType === 'scoreguard') {
          let date = new Date();
          const endDt = this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYYMMDD');
          widget.period = widget.period.split('~')[0] + '~' + endDt;
        }
      },

      /**
       * 차트명이 가로형 바 차트인지 확인하는 함수
       * @param chartName
       * @returns {boolean}
       */
      isRotatedBarChart(chartName) {
        return ['rotatedbar', 'stackedrotatedbar', 'fullstackedrotatedbar'].includes(chartName);
      },

      /**
       * 가로형 바 차트인 경우 속성을 변경하는 함수
       * @param widget
       * @param widgetProperty
       */
      setRotatedBarChartProperties(widget, widgetProperty) {
        if (this.isRotatedBarChart(widgetProperty.chartName)) {
          widgetProperty.chartType = widget.widgetTp.chartTypeCode.codeValue.replace('rotated', '');
          widgetProperty.rotated = true; // TODO: DB 이전 고려
        }
      },

      /**
       * 조회 대상 테이블명 설정
       * @param widget
       * @returns {string}
       */
      getTableName(widget) {
        const suffixes = {
          210: '_D',
          213: '_H',
        };
        return `${widget.widgetTp.targetQuery}${suffixes[widget.dateGroupCode] || ''}`;
      },

      /**
       * 차트 정보 가져오기
       * @param widget
       * @returns {{chartType: string, chartName: string}}
       */
      getChartInfo(widget) {
        const chartObject = this.$_getCode('search_type_widget').find(e => e.codeId === widget.widgetTp.chartTypeCd);
        return {
          chartType: chartObject.codeValue,
          chartName: chartObject.codeNm,
        };
      },

      /**
       * 기간 정보 가져오기
       * @param widget
       * @returns {{dayStart: string, dayEnd: string, period: string}}
       */
      getPeriod(widget) {
        const [dayStart, dayEnd] = widget.period.split('~');
        return { dayStart, dayEnd, period: widget.period };
      },

      /**
       * 위젯 속성을 생성하는 함수
       * @param widget
       * @returns {Object}
       */
      createWidgetProperty(widget) {
        const { chartType, chartName } = this.getChartInfo(widget);
        const { dayStart, dayEnd, period } = this.getPeriod(widget);
        const tableName = this.getTableName(widget);

        return {
          id: widget.id,
          widgetTpId: widget.widgetTpId,
          hidden: false,
          pinned: true,
          positionX: widget.positionX,
          positionY: widget.positionY,
          width: widget.width,
          height: widget.height,
          x: widget.positionX,
          y: widget.positionY,
          w: widget.width,
          h: widget.height,
          i: String(widget.id),
          title: widget.title,
          widgetType: widget.widgetTp.widgetShapeTypeFl,
          chartType,
          chartName,
          dayStart,
          dayEnd,
          period,
          widgetTp: widget.widgetTp,
          tableName,
          dateGroupCode: widget.dateGroupCode || widget.widgetTp.dateGroupCode,
          sortType: widget.sortType,
          durationType: widget.durationType,
          subjectType: widget.subjectType,
          columnList: this.strToList(widget.columnList),
          columnNameList: this.strToList(widget.columnNameList),
          columnGroupList: this.strToList(widget.widgetTp.columnGroupList),
          templateId: this.templateId,
          rotated: false,
          recent: widget.recent,
        };
      },

      /** @description : 위젯별 속성을 구성*/
      makeWidgetProperty(widget) {
        // 스코어 카드는 현재 시간과 데이터를 비교하기 떄문에 현재 시간을 구해서 endDt로 조작한다
        this.updateScoreCardEndDateToToday(widget);

        // 위젯 속성 생성
        const widgetProperty = this.createWidgetProperty(widget);

        // 가로형 바 차트 속성 업데이트
        this.setRotatedBarChartProperties(widget, widgetProperty);

        // grid layout item 구성
        this.item = {
          x: widget.positionX,
          y: widget.positionY,
          w: widget.width,
          h: widget.height,
          i: String(widget.id),
          obj: widgetProperty,
          static: true,
        };

        this.widgetProperty = widgetProperty;
        return widget;
      },

      /** @description : 대시보드에 위젯을 셋팅*/
      async setDashboard() {
        this.gridLayout.push(this.item);
        this.widget.layout.push(this.widgetProperty); //위젯의 속성이나 X,Y축 값 및 series들
      },

      /** 데이터 조회 */
      async getData(key, actionName) {
        const payload = {
          actionName: actionName,
          loading: true,
        };
        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          this.table[key] = getResData(res);
        }
      },

      /** 데이터 초기화 */
      async initData() {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_DASHBOARD_SELECT',
          loading: true,
        });

        if (isSuccess(res)) {
          const result = getResData(res).at(0);
          const keys = Object.keys(result);
          for (let key of keys) {
            this.table[key] = result[key];
          }
        }
      },

      /**
       * 메뉴 ID 를 통해 대시보드 템플릿 ID 조회
       * @param menuId
       * @returns {Promise<void>}
       */
      async getTemplateIdByMenuId(menuId) {
        const payload = {
          actionName: 'DASHBOARD_TEMPLATE_ID',
          data: { menuId },
          loading: true,
        };
        const res = await this.CALL_REPORT_API(payload);
        if (isSuccess(res)) {
          return getResData(res);
        }
      },

      /** 웹소켓 연결 */
      subscribeToTopics() {
        const vm = this;
        this.stompClient = this.$store.getters.getStompClient;

        if (this.stompClient && this.stompClient.connected) {
          this.subscription = this.stompClient.subscribe('/sub/dashboard/data', function (e) {
            vm.getData(e.body, 'REPORT_DASHBOARD_' + e.body);
          });

          // 웹소켓이 닫힐 때 자동으로 재연결 시도
          this.stompClient.ws.onclose = () => {
            vm.reconnectWebSocket();
          };

          // 웹소켓 오류 발생 시 처리
          this.stompClient.ws.onerror = error => {
            vm.reconnectWebSocket();
          };
        } else {
          this.reconnectWebSocket();
        }
      },

      /** 웹소켓 재연결 */
      reconnectWebSocket() {
        if (this.reconnectAttempt) return; // 이미 재연결 중이면 중복 실행 방지
        this.reconnectAttempt = true;

        setTimeout(() => {
          this.reconnectAttempt = false;
          this.subscribeToTopics();
        }, 2000);
      },

      /** 소켓 구독 및 재연결 제거 */
      disconnect() {
        if (this.subscription) {
          this.subscription.unsubscribe();
          this.subscription = null;
        }

        if (this.stompClient) {
          this.stompClient.ws.onclose = null;
          this.stompClient.ws.onerror = null;
          this.stompClient.disconnect();
          this.stompClient = null;
        }
      },
    },
    async mounted() {
      await this.initData();
      this.subscribeToTopics();

      const menuId = this.$route.params.menuId;
      this.templateId = await this.getTemplateIdByMenuId(menuId);

      // 대시보드 초기화
      await this.initDashboard();
    },
    activated() {
      this.subscribeToTopics();
    },
    deactivated() {
      this.disconnect();
    },
    beforeDestroy() {
      this.disconnect();
    },
  };
</script>
<style scoped>
  .page-container {
    background: #fff;
    padding: 10px 0;
    border-radius: 10px 10px 0 0;
    z-index: 5;
  }
</style>
