<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\playground
  FILE_NAME : list
  AUTHOR : hpmoon
  DATE : 2024-12-04
  DESCRIPTION : AI > LLM > Playground
-->
<template>
  <div class="page-sub-box">
    <div class="page-cardlist-box pad_top20">
      <ul class="page-card-list">
        <li>
          <div v-for="(model, index) in modelList" :key="index" class="template-card-box">
            <div class="template-card-body">
              <div class="template-card-main">
                <div class="card-header">
                  {{ model.codeNm }}
                </div>
                <div class="template-m-buttonbox">
                  <ul>
                    <li>
                      <DxButton
                        :disabled="isRunningPlayGround"
                        :text="$_lang('LLM_TESTER.WORD.RUN', { defaultValue: '실행' })"
                        :width="211"
                        :height="40"
                        class="btn_XS white light_filled"
                        type="normal"
                        @click="$router.push({ path: '/ai/llm-tester/playground/running-playground', query: { model } })"/>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

  import { DxButton } from "devextreme-vue/button";

  export default {
    name: 'AILLMPlaygroundList',
    components: {
      DxButton
    },

    data() {
      return {
        modelList: null,
        isRunningPlayGround: true,
      };
    },

    computed: {},

    methods: {

    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    async created() {
      const payload = {
        actionName: 'LLM_TESTER_LOCAL_MODEL_LIST',
        loading: false,
      }
      const res = await this.CALL_LLM_TESTER_API(payload);
      if (res.status !== 200) {
        this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        return false;
      }

      const codeModelList = this.$_getCode('llm_tester_model').map(item => ({
        codeValue: item.codeValue,
        codeNm: item.codeNm,
      }));

      const localModelList = res.data.map(item => ({
        codeValue: item.name,
        codeNm: item.name,
      }));

      this.modelList = [...codeModelList, ...localModelList];
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    async mounted() {
      const payload = {
        actionName: 'LLM_TESTER_PLAYGROUND_SESSION',
        loading: true,
      };
      const res = await this.CALL_LLM_TESTER_API(payload);
      if (res.status === 200) {
        this.isRunningPlayGround = res.data !== 'not_found';
      } else {
        this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        return false;
      }

      if (this.isRunningPlayGround) {
        this.$_Msg(this.$_lang('LLM_TESTER.MESSAGE.EXIST_PLAYGROUND_SESSION', { defaultValue: '현재 Playground를 다른 사용자가 작업 중이므로 사용할 수 없습니다.' }));
      }
    },
  };
</script>

<style lang="scss" scoped>
  .card-header {
    background-color: #545454;
    width: 235px;
    height: 100px;
    line-height: 100px;
    color: white;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }

  .page-card-list > li {
    margin: 0 20px 0 0px;
  }
</style>