import EUCSpIpt from '@/pages/euc/sp-ipt';

export default function SpIptRoutes(commonComponents) {
    return [
        {
            path: '/euc/sp-ipt',
            name: 'EUCSpIpt',
            components: { default: EUCSpIpt, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}