import EWMPersonalAgentCase from '@/pages/ewm/personal/agent/case/index.vue';
import EWMPersonalAgentTime from '@/pages/ewm/personal/agent/time/index.vue';
import EWMPersonalBoard from '@/pages/ewm/personal/board/index.vue';
import EWMPersonalBwCall from '@/pages/ewm/personal/bw-call/index.vue';
import EWMPersonalEducation from '@/pages/ewm/personal/education/index.vue';
import EWMPersonalEvaluation from '@/pages/ewm/personal/evaluation/index.vue';
import EWMPersonalEvaluationDetail from '@/pages/ewm/personal/evaluation/evaluation-detail.vue';
import EWMPersonalExam from '@/pages/ewm/personal/exam/index.vue';
import EWMTargetExamSheet from '@/pages/ewm/personal/exam/target-exam-sheet.vue';
import EWMTargetExamSheetResult from '@/pages/ewm/personal/exam/target-exam-sheet-result.vue';
import EWMTargetSheet from '@/pages/ewm/personal/exam/target-sheet.vue';
import EWMPersonalKpiSchedule from '@/pages/ewm/personal/kpi-schedule/index.vue';
import EWMPersonalSurvey from '@/pages/ewm/personal/survey/index.vue';
import EWMPersonalSurveyDetail from '@/pages/ewm/personal/survey/survey-detail.vue';
import EWMPersonalVacation from '@/pages/ewm/personal/vacation/index.vue';
import EWMPersonalVacationDetail from '@/pages/ewm/personal/vacation/vacation-detail.vue';
import EWMPersonalWorkSchedule from '@/pages/ewm/personal/work-schedule/index.vue';
import EWMScheduleDetail from '@/pages/ewm/personal/work-schedule/schedule-detail.vue';
import EWMPersonalWorkTimeEdit from '@/pages/ewm/personal/worktime-edit/index.vue';
import EWMWorkTimeDetail from '@/pages/ewm/personal/worktime-edit/worktime-detail.vue';

export default function PersonalRoutes(commonComponents) {
  return [
    {
      path: '/ewm/personal/agent/case',
      name: 'EWMPersonalAgentCase',
      components: { default: EWMPersonalAgentCase, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/agent/time',
      name: 'EWMPersonalAgentTime',
      components: { default: EWMPersonalAgentTime, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/board',
      name: 'EWMPersonalBoard',
      components: { default: EWMPersonalBoard, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/bw-call',
      name: 'EWMPersonalBwCall',
      components: { default: EWMPersonalBwCall, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/education',
      name: 'EWMPersonalEducation',
      components: { default: EWMPersonalEducation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/evaluation',
      name: 'EWMPersonalEvaluation',
      components: { default: EWMPersonalEvaluation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/evaluation/evaluation-detail',
      name: 'EWMPersonalEvaluationDetail',
      components: { default: EWMPersonalEvaluationDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/exam',
      name: 'EWMPersonalExam',
      components: { default: EWMPersonalExam, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/exam/target-exam-sheet',
      name: 'EWMTargetExamSheet',
      components: { default: EWMTargetExamSheet, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/exam/target-exam-sheet-result',
      name: 'EWMTargetExamSheetResult',
      components: { default: EWMTargetExamSheetResult, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/exam/target-sheet',
      name: 'EWMTargetSheet',
      components: { default: EWMTargetSheet, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/kpi-schedule',
      name: 'EWMPersonalKpiSchedule',
      components: { default: EWMPersonalKpiSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/survey',
      name: 'EWMPersonalSurvey',
      components: { default: EWMPersonalSurvey, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/survey/survey-detail',
      name: 'EWMPersonalSurveyDetail',
      components: { default: EWMPersonalSurveyDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/vacation',
      name: 'EWMPersonalVacation',
      components: { default: EWMPersonalVacation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/vacation/vacation-detail',
      name: 'EWMPersonalVacationDetail',
      components: { default: EWMPersonalVacationDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/work-schedule',
      name: 'EWMPersonalWorkSchedule',
      components: { default: EWMPersonalWorkSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/work-schedule/schedule-detail',
      name: 'EWMScheduleDetail',
      components: { default: EWMScheduleDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/worktime-edit',
      name: 'EWMPersonalWorkTimeEdit',
      components: { default: EWMPersonalWorkTimeEdit, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/personal/worktime-edit/worktime-detail',
      name: 'EWMWorkTimeDetail',
      components: { default: EWMWorkTimeDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}