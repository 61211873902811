import ESPCOMPGridDemo from '@/pages/ui/components/grid-demo';
import ESPCOMPTreeDemo from '@/pages/ui/components/tree-demo.vue';
import ESPCOMPCalendarDemo from '@/pages/ui/components/calendar-demo.vue';
import ESPCOMPButtonDemo from '@/pages/ui/components/button-demo.vue';
import ESPCOMPPopDemo from '@/pages/ui/components/pop-demo.vue';

export default function ComponentConfigRoutes(commonComponents) {
  return [
    {
      path: '/ui/components/grid-demo',
      name: 'ESPCOMPGridDemo',
      components: { default: ESPCOMPGridDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/tree-demo',
      name: 'ESPCOMPTreeDemo',
      components: { default: ESPCOMPTreeDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/calendar-demo',
      name: 'ESPCOMPCalendarDemo',
      components: { default: ESPCOMPCalendarDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/button-demo',
      name: 'ESPCOMPButtonDemo',
      components: { default: ESPCOMPButtonDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ui/components/pop-demo',
      name: 'ESPCOMPPopDemo',
      components: { default: ESPCOMPPopDemo, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
