<template>
	<div class="container" style="display: flex; flex-direction: column; min-height: 100%;">
		<div class="ewm page-sub-box min-h-lvh" style="flex: 1; overflow-y: auto; padding-bottom: 80px;">
			<!--'bg-type01'삽입시 최상단 'page-sub-box'만 배경적용 -->
			<div class="flex flex-col gap-y-6 divide-y">
				<div>
					<!-- <h2 class="text-2xl font-medium my-4">평가계획 상세</h2> -->
					<table class="table_form line-bin min-w-max max-w-screen-xl">
						<colgroup>
							<col style="width:100px;" />
							<col style="width:auto;" />
							<col style="width:100px;" />
							<col style="width:auto;" />
							<col style="width:120px;" />
							<col style="width:auto;" />
						</colgroup>
						<tbody>
							<tr>
								<th scope="row">
									<label for="label01">평가계획:</label>
								</th>
								<td>
									{{ evalScheduleInfo.scheNm }}
								</td>
								<th scope="row">
									<label for="label01">진행상태:</label>
								</th>
								<td>
									{{ evalScheduleInfo.evalProcessNm }}
								</td>
								<th scope="row">
									<label for="label01">평가표:</label>
								</th>
								<td>
									{{ evalScheduleInfo.sheetNm }}
								</td>
							</tr>
							<tr>
								<th scope="row">
									<label for="label01">평가구분:</label>
								</th>
								<td>
									{{ evalScheduleInfo.evalDivisionNm }}
								</td>
								<th scope="row">
									<label for="label01">평가기간:</label>
								</th>
								<td>
									{{ formatDate(evalScheduleInfo.evalStartDt, 'YYYY-MM-DD', 'YYYY-MM-DD') }}
									~
									{{ formatDate(evalScheduleInfo.evalEndDt, 'YYYY-MM-DD', 'YYYY-MM-DD') }}
								</td>
								<th scope="row">
									<label for="label01">이의제기 기간:</label>
								</th>
								<td>
									{{
										formatDate(
											evalScheduleInfo.evalFeedbackStartDt,
											'YYYY-MM-DDTHH:mm:ss',
											'YYYY-MM-DD',
										)
									}}
									~
									{{
										formatDate(evalScheduleInfo.evalFeedbackEndDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')
									}}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<div v-if="evalScheduleInfo.evalDivisionNm !== '일반평가'">
					<h3 class="text-xl font-medium my-4">회차관리</h3>
					<div>
						<div class="mt-4">
							<DxDataGrid
								class="grid-box eval-grid"
								ref="evalScheRoundGrid"
								:data-source="roundGridData.dataSource"
								:allow-column-resizing="true"
								:column-resizing-mode="'nextColumn'"
								:show-borders="false"
								:show-column-headers="true"
								:show-column-lines="true"
								:show-row-lines="true"
								:row-alternation-enabled="false"
								:hover-state-enabled="true"
								:word-wrap-enabled="true"
								:no-data-text="this.$_lang('CMN_NO_DATA')"
								:selected-row-keys="roundGridData.selectedRowKeys"
								width="100%"
								:height="220"
								@row-click="onRowClick"
								@edit-canceled="onRoundGridEditCanceled"
								@init-new-row="onRoundGridInitNewRow"
								@saving="onRoundSaveData"
								@selection-changed="onRoundGridSelectionChanged"
							>
								<DxEditing
									:allow-updating="true"
									:allow-adding="true"
									:allow-deleting="false"
									:select-text-on-edit-start="false"
									start-edit-action="click"
									mode="batch"
								/>
								<DxLoadPanel :enabled="true" />
								<DxScrolling mode="standard" />
								<DxSelection
									mode="multiple"
									:allow-select-all="true"
									show-check-boxes-mode="always"
									select-all-mode="allPages"
								/>

								<DxColumn
									caption="회차"
									data-field="evalRound"
									alignment="center"
									:allowEditing="false"
									:allow-sorting="false"
									width="80"
									cell-template="evalRoundTemplate"
								/>
								<template #evalRoundTemplate="{ data }">
									<div class="text-blue-500 underline cursor-pointer w-full">{{ data.data.evalRound }}</div>
								</template>
								<DxColumn
									caption="추출시작일자"
									alignment="center"
									data-field="recStartDt"
									data-type="date"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									format="yyyy-MM-dd"
								>
									<DxRequiredRule message="추출시작일자는 필수입니다." />
									<!-- <DxCustomRule
										message="추출시작일자는 평가기간 내여야 합니다."
										:ignore-empty-value="true"
										:validation-callback="options => {
											return options.data.recStartDt >= evalScheduleInfo.evalStartDt &&
												options.data.recStartDt <= evalScheduleInfo.evalEndDt;
										}"
									/> -->
								</DxColumn>
								<DxColumn
									caption="추출종료일자"
									alignment="center"
									data-type="date"
									data-field="recEndDt"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									format="yyyy-MM-dd"
								>
									<DxRequiredRule message="추출종료일자는 필수입니다." />
									<!-- <DxCustomRule
										message="추출종료일자는 평가기간 내여야 합니다."
										:ignore-empty-value="true"
										:validation-callback="options => {
											return options.data.recEndDt >= evalScheduleInfo.evalStartDt &&
												options.data.recEndDt <= evalScheduleInfo.evalEndDt;
										}"
									/> -->
									<DxCustomRule
										message="추출종료일자는 추출시작일자와 같거나 이후여야 합니다."
										:ignore-empty-value="true"
										:validation-callback="
											options => {
												return options.data.recStartDt ? options.data.recEndDt >= options.data.recStartDt : true;
											}
										"
									/>
								</DxColumn>
								<DxColumn
									caption="추출시작시간"
									alignment="center"
									data-field="recStartTime"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									:calculate-display-value="
										rowData =>
											rowData.recStartTime ? formatDate(rowData.recStartTime, 'HHmm', 'HH:mm') : null
									"
									:editor-options="{ maxLength: 4 }"
								>
									<DxRequiredRule message="추출시작시간은 필수입니다." />
									<DxPatternRule :pattern="valid.timePattern" message="24시간제 시간으로 입력하세요(0000~2359)" />
								</DxColumn>
								<DxColumn
									caption="추출종료시간"
									alignment="center"
									data-field="recEndTime"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									:calculate-display-value="
										rowData =>
											rowData.recEndTime ? formatDate(rowData.recEndTime, 'HHmm', 'HH:mm') : null
									"
									:editor-options="{ maxLength: 4 }"
								>
									<DxRequiredRule message="추출종료시간은 필수입니다." />
									<DxPatternRule :pattern="valid.timePattern" message="24시간제 시간으로 입력하세요(0000~2359)" />
									<DxCustomRule
										message="추출종료시간은 추출시작시간 이후여야 합니다."
										:ignore-empty-value="true"
										:validation-callback="
											options => {
												return options.data.recStartTime
													? options.data.recEndTime > options.data.recStartTime
													: true;
											}
										"
									/>
								</DxColumn>
								<DxColumn
									caption="최소상담시간(초)"
									alignment="center"
									data-field="recMinTime"
									data-type="number"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
								>
									<DxRequiredRule message="최소상담시간은 필수입니다." />
									<DxRangeRule message="1 이상 입력하세요." :min="1" />
								</DxColumn>
								<DxColumn
									caption="최대상담시간(초)"
									data-field="recMaxTime"
									data-type="number"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									alignment="center"
								>
									<DxRequiredRule message="최대상담시간은 필수입니다." />
									<DxRangeRule message="1 이상 입력하세요." :min="1" />
									<DxCustomRule
										message="최대상담시간은 최소상담시간과 같거나 커야 합니다."
										:ignore-empty-value="true"
										:validation-callback="
											options => {
												return options.data.recMinTime ? options.data.recMaxTime >= options.data.recMinTime : true;
											}
										"
									/>
								</DxColumn>
								<DxColumn
									caption="문의유형(대)"
									data-field="recDivisionUpperCd"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									alignment="center"
								>
									<DxLookup
										:data-source="dataGridLoopup.recDivisionUpperCd.dataSource"
										value-expr="codeId"
										display-expr="codeNm"
									/>
								</DxColumn>
								<DxColumn
									caption="문의유형(중)"
									data-field="recDivisionMiddleCd"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									alignment="center"
								>
									<DxLookup
										:data-source="dataGridLoopup.recDivisionMiddleCd.dataSource"
										value-expr="codeId"
										display-expr="codeNm"
									/>
								</DxColumn>
								<DxColumn
									caption="문의유형(소)"
									data-field="recDivisionLowerCd"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									alignment="center"
								>
									<DxLookup
										:data-source="dataGridLoopup.recDivisionLowerCd.dataSource"
										value-expr="codeId"
										display-expr="codeNm"
									/>
								</DxColumn>
								<DxColumn
									caption="추출건"
									data-field="recCount"
									data-type="number"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									alignment="center"
								>
									<DxRequiredRule message="추출건은 필수입니다." />
									<DxRangeRule message="1 이상 입력하세요." :min="1" />
								</DxColumn>
								<DxColumn
									caption="추출방식"
									data-field="recDrawTypeCd"
									:allowEditing="evalScheduleInfo.canModify"
									:allow-sorting="false"
									alignment="center"
								>
									<DxLookup
										:data-source="dataGridLoopup.recDrawTypeCd.dataSource"
										value-expr="codeId"
										display-expr="codeNm"
									/>
									<DxRequiredRule message="추출방식은 필수입니다." />
								</DxColumn>
							</DxDataGrid>
						</div>
					</div>
				</div>
				<div class="flex gap-x-6">
					<div class="w-1/3">
						<h3 class="text-xl font-medium my-4">평가자 목록</h3>
						<div>
							<div class="flex justify-start items-end">
								<DxButton
									text="평가자추가"
									type="button"
									class="btn_XS default filled add1"
									:height="30"
									:disabled="!evalScheduleInfo.canModify"
									@click="onAddManager"
								/>
								<DxButton
									text="삭제"
									type="button"
									class="btn_XS white light_filled trash"
									:height="30"
									:disabled="!evalScheduleInfo.canModify"
									@click="onManagerDeleteData"
								/>
								<div class="flex justify-end flex-grow">
									<div class="total-count-item">
										검색결과 <span class="tet-calr1">{{ managerTotalCount }}</span> 개
									</div>
								</div>
							</div>
							<div class="mt-4">
								<DxDataGrid
									class="grid-box eval-grid"
									key-expr="id"
									ref="evalManagerGrid"
									:data-source="managerGridData.dataSource"
									:allow-column-resizing="true"
									:column-resizing-mode="'nextColumn'"
									:show-borders="false"
									:show-column-headers="true"
									:show-column-lines="true"
									:show-row-lines="true"
									:row-alternation-enabled="false"
									:hover-state-enabled="true"
									:word-wrap-enabled="true"
									:no-data-text="this.$_lang('CMN_NO_DATA')"
									:selected-row-keys="managerGridData.selectedRowKeys"
									width="100%"
									:height="evalScheduleInfo.evalDivisionNm === '일반평가' ? 670 : 400"
								>
									<DxLoadPanel :enabled="true" />
									<DxScrolling mode="virtual" />
									<DxSelection
										mode="multiple"
										:allow-select-all="true"
										show-check-boxes-mode="always"
										select-all-mode="allPages"
									/>
									<DxSorting mode="multiple" />

									<DxColumn caption="부서" data-field="deptNmPath" :allowEditing="false" :allow-sorting="false" />
									<DxColumn
										caption="평가자[ID]"
										data-field="managerId"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
										:calculate-display-value="rowData => `${rowData.managerNm}[${rowData.managerId}]`"
									/>
									<DxColumn
										caption="배정대상자"
										data-field="targetNum"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
										width="100"
									/>
								</DxDataGrid>
							</div>
						</div>
					</div>
					<div class="w-2/3">
						<h3 class="text-xl font-medium my-4">평가대상자 목록</h3>
						<div>
							<div class="flex justify-start items-end">
								<DxButton
									text="대상자추가"
									type="button"
									class="btn_XS default filled add1"
									:height="30"
									:disabled="!evalScheduleInfo.canModify"
									@click="onAddTarget"
								/>
								<DxButton
									text="평가자자동배정"
									type="button"
									class="btn_XS default filled"
									:height="30"
									:disabled="!evalScheduleInfo.canModify"
									@click="onAssignManager"
								/>
								<DxButton
									text="삭제"
									type="button"
									class="btn_XS white light_filled trash"
									:height="30"
									:disabled="!evalScheduleInfo.canModify"
									@click="onTargetDeleteData"
								/>

								<div class="flex justify-end flex-grow">
									<div class="total-count-item">
										검색결과 <span class="tet-calr1">{{ targetTotalCount }}</span> 개
									</div>
								</div>
							</div>
							<div class="mt-4">
								<DxDataGrid
									class="grid-box eval-grid"
									key-expr="id"
									ref="evalTargetGrid"
									:data-source="targetGridData.dataSource"
									:allow-column-resizing="true"
									:column-resizing-mode="'nextColumn'"
									:show-borders="false"
									:show-column-headers="true"
									:show-column-lines="true"
									:show-row-lines="true"
									:row-alternation-enabled="false"
									:hover-state-enabled="true"
									:word-wrap-enabled="true"
									:no-data-text="this.$_lang('CMN_NO_DATA')"
									:selected-row-keys="targetGridData.selectedRowKeys"
									@selection-changed="onEvalTargetGridSelectionChanged"
									width="100%"
									:height="evalScheduleInfo.evalDivisionNm === '일반평가' ? 670 : 400"
								>
									<DxLoadPanel :enabled="true" />
									<DxScrolling mode="standard" />
									<DxSelection
										mode="multiple"
										:allow-select-all="true"
										show-check-boxes-mode="always"
										select-all-mode="allPages"
									/>
									<DxSorting mode="multiple" />

									<DxColumn caption="부서정보" data-field="targetDeptNmPath" :allow-sorting="true" />
									<DxColumn
										caption="업무그룹"
										data-field="targetWorkgroupCd"
										:allow-sorting="true"
										alignment="center"
										:calculate-sort-value="data => calculateSortValue(data, 'targetWorkgroupCd')"
									>
										<DxLookup
											:data-source="dataGridLoopup.workgroupCd.dataSource"
											value-expr="codeId"
											display-expr="codeNm"
										/>
									</DxColumn>
									<DxColumn
										caption="대상자[ID]"
										data-field="targetNm"
										:allow-sorting="true"
										alignment="center"
										:visible="false"
									/>
									<DxColumn
										caption="대상자[ID]"
										data-field="targetNmAgtId"
										:allow-sorting="true"
										alignment="center"
										:calculate-cell-value="rowData => `${rowData.targetNm}[${rowData.targetId}]`"
									/>
									<!-- <DxColumn
										caption="평가자[ID]"
										data-field="managerId"
										:allowEditing="false"
										:allow-sorting="false"
										alignment="center"
										:calculate-display-value="rowData => rowData.managerNm ? `${rowData.managerNm}[${rowData.managerId}]` : null"
									/> -->
									<DxColumn
										caption="평가자[ID]"
										data-field="managerNm"
										cell-template="managerTemplate"
										:allow-sorting="true"
										alignment="center"
										width="200"
										:visible="false"
									/>
									<DxColumn
										caption="평가자[ID]"
										data-field="managerNmAgtId"
										cell-template="managerTemplate"
										:allow-sorting="true"
										alignment="center"
										width="200"
										:calculate-cell-value="rowData => `${rowData.managerNm}[${rowData.managerId}]`"
									/>

									<template #managerTemplate="{ data }">
										<div class="flex flex-nowrap justify-end items-center gap-x-4">
											<div>
												{{ data.data.managerNm ? `${data.data.managerNm}[${data.data.managerId}]` : null }}
											</div>
											<DxButton
												text="편집"
												class="btn_XS white light_filled"
												:disabled="!evalScheduleInfo.canModify"
												@click="onEditTargetManager($event, data)"
											/>
										</div>
									</template>
								</DxDataGrid>
							</div>
						</div>
					</div>
				</div>
			</div>

			<modal-add-agent
				:isOpen="modalAddAgent.visible"
				:showModalTitle="true"
				:selectedIdList="modalAddAgent.selectedIdList"
				:maximumSelectionLength="modalAddAgent.maximumSelectionLength"
				@closeModal="onModalAddAgentClose"
				@saveModal="onModalAddAgentSave"
			/>
		</div>
		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<div class="bottom-btn-wrap">
					<DxButton text="닫 기" class="btn_XS white filled txt_S medium" :width="120" :height="40" @click="onCancelFormData" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
import { DxButton } from 'devextreme-vue/button';
import { DxDataGrid, DxColumn, DxEditing, DxLoadPanel, DxLookup, DxScrolling, DxSelection, DxSorting } from 'devextreme-vue/data-grid';
import { DxCustomRule, DxPatternRule, DxRangeRule, DxRequiredRule } from 'devextreme-vue/validator';

import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
import CustomStore from "devextreme/data/custom_store";

let vm = null;

export default {
  name: 'EWMEvaluationScheduleDetail',
	components: {
		DxButton,

		DxColumn,
		DxDataGrid,
		DxEditing,
		DxLoadPanel,
		DxLookup,
		DxScrolling,
		DxSelection,
		DxSorting,

		DxCustomRule,
		DxPatternRule,
		DxRangeRule,
		DxRequiredRule,

		ModalAddAgent,
	},
	props: {},
	watch: {},
	data() {
		return {
			codeMap: {},
			dataGridLoopup: {
				workgroupCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				recDrawTypeCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				recDivisionUpperCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				recDivisionMiddleCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
				recDivisionLowerCd: {
					dataSource: [],
					displayExpr: 'codeNm',
					valueExpr: 'codeId',
				},
			},
			modalAddAgent: {
				visible: false,
				selectedIdList: [],
				target: null,
				maximumSelectionLength: 0,
			},
			valid: {
				timePattern: /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/,
				minDate: getPastFromToday(20, 'years'),
				evalFeedbackStartDtMin: getPastFromToday(20, 'years'),
			},
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			evalScheduleInfo: {
				id: null,
				evalDivisionCd: null,
				evalDivisionNm: null,
				scheNm: null,
				evalSheetId: null,
				sheetNm: null,
				scheYmd: null,
				evalStartDt: null,
				evalEndDt: null,
				evalFeedbackStartDt: null,
				evalFeedbackEndDt: null,
				evalProcessCd: null,
				evalProcessNm: null,
				viewFl: 'Y',
				delFl: 'N',
				regId: this.$store.getters.getLoginId,
				canModify: true,
			},
			roundGridData: {
				dataSource: null,
				isClone: false,
				selectedCopyData: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				selectedRoundId: '',
				addedRowCount: 0,
			},
			managerTotalCount: 0,
			managerGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			targetTotalCount: 0,
			targetGridData: {
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
			},
			limitNumberTexts: {
				textLengths: {},
				maxLengths: {
					scheNm: 85,
				},
			},
		};
	},
	computed: {},
	methods: {
    formatDate,
		/** @description : 소트설정 */
		calculateSortValue(data, clsCd) {
			if (clsCd == 'targetWorkgroupCd') {
				return this.dataGridLoopup.workgroupCd.dataSource.find(e => data.targetWorkgroupCd == e.codeId).codeNm;
			}
		},
		/** @description : 인사팝업 열기 */
		onModalAddAgentOpen() {
			this.modalAddAgent.visible = true;
		},
		/** @description : 인사팝업 닫기 */
		onModalAddAgentClose() {
			this.modalAddAgent.visible = false;
			this.modalAddAgent.selectedIdList = [];
		},
		/** @description : 인사팝업 저장버튼 클릭 */
		onModalAddAgentSave(agtData) {
			this.onModalAddAgentClose();

			if (!agtData.length) {
				return;
			}

			if (this.modalAddAgent.target === 'MANAGER') {
				this.onManagerSaveData(agtData);
			} else if (this.modalAddAgent.target === 'TARGET') {
				this.onTargetSaveData(agtData);
			} else if (this.modalAddAgent.target === 'TARGET-MANAGER') {
				this.onTargetManagerSaveData(agtData);
			}
		},
		onRoundGridEditCanceled() {
			this.roundGridData.addedRowCount = 0;
		},
		/** @description : 회차 조회 */
		async selectRoundDataList() {
			this.roundGridData.dataSource = new CustomStore({
				key: 'id',
				async load() {
					const payload = {
						actionName: 'EWM_EVALUATION_SCHE_ROUND_LIST',
						data: {
							scheId: vm.evalScheduleInfo.id,
						},
						loading: false,
					};

					const res = await vm.CALL_EWM_API(payload);
					if (isSuccess(res)) {
						const rtnData = {
							data: res.data.data,
						};

						return rtnData;
					}
				},
			});
		},
		evalScheRoundGridRefresh() {
			//행추가 데이터 초기화
			this.roundGridData.addedRowCount = 0;
			this.managerGridData.dataSource = null;
			this.targetGridData.dataSource = null;
			this.roundGridData.selectedRowKeys = [];

			this.$refs['evalScheRoundGrid'].instance.refresh();
		},
		/** @description : 평가자 조회 */
		async selectManagerDataList() {
			if (vm.evalScheduleInfo.evalDivisionNm != '일반평가' && !vm.roundGridData.selectedRoundId) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_MANAGER_LIST',
				data: {
					scheId: vm.evalScheduleInfo.id,
					roundId: vm.roundGridData.selectedRoundId,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.managerGridData.dataSource = res.data.data;
				this.managerTotalCount = res.data.data.length;
			}
		},
		/** @description : 평가대상자 조회 */
		async selectTargetDataList() {
			if (vm.evalScheduleInfo.evalDivisionNm != '일반평가' && !vm.roundGridData.selectedRoundId) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_TARGET_LIST',
				data: {
					scheId: vm.evalScheduleInfo.id,
					roundId: vm.roundGridData.selectedRoundId,
				},
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.targetGridData.dataSource = res.data.data;
				this.targetTotalCount = res.data.data.length;
			}
		},
		/** @description : 평가자,대상자 조회 */
		selectManagerAndTargetDataList() {
			this.selectManagerDataList();
			this.selectTargetDataList();
		},
		/** @description : 회차 저장 */
		async onRoundSaveData(e) {
			e.cancel = true;

			if (!(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), { title: `<h2>알림</h2>` }))) {
				return;
			}

			if (!e.changes && e.changes.length < 1) {
				return;
			}

			let data = [];

			e.changes.forEach(d => {
				if (d.type === 'update') {
					//d.data.editId = this.$store.getters.getLoginId; //수정자
					let rowIndex = e.component.getRowIndexByKey(d.key);
					let row = e.component.getVisibleRows()[rowIndex];
					let updateData = row.data;

					data.push(updateData);
				} else if (d.type === 'insert') {
					if (Object.keys(d.data).length !== 0) {
						//d.data.regId = this.$store.getters.getLoginId; //등록자
						data.push({ id: null, ...d.data });
					}
				}
			});

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_ROUND_SAVE',
				data: { data: data },
			};

			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				e.component.cancelEditData();
				//e.component.repaint();

				vm.evalScheRoundGridRefresh();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 회차 삭제 */
		async onRoundDeleteData() {
			const selectedRowKeys = this.roundGridData.selectedRowKeys;

			if (!selectedRowKeys?.length) {
				this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_ROUND_DELETE',
				data: selectedRowKeys,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_DELETE'));

				vm.evalScheRoundGridRefresh();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/**@description : 회차 선택 확인 */
		validRoundId() {
			if (this.evalScheduleInfo.evalDivisionNm !== '일반평가' && !this.roundGridData.selectedRoundId) {
				this.$_Msg('회차를 선택하세요.');
				return false;
			}
			return true;
		},
		/** @description : 평가자 추가 */
		async onAddManager() {
			if(!this.validRoundId()) {
				return;
			}
			this.modalAddAgent.maximumSelectionLength = 0;
			this.modalAddAgent.target = 'MANAGER';
			this.onModalAddAgentOpen();
		},
		/** @description : 평가자 저장 */
		async onManagerSaveData(agtData) {
			/* if (!await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {title: `<h2>알림</h2>`})) {
				return;
			} */
			const filtedAgtIds = agtData.filter(agent => this.managerGridData.dataSource.findIndex(z => z.managerId == agent.agtid) < 0);

			const data = filtedAgtIds.map(agent => {
				return {
					scheId: vm.evalScheduleInfo.id,
					roundId: vm.roundGridData.selectedRoundId,
					managerId: agent.agtid,
				};
			});

			if (data.length < 1) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_MANAGER_SAVE',
				data: { data: data },
			};

			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				vm.selectManagerDataList();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 평가자 삭제 */
		async onManagerDeleteData() {
			const selectedRowKeys = this.$refs.evalManagerGrid.instance.getSelectedRowKeys();

			if (!selectedRowKeys?.length) {
				this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_MANAGER_DELETE',
				data: selectedRowKeys,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
				this.selectManagerAndTargetDataList();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 대상자 추가 */
		async onAddTarget() {
			if(!this.validRoundId()) {
				return;
			}
			this.modalAddAgent.maximumSelectionLength = 0;
			this.modalAddAgent.target = 'TARGET';

			this.onModalAddAgentOpen();
		},
		/** @description : 대상자 저장 */
		async onTargetSaveData(agtData) {
			const filtedAgtIds = agtData.filter(agent => this.targetGridData.dataSource.findIndex(z => z.targetId == agent.agtid) < 0);

			const data = filtedAgtIds.map(agent => {
				return {
					scheId: vm.evalScheduleInfo.id,
					roundId: vm.roundGridData.selectedRoundId,
					targetId: agent.agtid,
				};
			});

			if (data.length < 1) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_TARGET_SAVE',
				data: { data: data },
			};

			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				vm.selectManagerAndTargetDataList();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 평가대상자 그리드 selectionChanged */
		onEvalTargetGridSelectionChanged(e) {
			this.targetGridData.selectedRowsData = e.selectedRowsData;
		},
		/** @description : 평가자자동배정 */
		async onAssignManager() {
			this.targetGridData.selectedRowKeys = [];

			if(!this.validRoundId()) {
				return;
			}

			if (!this.managerGridData.dataSource.length) {
				this.$_Msg('평가자를 등록하세요');
				return;
			}

			if (!(await this.$_Confirm('기존 배정된 평가자도 자동배정 됩니다.\n자동배정 하시겠습니까?', { title: `<h2>알림</h2>` }))) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_TARGET_MANAGER_ASSIGN',
				data: {
					scheId: vm.evalScheduleInfo.id,
					roundId: vm.roundGridData.selectedRoundId,
				},
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.selectManagerAndTargetDataList();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 대상자 삭제 */
		async onTargetDeleteData() {
			const selectedRowKeys = this.$refs.evalTargetGrid.instance.getSelectedRowKeys();

			if (!selectedRowKeys?.length) {
				this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}

			if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
				return;
			}

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_TARGET_DELETE',
				data: selectedRowKeys,
				loading: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
				this.selectManagerAndTargetDataList();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description : 평가자 배정 편집 */
		onEditTargetManager(e, templateData) {
			//this.$_Msg(templateData.data.targetNm + ' 평가자 배정 편집');

			this.targetGridData.selectedRowKeys = [templateData.data.id];
			this.modalAddAgent.selectedIdList = [templateData.data.managerId];
			this.modalAddAgent.maximumSelectionLength = 1;

			this.modalAddAgent.target = 'TARGET-MANAGER';
			this.onModalAddAgentOpen();
		},
		/** @description : 평가자배정 저장 */
		async onTargetManagerSaveData(agtData) {
			//const filtedAgtIds = agtData.filter(agtId => this.targetGridData.dataSource.findIndex(z => z.targetId == agtId) < 0);
			if (agtData.length < 1) {
				return;
			}

			if (this.managerGridData.dataSource.findIndex(manager => manager.managerId == agtData[0].agtid) < 0) {
				this.$_Msg('평가자로 등록되어 있지 않습니다. 평가자로 먼저 등록하세요');
				return;
			}
			if (agtData[0] == this.targetGridData.selectedRowsData[0].managerId) {
				this.$_Msg('평가자와 대상자가 동일합니다. 평가자를 다시 선택하세요.');
				return;
			}

			const data = {
				id: this.targetGridData.selectedRowKeys[0],
				scheId: vm.evalScheduleInfo.id,
				roundId: vm.roundGridData.selectedRoundId,
				targetId: this.targetGridData.selectedRowsData[0].targetId,
				managerId: agtData[0].agtid,
			};

			const payload = {
				actionName: 'EWM_EVALUATION_SCHE_TARGET_MANAGER_SAVE',
				data: data,
			};

			const res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				vm.selectManagerAndTargetDataList();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		onRoundGridInitNewRow(e) {
			vm.roundGridData.addedRowCount++;

			if (this.roundGridData.isClone) {
				e.data = Object.assign({}, this.roundGridData.selectedCopyData);
				delete e.data.id; // remove PK property
				this.roundGridData.isClone = false;
			}

			e.data.scheId = this.evalScheduleInfo.id;
			e.data.evalRound = e.component.totalCount() + vm.roundGridData.addedRowCount;
		},
		onEvalScheRoundGridToolbarPreparing(e) {
			const toolbarItems = e.toolbarOptions.items;

			toolbarItems.forEach(item => {
				if (item.name === 'saveButton') {
					item.location = 'before';
					item.sortIndex = 40;
					item.options.icon = '';
					item.options.text = '회차저장';
					item.showText = 'always';
					item.options.elementAttr = { class: 'btn_XS default filled ' };
					item.options.width = '70';
					item.options.height = '30';
					item.options.visible = true;
					item.options.disabled = !vm.evalScheduleInfo.canModify;
				} else if (item.name === 'addRowButton') {
					item.location = 'before';
					item.sortIndex = 30;
					item.options.icon = '';
					item.options.text = '회차추가';
					item.showText = 'always';
					item.options.elementAttr = { class: 'btn_XS default filled add1' };
					item.options.width = '90';
					item.options.height = '30';
					item.options.visible = true;
					item.options.disabled = !vm.evalScheduleInfo.canModify;
				} else if (item.name === 'revertButton') {
					item.location = 'before';
					item.sortIndex = 50;
					item.options.icon = '';
					item.options.text = '취소';
					item.options.elementAttr = { class: 'btn_XS white light_filled ' };
					item.showText = 'always';
					item.options.width = '60';
					item.options.height = '30';
					item.options.visible = true;
				}
			});

			toolbarItems.unshift({
				location: 'before',
				widget: 'dxButton',
				options: {
					icon: '',
					type: 'normal',
					text: '복사',
					elementAttr: { class: 'btn_XS default filled' },
					width: 60,
					height: 30,
					disabled: !vm.evalScheduleInfo.canModify,
					onClick: () => vm.onRoundCopyData(),
				},
				sortIndex: 10,
			});

			toolbarItems.push({
				widget: 'dxButton',
				options: {
					icon: '',
					text: '삭제',
					showText: 'always',
					elementAttr: { class: 'btn_XS white light_filled trash' },
					width: 60,
					height: 30,
					disabled: !vm.evalScheduleInfo.canModify,
					onClick: () => vm.onRoundDeleteData(),
				},
				location: 'before',
				sortIndex: 60,
			});
		},
		// onRoundGridCellClick(e) {
		// 	if (e.rowType === 'data' && e.column.dataField === 'evalRound') {
		// 		this.roundGridData.selectedRowKeys = [e.data.id];
		// 	}
		// },
		/** @description : 상담사 클릭 이벤트  */
		onRowClick(e) {
			// this.$refs.surveysGrid.getInstance.selectRows([e.data.id], false);evalScheRoundGrid
			this.roundGridData.selectedRowKeys = [e.data.id];
		},
		onRoundGridSelectionChanged(e) {
			this.roundGridData.selectedRowsData = e.selectedRowsData;
			this.roundGridData.selectedRowKeys = e.selectedRowKeys;

			if (e.selectedRowKeys.length === 1) {
				if (e.selectedRowKeys[0] !== this.roundGridData.selectedRoundId) {
					this.roundGridData.selectedRoundId = e.selectedRowKeys[0];
					this.selectManagerAndTargetDataList();
				}
			} else {
				this.roundGridData.selectedRoundId = null;
				this.managerGridData.dataSource = null;
				this.targetGridData.dataSource = null;
			}
		},
		/** @description : 회차 복사 버튼 클릭 */
		onRoundCopyData() {
			if (vm.roundGridData.selectedRowsData.length < 1) {
				vm.$_Msg(vm.$_lang('CMN_NOT_SELECTED'));
				return;
			}

			vm.roundGridData.selectedRowsData.forEach(element => {
				vm.roundGridData.isClone = true;
				vm.roundGridData.selectedCopyData = element;
				vm.$refs['evalScheRoundGrid'].instance.addRow();
			});
		},
		/** @description : 닫기 버튼 클릭 메서드 */
		onCancelFormData() {
			this.$router.push('/ewm/evaluation/implementation/schedule');
			//this.createdData();
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList(
				'root_ewm_eval_division,root_ewm_eval_process,root_ewm_hr_workgroup,root_ewm_eval_rec_division,root_ewm_eval_rec_draw_type',
			);
		},
		/** @description: 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			vm = this;

			this.reqParams = this.$store.getters.getDetailParams;
			if (!this.reqParams) {
				this.$_goPrePage();
				return;
			}

			//this.selectDataList();
			await this.initCodeMap().then(() => {
				this.reqParams.evalDivisionNm = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2).find(
					c => c.codeId === this.reqParams.evalDivisionCd,
				).codeNm;

				/* this.reqParams.evalProcessNm = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_process'], 2).find(
					c => c.codeId === this.reqParams.evalProcessCd,
				).codeNm; */
				this.reqParams.evalProcessNm = this.$_enums.ewm.evalProcess.values.find(c => c.value === this.reqParams.evalProcessCd).label;

				this.dataGridLoopup.workgroupCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_workgroup'], 2);

				this.dataGridLoopup.recDivisionUpperCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_rec_division'], 2);
				this.dataGridLoopup.recDivisionMiddleCd.dataSource = this.$_fetchCodesByDepth(
					this.codeMap['root_ewm_eval_rec_division'],
					3,
				);
				this.dataGridLoopup.recDivisionLowerCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_rec_division'], 4);

				this.dataGridLoopup.recDrawTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_rec_draw_type'], 2);
			});

			this.evalScheduleInfo = this.reqParams;
			this.evalScheduleInfo.canModify = this.reqParams.evalProcessNm === this.$_enums.ewm.evalProcess.READY.label;

			this.$refs.evalScheRoundGrid.instance.option('onToolbarPreparing', this.onEvalScheRoundGridToolbarPreparing);

			this.selectRoundDataList();
			this.selectManagerAndTargetDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>
<style>
.eval-grid .dx-datagrid-header-panel {
	padding: 0;
	margin-bottom: 0.875rem;
}

.total-count-item {
	font-size: 13px;
	font-weight: normal;
	color: #545454;
}
</style>
