<!--
  PACKAGE_NAME : src\pages\op\user\list.vue
  FILE_NAME : list
  AUTHOR : ycnam
  DATE : 2024-07-10
  DESCRIPTION : 운영관리 > 인사/조직 > 사용자관리 화면
-->
<template>
  <div class="main">
    <esp-dx-data-grid :data-grid="dataGrid" ref="userGrid" />
    <modal-add-user
      v-if="modal.visible"
      :is-open="modal.visible"
      :user-data="modal.data"
      @saveModal="handleSaveModal"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import ModalAddUser from "@/pages/esp/hr/user/modal-add-user.vue";
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
  import EspDeleteButton from "@/components/common/buttons/esp-delete-button.vue";
  import {mountComponent} from "@/utils/devextreme-util";

  export default {
    name: 'ESPUserList',
    components: {
      EspDxDataGrid,
      ModalAddUser,
    },
    data() {
      return {
        modal: {
          visible: false,
          data: {},
        },
        openModal: false,
        dataGrid: {
          refName: 'userGrid',
          dataSource: [],
          apiActionNm: {
            select: 'USER_SELECT',
            //delete: 'USER_DELETE',
          },
          showActionButtons: {
            excel: true,
            delete: false,
            customButtons: [
              {
                template: (data, index, element) => {
                  mountComponent(
                    element,
                    EspDxButton,
                    {
                      i18n: 'COMPONENTS.ADD',
                      prefixIcon: 'add1',
                    },
                    {
                      handleClick: () => this.handleOpenModal(),
                    },
                    this,
                  );
                },
                location: 'before',
              },
              {
                template: (data, index, element) => {
                  mountComponent(
                    element,
                    EspDeleteButton,
                    {},
                    {
                      handleClick: () => this.handleDeleteUser()
                    },
                    this,
                  );
                },
                location: 'before',
              },
              {
                template: (data, index, element) => {
                  mountComponent(
                    element,
                    EspDxButton,
              {
                      i18n: 'COMPONENTS.UPLOAD',
                      color: 'white',
                      mode: 'outlined',
                      hint: '엑셀 파일로 상담원을 일괄 등록 합니다.',
                    },
             {
                      handleClick: () => {
                        // TODO : 김병윤, 엑셀업로드 구현 필요
                        this.$_Msg('일괄 업로드 기능은 준비중입니다.');
                      }
                    },
                    this,
                  );
                },
                location: 'before',
              },
            ],
          },
          editing: {
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false 설정
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false, // 추가 버튼을 없애고 싶으면 false 설정
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              visible: false,
            },
            {
              caption: '사용자 No',
              dataField: 'userNo',
            },
            {
              caption: '사용자명',
              dataField: 'userNm',
              cellTemplate: (container, options) => {
                if (options.value) {
                  const aTag = document.createElement('a');
                  aTag.innerText = options.value;
                  aTag.addEventListener('click', () => {
                    this.handleOpenModal(options.data);
                  });
                  container.append(aTag);
                }
              },
            },
            {
              caption: '사이트/센터/부서',
              dataField: 'siteNm',
              calculateCellValue: this.mergedDept,
            },
            {
              caption: '도급사',
              dataField: 'companyNm',
            },
            {
              caption: '사용여부',
              dataField: 'viewFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description 사이트/센터/부서 병합 */
      mergedDept(rowData) {
        if (!rowData) {
          return;
        }
        return `${rowData.siteNm} / ${rowData.tenantNm} / ${rowData.deptNm}`;
      },
      /**
       * @description 팝업 열기
       * @param data 모달로 넘길 설정 데이터
       * */
      handleOpenModal(data) {
        this.modal.visible = true;
        this.modal.data = data;
      },
      /**
       * @description : 팝업 저장
       */
      async handleSaveModal() {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        await this.$refs[this.dataGrid.refName].refreshData();
        this.modal.visible = false;
        this.modal.data = {};
      },
      /**
       * @description : 팝업 닫기
       */
      async handleCloseModal() {
        this.modal.visible = false;
        this.modal.data = {};
      },
      /** @description : 삭제 이벤트 */
      async handleDeleteUser() {
        let selectedRows = this.$refs[this.dataGrid.refName].getInstance.getSelectedRowsData();
        if (selectedRows.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
          return;
        }

        let deleteCtiId = [];

        for (let row of selectedRows) {
          deleteCtiId.push(row.ctiId);
        }

        const payload = {
          actionName: 'USER_DELETE',
          data: {
            data: {
              from: 'core',
              data: deleteCtiId,
            },
          },
          loading: true,
        };

        const res = await this.CALL_API(payload);
        if (res.status === 200) {
          this.$_Msg('삭제성공');
          this.selectDataList();
        } else {
          this.$_Msg('삭제실패');
        }
      },
      /** @description : 상담사 이름 + 상담사 로그인아이디 병합  */
      mergeUserInfo(rowData) {
        if (rowData === null || rowData === undefined) {
          return '';
        }
        return `${rowData.userNm} [${rowData.userNo}]`;
      },
    },
    created() {},
    mounted() {},
  };
</script>
