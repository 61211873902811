<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName"></esp-dx-data-grid>
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    name: 'CCTeamConfig',
    components: {
      EspDxDataGrid,
    },
    props: {},
    watch: {},
    data() {
      return {
        dataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'teamGrid',
          keyExpr: ['id', 'siteId'], // 복합키 설정
          dataSourceDefaultSortColumn: '+agtteamOrd',
          dataSource: [],
          apiActionNm: {
            select: 'CC_AGTTEAM_LIST',
            update: 'CC_AGTTEAM_MERGE',
          },
          showActionButtons: {
            select: true,
            copy: false,
            delete: false,
          },
          dragging: { // 드래그 설정
            sortKey: 'agtteamOrd', // 정렬 컬럼
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            changes: [], //null값으로 두면 에러남
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
          },
          columns: [
            {
              caption: '사이트명',
              i18n: 'COMMON.WORD.SITE_NM',
              dataField: 'siteId',
              lookup: {
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
              requiredRule: {},
            },
            {
              caption: '센터명',
              i18n: 'COMMON.WORD.TENANT_NM',
              dataField: 'tenantId',
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
              requiredRule: {},
            },
            {
              caption: '팀ID',
              i18n: 'CC.WORD.TEAM' + 'ID',
              dataField: 'id',
              allowUpdating: false,
              requiredRule: {},
            },
            {
              caption: '팀명',
              i18n: 'CC.WORD.TEAM_NAME',
              dataField: 'agtteamNm',
              requiredRule: {},
            },
            {
              caption: 'CTI 이름',
              i18n: 'CC.WORD.CTI_NAME',
              dataField: 'agtteam',
              allowUpdating: false,
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'agtteamOrd',
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
  };
</script>
