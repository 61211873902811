<!--
  PACKAGE_NAME : src\pages\cc\emc\config\agent.vue
  FILE_NAME : agent
  AUTHOR : ycnam
  DATE : 2024-11-21
  DESCRIPTION : EMC 상담원 관리
-->
<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid 
        :data-grid="dataGrid" 
        :ref="dataGrid.refName" 
        @init-new-row="onInitNewRow"
        @saving="onSaveData" 
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { isSuccess } from '@/utils/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
  import { DxSelectBox } from "devextreme-vue/select-box";

  export default {
    name: 'EMCAgentConfig',
    components: {
      EspDxDataGrid,
    },
    props: {},
    watch: {},
    data() {
      return {
        agentType: [
          {type: 1, name: '상담원'},
          {type: 2, name: '매니저'},
          {type: 3, name: '관리자'},
        ],
        dataGrid: {
          callApi: 'CALL_EMC_API',
          refName: 'emcAgentGrid',
          keyExpr: 'agentId',
          allowColumnResizing: true, //컬럼 사이즈 허용
          allowReordering: true, //inline속성 느낌
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          hoverStateEnabled: true,
          dataSourceDefaultSortColumn: '+agentId',
          dataSource: [],
          apiActionNm: {
            select: 'EMC_AGT_LIST',
            delete: 'EMC_AGT_DELETE',
          },
          customEvent: {
            initNewRow: true,
            reorder: false,
            saving: true,            
          },
          showActionButtons: {
            // 그리드 버튼 노출 설정값
            select: false,
            update: true,
            delete: true,
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: false,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          dragging: {
            sortColumn: 'agentId',
            allowReordering: false,
            dropFeedbackMode: 'push',
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          // scrolling:{  // 미사용시 주석처리
          //     mode: 'virtual' //스크롤 모드 : ['infinite', 'standard', 'virtual']
          // },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true,
            allowDeleting: false,
            allowAdding: true,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '상담원',
              i18n: 'EMC.WORD.AGTID',
              dataField: 'agentId',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              validationRules: [
                {
                  type: 'required',
                  message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: '상담원 이름',
              i18n: 'EMC.WORD.AGTNAME',
              dataField: 'agentName',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              validationRules: [
                {
                  type: 'required',
                  message: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE', { defaultValue: '필수값 입니다.' }),
                },
              ],
            },
            {
              caption: '상담원 유형',
              i18n: 'EMC.WORD.AGTTYPE',
              dataField: 'agentType',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              allowEditing: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
              cellTemplate: (container, options) => {
                const selectBox = new DxSelectBox({
                  propsData: {
                    items: this.agentType,
                    placeholder: '선택',
                    displayExpr: 'name',
                    valueExpr: 'type',
                    value: options.data.agentType != null ? options.data.agentType : 1,
                    elementAttr: {
                      class: 'check-type col mar_le15',
                    },
                    stylingMode: this.stylingMode,
                    onValueChanged: e => {
                      this.$refs.emcAgentGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                    },
                  },
                }).$mount();
                container.append(selectBox.$el);
              },
            },
            {
              caption: '팀 정보',
              i18n: 'EMC.WORD.AGTTEAM',
              dataField: 'agentTeam',
              alignment: 'center', // left center right
              visible: true,
              sortOrder: 'none', // acs desc none
              allowHeaderFiltering: false,
              fixed: false, // 컬럼 fix 시 사용
              fixedPosition: 'center', // left or right
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description: 그리드 행 추가시 초기 설정 */
      onInitNewRow(e) {
        e.data.viewFl = 'Y';
      },
      /** @description: 데이터 저장 메서드 */
      async onSaveData(e) {
        e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

        // 변경된 값이 없으면 메시지 출력
        if (e?.changes.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', {defaultValue: '변경된 데이터가 없습니다.'}));
        }

        let data = [];

        e.changes.forEach(d => {
          let dataKey = d.key; // ex) 1
          let dataMap = d.data; // ex) { value: 100, name: 'test' }
          d.data.memberId = this.$store.getters.getLoginId;

          //표시여부
          if (d.data?.viewFl) {
            d.data.viewFl = d.data.viewFl === 'Y' ? 'Y' : 'N';
          }
          // 수정/신규/병합 타입 확인 후 데이터 맵에 등록자/수정자 정보 추가
          if (d.type === 'update') {

            // 병합은 ROW 전체 데이터로 dataMap 설정
            e.component
              .byKey(dataKey)
              .then(data => {
                dataMap = Object.assign(data, d.data); // Object.assign() 으로 기존 데이터에 변경된 데이터를 덮어씌움
              })
              .catch(error => {
                this.$log.error(error);
              });
          }

          data.push(dataMap); // ex) [{ id: 1, value: 100, name: 'test' }]
        });

        const payload = {
          actionName: 'EMC_AGT_MERGE',
          data: data,
        }
        
        const res = await this.CALL_EMC_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          e.component.cancelEditData();
          this.refreshData(); 
        }
      },     
      /** @description : 그리드 refesh 메서드 */
      refreshData() {
        this.$refs[this.dataGrid.refName].refreshData();
      },   
    },
    async created() {},
  };
</script>
