<!--
  PACKAGE_NAME : src\pages\report\bi\index.vue
  FILE_NAME : index.vue
  AUTHOR : kwmoon
  DATE : 2024-06-21
  DESCRIPTION : BI 보고서 화면
-->
<template>
  <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
    <EspProgressLoader ref="progressLoader" :visible="false" :steps="[{ label: 'BI 모듈로드' }, { label: 'BI 데이터 로드' }]" />
    <div class="flex justify-between gap-2">
      <!-- (좌) BI 보고서 목록 -->
      <BiMenuList
        ref="biMenuList"
        :isShow="biMenu.isShow"
        @openWorkBook="openWorkBook"
        :getJsonWorkbook="getJsonWorkbook"
        :getTableNames="getTableNameInDataSheets"
      />

      <!-- (우) SpreadJS 화면 -->
      <div id="spreadJS" class="mt-5" :class="biMenu.isShow ? 'w-4/5' : 'w-full'">
        <template v-if="spreadJS.instance !== null">
          <component
            v-show="spreadJS.isShow"
            :is="spreadJS.componentNm"
            :styleInfo="spreadJS.styles"
            :spreadOptions="spreadJS.options"
            :config="spreadJS.config"
            @designerInitialized="designerInitialized"
          >
            <gc-spread-sheets ref="spreadContainer" />
          </component>
          <div v-show="spreadJS.isShow === false" class="p-10">
            <p><b>BI보고서 추가</b> 또는 <b>메뉴를 선택</b>해주시기 바랍니다.</p>
          </div>
        </template>
      </div>
    </div>

    <!-- (모달) 데이터 시트 추가-->
    <esp-dx-modal-popup
      :isOpen="dataSheet.popup.show"
      :option="{ title: '데이터 추가', width: '95%', height: '90%' }"
      @saveModal="applyDataSheet('add')"
      @closeModal="handleToggleCommonModal('addDataSheet', false)"
    >
      <template #content>
        <tabs ref="tabs" @selectedIndex="changedAddModalTab" :showNav="false">
          <tab :title="item.name" :key="item.id" v-for="item in dataSheet.popup.tabs">
            <div class="pt-2">
              <!-- 탭1: "보고서 데이터 추가" -->
              <wizardDataTable v-if="item.id === dataSheet.wizard" :ref="dataSheet.wizard" />
              <!-- 탭2: "DB 데이터 추가" -->
              <dbDataTable v-if="item.id === dataSheet.dbData" :ref="dataSheet.dbData" />
              <!-- 탭3: "URL 데이터" -->
              <UrlDateTable v-if="item.id === dataSheet.urlData" :ref="dataSheet.urlData" />
            </div>
          </tab>
        </tabs>
      </template>
    </esp-dx-modal-popup>

    <!-- (모달) 템플릿 불러오기 -->
    <esp-dx-modal-popup
      :option="{
        title: '템플릿 불러오기',
        width: 900,
        height: 700,
        minWidth: 900,
        minHeight: 700,
        closeOnOutsideClick: false,
      }"
      :isOpen="templateModal.isOpened"
      @saveModal="setBiTemplateReport"
      @closeModal="handleToggleTemplateModal(false)"
    >
      <template #content>
        <ModalSelectTemplate ref="templateModal" />
      </template>
    </esp-dx-modal-popup>

    <!-- (모달) 데이터 시트 기간 변경 -->
    <esp-dx-modal-popup
      :isOpen="updateDateModal.isOpened"
      :option="{
        title: '데이터 시트 날짜 변경',
        width: 400,
        height: 180,
      }"
      @saveModal="handleApplyUpdateDateModal"
      @closeModal="handleCloseUpdateDateModal"
    >
      <template #content>
        <div class="text-center pt-2">
          <DateRangeBox ref="updateRangeDate" :startDt="updateDateModal.startDt" :endDt="updateDateModal.endDt" />
        </div>
      </template>
    </esp-dx-modal-popup>
  </div>
</template>

<script>
  import DxContextMenu from 'devextreme-vue/context-menu';
  import DxTreeView from 'devextreme-vue/tree-view';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { getPastFromToday, getResData, isEmpty, isSuccess } from '@/utils/common-lib';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxButton } from 'devextreme-vue/button';
  import ModalShareMember from '@/pages/report/bi/modal-share-member.vue';
  import ModalSelectTemplate from '@/pages/report/bi/modal-select-template.vue';
  import CustomDxPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import EspDxModalPopup from '@/components/devextreme/esp-dx-modal-popup.vue';
  import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxColumn, DxSearchPanel, DxSelection, DxTreeList } from 'devextreme-vue/tree-list';
  import { DxFilterRow, DxPaging, DxScrolling, DxSorting } from 'devextreme-vue/data-grid';
  import DxSwitch from 'devextreme-vue/switch';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import SearchBox from '@/components/report/search-box.vue';
  import wizardDataTable from '@/pages/report/bi/wizard-data-table.vue';
  import dbDataTable from '@/pages/report/bi/db-data-table.vue';
  import UrlDateTable from '@/pages/report/bi/url-date-table.vue';
  import { v4 as uuidV4 } from 'uuid';
  import moment from 'moment/moment';
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import { EventBus } from '@/event-bus';
  import BiMenuList from '@/pages/report/bi/bi-menu-list.vue';
  import EspProgressLoader from '@/components/common/esp-progress-loader.vue';

  let vm = null;
  export default {
    name: 'ReportBIIndex',
    components: {
      EspDxModalPopup,
      BiMenuList,
      DateRangeBox,
      UrlDateTable,
      SearchBox,
      DxSearchPanel,
      DxDropDownBox,
      DxSwitch,
      DxColumn,
      DxPaging,
      DxScrolling,
      DxFilterRow,
      DxSorting,
      DxSelection,
      DxTreeList,
      DxToolbarItem,
      DxButton,
      DxTextBox,
      DxRequiredRule,
      DxValidator,
      DxTreeView,
      DxDateBox,
      DxSelectBox,
      DxPopup,
      CustomDxPopup,
      DxContextMenu,
      ModalShareMember,
      ModalSelectTemplate,
      Tabs,
      Tab,
      dbDataTable,
      wizardDataTable,
      EspProgressLoader,
    },
    props: {},
    watch: {},
    data() {
      return {
        //BI SpreadJS 부분
        spreadJS: {
          instance: null,
          isShow: false, //첫 진입 시 false | 목록 추가 시 true (새로운 보고서 추가 시 새로운 메뉴가 포커싱)
          isLoading: false, //로딩 중 다른 작업 방지
          componentNm: 'gc-spread-sheets-designer', //스프레드 시트 컴포넌트 명칭
          //Designer 초기화 시
          workbook: null, //생성된 워크 북 객체
          sheet: null, // 현재 사용하느 시트
          designer: null, // 디자이너 객체
          excelIO: null, // 엑셀 IO 객체
          //설정
          styles: { height: '83vh', width: '100%' },
          options: {
            sheetCount: 1,
          },
          config: null, //디자이너 컴포넌트 Config 정보
        },
        // 데이터 시트
        dataSheet: {
          wizard: 'wizard-report',
          dbData: 'db-data',
          urlData: 'url-data',
          popup: {
            show: false,
            tabIndex: 0,
            selectedTabId: 'wizard-report',
            tabs: [
              { id: 'wizard-report', name: '보고서 데이터' },
              { id: 'db-data', name: 'DB 데이터' },
              // { id: 'url-data', name: 'URL 데이터' },
            ],
          },
        },
        // BI 보고서 리스트 관련
        biMenu: {
          isShow: true, //사이드 메뉴 활성화 여부
          selectedItem: null,
        },
        selectedRowKeys: [],
        previousSelectedKeys: [],
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        // 엑셀 템플릿
        templateModal: {
          isOpened: false,
          component: null, // ModalSelectTemplate
        },

        // (시트 공통) 날짜 변경 모달
        updateDateModal: {
          isOpened: false,
          startDt: getPastFromToday(0, 'days'),
          endDt: getPastFromToday(0, 'days'),
        },
      };
    },
    computed: {
      //"데이터 시트 추가" 시 "보고서 데이터" 컴포넌트
      addWizardDataTable() {
        return this.$refs[this.dataSheet.wizard].at(0);
      },
      /** 데이터 시트 추가 팝업 내  */
      biDataComponent() {
        return this.$refs[this.dataSheet.dbData].at(0);
      },
    },

    methods: {
      /** 보고서 최초 생성 */
      getJsonWorkbook() {
        return JSON.stringify(this.spreadJS.workbook.toJSON());
      },

      /** 현재 SpreadJs 로딩 중인지 체크 하는 함수 */
      validateOnLoading() {
        if (this.spreadJS.isLoading) {
          this.selectedRowKeys = [...this.previousSelectedKeys];
          this.$_Toast(this.$_lang('REPORT.BI_MENU_LOCKED_DURING_LOADING', { defaultValue: '현재 보고서를 불러오는 중입니다.' }));
          throw new Error('현재 불러오기 이벤트가 진행 중입니다.');
        }
      },

      /** BI 보고서 데이터 조회 */
      async getBiReportData(id) {
        return await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_DATA_SELECT',
          path: `/${id}`,
          loading: true,
        });
      },

      /** BI 보고서 스프레드 시트 출력 */
      async openWorkBook(e) {
        this.validateOnLoading(); // 현재 로딩중인지 체크

        const item = e.selectedRowsData.at(0);
        if (isEmpty(item)) {
          this.resetBiReport(); // 선택된 보고서 항목 없을 경우 초기화 후 중단
          return;
        } else if (item.isCategory) {
          return; // 선택한 메뉴가 카테고리라면 중단
        }

        // 카테고리가 아닌 경우
        this.previousSelectedKeys = e.selectedRowKeys;
        this.spreadJS.isShow = true;

        await this.$nextTick();
        await this.resetSheet();
        this.updateCommandMap(this.spreadJS.config, 'common');

        const res = await this.getBiReportData(item.id);
        if (isSuccess(res)) {
          this.biMenu.selectedItem = getResData(res)[0];
          this.biMenu.selectedItem.menuNm = item.name;
          this.biMenu.selectedItem.parentId = item.categoryId;

          const { content, blobContent } = this.biMenu.selectedItem;
          this.updateCommandMap(this.spreadJS.config, 'common');
          if (blobContent === null) {
            this.spreadJS.isLoading = true;
            this.spreadJS.workbook.fromJSON(content);
            this.spreadJS.isLoading = false;
          } else {
            const byteCharacters = atob(blobContent);
            const uint8Array = new Uint8Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              uint8Array[i] = byteCharacters.charCodeAt(i);
            }
            const blob = new Blob([uint8Array], { type: 'application/zip' });
            this.spreadJS.isLoading = true;
            this.spreadJS.workbook.open(
              blob,
              e => {
                vm.spreadJS.isLoading = false;
              },
              e => {
                vm.spreadJS.isLoading = false;
                console.log(e); // error callback
              },
              {
                lazyLoad: true,
              },
            );
          }

          this.spreadJS.workbook.bind(this.spreadJS.instance.Spread.Sheets.Events.PivotTableChanged, function (evt, args) {
            try {
              vm.sheet = this.spreadJS.workbook.getActiveSheet();
              var pt = vm.sheet.pivotTables.get(args.pivotTableName);
              if (pt) {
                var sheetRow = vm.sheet.getRowCount();
                var sheetCol = vm.sheet.getColumnCount();
                var ptRange = pt.getRange();
                if (ptRange.content) {
                  var ptRowCount = ptRange.content.row + ptRange.content.rowCount;
                  vm.sheet.setRowCount(Math.max(ptRowCount, sheetRow));
                  var ptColCount = ptRange.content.col + ptRange.content.colCount;
                  vm.sheet.setColumnCount(Math.max(ptColCount, sheetCol));
                }
              }
            } catch (e) {}
          });
        }
      },

      /** 스프레드 시트의 모든 시트를 지우고 새 시트를 추가합니다.*/
      async resetSheet() {
        this.spreadJS.workbook.clearSheets();
        const newSheet = new this.spreadJS.instance.Spread.Sheets.Worksheet('NewSheet');
        this.spreadJS.workbook.addSheet(0, newSheet);
      },

      /** BI 보고서 목록 초기화 */
      resetBiReport() {
        if (this.spreadJS.workbook) {
          this.spreadJS.workbook.clearSheets();
        }
        this.spreadJS.isShow = false;
        this.spreadJS.isLoading = false;
        this.biMenu.selectedItem = null;

        this.selectedRowKeys = [];
        this.previousSelectedKeys = [];

        this.sheet = null;
        this.$refs.biMenuList.initBiMenu(false);
      },

      /** 템플릿으로 불러온 보고서를 현재 보고서로 설정 */
      async setBiTemplateReport() {
        const selectedItem = await this.$refs.templateModal.getSelectedTemplate();
        const json = JSON.parse(selectedItem.content);
        this.spreadJS.workbook.fromJSON(json);

        const workbook = this.spreadJS.workbook;
        const sheetCount = workbook.getSheetCount();

        // 시트별 최소 작업공간 설정
        for (let i = 0; i < sheetCount; i++) {
          const sheet = workbook.getSheet(i);

          // 현재 설정된 행/열 범위 내에서 검사
          const currentRowCount = sheet.getRowCount();
          const currentColCount = sheet.getColumnCount();

          // 최소 200행, 50열을 유지하며, 그 이상을 넘어갈 경우 20씩 늘림
          const maxRow = currentRowCount > 180 ? currentRowCount + 20 : 200;
          const maxCol = currentColCount > 30 ? currentColCount + 20 : 50;

          sheet.setRowCount(maxRow);
          sheet.setColumnCount(maxCol);
        }

        this.handleToggleTemplateModal(false);
      },

      /** 템플릿 모달 토글 */
      handleToggleTemplateModal(isOpen) {
        this.templateModal.component = isOpen ? 'ModalSelectTemplate' : null;
        this.templateModal.isOpened = isOpen;
      },

      /** 시트 헤더용 포맷 데이터 변환 <br />
       * TODO: 추후 네이밍 일치 필요 */
      transformHeaderData(param) {
        const {
          title,
          // 날짜
          startDt,
          endDt,
          // 시간
          startTime_H,
          startTime_M,
          endTime_H,
          endTime_M,
          // 보고서 유형
          reportType,
          report_type,
        } = param;

        const transObj = {
          title: title,
          startDt: startDt,
          endDt: endDt,
          sHH: startTime_H,
          sMM: startTime_M,
          eHH: endTime_H,
          eMM: endTime_M,
          reportType: reportType || report_type,
        };

        return { ...param, ...transObj };
      },

      /** 데이터 시트 헤더 정보를 그리는 함수 */
      drawDataSheetHeader(sheet, infoObj) {
        this.drawTitleAtDataSheetHeader(sheet, infoObj.title);
        this.drawUpdateDataSheetHeader(sheet, infoObj);
      },
      /** 시트 보더 스타일 */
      createBlackThinBorderStyle() {
        return new this.spreadJS.instance.Spread.Sheets.LineBorder('black', this.spreadJS.instance.Spread.Sheets.LineStyle.thin);
      },

      /** 데이터 시트 헤더 타이틀 정보 입력 및 스타일 */
      drawTitleAtDataSheetHeader(sheet, title) {
        this.drawValueAtSheet(sheet, 2, 1, title, () => {
          sheet.addSpan(2, 1, 3, 4);
          const range = sheet.getRange(2, 1, 3, 4);
          range.hAlign(this.spreadJS.instance.Spread.Sheets.HorizontalAlign.center);
          range.vAlign(this.spreadJS.instance.Spread.Sheets.VerticalAlign.center);
          range.font('bold 20px/30px Nanum Gothic');
          range.borderLeft(this.createBlackThinBorderStyle());
          range.borderTop(this.createBlackThinBorderStyle());
          range.borderRight(this.createBlackThinBorderStyle());
          range.borderBottom(this.createBlackThinBorderStyle());
        });
      },

      /** 데이터 시트 헤더 검색 조건 그리는 함수 (타이틀, 조회기간, 보고서유형, 조회일시) */
      drawUpdateDataSheetHeader(sheet, infoObj) {
        const { startDt, endDt, sHH, sMM, eHH, eMM, reportType } = infoObj;

        this.drawValueAtSheet(sheet, 2, 5, '조회 기간');
        const rangeTime = sHH === undefined ? '' : ` (${sHH}:${sMM} ~ ${eHH}:${eMM})`;
        this.drawValueAtSheet(sheet, 2, 6, `${startDt} ~ ${endDt}${rangeTime}`);

        this.drawValueAtSheet(sheet, 3, 5, '보고서 유형');
        this.drawValueAtSheet(sheet, 3, 6, this.getReportTypeName(reportType));

        this.drawValueAtSheet(sheet, 4, 5, '조회 일시');
        this.drawValueAtSheet(sheet, 4, 6, moment().format('YYYY-MM-DD HH:mm:ss'));
      },

      /** 보고서 유형 명칭 반환 함수 */
      getReportTypeName(type) {
        const findReportType = this.$_enums.report.reportType.values.find(item => item.value === type);
        if (findReportType) {
          return findReportType.label;
        }

        return '기간별';
      },

      /**
       * 현재 Active 중인 Sheet에 값 추가 및 스타일링 CallBack 실행
       * (값을 셋팅하는 셀에 적용할 스타일들을 "들여쓰기"로 구분해서 보여주기 위해 만듬)
       * @param sheet: 적용할 시트
       * @param row: 행 넘버 (0부터 시작)
       * @param col: 열 넘버 (0부터 시작)
       * @param value: Any
       * @param styleFn: Style CallBack
       */
      drawValueAtSheet(sheet, row, col, value, styleFn = () => {}) {
        sheet.setValue(row, col, value);
        styleFn();
      },

      /** 해당 페이지 내에서 사용하는 모달 타입 별 토글 처리 */
      handleToggleCommonModal(modalType, bool) {
        // 보고서 검색 조건
        if ('addDataSheet' === modalType) {
          // spreadJS > 보고서 검색 조건 > 데이터 시트 추가
          this.dataSheet.popup.show = bool;
        } else if ('selectTemplateModal' === modalType) {
          this.modal.currentComponent = 'ModalSelectTemplate'; // 템플릿 선택 모달
          this.modal.initData = {
            title: `템플릿 불러오기`,
            width: 900,
            height: 700,
            buttons: {
              save: { text: '확인' },
              cancel: { text: '취소' },
            },
          };
          this.modal.sendData = {
            modal: 'ModalSelectTemplate',
            spreadInstance: this.spreadJS.instance,
            spreadExcelIO: this.spreadJS.excelIO,
          };
          this.handleToggleTemplateModal(true);
        }
      },

      /**
       * "데이터 추가" 팝업 탭('보고서 데이터 추가', '시트 추가', 'URL 데이터')
       * 변경 시 해당 탭 ID 값 저장하는 함수
       * @param index 선택한 탭 인덱스
       */
      changedAddModalTab(index) {
        this.dataSheet.popup.selectedTabId = this.dataSheet.popup.tabs[index].id;
      },

      /**
       * "데이터 추가" 팝업에서 "적용" 버튼 클릭했을 시 동작하는 함수
       * "위자드 보고서", "시트 추가", "URL 데이터" 탭에 따라
       * 각각 다른 동작을 수행한다.
       * TODO: "URL 데이터" 탭은 추후 개발 예정
       */
      async applyDataSheet() {
        const { selectedTabId } = this.dataSheet.popup;
        if (this.dataSheet.wizard === selectedTabId) {
          await this.applySheetByReportData();
        } else if (this.dataSheet.dbData === selectedTabId) {
          await this.fetchCreateSheetByBiData();
        } else if (this.dataSheet.urlData === selectedTabId) {
          console.warn('현재 미구현');
        }
      },

      /** 데이터 시트 내 데이터 테이블명 조회 (없을 시 생성) */
      getDataTableName() {
        this.spreadJS.sheet = this.spreadJS.workbook.getActiveSheet();
        const tables = this.spreadJS.sheet.tables.all();
        if (tables.length === 0) {
          return `esp.${uuidV4().replace(/-/g, '')}`;
        }
        // 수정 시 기존 테이블 사용
        return tables.at(0).name();
      },

      /** workbook 내 시트 추가 */
      createNewSheet(workbook, sheetName) {
        workbook.addSheet(workbook.getSheetCount(), this.getWorksheet(sheetName));
        workbook.setActiveSheetIndex(workbook.getSheetCount() - 1);
        return workbook.getActiveSheet();
      },

      /** 새로운 시트명 내 특수문자 제거 및 prefix(N) */
      convertNewSheetName(name) {
        const rename = name.replace(/[`~!@#$%^&*()|+\-=?;:'"<>\{\}\[\]\\\/ ]/gim, ''); // 특수문자 제거
        // 중복 시트명 있는지 체크하여 없으면 사용
        if (isEmpty(this.spreadJS.workbook.getSheetFromName(rename))) {
          return rename; // 존재하지 않을 시 해당 시트명 사용
        }

        // 같은 시트명 존재 시 숫자 붙여서 반환
        const sheetCount = this.spreadJS.workbook.getSheetCount();
        let resultName = `${rename}${sheetCount + 1}`;
        for (let i = 0; i < sheetCount; i++) {
          const newName = `${rename}${i + 1}`;
          if (isEmpty(this.spreadJS.workbook.getSheetFromName(newName))) {
            resultName = newName;
            break;
          }
        }

        return resultName;
      },

      /** "데이터 추가" 팝업에서 "위자드 보고서" 셋팅된 조건 유효성 체크 후 새로운 데이터 시트를 추가하는 함수 */
      async applySheetByReportData() {
        //유효성 검사
        const searchBoxRef = this.addWizardDataTable;
        if (searchBoxRef === undefined) {
          const errMessage = '메뉴 선택 후 검색 조건을 설정해주세요.';
          this.$_Msg(errMessage);
          throw new Error(errMessage);
        }

        const errMessage = searchBoxRef.validateMessageByParams();
        if (errMessage.trim()) {
          this.$_Msg(errMessage);
          throw new Error(errMessage);
        }

        //데이터 시트 생성
        const { reportId, menuNm, columns, menuId } = searchBoxRef.getReportInfo(); //보고서 ID, 보고서 명
        this.createNewSheet(this.spreadJS.workbook, this.convertNewSheetName(menuNm));

        //기초작업
        this.sheet = this.spreadJS.workbook.getActiveSheet(); //현재 시트 담기

        // 1.상단 보고서 정보 그리기
        const searchParams = searchBoxRef.getReportSearchParams(); //셋팅된 조건 가져오기
        this.drawDataSheetHeader(this.sheet, this.transformHeaderData({ ...searchParams.search, title: menuNm }));

        // 2.컬럼 API 및 현재 활성화된 페이지 테이블 추가 셋팅
        const biId = this.biMenu.selectedItem.id;
        const biDataType = 'wizard';
        const dataTableName = this.getDataTableName();
        this.sheet.setColumnCount(columns.length + 10);
        this.createDataTable(this.sheet, dataTableName, columns.length);
        this.sheet.frozenRowCount(7); //컬럼 생성 후 7번 row 틀 고정

        // 3.데이터 파라미터 셋팅
        searchBoxRef.updateAllReportParam(0);
        const params = searchBoxRef.getFinalReportParam();
        params.menuNm = menuNm; // 보고서 명
        params.reportId = reportId; // 보고서 ID (XML 파일명))
        params.biId = biId; // BI ID
        params.biTableNm = dataTableName; // tableNm (uuid)
        params.biDataType = biDataType;
        params.paging = false; // 페이징 여부
        params.pagesize = 0; // 페이지 사이즈
        params.currentpage = 0; // 현재 페이지
        params.filter = 7; // 데이터 + 소계 + 평균
        params.workSection = this.$_getCode('work_section')?.find(e => e.codeNm === '조회')?.codeValue; // 작업타입

        // 4. 보고서 조회 결과 데이터 가져온 후 바인딩
        const reportResult = await searchBoxRef.getReportResult(params);
        const reportTable = this.sheet.tables.findByName(dataTableName);
        reportTable.bind(this.getTableColumnsByXmlColumns(columns), '', this.convertForExcel(columns, reportResult));

        //열려있던 "데이터 추가 or 수정" 팝업 닫기
        this.handleToggleCommonModal('addDataSheet', false);
      },

      /** 엑셀에 맞게 데이터 변환 (초, 퍼센트) */
      convertForExcel(columns, result) {
        // 변환이 필요한 포맷에 대한 컬럼명 추출
        const { timeColumns, perColumns } = columns.reduce(
          (acc, column) => {
            const key = column.format.toLowerCase();
            if (key === 'fmtime') acc.timeColumns.push(column.dataField);
            if (key === 'fmpercent') acc.perColumns.push(column.dataField);
            return acc;
          },
          { timeColumns: [], perColumns: [] },
        );

        // 중복되는 내부 함수 정의
        const applyConversion = (row, columns, divisor) => {
          columns.forEach(column => {
            if (typeof row[column] === 'number') {
              row[column] /= divisor;
            }
          });
        };

        // 반환
        return result.map(row => {
          applyConversion(row, timeColumns, 86400);
          applyConversion(row, perColumns, 100);
          return row;
        });
      },

      /** 새로운 워크 시트 생성 */
      getWorksheet(sheetNm) {
        return new this.spreadJS.instance.Spread.Sheets.Worksheet(sheetNm);
      },

      /** 공통 관리 데이터 쿼리를 조회하여 반환하는 API */
      async fetchXmlFileColumnList(params, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'XML_FILE_COLUMN_LIST',
          data: { ...params },
          loading: loading,
        });

        if (isSuccess(res)) return getResData(res);
        return [];
      },

      /** 공통 관리 데이터 쿼리를 조회하여 반환하는 API */
      async fetchXmlFileResultList(params, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'XML_FILE_RESULT_LIST',
          data: { data: params },
          loading: loading,
        });
        if (isSuccess(res)) return getResData(res);
        return [];
      },

      /** 현재 활성화된 시트 내 테이블을 생성 */
      createDataTable(sheet, name, colSize) {
        const dataTable = sheet.tables.add(name, 6, 1, 1, colSize);
        dataTable.expandBoundRows(true); // 데이터 추가될 시 row 자동 확장기능
        dataTable.autoGenerateColumns(false); // 컬럼 자동 생성 기능
        dataTable.style(this.spreadJS.instance.Spread.Sheets.Tables.TableThemes['light18']);
        return dataTable;
      },

      /** XML 에 정의된 보고서 컬럼을 SpreadJS의 테이블 컬럼으로 변환 */
      getTableColumnsByXmlColumns(cols) {
        return cols.map((col, i) => {
          const { dataField, caption, multiHeaderNm, format } = col;
          const columnNm = multiHeaderNm ? `${multiHeaderNm} ${caption}` : caption;
          let fmt = '';
          if (format === 'fmtime') fmt = '[h]:mm:ss';
          else if (format === 'fmNumber') fmt = '#,##0';
          else if (format === 'fmPercent') fmt = '0.00%';
          return new this.spreadJS.instance.Spread.Sheets.Tables.TableColumn(i + 1, dataField, columnNm, fmt);
        });
      },

      /** 워크북이 담고 있는 N개 시트 내 데이터 테이블명 리스트 반환 */
      getTableNameInDataSheets() {
        const dataTableNames = [];
        const workbook = this.spreadJS.workbook;
        const sheetCount = workbook.getSheetCount();
        for (let i = 0; i < sheetCount; i++) {
          const sheet = workbook.getSheet(i);
          const tables = sheet.tables.all();
          if (tables.length > 0 && tables.at(0).name().indexOf('esp.') > -1) {
            dataTableNames.push(tables.at(0).name());
          }
        }

        return dataTableNames;
      },

      /** 날짜 변경 모달 적용 버튼 클릭 시 호출 */
      async handleApplyUpdateDateModal() {
        const startDt = this.$refs.updateRangeDate.getStartDate();
        const endDt = this.$refs.updateRangeDate.getEndDate();
        await this.fetchSheetUpdateByDate(startDt, endDt);
      },

      /** 활성화된 모든 데이터 테이을 가진 시트를 날짜 변경 건으로 재작성 */
      async fetchSheetUpdateByDate(startDt, endDt) {
        EventBus.$emit('app:progress', true);

        const tables = this.getTableNameInDataSheets(); // 데이터 시트에서 테이블 명을 가져옴
        if (tables.length === 0) {
          return this.$_Msg('데이터 시트를 추가해주시기 바랍니다.');
        }

        // id, name 을 이용해 데이트 시트에 사용된 파라미터 리스트 가져옴
        const params = await this.getAllSheetsParameters(this.biMenu.selectedItem.id, tables, false);

        // 셋팅한 날짜로 데이터 시트를 갱신
        await this.updateDataSheets(params, startDt, endDt);

        // 모달 닫기
        this.handleCloseUpdateDateModal();
        EventBus.$emit('app:progress', false);
      },

      /** 워크북 전체 데이터 시트 별 보고서 항목 및 결과로 재작성 */
      async updateDataSheets(params, startDt, endDt) {
        const loading = false;
        for (const data of params) {
          const param = this.updateSheetParams(JSON.parse(data.param), startDt, endDt);

          let columns = null;
          let result = null;
          const type = data.type;
          if ('wizard' === type) {
            columns = (await this.fetchGetReportMenuInfo(param.reportId, loading))?.columns || [];
            result = await this.fetchReportResult(param, loading);
          } else if ('bi-report' === type) {
            columns = await this.fetchXmlFileColumnList(param, loading);
            result = await this.fetchXmlFileResultList(param, loading);
          }
          this.updateSingleSheet(param, columns, this.convertForExcel(columns, result));
        }
      },

      /** 각 시트를 재작성 하는 함수 */
      updateSingleSheet(param, columns, result) {
        // TODO: UNIQUE 한 값이라 ID가 더 어울림
        // TODO: 개념 맞추려면 DB 부터 전체적으로 변경 필요하기 때문에 보류
        const dataTableName = param.biTableNm;
        const sheet = this.findSheetByDataTableName(dataTableName);
        const table = sheet.tables.findByName(dataTableName);

        this.drawUpdateDataSheetHeader(sheet, this.transformHeaderData(param));
        table.bind(this.getTableColumnsByXmlColumns(columns), '', result);
        sheet.resumePaint();
      },

      /** 워크북 내 일치하는 데이터 테이블명을 가진 시트 찾아서 반환 */
      findSheetByDataTableName(name) {
        const workbook = this.spreadJS.workbook;
        const sheetCount = workbook.getSheetCount();
        for (let i = 0; i < sheetCount; i++) {
          const sheet = workbook.getSheet(i);
          const table = sheet.tables.all().find(table => table.name() === name);

          if (table) {
            return sheet;
          }
        }

        throw new Error(`'${name}' 데이터 테이블을 찾지 못 했습니다.`);
      },

      /** 위자드 보고서 정보 조회 from Id */
      async fetchGetReportMenuInfo(id, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_INFO_BY_ID',
          data: { reportId: id },
          loading: loading,
        });
        if (isSuccess(res)) return getResData(res)?.at(0);
      },

      /** 보고서 조회 결과 데이터 가져오기 */
      async fetchReportResult(params, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_RESULT_LIST',
          data: { data: params },
          loading: loading,
        });
        if (isSuccess(res)) {
          return getResData(res);
        }
        return [];
      },

      /** 시트를 재조회할 파라미터를 재작성하여 반환 */
      updateSheetParams(param, startDt, endDt) {
        param.loginId = this.$store.getters.getLoginId;
        param.startDt = startDt;
        param.endDt = endDt;
        return param;
      },

      /** 활성화 된 BI 메뉴 내 존재하는 <데이터 시트> 파라미터 조회 */
      async getAllSheetsParameters(id, names, loading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_DATA_PARAM_LIST',
          path: `/${id}/param`,
          data: { name: names },
          loading: loading,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
      },

      /** 공통 날짜 변경 모달 닫기 */
      handleCloseUpdateDateModal() {
        this.updateDateModal.isOpened = false;
      },

      /** 데이터 시트 추가: BI 데이터 */
      async fetchCreateSheetByBiData() {
        const params = this.biDataComponent.getFormData();

        // 시트명 설정 및 생성
        const sheet = this.createNewSheet(this.spreadJS.workbook, this.convertNewSheetName(params.description));

        // 헤더 셋팅
        this.drawDataSheetHeader(sheet, this.transformHeaderData({ ...params, title: params.description }));

        // 컬럼 셋팀
        const columns = await this.fetchXmlFileColumnList(params);
        sheet.setColumnCount(columns.length + 10);

        // 테이블 데이터 생성
        const dataTableName = this.getDataTableName();
        const dataTable = this.createDataTable(sheet, dataTableName, columns.length);
        sheet.frozenRowCount(7); //컬럼 생성 후 7번 row 틀 고정

        // 데이터 불러오기
        const dataList = await this.fetchXmlFileResultList({
          ...params,
          // 이력을 저장하기 위한 추가 파라미터
          // (TODO: 중복 값은 메타데이터 정의 후 일치 필요)
          biId: this.biMenu.selectedItem.id,
          biDataType: params.reportType,
          biTableNm: dataTableName,
        });

        const tableColumns = this.getTableColumnsByXmlColumns(columns);
        dataTable.bind(tableColumns, '', dataList);
        this.handleToggleCommonModal('addDataSheet', false);
      },

      /**
       * SpreadJS 커맨드 (임의 정의한 버튼)으로 동작하는 함수
       * updateCommandMap 구분 값이 init 인 경우
       * config.commandMap 내 초기 객체 넣는 함수
       */
      initCommandMap(config) {
        config.commandMap = {
          toggleSideBar: {
            text: '메뉴\n열기/닫기',
            width: '200px',
            iconClass: 'bi-command-slide-menu',
            bigButton: 'true',
            commandName: 'toggleSideBar',
            execute: () => this.handleToggleShowLeftMenu(),
          },
          // 데이터
          addDataSheet: {
            text: '데이터\n시트 추가',
            width: '200px',
            iconClass: 'bi-add-sheet-icon',
            bigButton: 'true',
            commandName: 'addDataSheet',
            enableContext: '!disabled',
            execute: () => this.handleToggleCommonModal('addDataSheet', true),
          },
          // 날짜 변경
          editDate: {
            text: '날짜\n수정',
            width: '200px',
            iconClass: 'bi-command-calendar-time',
            bigButton: 'true',
            commandName: 'editDate',
            enableContext: '!disabled',
            execute: () => (vm.updateDateModal.isOpened = true),
          },
          // 데이터 저장
          saveToDB: {
            text: '보고서\n저장',
            width: '200px',
            iconClass: 'bi-command-save-data',
            bigButton: 'true',
            commandName: 'saveToDB',
            enableContext: '!disabled',
            execute: () => this.saveWorkbook(),
          },
          // xlsx 다운로드
          downloadXlsx: {
            text: '다운로드\n(xlsx)',
            width: '200px',
            iconClass: 'bi-command-download-xlsx',
            bigButton: 'true',
            commandName: 'downloadXlsx',
            enableContext: '!disabled',
            execute: () => this.exportXlsx(),
          },
          // 템플릿 불러오기
          addBiTemplate: {
            text: '템플릿\n불러오기',
            width: '200px',
            iconClass: 'bi-edit-sheet-icon',
            bigButton: 'true',
            commandName: 'addBiTemplate',
            execute: () => this.handleToggleTemplateModal(true),
          },
        };
      },

      /** spreadJS.config.commandMap 설정 변경 */
      updateCommandMap(config, type) {
        if ('init' === type) {
          return this.initCommandMap(config);
        }

        // type === 'common'
        config.commandMap.saveToDB.enableContext = '!disabled';
        config.commandMap.toggleSideBar.enableContext = '!disabled';
        config.commandMap.addDataSheet.enableContext = '!disabled';
        config.commandMap.editDate.enableContext = '!disabled';
        config.commandMap.addBiTemplate.enableContext = '!disabled';
      },
      /** spreadJS 커스텀 커맨드: 메뉴 열기/닫기 (좌측 보고서 목록 영역 토글 함수) */
      handleToggleShowLeftMenu() {
        this.biMenu.isShow = !this.biMenu.isShow;
      },
      /** SpreadJS 현재 활성화된 워크시트 다운로드 기능 */
      exportXlsx() {
        const options = this.getXlsxOptions();
        const date = getPastFromToday(0, 'days', 'YYYYMMDDHHmmss');
        const fileNm = `${this.biMenu.selectedItem.menuNm}_${date}.xlsx`;
        this.spreadJS.workbook.export(
          function (blob) {
            saveAs(blob, fileNm);
          },
          function (e) {
            console.log(e);
          },
          options,
        );
      },

      /** xlsx 다운로드 옵션 */
      getXlsxOptions() {
        const saveOptions = {
          includeBindingSource: true,
          includeStyles: true,
          includeFormulas: true,
          saveAsView: false,
          rowHeadersAsFrozenColumns: false,
          columnHeadersAsFrozenRows: false,
          includeAutoMergedCells: false,
          includeCalcModelCache: false,
          saveR1C1Formula: false,
          includeUnusedNames: true,
          includeEmptyRegionCells: true,
          encoding: 'UTF-8',
          rowDelimiter: '\r\n',
          columnDelimiter: ',',
          sheetIndex: 0,
          row: 0,
          column: 0,
          rowCount: 200,
          columnCount: 20,
        };

        const xlsxConfig = [
          { propName: 'includeBindingSource', type: 'boolean', default: false },
          { propName: 'includeStyles', type: 'boolean', default: true },
          { propName: 'includeFormulas', type: 'boolean', default: true },
          { propName: 'saveAsView', type: 'boolean', default: false },
          { propName: 'rowHeadersAsFrozenColumns', type: 'boolean', default: false },
          { propName: 'columnHeadersAsFrozenRows', type: 'boolean', default: false },
          { propName: 'includeAutoMergedCells', type: 'boolean', default: false },
          { propName: 'includeUnusedNames', type: 'boolean', default: true },
          { propName: 'includeEmptyRegionCells', type: 'boolean', default: true },
        ];

        let options = {};
        xlsxConfig.forEach(prop => {
          let v = saveOptions[prop.propName];
          if (prop.type === 'number') {
            v = +v;
          }
          options[prop.propName] = v;
        });

        return options;
      },

      /** 리본 "보고서 검색조건" 탭 내 버튼 셋팅 */
      setInitCustomRibbon() {
        const HORIZONTAL = 'horizontal';
        const funcRibbon = (label, children) => ({
          label: label,
          commandGroup: {
            children: children,
          },
        });

        const reportRibbon = {
          id: 'report-ribbon',
          text: '보고서 검색조건',
          buttonGroups: [
            funcRibbon('보고서 목록', [
              {
                direction: HORIZONTAL,
                commands: ['toggleSideBar'],
              },
            ]),
            funcRibbon('데이터', [
              {
                direction: HORIZONTAL,
                commands: ['addDataSheet', 'editDate', 'addBiTemplate'],
              },
            ]),
            funcRibbon('데이터 저장', [
              {
                direction: HORIZONTAL,
                commands: ['saveToDB', 'downloadXlsx'],
              },
            ]),
          ],
        };

        this.spreadJS.config.ribbon = [reportRibbon, ...this.spreadJS.instance.Spread.Sheets.Designer.DefaultConfig.ribbon];
      },

      /** 워크북 DB 저장 */
      saveWorkbook() {
        this.spreadJS.workbook.save(
          blob => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = async () => {
              const base64data = reader.result;
              await vm.fetchBlobDataUpdate(base64data);
            };
          },
          () => {},
          { includeBindingSource: true },
        );
      },

      /** 선택된 BI 보고서 Blob 데이터 저장 */
      async fetchBlobDataUpdate(base64Data) {
        const { id, name, content } = this.biMenu.selectedItem;
        const res = await this.CALL_REPORT_API({
          actionName: 'BI_REPORT_DATA_UPDATE',
          path: `/${id}`,
          data: {
            data: {
              content: base64Data,
            },
            workLog: {
              content: { id, name, content: base64Data.length },
              preContent: { id, name, content: content.length },
            },
          },
          loading: true,
        });

        const messageKey = isSuccess(res) ? 'CMN_SUC_SAVE' : 'CMN_ERR_SAVE';
        return this.$_Toast(this.$_lang(messageKey));
      },

      /** Default 폰트 세팅(맑은 고딕) */
      setStyledGodicFont() {
        const theme = new this.spreadJS.instance.Spread.Sheets.Theme(
          'koCustomTheme',
          this.spreadJS.instance.Spread.Sheets.ThemeColors.Office,
          '맑은 고딕',
          '맑은 고딕',
        );

        if (this.spreadJS.workbook) {
          this.spreadJS.workbook.sheets.forEach(item => item.currentTheme(theme));
        }
      },

      /**
       * SpreadJS Designer 초기화
       * @param designer
       */
      designerInitialized(designer) {
        this.spreadJS.workbook = designer.getWorkbook();
        this.sheet = this.spreadJS.workbook.getSheet(0); // 첫 번째 시트를 얻습니다.
        this.spreadJS.designer = designer;
        this.spreadJS.config = this.$_commonlib.cloneObj(this.spreadJS.instance.Spread.Sheets.Designer.DefaultConfig);
        this.setInitCustomRibbon();
        this.setStyledGodicFont();
        this.updateCommandMap(this.spreadJS.config, 'init');
      },

      /** 스프레드 시트 UI 다시 그리기 */
      repaint() {
        if (this.spreadJS.workbook) {
          this.spreadJS.workbook.resumePaint();
          this.spreadJS.workbook.repaint();
        }
      },

      /** SpreadJS 라이브러리 import */
      async importSpreadJS() {
        await Promise.all([
          import('@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white.css'),
          import('@grapecity/spread-sheets-designer/styles/gc.spread.sheets.designer.min.css'),
          import('@grapecity/spread-sheets-print'),
          import('@grapecity/spread-sheets-charts'),
          import('@grapecity/spread-sheets-shapes'),
          import('@grapecity/spread-sheets-slicers'),
          import('@grapecity/spread-sheets-pivot-addon'),
          import('@grapecity/spread-sheets-tablesheet'),
          import('@grapecity/spread-sheets-io'),
          import('@grapecity/spread-sheets-designer-resources-ko'),
          import('@grapecity/spread-sheets-resources-ko'),
          import('@grapecity/spread-sheets-vue'),
          import('@grapecity/spread-sheets-designer-vue'),
        ]);

        const SpreadJS = await import('@grapecity/spread-sheets');
        SpreadJS.Spread.Common.CultureManager.culture('ko-kr');
        SpreadJS.Spread.Sheets.LicenseKey = process.env.VUE_APP_BI_DEPLOY_LICENSE;
        SpreadJS.Spread.Sheets.Designer.LicenseKey = process.env.VUE_APP_BI_DESIGNER_LICENSE;
        this.spreadJS.instance = SpreadJS;

        // 템플릿 등록을 위한 ExcelIO 추가
        const ExcelIO = await import('@grapecity/spread-excelio');
        ExcelIO.LicenseKey = process.env.VUE_APP_BI_DEPLOY_LICENSE; // ExcelIO도 별도로 라이선스 등록 필요
        this.spreadJS.excelIO = new ExcelIO.IO();
      },
    },
    async created() {},
    async mounted() {
      vm = this;
      const progressLoader = this.$refs.progressLoader;
      try {
        progressLoader.show();
        progressLoader.startStep(0);
        await this.importSpreadJS();
        const { instance, excelIO } = this.spreadJS;
        await this.$refs.templateModal.initSpreadJS(instance, excelIO);
        progressLoader.completeStep(0);
        progressLoader.startStep(1);
        await this.$refs.biMenuList.initBiMenu(); //카테고리 그룹 목록 생성
        progressLoader.completeStep(1);
      } catch (error) {
        progressLoader.setStepError(progressLoader.currentStepIndex, '모듈 로드 중 오류가 발생했습니다: ' + error.message);
        throw error;
      }
    },
    activated() {
      this.repaint(); // 탬 동작 후 간헐적으로 스프레드시트 깨지는 현상 방어
    },
  };
</script>
<style>
  .full-panels .gc-sidePanel-content .gc-flexcontainer .gc-column-set {
    height: 84vh;
  }
  .gc-column-set {
    color: white;
  }
  .gc-column-set,
  .gc-column-set * {
    color: inherit;
  }
  .gc-file-menu-list-new .new-sheet-temmplate-container {
    width: 230px !important;
    height: 210px !important;
    padding: 10px !important;
  }
  .bi-edit-sheet-icon {
    background-image: url('../../../assets/images/bi/editTableSheet.png');
    background-size: 35px 35px;
  }

  .bi-add-sheet-icon {
    background-image: url('../../../assets/images/bi/makeTableSheet.png');
    background-size: 35px 35px;
  }
  .bi-report-search-list-icon {
    background-image: url('../../../assets/images/bi/searchList.png');
    background-size: 35px 35px;
  }

  .bi-command-save-data {
    background-image: url('../../../assets/images/bi/saveTempleteData.png');
    background-size: 35px 35px;
  }

  .bi-command-download-xlsx {
    background-image: url('../../../assets/images/bi/download.png');
    background-size: 38px 38px !important;
  }

  .bi-command-slide-menu {
    background-image: url('../../../assets/images/bi/menuSlide.png');
    background-size: 35px 35px;
  }
  .bi-command-calendar-time {
    background-image: url('../../../assets/images/calendar_time.png');
    background-size: 38px 38px !important;
  }

  .gc-drop-down-list {
    z-index: 999 !important;
  }

  .gc-statusbar-statusitem-container span {
    color: white !important;
  }
</style>
