<template>
	<div class="container">
		<div class="page-sub-box ewm">
			<div class="locker_setting_list sub_new_style01 sub_ui_box1">
				<div class="page_search_box line_bottom_1px">
					<div class="flex inner">
						<div class="mt-2">
							기준년월
						</div>
						<DxDateBox
							styling-mode="outlined"
							width="100"
							v-model="searchType.customTypes.frScheYmd"
							type="date"
							dateSerializationFormat="yyyyMMdd"
							date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
							display-format="yyyy-MM"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:max="searchType.customTypes.toScheYmd"
							maxLength="7"
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'decade',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayStartChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-1">
							~
						</div>
						<DxDateBox
							styling-mode="outlined"
							width="100"
							v-model="searchType.customTypes.toScheYmd"
							type="date"
							dateSerializationFormat="yyyyMMdd"
							date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
							display-format="yyyy-MM"
							invalid-date-message="입력 데이터가 유효하지 않습니다."
							:min="searchType.customTypes.frScheYmd"
							maxLength="7"
							:calendar-options="{
								zoomLevel: 'year',
								minZoomLevel: 'decade',
								maxZoomLevel: 'year',
							}"
							@value-changed="onDayEndChanged"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="기준년월은 필수입니다." />
							</DxValidator>
						</DxDateBox>
						<div class="mt-2">
							성명
						</div>
						<DxButton
							styling-mode="outlined"
							:text="searchType.customTypes.targetName"
							:width="120"
							:height="30"
							class="btn_XS white light_filled"
							@click="onOpenTarget"
						>
						</DxButton>
						<DxTextBox
							styling-mode="filled"
							v-model="searchType.customTypes.targetId"
							:width="120"
							:height="30"
							:read-only="true"
							class="mar_ri10"
						>
							<DxValidator validation-group="validationSearch">
								<DxRequiredRule message="대상자는 필수입니다." />
							</DxValidator>
						</DxTextBox>

						<DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectData" />
					</div>
				</div>
			</div>
			<div class="flex flex-col gap-y-6 divide-y">
				<div>
					<div class="sub_title_txt my-4 flex">
						<h2>평가요약</h2>
						<DxButton 
							class="btn_XS green ligt_filled" 
							:height="30" 
							icon="export"
							@click="onExportingCheck($event, evalSummaryGridData)">
						</DxButton>
						<div class="flex justify-end flex-grow mt-1">
							<div class="total-count-item">
								검색결과 <span class="tet-calr1">{{ evalSummaryGridData.totalCount }}</span> 개
							</div>
						</div>
					</div>
					<DxDataGrid
						key-expr="key"
						class="grid-box eval-grid"
						ref="evalSummaryGridData"
						:data-source="evalSummaryGridData.dataSource"
						:allow-column-resizing="true"
						:column-resizing-mode="'nextColumn'"
						:show-borders="false"
						:show-column-headers="true"
						:show-column-lines="true"
						:show-row-lines="true"
						:row-alternation-enabled="false"
						:hover-state-enabled="true"
						:word-wrap-enabled="true"
						:no-data-text="this.$_lang('CMN_NO_DATA')"
						width="100%"
						height="250"
					>
						<DxPaging :enabled="false" />
						<DxLoadPanel :enabled="true" />
						<DxScrolling mode="standard" />
						<!-- <dx-export
							v-if="evalSummaryGridData.showActionButtons.excel"
							:enabled="evalSummaryGridData.export.enabled"
							:allow-export-selected-data="evalSummaryGridData.export.allowExportSelectedData"
							:texts="evalSummaryGridData.export.exportButtonText"
						/> -->
						<!-- <DxSelection
							mode="single"
							:allow-select-all="false"
							show-check-boxes-mode="always"
							select-all-mode="allPages"
						/> -->
						<DxColumn
							caption="평가구분"
							data-field="evalDivisionCd"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						>
							<DxLookup :data-source="dataGridLoopup.evalDivisionCd" value-expr="codeId" display-expr="codeNm" />
						</DxColumn>
						<!-- <template #evalDivisionCdTemplate="{ data }">
							<span>{{ evalSummaryGridData.dataSource != null && data.rowIndex != 0
								&& evalSummaryGridData.dataSource[data.rowIndex - 1].evalDivisionCd == data.data.evalDivisionCd ? ''
									: dataGridLoopup.evalDivisionCd.filter(z => data.data.evalDivisionCd == z.codeId)[0].codeNm }}</span>
						</template> -->
						<DxColumn caption="평가계획명" data-field="scheNm" :allowEditing="false" :allow-sorting="true" />
						<DxColumn caption="평가표" data-field="sheetNm" :allowEditing="false" :allow-sorting="true" />
						<DxColumn
							caption="전체 평균점수"
							data-field="allAvgScore"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="총점"
							data-field="totalSore"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="평가표 상위구성"
							data-field="questionParentGroup"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="평가표 하위구성"
							data-field="questionGroup"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="배점"
							data-field="questionScore"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
						<DxColumn
							caption="평가점수"
							data-field="score"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						/>
					</DxDataGrid>
				</div>
				<div>
					<div class="sub_title_txt my-4 flex">
						<h2>12개월간 평가점수 추이</h2>
						<DxButton 
							class="btn_XS green ligt_filled" 
							:height="30" 
							icon="export"
							@click="onExportingCheck($event, trendTwelveMonthsGridData)">
						</DxButton>
						<div class="flex justify-end flex-grow mt-2">
							<div class="total-count-item">
								검색결과 <span class="tet-calr1">{{ trendTwelveMonthsGridData.totalCount }}</span> 개
							</div>
						</div>
					</div>
					<DxDataGrid
						key-expr="key"
						class="grid-box eval-grid"
						ref="trendTwelveMonthsGridData"
						:data-source="trendTwelveMonthsGridData.dataSource"
						:allow-column-resizing="true"
						:column-resizing-mode="'nextColumn'"
						:show-borders="false"
						:show-column-headers="true"
						:show-column-lines="true"
						:show-row-lines="true"
						:row-alternation-enabled="false"
						:hover-state-enabled="true"
						:word-wrap-enabled="true"
						:no-data-text="this.$_lang('CMN_NO_DATA')"
						width="100%"
						height="200"
					>
						<DxLoadPanel :enabled="true" />
						<DxScrolling mode="standard" />
						<!-- <dx-export
							v-if="trendTwelveMonthsGridData.showActionButtons.excel"
							:enabled="trendTwelveMonthsGridData.export.enabled"
							:allow-export-selected-data="trendTwelveMonthsGridData.export.allowExportSelectedData"
							:texts="trendTwelveMonthsGridData.export.exportButtonText"
						/> -->
						<!-- <DxSelection
							mode="single"
							:allow-select-all="false"
							show-check-boxes-mode="always"
							select-all-mode="allPages"
						/> -->
						<DxColumn
							caption="평가구분"
							data-field="evalDivisionCd"
							alignment="center"
							:allowEditing="false"
							:allow-sorting="true"
						>
							<DxLookup :data-source="dataGridLoopup.evalDivisionCd" value-expr="codeId" display-expr="codeNm" />
						</DxColumn>
						<DxColumn
							v-for="index in 12"
							:key="index"
							:caption="getTrendTwelveMonthsGridCaption(index)"
							:data-field="`totalScore_${index}`"
							:allow-sorting="false"
							alignment="center"
							:calculate-display-value="
								rowData => (rowData[`totalScore_${index}`] ? rowData[`totalScore_${index}`] : '-')
							"
						/>
					</DxDataGrid>
				</div>
				<div>
					<div class="sub_title_txt my-4 flex">
						<h2>12개월간 평가점수 추이(chart)</h2>
					</div>
					<div class="mt-4">
						<DxChart id="trendTwelveMonthsChart" :data-source="trendTwelveMonthsChartData.dataSource">
							<DxCommonSeriesSettings type="line" argument-field="scheYm" />
							<DxSeries
								v-for="(s, index) in trendTwelveMonthsChartData.series"
								:key="index + 1"
								:value-field="s.evalDivisionCd.toString()"
								:name="s.evalDivisionNm"
								:ignore-empty-points="true"
							>
								<DxLabel :visible="false" />
								<DxPoint :size="10" />
							</DxSeries>
							<DxMargin :bottom="0" />
							<DxArgumentAxis :value-margins-enabled="false" discrete-axis-division-mode="crossLabels">
								<DxGrid :visible="true" />
							</DxArgumentAxis>
							<DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="bottom" />
							<DxExport :enabled="false" />
							<DxTooltip :enabled="true" />
						</DxChart>
					</div>
				</div>
			</div>
		</div>

		<modal-add-agent
			:isOpen="agent.popupVisible"
			:showModalTitle="true"
			:selectedIdList="[]"
			@closeModal="onCloseTarget(false)"
			@saveModal="onSelectTarget"
			:minimumSelectionLength="1"
			:maximumSelectionLength="1"
			:saveBtnTxt="'선택'"
		/>

		<dx-popup
      v-model="modal.visible"
      :show-title="modal.showTitle"
      :title="modal.initData.title"
      :width="modal.initData.width"
      :height="modal.initData.height"
      :drag-enabled="modal.dragEnabled"
      :resize-enabled="modal.resizeEnabled"
      :show-close-button="modal.showCloseButton"
      :hide-on-outside-click="modal.closeOnOutsideClick"
      :visible="modal.visible"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            ref="reasonModalRef"
            v-if="modal.content.sendData"
            :is="modal.content.currentComponent"
            :modalData="modal.content.sendData"
            :isModal="modal.content.isModal"
          />
        </div>
      </template>

      <dx-toolbar-item
        :widget="modal.toolbarItem.widget"
        :toolbar="modal.toolbarItem.toolbar"
        :location="modal.toolbarItem.location"
        :options="modal.toolbarItem.closeOptions"
      />
      <dx-toolbar-item
        :widget="modal.toolbarItem.widget"
        :toolbar="modal.toolbarItem.toolbar"
        :location="modal.toolbarItem.location"
        :options="modal.toolbarItem.saveOptions"
      />
    </dx-popup>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxColumn, DxDataGrid, DxLoadPanel, DxLookup, DxPaging, DxScrolling, DxExport } from 'devextreme-vue/data-grid';
import {
	DxChart,
	DxSeries,
	DxArgumentAxis,
	DxCommonSeriesSettings,
	DxGrid,
	DxLabel,
	DxMargin,
	DxLegend,
	DxPoint,
	DxTooltip,
} from 'devextreme-vue/chart';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { getPastFromToday, isSuccess, formatDate } from '@/utils/common-lib';
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
import moment from "moment";

import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';

let vm = this;

export default {
  name: 'EWMEvaluationReportPersonal',
	components: {
		DxButton,
		DxDateBox,

		DxColumn,
		DxDataGrid,
		DxLoadPanel,
		DxLookup,
		DxScrolling,

		DxChart,
		DxSeries,
		DxArgumentAxis,
		DxCommonSeriesSettings,
		DxExport,
		DxGrid,
		DxLabel,
		DxMargin,
		DxLegend,
		DxPaging,
		DxPoint,
		DxTextBox,
		DxTooltip,

		DxValidator,
		DxRequiredRule,

		ModalAddAgent,
		ModalDownloadReason,
		DxToolbarItem,
		DxPopup,
	},
	props: {},
	watch: {},
	data() {
		return {
			codeMap: {},
			dataGridLoopup: {
				evalDivisionCd: [],
			},
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			agent: {
				popupVisible: false,
			},
			searchType: {
				obj: {},
				defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
				defaultValue: 'ROOT',
				customTypes: {
					frScheYmd: getPastFromToday(11, 'months'),
					toScheYmd: getPastFromToday(0, 'days'),
					isFeedback: false,
					targetName: '', //this.$store.getters.getLoginNm,
					targetId: '', //this.$store.getters.getLoginId,
				},
				paramsData: {},
			},
			evalSummaryGridData: {
				instance: '1',
				showActionButtons: { 
					excel: true, 
					customButtons: [],
				},
				showContextMenuItems: {
					excel: true, 
				},
				export: {
					enabled: true, // 엑셀 다운로드 버튼 표시 여부
					title: '평가요약', // 엑셀 파일명
					allowExportSelectedData: true, // 선택한 데이터만 다운로드 허용 여부
					exportButtonText: {
						exportTo: this.$_lang('COMPONENTS.EXCEL_DOWNLOAD', { defaultValue: '엑셀 다운로드' }),
						exportAll: this.$_lang('COMPONENTS.ALL_DOWNLOAD', { defaultValue: '전체 다운로드' }),
						exportSelectedRows: this.$_lang('COMPONENTS.SELECTED_DATA_DOWNLOAD', { defaultValue: '선택한 데이터 다운로드' }),
					},
					cellwidth: 30, // 셀 너비
					autoFilterEnabled: false, // 자동 필터 사용 여부
				},
				columnsCnt: [
					{}, {}, {}, {}, {}, {}, {}, {}, {},
				],
				dataSource: null,
				selectedRowsData: [],
				selectedRowKeys: [],
				totalCount: 0,
			},
			trendTwelveMonthsGridData: {
				instance: '2',
				showActionButtons: { 
					excel: true, 
					customButtons: [],
				},
				showContextMenuItems: {
					excel: true, 
				},
				export: {
					enabled: true, // 엑셀 다운로드 버튼 표시 여부
					title: '12개월간 평가점수 추이', // 엑셀 파일명
					allowExportSelectedData: true, // 선택한 데이터만 다운로드 허용 여부
					exportButtonText: {
						exportTo: this.$_lang('COMPONENTS.EXCEL_DOWNLOAD', { defaultValue: '엑셀 다운로드' }),
						exportAll: this.$_lang('COMPONENTS.ALL_DOWNLOAD', { defaultValue: '전체 다운로드' }),
						exportSelectedRows: this.$_lang('COMPONENTS.SELECTED_DATA_DOWNLOAD', { defaultValue: '선택한 데이터 다운로드' }),
					},
					cellwidth: 30, // 셀 너비
					autoFilterEnabled: false, // 자동 필터 사용 여부
				},
				columnsCnt: [
					{}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {}, {},
				],
				dataSource: null,
				dataFieldIdx: {},
				trendTwelveMonthsGridColumns: [],
				totalCount: 0,
			},
			trendTwelveMonthsChartData: {
				dataSource: null,
				series: [],
				trendMonths: [],
			},
			modal: {
				now: null,
				visible: false,
				showTitle: true,
				initData: {
					title: null,
					width: 600,
					height: 400,
				},
				dragEnabled: true,
				resizeEnabled: true,
				showCloseButton: false,
				closeOnOutsideClick: false,
				content: {
					sendData: null,
					currentComponent: null,
					isModal: false,
				},
				toolbarItem: {
					widget: 'dxButton',
					toolbar: 'bottom',
					location: 'center',
					closeOptions: {
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => this.isOpenModal(false),
					},
					saveOptions: {
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
						width: '120',
						height: '40',
						onClick: () => this.onDownloadReason(),
					},
				},
			},
		};
	},
	computed: {},
	methods: {
		onExportingCheck(e, target) {
			this.modal.now = target;

			const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
			e = this.makeSaveHistory(e);
			if (useDownReason) {
				e.cancel = true;
				this.onOpenModal(
					'ModalDownloadReason',
					{
						title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', { defaultValue: '다운로드 사유' }),
						width: this.modal.initData.width || '600',
						height: this.modal.initData.height || '400',
					},
					e,
				);
			} else {
				this.onExporting(e);
			}
		},
		onOpenModal(componentNm, componentInitData, sendData) {
			this.modal.initData = componentInitData; 
			this.modal.content.currentComponent = componentNm;
			this.modal.content.sendData = sendData;
			this.isOpenModal(true);
		},
		isOpenModal(data) {
			this.modal.visible = data;
			if (!data) {
				this.modal.content.currentComponent = null;
				this.modal.initData = {};
			}
		},
		onDownloadReason() {
			let event = this.modal.content.sendData;
			const reason = this.$refs.reasonModalRef.reason;
			if (reason.trim() === '') {
				this.$_Msg(
					this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
						defaultValue: '다운로드 사유를 입력하세요.',
					}),
				);
				event.cancel = true;
			} else {
				event = this.makeSaveHistory(event, reason);
				event.cancel = false;
				this.onExporting(event);
				this.isOpenModal(false);
			}
		},
		makeSaveHistory(event, reason = '') {
			const user = {
				userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
				userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
				deptNm: this.$store.getters.getUserInfo?.deptNm || '',
				gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
			};

			const payload = {
				actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
				data: [
					{
						...user,
						reason,
						fileType: 'EXCEL',
						fileNm: this.modal.now.export?.title,
					},
				],
			};

			event.onSaveHistory = async fileNm => {
				payload.data[0].fileNm = fileNm || this.modal.now.export?.title;
				return await this.CALL_API(payload);
			};
			return event;
		},
		onExporting(e) {
			console.log(this.modal.now.instance)
			const title = this.modal.now.export?.title || this.getMenuTitle;
			const workbook = new ExcelJS.Workbook();
			const worksheet = workbook.addWorksheet(title);

			const columnsArr = [];
			this.modal.now.columnsCnt.forEach(() => {
				columnsArr.push({ width: this.modal.now.export.cellwidth });
			});
			worksheet.columns = columnsArr;

			const today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

			exportDataGrid({
				component: this.modal.now.instance == 1? this.$refs.evalSummaryGridData.instance : this.$refs.trendTwelveMonthsGridData.instance,
				worksheet: worksheet,
				keepColumnWidths: false,
				autoFilterEnabled: this.modal.now.export.autoFilterEnabled, 
				topLeftCell: { row: 4, column: 1 },
				customizeCell: ({ gridCell, excelCell }) => {
					if (gridCell.rowType === 'header') {
						excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
						excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
					} else {
						if (excelCell.fullAddress.row % 2 === 0) {
							excelCell.fill = {
								type: 'pattern',
								pattern: 'solid',
								fgColor: { argb: 'F2F2F2' },
								bgColor: { argb: 'F2F2F2' },
							};
						}
					}

					const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
					excelCell.border = {
						bottom: borderStyle,
						left: borderStyle,
						right: borderStyle,
						top: borderStyle,
					};
				},
			})
			.then(() => {
				const titleRow = worksheet.getRow(2);
				titleRow.height = 40;
				if (e.format === 'xlsx') {
					worksheet.mergeCells(2, 1, 2, this.modal.now.columnsCnt.length);
				}
				titleRow.getCell(1).value = title;
				titleRow.getCell(1).font = { size: 22, bold: true };
				titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

				const hearderRow = worksheet.getRow(4);
				hearderRow.height = 30;
			})
			.then(() => {
				let fileName;
				if (e.format === 'csv') {
					fileName = `${title}_${today}.csv`;
					workbook.csv.writeBuffer().then(buffer => {
						saveAs(new Blob([buffer], { type: 'text/csv' }), fileName);
					});
				} else {
					fileName = `${title}_${today}.xlsx`;
					workbook.xlsx.writeBuffer().then(buffer => {
						saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
					});
				}
				return fileName;
			})
			.then(fileName => {
				e.onSaveHistory(fileName);
			});
			e.cancel = true;
		},
		onOpenTarget() {
			this.agent.popupVisible = true;
		},
		async onCloseTarget() {
			this.agent.popupVisible = false;
		},
		onSelectTarget(value) {
			this.searchType.customTypes.targetName = value[0].agtNm;
			this.searchType.customTypes.targetId = value[0].agtid;
			this.onCloseTarget().then(() => this.selectData());
		},
		/** @description : 초기 12개월간 평가점수 추이 그리드 캡션 설정 */
		getTrendTwelveMonthsGridCaption(index) {
			return moment(getPastFromToday(0, 'days'))
				.subtract(12 - index, 'months')
				.format('YYYY-MM');
		},
		selectData() {
			if (!validationEngine.validateGroup('validationSearch').isValid) {
				return;
			}

			this.selectEvalSummaryGridDataList();
			this.selectTrendTwelveMonthsDataList();
		},
		/** @description : 평가요약 조회 */
		async selectEvalSummaryGridDataList() {
			vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.frScheYmd;
			vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;
			vm.searchType.paramsData['targetId'] = vm.searchType.customTypes.targetId;

			const payload = {
				actionName: 'EWM_EVALUATION_PERSONAL_REPORT_EVAL_SUMMARY',
				data: vm.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.evalSummaryGridData.dataSource = res.data.data;
				this.evalSummaryGridData.totalCount = res.data.data.length;
			}
		},
		/** @description : 12개월간 평가점수 추이 조회 */
		async selectTrendTwelveMonthsDataList() {
			vm.searchType.paramsData['frScheYmd'] = this.$_commonlib
				.moment(vm.searchType.customTypes.toScheYmd)
				.subtract(11, 'months')
				.format('YYYYMMDD');
			vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;
			vm.searchType.paramsData['targetId'] = vm.searchType.customTypes.targetId;

			this.setTrendTwelveMonthsGridColumns(vm.searchType.customTypes.toScheYmd, 11);

			const payload = {
				actionName: 'EWM_EVALUATION_PERSONAL_REPORT_TREND_12M',
				data: vm.searchType.paramsData,
				loading: false,
			};

			const res = await vm.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				const evalDivisionCds = [...new Set(res.data.data.map(z => z.evalDivisionCd))];

				const trendTwelveMonthsGridDataSource = evalDivisionCds.map((z, index) => {
					const rowData = {
						key: index + 1,
						evalDivisionCd: z,
						evalDivisionNm: this.dataGridLoopup.evalDivisionCd.find(el => el.codeId == z).codeNm,
					};

					res.data.data
						.filter(el => el.evalDivisionCd == z)
						.forEach(e => {
							const dataFieldIdx = this.trendTwelveMonthsGridData.dataFieldIdx[e.scheYm];
							rowData[`totalScore_${dataFieldIdx}`] = e.totalScore;
							rowData['targetAgtId'] = e.targetAgtId;
							rowData['scheYm'] = e.scheYm;
						});

					return rowData;
				});

				this.trendTwelveMonthsGridData.dataSource = trendTwelveMonthsGridDataSource;
				this.trendTwelveMonthsGridData.totalCount = trendTwelveMonthsGridDataSource.length;

				this.trendTwelveMonthsChartData.series = trendTwelveMonthsGridDataSource;

				console.log('this.trendTwelveMonthsChartData.series', this.trendTwelveMonthsChartData.series);

				const chartDs = [];

				this.trendTwelveMonthsChartData.trendMonths.forEach(y => {
					const chartData = {
						scheYm: y,
					};

					evalDivisionCds.forEach(z => {
						const dataIndex = res.data.data.findIndex(re => re.evalDivisionCd === z && re.scheYm === y);

						chartData[z] = dataIndex > -1 ? res.data.data[dataIndex].totalScore : null;
					});

					chartDs.push(chartData);
				});

				this.trendTwelveMonthsChartData.dataSource = chartDs;

				console.log('this.trendTwelveMonthsChartData.dataSource', this.trendTwelveMonthsChartData.dataSource);
			}
		},
		/** @description : 12개월 평가 추이 그리드 캡션 세팅 */
		setTrendTwelveMonthsGridColumns(toScheYmd, pastTrendMonths) {
			this.trendTwelveMonthsChartData.trendMonths = [];

			let count = 0;
			while (count <= pastTrendMonths) {
				const scheYmd = this.$_commonlib
					.moment(toScheYmd)
					.subtract(pastTrendMonths - count, 'months')
					.format('yyyy-MM');
				this.trendTwelveMonthsGridData.dataFieldIdx[scheYmd] = count + 1;
				this.trendTwelveMonthsChartData.trendMonths.push(scheYmd);

				this.$refs.trendTwelveMonthsGridData.instance.columnOption(`totalScore_${count + 1}`, 'caption', scheYmd);

				count++;
			}
		},
		onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.frScheYmd = changed;
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.toScheYmd = changed;
		},
		/** @description : 코드 로드 */
		async initCodeMap() {
			this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
		},
		async createdData() {
			vm = this;

			await this.initCodeMap().then(() => {
				this.dataGridLoopup.evalDivisionCd = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
			});
		},
		mountedData() {
			//this.selectData();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>
<style lang="scss" scoped>
.page_search_box .inner div {
	display: inline-block;
}
.page_search_box .inner > div:not(.box-btn-search) {
	vertical-align: middle;
	margin-right: 10px;
}
.page_search_box .spec-type-box {
	display: inline-block;
}
.page_search_box .inner .box-btn-search {
	margin-right: 15px;
}
.page_search_box #searchTooltip {
	margin-right: 5px;
	font-size: 22px;
	color: #808080;
}
#trendTwelveMonthsChart {
	height: 300px;
}
</style>
