import EUCInfoPushSetting from '@/pages/euc/info-push/setting';
import EUCInfoPushSettingDeptUpdate from '@/pages/euc/info-push/setting/dept/update.vue';
import EUCInfoPushSettingPatternUpdate from '@/pages/euc/info-push/setting/pattern/update.vue';
import EUCInfoPushSettingUserUpdate from '@/pages/euc/info-push/setting/user/index.vue';

export default function InfoPushSettingRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/setting',
            name: 'EUCInfoPushSetting',
            components: { default: EUCInfoPushSetting, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/setting/dept/update',
            name: 'EUCInfoPushSettingDeptUpdate',
            components: { default: EUCInfoPushSettingDeptUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/setting/pattern/update',
            name: 'EUCInfoPushSettingPatternUpdate',
            components: { default: EUCInfoPushSettingPatternUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/setting/user/update',
            name: 'EUCInfoPushSettingUserUpdate',
            components: { default: EUCInfoPushSettingUserUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}