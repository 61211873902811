<template>
  <div class="container">
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">기준년월</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="100"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy-MM"
              date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
              invalid-date-message="입력 데이터가 유효하지 않습니다."
              :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
              @value-changed="onChangePeriod"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-2 pl-2">생산성계획</div>
            <div>
              <DxTextBox :styling-mode="textStylingMode" v-model="modal.scheNm" :width="120" :height="30" :read-only="true">
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="생산성계획 선택은 필수입니다." />
                </DxValidator>
              </DxTextBox>
            </div>
            <div>
              <DxButton icon="search" :width="40" :height="30" @click="chooseKpiSche" />
            </div>
            <div class="mt-2">스케줄 적용여부</div>
            <div class="mt-1">
              <DxCheckBox class="checkbox" :value="modal.agtScheApply" :disabled="true" />
            </div>
            <div class="pl-2">
              <DxButton text="진행" :height="30" class="btn_XS default filled" @click="onProgress" />
            </div>
            <div>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </div>
        </div>
      </div>

      <div class="gap-y-6">

        <div class="max-h-5/12">
          <DxDataGrid
            id="dxDataGrid"
            class="grid-box"
            ref="kpiStdGrid"
            :allow-column-reordering="kpiStdGrid.allowColumnReordering"
            :allow-column-resizing="kpiStdGrid.allowColumnResizing"
            :column-auto-width="kpiStdGrid.columnAutoWidth"
            :column-min-width="kpiStdGrid.columnMinWidth"
            :column-resizing-mode="kpiStdGrid.columnResizingMode"
            :data-source="kpiStdGrid.dataSource"
            :focused-row-enabled="kpiStdGrid.focusedRowEnabled"
            :no-data-text="noDataText()"
            :height="kpiStdGrid.height"
            :hover-state-enabled="kpiStdGrid.hoverStateEnabled"
            :key-expr="kpiStdGrid.keyExpr"
            :row-alternation-enabled="kpiStdGrid.rowAlternationEnabled"
            :show-borders="kpiStdGrid.showBorders"
            :show-column-headers="kpiStdGrid.showColumnHeaders"
            :show-column-lines="kpiStdGrid.showColumnLines ? kpiStdGrid.showColumnLines : true"
            :show-row-lines="kpiStdGrid.showRowLines"
            :width="kpiStdGrid.width"
            :word-wrap-enabled="kpiStdGrid.wordWrapEnabled"
            @exporting="onExportingCheck"
            @toolbar-preparing="onToolbarPreparing"
            :cache-enabled="false"
          >

            <DxExport
              v-if="showActionButtons.excel"
              :enabled="showActionButtons.excel.enabled === undefined ? true : showActionButtons.excel.enabled"
              :allow-export-selected-data="
                showActionButtons.excel.allowExportSelectedData === undefined ? true : showActionButtons.excel.allowExportSelectedData
              "
              :texts="showActionButtons.excel.exportButtonText ? showActionButtons.excel.exportButtonText : kpiStdGrid.exportButtonText"
            />
            <template v-for="(column, index) in kpiStdGrid.columns">
              <template v-if="column.multiHeaderNm">
                <DxColumn :key="index" :caption="$_lang(column.i18n, { defaultValue: column.multiHeaderNm })">
                  <DxColumn
                    v-for="(column2, index2) in column.columns"
                    :key="`${index}_${index2}`"
                    :alignment="column2.alignment"
                    :allow-editing="column2.allowEditing"
                    :allow-exporting="column2.allowExporting"
                    :allow-filtering="column2.allowFiltering"
                    :allow-grouping="column2.allowGrouping"
                    :allow-header-filtering="column2.allowHeaderFiltering"
                    :allow-sorting="column2.allowSorting"
                    :calculate-sort-value="column2.calculateSortValue"
                    :calculate-cell-value="column2.calculateCellValue"
                    :calculate-display-value="column2.calculateDisplayCellValue"
                    :caption="$_lang(column2.i18n, { defaultValue: column2.caption })"
                    :cell-template="column2.cellTemplate"
                    :css-class="column2.cssClass"
                    :customize-text="column2.customizeText"
                    :data-field="column2.dataField"
                    :data-type="column2.dataType"
                    :edit-cell-template="column2.editCellTemplate"
                    :editor-options="column2.editorOptions"
                    :editor-type="column2.editorType"
                    :filterValue="column2.filterValue"
                    :fixed="column2.fixed"
                    :fixed-position="column2.fixedPosition"
                    :format="column2.format"
                    :group-index="column2.groupIndex"
                    :header-cell-template="column2.headerCellTemplate"
                    :height="column2.height"
                    :set-cell-value="column2.setCellValue"
                    :sort-order="column2.sortOrder"
                    :validation-rules="column2.validationRules"
                    :visible="column2.visible"
                    :width="column2.width"
                    :min-width="column2.minWidth === 0 || column2.minWidth ? column2.minWidth : 50"
                  >
                    <!-- selectBox 옵션 -->
                    <DxLookup
                      v-if="column2.lookup"
                      :data-source="column2.lookup.dataSource"
                      :display-expr="column2.lookup.displayExpr"
                      :value-expr="column2.lookup.valueExpr"
                      :allow-clearing="column2.lookup.allowClearing"
                    />

                    <!-- 헤더필터 설정 -->
                    <DxHeaderFilter v-if="column2.headerFilter" :data-source="column2.headerFilter.dataSource" />

                    <!-- 필수조건 설정 -->
                    <DxRequiredRule
                        v-if="column2.requiredRule"
                        :message="$_lang(column2.requiredRule.i18n, { defaultValue: column2.requiredRule.message })"
                    />
                    <!-- 패턴 규칙 설정 -->
                    <DxPatternRule v-if="column2.patternRule" :pattern="column2.patternRule.pattern" :message="column2.patternRule.message" />
                    <!-- 커스텀 규칙 설정 -->
                    <DxCustomRule
                      v-if="column2.customRule"
                      type="custom"
                      :validationCallback="column2.customRule.callback"
                      :message="column2.customRule.message"
                    />
                  </DxColumn>
                </DxColumn>
              </template>
              <template v-else>
                <DxColumn
                  :key="index"
                  :alignment="column.alignment"
                  :allow-editing="column.allowEditing"
                  :allow-exporting="column.allowExporting"
                  :allow-filtering="column.allowFiltering"
                  :allow-grouping="column.allowGrouping"
                  :allow-header-filtering="column.allowHeaderFiltering"
                  :allow-sorting="column.allowSorting"
                  :calculate-sort-value="column.calculateSortValue"
                  :calculate-cell-value="column.calculateCellValue"
                  :calculate-display-value="column.calculateDisplayCellValue"
                  :caption="$_lang(column.i18n, { defaultValue: column.caption })"
                  :cell-template="column.cellTemplate"
                  :css-class="column.cssClass"
                  :customize-text="column.customizeText"
                  :data-field="column.dataField"
                  :data-type="column.dataType"
                  :edit-cell-template="column.editCellTemplate"
                  :editor-options="column.editorOptions"
                  :editor-type="column.editorType"
                  :filterValue="column.filterValue"
                  :fixed="column.fixed"
                  :fixed-position="column.fixedPosition"
                  :format="column.format"
                  :group-index="column.groupIndex"
                  :header-cell-template="column.headerCellTemplate"
                  :height="column.height"
                  :set-cell-value="column.setCellValue"
                  :sort-order="column.sortOrder"
                  :validation-rules="column.validationRules"
                  :visible="column.visible"
                  :width="column.width"
                  :min-width="column.minWidth === 0 || column.minWidth ? column.minWidth : 50"
                >
                  <!-- selectBox 옵션 -->
                  <DxLookup
                    v-if="column.lookup"
                    :data-source="column.lookup.dataSource"
                    :display-expr="column.lookup.displayExpr"
                    :value-expr="column.lookup.valueExpr"
                    :allow-clearing="column.lookup.allowClearing"
                  />

                  <!-- 헤더필터 설정 -->
                  <DxHeaderFilter v-if="column.headerFilter" :data-source="column.headerFilter.dataSource" />

                  <!-- 필수조건 설정 -->
                  <DxRequiredRule v-if="column.requiredRule" :message="column.requiredRule.message" />
                  <!-- 패턴 규칙 설정 -->
                  <DxPatternRule v-if="column.patternRule" :pattern="column.patternRule.pattern" :message="column.patternRule.message" />
                  <!-- 커스텀 규칙 설정 -->
                  <DxCustomRule
                    v-if="column.customRule"
                    type="custom"
                    :validationCallback="column.customRule.callback"
                    :message="column.customRule.message"
                  />
                </DxColumn>
              </template>
            </template>
          </DxDataGrid>
        </div>
        <div class="h-7/12">
          <esp-dx-data-grid :data-grid="resultGrid" ref="resultGrid"/>
        </div>
      </div>
    </div>
    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
            ref="reasonModalRef"
            :is="modal.currentComponent"
            :contentData="modal.contentData"
            v-model="modal.contentData">
          </component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
        modal.initData.hasOwnProperty('buttons')
          ? modal.initData.buttons.hasOwnProperty('save')
            ? modal.initData.buttons.hasOwnProperty('save')
            : false
          : false
      "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.height
              : 40
            : 40,
          onClick: () => {
            onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
        modal.initData.hasOwnProperty('buttons')
          ? modal.initData.buttons.hasOwnProperty('cancel')
            ? modal.initData.buttons.hasOwnProperty('cancel')
            : !modal.initData.buttons.hasOwnProperty('cancel')
          : false
      "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.width
              : 120
            : 120,
          height: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.height
              : 40
            : 40,
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal.currentComponent === 'ModalDownloadReason'"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => this.isOpenModal(false),
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="modal.currentComponent === 'ModalDownloadReason'"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => this.onDownloadReason(),
        }"
      />
    </DxPopup>

    <!-- /Modal Layer -->
  </div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxButton } from 'devextreme-vue/button';
import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import validationEngine from 'devextreme/ui/validation_engine';
import {formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import ModalKpiSche from '@/pages/ewm/productivity/report/result/modal-kpi-sche';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import {
  DxColumn,
  DxCustomRule,
  DxDataGrid,
  DxExport,
  DxHeaderFilter,
  DxLookup,
  DxPatternRule
} from "devextreme-vue/data-grid";

let vm = null;

export default {
  name: 'EWMReportResult',
  components: {
    DxHeaderFilter, DxColumn, DxCustomRule, DxPatternRule, DxLookup,
    DxDataGrid,
    DxExport,
    EspDxDataGrid,
    DxDateBox,
    DxTextBox,
    DxCheckBox,
    DxButton,

    DxRequiredRule,
    DxValidator,
    DxValidationGroup,

    DxPopup,
    DxToolbarItem,

    ModalKpiSche,
    ModalDownloadReason
  },
  props: {},
  watch: {},
  data() {
    return {
      textStylingMode: 'filled',
      stylingMode: 'outlined', //outlined, underlined, filled
      searchType: {
        obj: {},
        defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
        defaultValue: 'ROOT',
        customTypes: {
          dayStart: getPastFromToday(0, 'months'),
          // dayEnd: getPastFromToday(-5, 'months'),
          targetName: '',
          targetId: '',
        },
        paramsData: null,
      },
      codes: {
        productionTypeCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
      },
      modal: {
        isOpened: false,
        initData: {},
        contentData: null,
        componentName: '',
        agtScheApply: false,
        scheId: null,
        scheNm: null,
      },
      showActionButtons: {
        excel: {
          enabled:true,
          allowExportSelectedData:false
        },
        customButtons: [],
      },
      kpiStdGrid: {
        keyExpr: 'id',
        refName: 'kpiStdGrid',
        allowColumnResizing: true,
        columnResizingMode: 'widget',
        columnAutoWidth: true,
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        // width:'200',     // 주석처리시 100%
        height: '30vh', // 주석처리시 100%
        dataSource: [],
        scrolling: {
          mode: 'standard',
        },
        callApi: 'CALL_EWM_API',
        apiActionNm: {},
        customEvent: {},
        excel: {
          title: '생산성결과 보고서',
          // autoFilterEnabled: true,
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        exportButtonText: {
          //엑셀 다운로드 정보
          exportTo: '엑셀다운로드',
          exportAll: '전체 다운로드',
          exportSelectedRows: '선택한 데이터 다운로드',
        },
        /* selecting: {
        mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
        selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
        showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
      }, */
        columns: [
          {
            caption: '구분',
            dataField: 'productionTypeCd',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '평가기준명',
            dataField: 'productionName',
            alignment: 'left',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'IB(%)',
            dataField: 'inCntWeight',
            alignment: 'center',
            visible: false,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'OB(%)',
            dataField: 'outCntWeight',
            alignment: 'center',
            visible: false,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            multiHeaderNm: '가중치',
            columns: [
              {
                caption: 'IB(%)',
                dataField: 'inTimeWeight',
                alignment: 'center',
                visible: true,
                width: 120,
                allowEditing: false,
                allowHeaderFiltering: false,
                calculateSortValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return rowData.inCntWeight;
                  } else {
                    return rowData.inTimeWeight;
                  }
                },
                calculateDisplayCellValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return `${rowData.inCntWeight} %`;
                  } else {
                    return `${rowData.inTimeWeight} %`;
                  }
                },
              },
              {
                caption: 'OB(%)',
                dataField: 'outTimeWeight',
                alignment: 'center',
                visible: true,
                width: 120,
                allowEditing: false,
                allowHeaderFiltering: false,
                calculateSortValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return rowData.outCntWeight;
                  } else {
                    return rowData.outTimeWeight;
                  }
                },
                calculateDisplayCellValue: rowData => {
                  if (rowData.productionTypeCd == 1274) {
                    return `${rowData.outCntWeight} %`;
                  } else {
                    return `${rowData.outTimeWeight} %`;
                  }
                },
              },
            ],
          },
          {
            caption: '평가방식',
            dataField: 'evaluationMethod',
            alignment: 'left',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '배점',
            dataField: 'productionScore',
            alignment: 'center',
            visible: true,
            width: 110,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
        ],
      },
      originColumns: [
        {
          caption: '부서정보',
          dataField: 'deptNmPath',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '상담사',
          dataField: 'agtId',
          alignment: 'center',
          width: 120,
          visible: false,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '상담사',
          dataField: 'agtIdAndNm',
          alignment: 'center',
          width: 120,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          calculateSortValue: data => `${data.agtNm} [${data.agtId}]`,
          calculateCellValue: rowData => {
            return `${rowData.agtNm} [${rowData.agtId}]`;
          },
          fixed: true,
        },
        {
          caption: '총근무시간',
          dataField: 'workTime',
          alignment: 'center',
          width: 110,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '기준일수',
          dataField: 'workBaseYmd',
          alignment: 'center',
          width: 110,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
          fixed: true,
        },
        {
          caption: '총통화건수',
          dataField: 'ans',
          alignment: 'center',
          width: 110,
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '총통화시간',
          dataField: 'talkTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: 'I/B',
          dataField: 'dnTalkTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: 'O/B',
          dataField: 'obExTalkTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          multiHeaderNm: '내선전화',
          columns: [
            {
              caption: 'IN',
              dataField: 'dnAns',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: 'OB',
              dataField: 'obInConn',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
          ],
        },
        {
          caption: '외선 OB',
          dataField: 'obExConn',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '호전환 건수',
          dataField: 'transout',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '대기',
          dataField: 'readyTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '후처리',
          dataField: 'acwTime',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          multiHeaderNm: '이석',
          columns: [
            {
              caption: '이석1',
              dataField: 'nrsn1Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석2',
              dataField: 'nrsn2Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석3',
              dataField: 'nrsn3Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석4',
              dataField: 'nrsn4Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석5',
              dataField: 'nrsn5Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석6',
              dataField: 'nrsn6Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석7',
              dataField: 'nrsn7Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석8',
              dataField: 'nrsn8Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석9',
              dataField: 'nrsn9Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '이석10',
              dataField: 'nrsn10Time',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
          ],
        },
      ],
      methodColumns: [],
      rankColumns: [
        {
          caption: '점수합계',
          dataField: 'totalResultScore',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
        {
          caption: '순위',
          dataField: 'resultRank',
          alignment: 'center',
          visible: true,
          allowEditing: false,
          allowHeaderFiltering: false,
        },
      ],
      resultGrid: {
        keyExpr: 'targetId',
        refName: 'resultGrid',
        allowColumnResizing: true,
        columnResizingMode: 'widget',
        columnAutoWidth: true,
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        // width:'200',     // 주석처리시 100%
        height: '50vh', // 주석처리시 100%
        dataSource: [],
        scrolling: {
          mode: 'standard',
          columnRenderingMode:'standard',
        },
        callApi: 'CALL_EWM_API',
        apiActionNm: {},
        customEvent: {},
        showActionButtons: {
          customButtons: [],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '부서정보',
            dataField: 'deptNmPath',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            fixed: true,
          },
          {
            caption: '상담사',
            dataField: 'agtId',
            alignment: 'center',
            width: 120,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            calculateSortValue: data => `${data.agtNm} [${data.agtId}]`,
            calculateDisplayCellValue: rowData => {
              return `${rowData.agtNm} [${rowData.agtId}]`;
            },
            fixed: true,
          },
          {
            caption: '총근무시간',
            dataField: 'workTime',
            alignment: 'center',
            width: 110,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            fixed: true,
          },
          {
            caption: '기준일수',
            dataField: 'workBaseYmd',
            alignment: 'center',
            width: 110,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
            fixed: true,
          },
          {
            caption: '총통화건수',
            dataField: 'ans',
            alignment: 'center',
            width: 110,
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '총통화시간',
            dataField: 'talkTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'I/B',
            dataField: 'dnTalkTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: 'O/B',
            dataField: 'obExTalkTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            multiHeaderNm: '내선전화',
            columns: [
              {
                caption: 'IN',
                dataField: 'dnAns',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: 'OB',
                dataField: 'obInConn',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
            ],
          },
          {
            caption: '외선 OB',
            dataField: 'obExConn',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '호전환 건수',
            dataField: 'transout',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '대기',
            dataField: 'readyTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '후처리',
            dataField: 'acwTime',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            multiHeaderNm: '이석',
            columns: [
              {
                caption: '이석1',
                dataField: 'nrsn1Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석2',
                dataField: 'nrsn2Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석3',
                dataField: 'nrsn3Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석4',
                dataField: 'nrsn4Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석5',
                dataField: 'nrsn5Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석6',
                dataField: 'nrsn6Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석7',
                dataField: 'nrsn7Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석8',
                dataField: 'nrsn8Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석9',
                dataField: 'nrsn9Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
              {
                caption: '이석10',
                dataField: 'nrsn10Time',
                alignment: 'center',
                visible: true,
                allowEditing: false,
                allowHeaderFiltering: false,
              },
            ],
          },
        ],
      },
    };
  },
  computed: {
    searchValidationGroup: function () {
      return this.$refs['searchValidationGroup'].instance;
    },
  },
  methods: {
    onChangePeriod() {
      this.modal.scheId = null;
      this.modal.scheNm = null;
    },
    /** @description : 생산성 계획 선택 */
    chooseKpiSche() {
      this.onOpenModal(
          'ModalKpiSche',
          {
            title: '생산성평가계획',
            buttons: {
              save: {text: '선택'},
              cancel: {text: '닫기'},
            },
            width: '40%',
            height: '50%',
          },
          {
            dayStart: this.searchType.customTypes.dayStart,
          },
      );
    },
    onOpenModal(componentNm, componentInitData, data) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.contentData = data;
      this.isOpenModal(true);
    },
    /** @description: 팝업 오픈 체크 메서드 */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    async onConfirmModal() {
      if (!this.modal.contentData.val?.length) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        return;
      }

      this.modal.agtScheApply = this.modal.contentData.val[0].agtScheApply == 'N' ? false : true;
      this.modal.scheNm = this.modal.contentData.val[0].scheNm;
      this.modal.scheId = this.modal.contentData.val[0].id;

      this.isOpenModal(false);
    },
    async selectDataList() {
      if (!validationEngine.validateGroup('validationSearch').isValid) {
        return;
      }

      const payload1 = {
        actionName: 'EWM_PRODUCTIVITY_SCHEDULE_DETAIL_LIST',
        data: {
          scheId: this.modal.scheId,
        },
        loading: false,
      };

      const res1 = await vm.CALL_EWM_API(payload1);

      if (isSuccess(res1)) {
        this.methodColumns = [];
        res1.data.data[0].scheStds.forEach(d => {
          this.methodColumns.push({
            caption: d.productionName,
            dataField: d.kpiProStdId + '',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          });
        });
        res1.data.data[0].scheStds.forEach(d => {
          this.methodColumns.push({
            caption: d.productionName + ' 환산점수',
            dataField: d.kpiProStdId + 'Score',
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowHeaderFiltering: false,
          });
        });
        this.kpiStdGrid.dataSource = res1.data.data[0].scheStds;

        const dynamicColumns = [...this.originColumns, ...this.methodColumns, ...this.rankColumns];
        this.$set(this.resultGrid, 'columns', dynamicColumns);

        const payload2 = {
          actionName: 'EWM_PRODUCTIVITY_REPORT_RESULT_LIST',
          data: {
            scheId: this.modal.scheId,
          },
          loading: false,
        };

        const res2 = await vm.CALL_EWM_API(payload2);

        if (isSuccess(res2)) {
          res2.data.data.forEach(item => {
            const resultScore = item.resultScore;
            Object.keys(resultScore).forEach(data => {
              item[data] = resultScore[data];
            });
            delete item.resultScore;
          });
          this.resultGrid.dataSource = res2.data.data;
        }
      }
    },
    async onProgress() {
      if (!validationEngine.validateGroup('validationSearch').isValid) {
        return;
      }

      if (await this.$_Confirm('생산성평가를 진행 하시겠습니까? \n (평가진행된 내역이 있는 경우 삭제 후 진행됩니다.)')) {
        const payload = {
          actionName: 'EWM_PRODUCTIVITY_REPORT_RESULT_SAVE',
          data: {
            startDt: this.searchType.customTypes.dayStart.substring(0, 6) + '01',
            endDt: this.searchType.customTypes.dayStart.substring(0, 6) + '30',
            scheId: this.modal.scheId,
          },
          loading: false,
        };
        const res = await vm.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
        }
      }
    },
    exportToExcel() {
      const workbook = new Workbook();
      const standard = workbook.addWorksheet('기준');
      const result = workbook.addWorksheet('결과');

      const title = "생산성 결과 보고서";
      let today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

      exportDataGrid({
        worksheet: standard,
        component: this.$refs.kpiStdGrid.instance,
        topLeftCell: {row: 4, column: 1},
        customizeCell: ({gridCell, excelCell}) => {
          if (gridCell.rowType === 'header') {
            //header 영역 설정
            excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'C6EFCE'}};
            excelCell.alignment = {horizontal: 'center', vertical: 'middle'};
          } else {
            //data 영역 배경색 설정
            if (excelCell.fullAddress.row % 2 === 0) {
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'F2F2F2'},
                bgColor: {argb: 'F2F2F2'},
              };
            }
          }

          const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};
          excelCell.border = {
            bottom: borderStyle,
            left: borderStyle,
            right: borderStyle,
            top: borderStyle,
          };
        },
      }).then(() => {
        const titleRow = standard.getRow(2);
        titleRow.height = 40;
        standard.mergeCells(2, 1, 2, this.kpiStdGrid.columns.length);

        titleRow.getCell(1).value = title;
        titleRow.getCell(1).font = {size: 22, bold: true};
        titleRow.getCell(1).alignment = {horizontal: 'center', vertical: 'middle'};

        const hearderRow = standard.getRow(4);
        hearderRow.height = 30;
      }).then(() => exportDataGrid({
        worksheet: result,
        component: this.$refs.resultGrid.getInstance,
        topLeftCell: {row: 4, column: 1},
        customizeCell: ({gridCell, excelCell}) => {
          if (gridCell.rowType === 'header') {
            //header 영역 설정
            excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'C6EFCE'}};
            excelCell.alignment = {horizontal: 'center', vertical: 'middle'};
          } else {
            //data 영역 배경색 설정
            if (excelCell.fullAddress.row % 2 === 0) {
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'F2F2F2'},
                bgColor: {argb: 'F2F2F2'},
              };
            }
          }

          const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};
          excelCell.border = {
            bottom: borderStyle,
            left: borderStyle,
            right: borderStyle,
            top: borderStyle,
          };
        }
      })).then(() => {
        const titleRow = result.getRow(2);
        titleRow.height = 40;

        result.mergeCells(2, 1, 2, this.resultGrid.columns.length);

        titleRow.getCell(1).value = "생산성 결과 보고서";
        titleRow.getCell(1).font = {size: 22, bold: true};
        titleRow.getCell(1).alignment = {horizontal: 'center', vertical: 'middle'};

        const hearderRow = result.getRow(4);
        hearderRow.height = 30;
      }).then(() => {
        let fileName = `${title}_${today}.xlsx`;
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], {type: 'application/octet-stream'}), fileName);
        });
      });
    },
    noDataText() {
      return this.$_lang('COMMON.MESSAGE.CMN_NO_DATA', {defaultValue: '데이터가 없습니다.'});
    },
    /** @description: 그리드 초기화 이벤트 */
    onInitialized(e) {
      if (this.checkObjKeyByCustomEvent('initialized')) {
        //커스텀시 해당 페이지의 이벤트 호출
        this.$emit('initialized', e);
      }
    },
    /** @description : 커스텀이벤트의 object key 값 체크 */
    checkObjKeyByCustomEvent(objectKey) {
      if (!this.dataGrid || typeof this.dataGrid !== 'object') {
        return false; // dataGrid 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
      }

      const customEvent = this.dataGrid.customEvent;
      if (typeof customEvent !== 'object') {
        return false; // customEvent 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
      }

      const hasObjectKey = Object.prototype.hasOwnProperty.call(customEvent, objectKey);
      return hasObjectKey ? customEvent[objectKey] : false; // objectKey가 customEvent에 존재하면 그 값을 반환하고, 그렇지 않으면 false 반환
    },
    makeSaveHistory(event, reason = '') {
      const user = {
        userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
        userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
        deptNm: this.$store.getters.getUserInfo?.deptNm || '',
        gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
      };

      const payload = {
        actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
        data: [
          {
            ...user,
            reason,
            fileType: 'EXCEL',
            fileNm: this.kpiStdGrid.excel?.title,
          },
        ],
        loading: false,
      };

      event.onSaveHistory = async fileNm => {
        payload.data[0].fileNm = fileNm || this.kpiStdGrid.excel?.title;
        return await this.CALL_API(payload);
      };
      return event;
    },
    onExportingCheck(e) {
      const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
      e = this.makeSaveHistory(e);
      if (useDownReason) {
        e.cancel = true;
        this.onOpenModal(
            'ModalDownloadReason',
            {
              title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', {defaultValue: '다운로드 사유'}),
              width: '600',
              height: '400',
            },
            e,
        );
      } else {
        this.exportToExcel();
      }
    },
    /**
     * @description: 엑셀 다운로드 사유 입력 모달 이벤트
     */
    onDownloadReason() {
      let event = this.modal.contentData;
      const reason = this.$refs.reasonModalRef.reason;
      if (reason.trim() === '') {
        this.$_Msg(
            this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
              defaultValue: '다운로드 사유를 입력하세요.',
            }),
        );
        event.cancel = true;
      } else {
        event = this.makeSaveHistory(event, reason);
        event.cancel = false;
        this.exportToExcel();
        this.isOpenModal(false);
      }
    },
    /** @description: 그리드 상단 툴바 버튼 관련 이벤트 */
    onToolbarPreparing(e) {
      if (this.checkObjKeyByCustomEvent('toolbarPreparing')) {
        //커스텀시 해당 페이지의 이벤트 호출
        this.$emit('toolbar-preparing', e);
      } else {
        const toolbarItems = e.toolbarOptions.items;

        if (this.showActionButtons?.excel) {
          toolbarItems.forEach(d => {
            if (d.name === 'exportButton') {
              d.options.icon = 'export';
              d.location = 'before';
              d.sortIndex = 100;
              if (this.showActionButtons?.csv) {
                e.format = 'csv';
                d.options.items = d.options.items.concat([
                  {
                    icon: 'txtfile',
                    text: `csv ${this.$_lang('COMPONENTS.DOWNLOAD')}`,
                    onClick: () => {
                      this.onExportingCheck(e);
                    },
                  },
                ]);
              }
            }
          });
        }
      }
    },
    /** @description : 코드 로드 */
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_kpi_production_type');
    },
    /** @description : 라이프사이클 created 호출되는 메서드 */
    async createdData() {
      vm = this;

      await this.initCodeMap().then(() => {
        this.codes.productionTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_kpi_production_type'], 2);

        this.$refs.kpiStdGrid.instance.columnOption('productionTypeCd', 'lookup', this.codes.productionTypeCd);
      });
    },
    /** @description : 라이프사이클 mounted 호출되는 메서드 */
    mountedData() {
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
};
</script>
