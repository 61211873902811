<template>
  <div class="container content-themes">
    <div class="maxHeight">
      <DxValidationGroup ref="validationGroup">
        <!-- 1차 -->
        <div class="page-sub-box clearfix ui-glid-box top-box">
          <div class="sub_title_txt my-4 pt-4">
            <h2>공지사항 설정</h2>
          </div>
          <table class="table_form line-bin">
            <colgroup>
              <col style="width: 150px" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <label for="label5"> 카테고리 <span class="icon_require">필수항목</span> </label>
                </th>
                <td class="clearfix">
                  <DxSelectBox
                    :items="codes.categoryCd.dataSource"
                    display-expr="codeNm"
                    value-expr="codeId"
                    :value="formData.categoryCd"
                    :styling-mode="config.stylingMode"
                    width="200"
                    class="mar_ri10"
                    @value-changed="changeCategoryCd"
                  >
                    <DxValidator>
                      <DxRequiredRule message="카테고리 선택은 필수입니다." />
                    </DxValidator>
                  </DxSelectBox>
                </td>
              </tr>
              <tr>
                <th scope="row"> 
                  <label for="label5"> 제목 <span class="icon_require">필수항목</span> </label>
                </th>
                <td>
                  <DxTextBox
                    v-model="formData.title"
                    :styling-mode="config.stylingMode"
                    :width="400"
                    class="mar_ri10 alB"
                    :max-length="limitNumberTexts.maxLengths.title"
                    @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'title')"
                  >
                    <DxValidator>
                      <DxRequiredRule message="재목은 필수입니다." />
                    </DxValidator>
                  </DxTextBox>
                  {{ formData.title ? formData.title.length : 0 }} / {{ limitNumberTexts.maxLengths.title }}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">
                    공개 여부
                    <span>
                      <button
                        class="mdi mdi-help-circle"
                        id="viewTooltip"
                        @mouseenter="setTooltips('view')"
                        @mouseleave="setTooltips('view')"
                      ></button>
                      <DxPopover
                        target="#viewTooltip"
                        :visible="config.tooltip.isVisible.view"
                        :hide-on-outside-click="config.tooltip.closeOnOutsideClick"
                      >
                        <span v-html="config.tooltip.viewContents"></span>
                      </DxPopover>
                    </span>
                  </label>
                </th>
                <td>
                  <DxSwitch :value="getViewFl" @value-changed="onChangedViewFl($event)"/>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">
                    공지 유형
                    <span>
                      <button
                        class="mdi mdi-help-circle"
                        id="typeTooltip"
                        @mouseenter="setTooltips('type')"
                        @mouseleave="setTooltips('type')"
                      ></button>
                      <DxPopover
                        target="#typeTooltip"
                        :visible="config.tooltip.isVisible.type"
                        :hide-on-outside-click="config.tooltip.closeOnOutsideClick"
                      >
                        <span v-html="config.tooltip.typeContents"></span>
                      </DxPopover>
                    </span>
                  </label>
                </th>
                <td class="space-x-4 flex">
                  <div>
                    <DxRadioGroup 
                      :items="config.typeCd" 
                      v-model="formData.typeCd" 
                      layout="horizontal" 
                      value-expr="key"
                      display-expr="value"
                    />
                  </div>
                  <div class="space-x-2" v-if="formData.typeCd === '1'">
                    <DxDateBox
                      v-model="formData.typeValidFrDt"
                      :styling-mode="config.stylingMode"
                      :width="120"
                      type="date"
                      display-format="yyyy.MM.dd"
                      dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                      dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                      :max="formData.typeValidToDt"
                      maxLength="10"
                      invalid-date-message="입력 데이터가 유효하지 않습니다."
                    >
                    </DxDateBox>
                    <span class="dash">~</span>
                    <DxDateBox
                      v-model="formData.typeValidToDt"
                      :styling-mode="config.stylingMode"
                      :width="120"
                      type="date"
                      display-format="yyyy.MM.dd"
                      dateSerializationFormat="yyyy-MM-ddTHH:mm:ss"
                      dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                      :min="formData.typeValidFrDt"
                      maxLength="10"
                      invalid-date-message="입력 데이터가 유효하지 않습니다."
                    >
                    </DxDateBox>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5"> 공지 대상 </label>
                </th>
                <td class="space-x-4 flex">
                  <div>
                    <DxRadioGroup 
                      :items="config.target" 
                      v-model="formData.target" 
                      layout="horizontal" 
                      value-expr="key"
                      display-expr="value"
                    />
                  </div>
                  <div class="space-x-2" v-if="formData.target === '1'">
                    <DxTagBox
                      ref="tagBox"
                      placeholder=""
                      :show-selection-controls="false"
                      :search-enabled="false"
                      :data-source="tagBoxData"
                      :value="selectedCondition"
                      display-expr="name"
                      value-expr="id"
                      :multiline="false"
                      height="30"
                      width="550"
                      @value-changed="onTagValueChanged"
                      :styling-mode="config.stylingMode"
                    >
                    </DxTagBox>
                    <DxButton class="btn_XS white outlined add2" text="선택" type="button" :height="30" @click="onOpen" />
                    <DxButton class="btn_XS white outlined del1" text="전체삭제" type="button" :height="30" @click="deleteConditions" />
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5">
                    알림  
                    <span>
                      <button
                        class="mdi mdi-help-circle"
                        id="alertTooltip"
                        @mouseenter="setTooltips('alert')"
                        @mouseleave="setTooltips('alert')"
                      ></button>
                      <DxPopover
                        target="#alertTooltip"
                        :visible="config.tooltip.isVisible.alert"
                        :hide-on-outside-click="config.tooltip.closeOnOutsideClick"
                      >
                        <span v-html="config.tooltip.alertContents"></span>
                      </DxPopover>
                    </span>
                  </label>
                </th>
                <td>
                  <DxCheckBox class="checkbox" v-model="formData.alertFl" text="알림 발송"></DxCheckBox>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 2차 -->
        <div class="page-sub-box clearfix ui-glid-box top-box">
          <div class="sub_title_txt my-4">
            <h2>공지사항 내용</h2>
          </div>
          <table class="table_form line-bin">
            <colgroup>
              <col style="width: 150px" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <label for="label5"> 첨부파일 </label>
                </th>
                <td>
                  <DxButton
                    text="파일추가"
                    type="button"
                    @click="uploadFiles"
                    class="btn_XS white light_filled"
                    :height="15"
                  />
                  <DxFileUploader
                    ref="fileUploader"
                    id="file-uploader"
                    :multiple="true"
                    upload-mode="useButtons"
                    dialog-trigger="#dropzone-external"
                    :visible="false"
                    @value-changed="onChangedAttachedFiles"
                  />
                  <div 
                    v-if="formData.fileGroupId" 
                    class="max-h-44 overflow-y-auto"
                  >
                    <div 
                      v-for="(item, i) in config.fileList"
                      class="mt-3 p-1 space-x-4 border border-gray-400 w-1/3"
                    >
                      <DxButton icon="remove" @click="deleteFiles(i)" />
                      <span class="pt-1 ml-2 text-lg">{{ config.fileList[i]?.name ?? config.fileList[i]?.originalFileFullName }}</span>
                      <span class="pt-1">{{ ((config.fileList[i]?.size ?? config.fileList[i]?.fileSize) / 1024).toFixed(2) }}KB</span>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label5"> 내용 </label>
                </th>
                <td>
                  <DxHtmlEditor class="auto-height-editor" width="w-full" :styling-mode="config.stylingMode" v-model="formData.content">
                    <DxToolbar>
                      <DxItem name="undo" />
                      <DxItem name="redo" />
                      <DxItem name="font" />
                      <DxItem name="separator" />
                      <DxItem name="header" />
                      <DxItem name="separator" />
                      <DxItem name="bold" />
                      <DxItem name="italic" />
                      <DxItem name="strike" />
                      <DxItem name="underline" />
                      <DxItem name="separator" />
                      <DxItem name="image" />
                      <DxItem name="separator" />
                      <DxItem name="insertTable" />
                    </DxToolbar>
                    <DxValidator>
                      <DxRequiredRule message="내용은 필수입니다." />
                    </DxValidator>
                  </DxHtmlEditor>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </DxValidationGroup>
    </div>
  
    <section class="terms bottom-btn-box">
      <div class="page-sub-box">
        <div class="bottom-btn-wrap">
          <DxButton
            text="저 장"
            :width="120"
            :height="40"
            class="default filled txt_S medium"
            :visible="!config.updateYn"
            @click="onSaveForm"
          />
          <DxButton
            text="수  정"
            :width="120"
            :height="40"
            class="default filled txt_S medium"
            :visible="config.updateYn"
            @click="onUpdateForm"
          />
          <DxButton text="취 소" :width="120" :height="40" class="white filled txt_S medium" @click="onCancelForm" />
        </div>
      </div>
    </section>

    <!-- Modal Layer -->
    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :contentData="modal.contentData" v-model="modal.contentData"></component>
        </div>
      </template>
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            onConfirmModal();
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>
  
<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxRadioGroup } from 'devextreme-vue/radio-group';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxTagBox } from 'devextreme-vue/tag-box';
import { DxPopover } from 'devextreme-vue/popover';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxHtmlEditor, DxItem, DxToolbar } from 'devextreme-vue/html-editor';
import { formatDate, isSuccess, getPastFromToday } from '@/utils/common-lib';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxValidationGroup } from 'devextreme-vue/validation-group';
import { DxPopup, DxToolbarItem } from 'devextreme-vue/popup';
import modalSelectTargetGroup from '@/pages/ewm/exam/exam-report/group-report/modal-select-target-group.vue';
import { espApi } from '@/api';

let vm = this;
  
export default {
  name: 'EWMOpBoardConfig',
  components: {
    DxDateBox,
    DxButton,
    DxTextBox,
    DxSelectBox,
    DxCheckBox,
    DxRadioGroup,
    DxSwitch,
    DxTagBox,
    DxFileUploader,
    DxHtmlEditor,
    DxToolbar,
    DxItem,
    DxPopover,

    DxValidator,
    DxRequiredRule,
    DxValidationGroup,
    DxPopup,
    DxToolbarItem,
    modalSelectTargetGroup,
  },
  props: {},
  watch: {
		'modal.contentData'(val) {
      if(Array.isArray(val)){
        this.config.depts = val.map(i => i.codeId);
      }
		},
		tagBoxData(val) {
			const tagBoxDataSource = val;
			// 부서
			const selectedDeptIds = tagBoxDataSource.filter(tag => tag.type === 'dept').map(tag => tag.id);

			if (selectedDeptIds && selectedDeptIds.length > 0 && this.modal.contentData) {
				const selectedDept = this.modal?.contentData.filter(item => selectedDeptIds.includes(item.codeId));

				this.$set(this.modal, 'contentData', selectedDept);
			} else {
				this.$set(this.modal, 'contentData', []);
			}
		},
	},
  data() {
    return {
      selectedCondition: [],
      tagBoxData: [],
      targetGridData: [],
      config: {
        updateYn: false, 
        stylingMode: 'outlined', 
        typeCd: [
          {key: '0', value: '일반 공지'}, 
          {key: '1', value: '중요 공지'}
        ],
        target: [
          {key: '0', value: '전체'}, 
          {key: '1', value: '부서 선택'}
        ],
        sendAlram: false,
        tooltip: {
          isVisible: {
            view: false,
            type: false,
            alert: false,
          },
          closeOnOutsideClick: false,
          viewContents: `[비공개로 설정하면, 본인만 글을 볼 수 있으며 다른 사용자는 볼 수 없습니다.] `,
          typeContents: `[중요 공지로 설정하면, 지정한 기간 동안 목록 상단에 중요 공지사항으로 노출됩니다.]`,
          alertContents: `[알림 발송을 설정하면, 공지 등록 시 공지 대상자에게 시스템 알림이 발송됩니다.]`,
        },
        depts: [],
        fileList: [],
        successFileList: [],
      },
      codes: {
        categoryCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
        dept0: {
          dataSource: [],
        },
        dept1: {
					dataSource: [],
				},
				dept2: {
					dataSource: [],
				},
				dept3: {
					dataSource: [],
				},
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
        componentName: '',
        agent: {
          locName: '',
          popupVisible: false,
          selectedIdList: [],
          managerSelectedIdList: [],
          targetSelectedIdList: [],
        },
      },
      limitNumberTexts: {
        textLengths: {},
        maxLengths: {
          title: 150,
        },
      },
      formData: {
        id: null,
        boardCd: '',
        categoryCd: null,
        title: '',
        viewFl: 'Y',
        typeCd: '0',
        typeValidFrDt: getPastFromToday(0, 'days'),
        typeValidToDt: getPastFromToday(-7, 'days'),
        target: '0',
        alertFl: false,
        content: '',
        fileGroupId: '',
        readCnt: null,
        commentCnt: null,
        delFl: '',
        regDt: '',
        regId: '',
        regNm: '',
        editDt: '',
        editId: '',
      }
    };
  },
  computed: {
    validationGroup: function () {
      return this.$refs['validationGroup'].instance;
    },
    getViewFl() {
      return this.formData.viewFl === 'Y';
    },
  },
  methods: {
    setTooltips(key) {
      this.config.tooltip.isVisible[key] = !this.config.tooltip.isVisible[key];
    },
    changeCategoryCd(e){
      this.formData['categoryCd'] = e.value;
    },
    onChangedViewFl(e) {
      if (e.value) this.formData.viewFl = 'Y';
      else this.formData.viewFl = 'N';
    },
    async unSelectedConditions(e) {
      const selectedConditions = e.value;

      const unSelectedConditions = vm.tagBoxData.filter(item => !selectedConditions.includes(item.id));

      unSelectedConditions.some(uitem => {
        const index = vm.tagBoxData.indexOf(uitem);
        if (index > -1) {
          vm.tagBoxData.splice(index, 1);
        }
      });
    },
    onTagValueChanged(e) {
			this.unSelectedConditions(e)
				.then(() => {
					console.log('상세 필터 선택된 조건 삭제 시',vm.tagBoxData)
				})
		},
    onOpenModal(componentNm, componentInitData, data) {
			this.modal.currentComponent = componentNm;
			this.modal.initData = componentInitData;
			this.modal.contentData = data;
			this.isOpenModal(true);
		},
		/** @description: 팝업 오픈 체크 메서드 */
		isOpenModal(data) {
			this.modal.isOpened = data;
			if (!data) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
    onOpen() {
      if(this.tagBoxData.length != this.config.depts.length){
        this.config.depts = this.tagBoxData.map(t => t.id);
      }

			this.onOpenModal(
				'modalSelectTargetGroup',
				{
					title: '부서 선택',
					buttons: {
						save: { text: '선택' },
						cancel: { text: '닫기' },
					},
					width: '500',
					height: '750',
				},
				this.config.depts,
        console.log(this.config.depts, 'id만 들어가는구나')
			);
		},
    deleteConditions() {
      vm.deleteTagboxes()
        .then(() => {
          this.$set(this, 'tagBoxData', vm.tagBoxData);
          this.$set(this, 'selectedCondition', []);
          this.$set(this.config, 'depts', []);
        })
        // .then(() => {
        //   console.log('이건 언제 나오는거지')
        // });
    },
    async deleteTagboxes() {
      vm.tagBoxData.splice(0, vm.tagBoxData.length);
    },
    // isDepthConsistent(list) {
    //   if (list.length === 0) return true;
    //   const firstDepth = list[0].depth;
    //   return list.every(item => item.depth === firstDepth);
    // },
    onConfirmModal() {
			const deptCodeList = this.modal.contentData;
			// if (!this.isDepthConsistent(deptCodeList)) {
      //   this.$_Msg('같은 depth를 선택해 주세요.');
      //   return;
      // }
			
      if(vm.tagBoxData) this.removeUnSelectedDept(deptCodeList);
			this.addDeptInTagBox(deptCodeList);

			if (!this.modal.contentData.length) {
				this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
				return;
			}
			
			this.isOpenModal(false);
		},
    removeUnSelectedDept(selectedDept) {
			const selectedConditions = selectedDept.map(data => data.codeId);
			const unselectedItems = vm.tagBoxData
				.filter(item => item.type === 'dept')
				.filter(item => !selectedConditions.includes(item.id));

			unselectedItems.some(uitem => {
				const index = vm.tagBoxData.indexOf(uitem);
				if (index > -1) {
					vm.tagBoxData.splice(index, 1);
				}
			});
		},
    addDeptInTagBox(selectedCode) {
			selectedCode.some(code => {
				const isExist = vm.tagBoxData?.map(t => t.id).includes(code.codeId);
				if (!isExist) {
					vm.tagBoxData.push({
						id: code.codeId,
						name: code.codeNm,
						type: 'dept',
					});
				}
			});
			this.updateTagBox();
		},
		updateTagBox() {
			this.$set(this, 'tagBoxData', vm.tagBoxData);
			const selectedConditions = vm.tagBoxData.map(data => data.id);
      console.log('dfdsfsdfdf',selectedConditions);
			this.$set(this, 'selectedCondition', selectedConditions);
		},
    uploadFiles() {
      this.$refs.fileUploader.instance._selectButtonClickHandler();
    },
    onChangedAttachedFiles(e) {
      console.log('파일업로드ㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡㅡ', e.value)
      this.config.fileList = e.value;
      // this.config.fileList = [...(this.config.fileList || []), ...e.value];
      this.formData.fileGroupId = e.value[0].name;
    },
    deleteFiles(i){
      this.config.fileList.splice(i, 1);
      if(this.config.fileList.length === 0) this.formData.fileGroupId = null;
    },
    async onSaveForm() {
      if (!vm.validationGroup.validate().isValid) {
        return false;
      }

      if (
        !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
          title: `<h2>알림</h2>`,
        }))
      ) {
        return;
      }

      if (this.config.fileList.length != 0) {
        const fileRes = await espApi.fileUpload(this.config.fileList);
        if (isSuccess(fileRes)) {
          console.log('파일 업로드 완료', fileRes.data.data);
          this.config.successFileList = fileRes.data.data;
          this.formData.fileGroupId = fileRes.data.data[0].fileGroupId;
        }
      }

      let params = {
        id: this.formData.id,
        categoryCd: this.formData.categoryCd,
        typeCd: this.formData.typeCd,
        typeValidFrDt: formatDate(this.formData.typeValidFrDt,'YYYYMMDD','YYYY-MM-DDTHH:mm:ss'),
        typeValidToDt: formatDate(this.formData.typeValidToDt,'YYYYMMDD','YYYY-MM-DDTHH:mm:ss'),
        title: this.formData.title,
        target: this.formData.target === '0' ? '전체' : this.tagBoxData.map(t => t.id).join('|'),
        alertFl: this.formData.alertFl === false ? 'N' : 'Y',
        fileGroupId: this.formData.fileGroupId,
        // content: this.formData.content.replace(/<\/?[^>]+(>|$)/g, ""),
        content: this.formData.content,
        viewFl: this.formData.viewFl,
        regId: this.$store.getters.getLoginId,
      };
      console.log('저장할 내용',params);

      const payload = {
        actionName: 'EWM_OP_BOARD_SAVE',
        data: params,
        loading: true,
      };
      
      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        if(this.formData.alertFl){
          this.onSseCheck();
        }
        await this.$router.push({ path: '/ewm/op/board' });
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        if(this.config.successFileList.length != 0){
          this.config.successFileList.forEach(d => {
            this.$_deleteAttachFile(d.fileGroupId, d.fileName);
          })
        }
      }
    },
    async onUpdateForm(){
      if (!vm.validationGroup.validate().isValid) {
        return false;
      }

      if (
        !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
          title: `<h2>알림</h2>`,
        }))
      ) {
        return;
      }

      if (this.config.fileList.length != 0) {
        const fileRes = await espApi.fileUpload(this.config.fileList);
        if (isSuccess(fileRes)) {
          console.log('파일 업로드 완료', fileRes.data.data);
          this.config.successFileList = fileRes.data.data;
          this.formData.fileGroupId = fileRes.data.data[0].fileGroupId;
        }
      }

      let params = {
        id: this.formData.id,
        boardCd: this.formData.boardCd,
        categoryCd: this.formData.categoryCd,
        typeCd: this.formData.typeCd,
        typeValidFrDt: formatDate(this.formData.typeValidFrDt,'YYYYMMDD','YYYY-MM-DDTHH:mm:ss'),
        typeValidToDt: formatDate(this.formData.typeValidToDt,'YYYYMMDD','YYYY-MM-DDTHH:mm:ss'),
        title: this.formData.title,
        target: this.formData.target === '0' ? '전체' : this.tagBoxData.map(t => t.id).join('|'),
        alertFl: this.formData.alertFl === false ? 'N' : 'Y',
        fileGroupId: this.formData.fileGroupId,
        content: this.formData.content,
        viewFl: this.formData.viewFl,
        readCnt: this.formData.readCnt,
        commentCnt: this.formData.commentCnt,
        delFl: this.formData.delFl,
        regDt: this.formData.regDt,
        regId: this.formData.regId,
        editDt: this.formData.editDt,
        editId: this.formData.editId,
      };
      console.log('수정할 내용',typeof params.categoryCd);
      
      const payload = {
        actionName: 'EWM_OP_BOARD_UPDATE',
        data: params,
        loading: true,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        if(this.formData.alertFl){
          this.onSseCheck();
        }
        await this.$router.push({ path: '/ewm/op/board' });
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        if(this.config.successFileList.length != 0){
          this.config.successFileList.forEach(d => {
            this.$_deleteAttachFile(d.fileGroupId, d.fileName);
          })
        }
      }
    },
    async onSseCheck(){
      //임시 sse 연결 (저장시 전체)
      const ssePayload = {
				actionName: 'EWM_SSE_CONNECT_USERS',
				loading: false,
			};
			const sseRes = await vm.CALL_EWM_API(ssePayload);
			if (isSuccess(sseRes)) {
				console.log('sseeeeeeeeeeeeeeeeeeeConnectUsersssssssss',sseRes.data.data);
			}
    },
    onCancelForm() {
      this.$router.push({ path: '/ewm/op/board' });
    },
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_op_board_categorycd,root_ewm_hr_dept');
    },
    async createdData() {
			vm = this;

      this.reqParams = this.$store.getters.getDetailParams;
      this.config.updateYn = this.reqParams ? this.reqParams.updateYn : false;

      if (this.config.updateYn) {
        this.formData = {
          id: this.reqParams.formData.id,
          boardCd: this.reqParams.formData.boardCd,
          categoryCd: Number(this.reqParams.formData.categoryCd),
          typeCd: this.reqParams.formData.typeCd,
          title: this.reqParams.formData.title,
          viewFl: this.reqParams.formData.viewFl,
          typeValidFrDt: this.reqParams.formData.typeValidFrDt,
          typeValidToDt: this.reqParams.formData.typeValidToDt,
          target: this.reqParams.formData.target === '전체' ? '0' : '1',
          alertFl: this.reqParams.formData.alertFl === 'N'? false : true,
          content: this.reqParams.formData.content,
          fileGroupId: this.reqParams.formData.fileGroupId,
          readCnt: this.reqParams.formData.readCnt,
          commentCnt: this.reqParams.formData.commentCnt,
          delFl: this.reqParams.formData.delFl,
          regDt: this.reqParams.formData.regDt,
          regId: this.reqParams.formData.regId,
          regNm: this.reqParams.formData.regNm,
          editDt: this.reqParams.formData.editDt,
          editId: this.reqParams.formData.editId,
        };
        this.config.fileList = await this.$_getListAttachFile(this.reqParams.formData.fileGroupId);
        if(this.formData.target === '1'){
          this.config.depts = this.reqParams.formData.target.split('|').map(item => parseInt(item.trim()));
        }
      }
      
			await this.initCodeMap().then(() => {
				this.codes.categoryCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_op_board_categorycd'], 2);

        this.codes.dept1.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 2);
        this.codes.dept2.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 3);
        this.codes.dept3.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4);

        this.codes.dept0.dataSource = [
          {
            codeId: this.codes.dept1.dataSource.length > 0 ? this.codes.dept1.dataSource[0].parentId : -1,
            codeKey: 'ewm_hr_dept',
            codeNm: '전체',
            codeValue: 'ewm_hr_dept',
            depth: 1,
            parentId: null,
            path: '',
            pathNmFull: '전체',
          },
        ];
        this.targetGridData = [
          ...this.codes.dept3.dataSource, 
          ...this.codes.dept2.dataSource, 
          ...this.codes.dept1.dataSource,
          ...this.codes.dept0.dataSource
        ];
			});

      const filterData = this.targetGridData.filter(item => this.config.depts.includes(item.codeId));
      if (filterData && filterData.length > 0) {
        filterData.forEach(f => {
          vm.tagBoxData.push({
            id: f.codeId,
            name: f.codeNm,
            type: 'dept',
          });
        })
        this.updateTagBox();
      }
		},
    mountedData() {

		},
  },
  created() {
		this.createdData();
	},
	mounted() {
		this.mountedData();
	},
};
</script>

<style scoped> 
.maxHeight {
  height: calc(100vh - 200px);
  overflow: auto;
}
.auto-height-editor {
  min-height: 380px; 
  height: auto; 
}
</style>