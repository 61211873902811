<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="flex space-x-2">
              <div class="mt-2">기준년월</div>
              <DxDateBox
                  :edit-enabled="false"
                  :styling-mode="config.stylingMode"
                  width="100"
                  v-model="searchType.customTypes.dayStart"
                  type="date"
                  display-format="yyyy-MM"
                  dateSerializationFormat="yyyyMMdd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  :max="searchType.customTypes.dayEnd"
                  maxLength="10"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                  @value-changed="onDayStartChanged"
                  :calendar-options="{
										zoomLevel: 'year',
										minZoomLevel: 'year',
										maxZoomLevel: 'year',
									}"
              >
                <DxValidator validation-group="evalScheduleListValidGroup">
                  <DxRequiredRule message="기준년월은 필수입니다."/>
                </DxValidator>
              </DxDateBox>
              <div class="mt-1">
                ~
              </div>
              <DxDateBox
                  :styling-mode="config.stylingMode"
                  width="100"
                  v-model="searchType.customTypes.dayEnd"
                  type="date"
                  display-format="yyyy-MM"
                  dateSerializationFormat="yyyyMMdd"
                  dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                  maxLength="10"
                  :min="searchType.customTypes.dayStart"
                  invalid-date-message="입력 데이터가 유효하지 않습니다."
                  @value-changed="onDayEndChanged"
                  :calendar-options="{
										zoomLevel: 'year',
										minZoomLevel: 'year',
										maxZoomLevel: 'year',
									}"
              >
                <DxValidator validation-group="evalScheduleListValidGroup">
                  <DxRequiredRule message="기준년월은 필수입니다."/>
                </DxValidator>
              </DxDateBox>
            </div>

            <div class="mt-2">평가계획</div>
            <div>
              <DxSelectBox
                  placeholder="평가계획 선택"
                  :items="scheList"
                  display-expr="scheNm"
                  value-expr="id"
                  v-model="searchType.customTypes.evalSchedule"
                  :styling-mode="config.stylingMode"
                  :width="200"
                  :height="30"
                  @value-changed="onAddSheetName"
              >
                <DxValidator validation-group="searchValidationGroup">
                  <DxRequiredRule message="평가계획을 선택해 주세요."/>
                </DxValidator>
              </DxSelectBox>
            </div>
            <div class="mt-2">평가표명</div>
            <div>
              <DxTextBox
                  :styling-mode="config.textStylingMode"
                  :value="searchType.textboxTypes.evalDivisionCdNm"
                  :width="120"
                  :height="30"
                  :read-only="true"
              >
                <DxValidator validation-group="searchValidationGroup">
                  <DxRequiredRule message="평가계획을 선택해 주세요."/>
                </DxValidator>
              </DxTextBox>
            </div>
            <div>
              <DxTextBox
                  :styling-mode="config.textStylingMode"
                  :value="searchType.textboxTypes.sheetNm"
                  :width="170"
                  :height="30"
                  :read-only="true"
              >
              </DxTextBox>
            </div>
            <DxButton
                text="검색"
                class="btn_M box-btn-search"
                type="button"
                :width="70"
                :height="30"
                @click="selectDataList"
            />
          </div>
        </div>


          <div class="filter">
            <div class="filter-container">
              <div class="item">상세 필터</div>
              <!-- <div class="item">
                <DxRadioGroup
                    :items="filterRadioOption.items"
                    :value="filterRadioOption.items[0]"
                    layout="horizontal"
                />
              </div> -->
              <div class="space-x-2">
                <DxTagBox
                    ref="tagBox"
                    placeholder=""
                    :show-selection-controls="false"
                    :search-enabled="false"
                    :data-source="tagBoxData"
                    :value="selectedCondition"
                    display-expr="name"
                    value-expr="id"
                    :multiline="false"
                    :height="30"
                    width="700"
                    @value-changed="onTagValueChanged"
                    :styling-mode="config.stylingMode"
                >
                </DxTagBox>
                <DxButton class="btn_XS white outlined add2" text="선택" type="button" :height="30" @click="searchDeptConditionOpen"/>
                <DxButton class="btn_XS white outlined del1" text="전체삭제" type="button" :height="30" @click="deleteConditions"/>
              </div>
            </div>
          </div>

          <!--          <div class="flex space-x-2 pb-6" style="display: none;">
                      <div class="mt-2">조회기준</div>
                      <div>
                        <DxButton text="+ 선택" type="button" :height="30" @click="searchDeptConditionOpen"/>
                      </div>
                      <div class="border-none">
                        <DxTagBox
                            ref="tagBox"
                            placeholder=""
                            :show-selection-controls="false"
                            :search-enabled="false"
                            :data-source="tagBoxData"
                            :value="selectedCondition"
                            display-expr="name"
                            value-expr="id"
                            :multiline="false"
                            :height="30"
                            @value-changed="onTagValueChanged"
                            :styling-mode="config.stylingMode"
                        >
                        </DxTagBox>
                      </div>
                    </div>-->
      </div>
      <!-- Top Layer -->

      <div class="w-1/2 mt-6">
        <DxDataGrid
            class="grid-box"
            ref="groupReport"
            :allow-column-reordering="true"
            :data-source="groupReport.dataSource"
            :allow-column-resizing="true"
            :column-resizing-mode="'nextColumn'"
            :show-borders="false"
            :show-column-headers="true"
            :show-column-lines="true"
            :show-row-lines="true"
            :row-alternation-enabled="false"
            :hover-state-enabled="true"
            :word-wrap-enabled="true"
            :no-data-text="this.$_lang('CMN_NO_DATA')"
            :selected-row-keys="groupReport.selectedRowKeys" 
            @exporting="onExportingCheck"   
            @toolbar-preparing="onToolbarPreparing"
            width="100%"
            height="calc(60vh- 200px)"
        >
          <DxFilterRow :visible="true"/>
          <DxHeaderFilter :visible="true"/>
          <!-- 엑셀 다운로드 설정 -->
          <DxExport
            v-if="showActionButtons.excel"
            :enabled="showActionButtons.excel.enabled === undefined ? true : showActionButtons.excel.enabled"
            :texts="showActionButtons.excel.exportButtonText ? showActionButtons.excel.exportButtonText : exportButtonText"
          />

          <DxColumn
              caption="센터"
              data-field="pathNmFull"
              alignment="center"
              :allow-sorting="false"
              :allowHeaderFiltering="true"
              :allowFiltering="true"
              :calculate-cell-value="getCenterName"
          >
          </DxColumn>
          <DxColumn
              caption="파트"
              :visible="isPartVisible"
              alignment="center"
              :allow-sorting="false"
              :allowHeaderFiltering="true"
              :allowFiltering="true"
              :calculate-cell-value="getPartNm"
          >
          </DxColumn>
          <DxColumn
              caption="팀"
              :visible="isTeamVisible"
              alignment="center"
              :allow-sorting="false"
              :allowHeaderFiltering="true"
              :allowFiltering="true"
              :calculate-cell-value="getTeamNm"
          >
          </DxColumn>
          <DxColumn
              caption="평균점수"
              data-field="avgScore"
              alignment="center"
              :allow-sorting="false"
              :allowHeaderFiltering="true"
              :allowFiltering="true"
              :customize-text="customizeText"
          >
            <!--          <DxColumn
              caption="평균점수"
              data-field="avgScore"
              alignment="center"
              :allow-sorting="false"
              :allowHeaderFiltering="true"
              :allowFiltering="true"
              :format="{ type: 'fixedPoint', precision: 1 }" // format은 올림, 혹은 반올림
          >-->
          </DxColumn>
          <DxColumn
              caption="순위"
              data-field="deptRanking"
              alignment="center"
              :allow-sorting="false"
              :allowHeaderFiltering="true"
              :allowFiltering="true"
              :calculate-display-value="ranking"
          />
        </DxDataGrid>
      </div>
    </div>
    <!-- Main Layer -->

    <!-- Modal Layer -->
    <DxPopup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component  
                      ref="reasonModalRef"
                      v-model="modal.contentData"
                      :is="modal.currentComponent"
                      :contentData="modal.contentData"
                      :isModal="modal.isModal"
          ></component>
        </div>
      </template>
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: false
						: false
				"
          :options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						onSelectSearchCondition();
					},
				}"
      />
      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
          :options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
      />
      <DxToolbarItem
        v-if="modal.currentComponent === 'ModalDownloadReason'"
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => this.isOpenModal(false),
        }"
      />
      <DxToolbarItem
        v-if="modal.currentComponent === 'ModalDownloadReason'" 
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="true"
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => this.onDownloadReason(),
        }"
      />
    </DxPopup>
    <!-- /Modal Layer -->
  </div>
</template>

<script>
import {DxDateBox} from 'devextreme-vue/date-box';
import {DxButton} from 'devextreme-vue/button';
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import DxRadioGroup from 'devextreme-vue/radio-group';
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  DxFilterRow,
  DxHeaderFilter,
  DxLookup,
  DxLoadPanel,
  DxExport,
} from 'devextreme-vue/data-grid';
import {DxValidator, DxRequiredRule} from 'devextreme-vue/validator';
import {DxValidationGroup} from 'devextreme-vue/validation-group';
import {getPastFromToday, isSuccess, formatDate } from '@/utils/common-lib';
import modalSelectTargetGroup from '@/pages/ewm/exam/exam-report/group-report/modal-select-target-group.vue';
import {DxTagBox} from 'devextreme-vue/tag-box';
import validationEngine from "devextreme/ui/validation_engine";
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';

let vm = this;

export default {
  name: 'EWMEvaluationReportDepartment',
  components: {
    DxTagBox,
    DxDateBox,
    DxButton,
    DxTextBox,
    DxSelectBox,
    DxPopup,
    DxToolbarItem,
    DxDataGrid,
    DxColumn,
    DxSelection,
    DxFilterRow,
    DxHeaderFilter,
    DxLookup,
    DxLoadPanel,
    DxRequiredRule,
    DxValidator,
    DxValidationGroup,
    modalSelectTargetGroup,
    DxRadioGroup,
    DxExport,
    ModalDownloadReason
  },
  props: {},
  watch: {
    'modal.contentData'(val) {
      // console.log('modal.contentData', this.modal.contentData);
      // console.log('val >>>>', val);
      if(Array.isArray(val)){
        this.searchType.depts = val.map(i => i.codeId);
      }
    },
    tagBoxData(val) {
      const tagBoxDataSource = val;
      // 부서
      const selectedDeptIds = tagBoxDataSource.filter(tag => tag.type === 'dept').map(tag => tag.id);

      if (selectedDeptIds && selectedDeptIds.length > 0 && this.modal.contentData) {
        const selectedDept = this.modal?.contentData?.filter(item => selectedDeptIds.includes(item.codeId));

        this.$set(this.modal, 'contentData', selectedDept);
      } else {
        this.$set(this.modal, 'contentData', []);
      }
    },
  },
  data() {
    return {
      filterRadioOption: {
        items: ['부서']
      },
      isPartVisible: false,
      isTeamVisible: false,
      selectedCondition: [],
      tagBoxData: [],
      showActionButtons: {
        excel: {
          allowExportSelectedData: false,
        },
        customButtons: [],
      },
      dataGrid: {
        excel: {
          title: '그룹별 평가보고서'
        },
        columns: [
          /* 엑셀 title 길이 위해  */
          {}, {}, {}
        ],
      },
      exportButtonText: {
        //엑셀 다운로드 정보
        exportTo: '엑셀다운로드',
        exportAll: '전체 다운로드',
        exportSelectedRows: '선택한 데이터 다운로드',
      },
      codes: {
        evalDivisionCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
        defaultDeptCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
      },
      config: {
        textStylingMode: 'filled',
        stylingMode: 'outlined',
      },
      modal: {
        isOpened: false,
        initData: {},
        contentData: null,
        componentName: '',
        currentComponent: null,
      },
      searchType: {
        obj: {},
        defaultObj: {
          id: 'ROOT',
          codeValue: 'ROOT',
          codeNm: '전체',
        },
        defaultValue: 'ROOT',
        customTypes: {
          dayStart: getPastFromToday(11, 'months'),
          dayEnd: getPastFromToday(0, 'days'),
          evalSchedule: null,
        },
        textboxTypes: {},
        depts: [],
        deptsNm: null,
        paramsData: null,
      },
      scheList: [], //시험계획리스트
      groupReport: {
        dataSource: null,
        selectedRowsData: [],
        selectedRowKeys: [],
      },
    };
  },
  computed: {},
  methods: {
    /** @description: 그리드 상단 툴바 버튼 관련 이벤트 */
    onToolbarPreparing(e) {
      if (this.checkObjKeyByCustomEvent('toolbarPreparing')) {
        //커스텀시 해당 페이지의 이벤트 호출
        this.$emit('toolbar-preparing', e);
      } else {
        const toolbarItems = e.toolbarOptions.items;
        let vm = this;

        toolbarItems.forEach(item => {
          if (item.name === 'saveButton') {
            item.location = 'before';
            item.sortIndex = 40;
            item.options.icon = '';
            item.options.text = '저장';
            item.options.hint = '저장';
            item.showText = 'always';
            item.options.elementAttr = { class: 'btn_XS default filled' };
            item.options.width = '60';
            item.options.height = '30';
          } else if (item.name === 'addRowButton') {
            item.location = 'before';
            item.sortIndex = 30;
            item.options.icon = '';
            item.options.text = '추가';
            item.options.hint = '추가';
            item.showText = 'always';
            item.options.elementAttr = { class: 'btn_XS default filled add1' };
            item.options.width = '60';
            item.options.height = '30';
          } else if (item.name === 'revertButton') {
            item.location = 'before';
            item.sortIndex = 50;
            item.options.icon = '';
            item.options.text = '취소';
            item.options.hint = '취소';
            item.options.elementAttr = { class: 'btn_XS white light_filled ' };
            item.showText = 'always';
            item.options.width = '60';
            item.options.height = '30';
          }
        });

        if (this.showActionButtons?.excel) {
          toolbarItems.forEach(d => {
            if (d.name === 'exportButton') {
              d.options.icon = 'export';
              d.location = 'before';
              d.sortIndex = 100;
              if (this.showActionButtons?.csv) {
                e.format = 'csv';
                d.options.items = d.options.items.concat([
                  {
                    icon: 'txtfile',
                    text: `csv ${this.$_lang('COMPONENTS.DOWNLOAD')}`,
                    onClick: () => {
                      this.onExportingCheck(e);
                    },
                  },
                ]);
              }
            }
          });
        }

        if (this.dataGrid?.hideSaveBtn) {
          //저장
          toolbarItems.forEach(d => {
            if (d.name === 'saveButton') {
              d.options.visible = false;
            }
          });
        }

        if (this.dataGrid?.hideCancelBtn) {
          //취소
          toolbarItems.forEach(d => {
            if (d.name === 'revertButton') {
              d.options.visible = false;
            }
          });
        }

      }
    },
        /**
     * @description: 엑셀 다운로드 사유 입력 모달 이벤트
     */
    onDownloadReason() {
      let event = this.modal.contentData;
      const reason = this.$refs.reasonModalRef.reason;
      if (reason.trim() === '') {
        this.$_Msg(
          this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
            defaultValue: '다운로드 사유를 입력하세요.',
          }),
        );
        event.cancel = true;
      } else {
        event = this.makeSaveHistory(event, reason);
        event.cancel = false;
        this.onExporting(event);
        this.isOpenModal(false);
      }
    },
    onExportingCheck(e) {
      const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
      e = this.makeSaveHistory(e);
      if (useDownReason) {
        e.cancel = true;
        this.onOpenModal(
          'ModalDownloadReason',
          {
            title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', { defaultValue: '다운로드 사유' }),
            width: '600',
            height: '400',
          },
          e,
        );
      } else {
        this.onExporting(e);
      }
    }, 
    /** @description: 엑셀 다운로드 이벤트 */
    onExporting(e) {
      if (this.checkObjKeyByCustomEvent('exporting')) {
        //커스텀시 해당 페이지의 이벤트 호출
        // 커스텀 시 파일 다운로드 이력은 직접 구현해야함. 성공 유무를 확인할 수 없음
        // e.onSaveHistory();
        this.$emit('exporting', e);
      } else {
        //Grid Excel Export
        const title = this.dataGrid.excel.title;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        // Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
        let columnsArr = [];
        this.dataGrid.columns.forEach(() => {
          columnsArr.push({ width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30 });
        });
        worksheet.columns = columnsArr;

        let today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
          topLeftCell: { row: 4, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
              //data 영역 배경색 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }
            }

            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(() => {
            const titleRow = worksheet.getRow(2);
            titleRow.height = 40;
            if (e.format === 'xlsx') {
              worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
            }
            titleRow.getCell(1).value = title;
            titleRow.getCell(1).font = { size: 22, bold: true };
            titleRow.getCell(1).alignment = { horizontal: 'center', vertical: 'middle' };

            const hearderRow = worksheet.getRow(4);
            hearderRow.height = 30;
          })
          .then(() => {
            let fileName;
            if (e.format === 'csv') {
              fileName = `${title}_${today}.csv`;
              workbook.csv.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'text/csv' }), fileName);
              });
            } else {
              fileName = `${title}_${today}.xlsx`;
              workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
              });
            }
            return fileName;
          })
          .then(fileName => {
            // 다운로드 이력 저장
            e.onSaveHistory(fileName);
          });
        e.cancel = true;
      }
    },
    makeSaveHistory(event, reason = '') {
      const user = {
        userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
        userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
        deptNm: this.$store.getters.getUserInfo?.deptNm || '',
        gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
      };

      const payload = {
        actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
        data: [
          {
            ...user,
            reason,
            fileType: 'EXCEL',
            fileNm: this.dataGrid.excel?.title,
          },
        ],
        loading: false,
      };

      event.onSaveHistory = async fileNm => {
        payload.data[0].fileNm = fileNm || this.dataGrid.excel?.title;
        return await this.CALL_API(payload);
      };
      return event;
    },     
    /** @description : 커스텀이벤트의 object key 값 체크 */
    checkObjKeyByCustomEvent(objectKey) {
      if (!this.dataGrid || typeof this.dataGrid !== 'object') {
        return false; // dataGrid 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
      }

      const customEvent = this.dataGrid.customEvent;
      if (typeof customEvent !== 'object') {
        return false; // customEvent 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
      }

      const hasObjectKey = Object.prototype.hasOwnProperty.call(customEvent, objectKey);
      return hasObjectKey ? customEvent[objectKey] : false; // objectKey가 customEvent에 존재하면 그 값을 반환하고, 그렇지 않으면 false 반환
    },
    deleteConditions() {
      vm.deleteTagboxes()
          .then(() => {
            this.$set(this, 'tagBoxData', vm.tagBoxData);
            this.$set(this, 'selectedCondition', []);
            this.$set(this.searchType, 'depts', []);
          })
          .then(() => {
            vm.selectDataList();
          })
    },
    async deleteTagboxes() {
      vm.tagBoxData.splice(0, vm.tagBoxData.length); // splice를 사용하여 배열의 모든 요소를 제거
    },
    customizeText(cellInfo) {
      // 내림 (버림)하여 소수 첫째 자리까지만 표시
      const value = Math.floor(cellInfo.value * 10) / 10;
      return value.toFixed(1);
    },
    getCenterName(rowData) {
      const paths = rowData.pathNmFull.split('>');
      if (paths.length === 0 || rowData.pathNmFull == '전체평균점수') {
        return rowData.pathNmFull;
      }

      const center = paths?.[0];
      return center;
    },
    getTeamNm(rowData) {
      const paths = rowData.pathNmFull.split('>');
      if (paths.length === 0 || rowData.pathNmFull == '전체평균점수') {
        return;
      }
      const team = paths?.[2];
      return team;
    },
    getPartNm(rowData) {
      const paths = rowData.pathNmFull.split('>');
      if (paths.length === 0 || rowData.pathNmFull == '전체평균점수') {
        return;
      }
      const part = paths?.[1];
      return part;
    },
    addColumns(resultData) {
      resultData.some(result => {
        const pathNmFull = result.pathNmFull;
        if (pathNmFull.includes('>')) {
          const pathNmFulls = pathNmFull.split('>');
          if (pathNmFulls.length <= 1) {
            this.$set(this, 'isPartVisible', false);
            this.$set(this, 'isTeamVisible', false);
          }
          if (pathNmFulls.length == 2) {
            this.$set(this, 'isPartVisible', true);
            this.$set(this, 'isTeamVisible', false);
          }
          if (pathNmFulls.length == 3) {
            this.$set(this, 'isPartVisible', true);
            this.$set(this, 'isTeamVisible', true);
          }
        } else {
          this.$set(this, 'isPartVisible', false);
          this.$set(this, 'isTeamVisible', false);
        }
      });
    },
    async unSelectedConditions(e) {
      // 선택된 조건들
      const selectedConditions = e.value;

      // 선택되지 않는 조건 삭제
      const unSelectedConditions = vm.tagBoxData.filter(item => !selectedConditions.includes(item.id));

      unSelectedConditions.some(uitem => {
        const index = vm.tagBoxData.indexOf(uitem);
        if (index > -1) {
          vm.tagBoxData.splice(index, 1);
        }
      });
    },
    onTagValueChanged(e) {
      this.unSelectedConditions(e)
          .then(() => {
            vm.selectDataList();
          })
    },
    /** @description: 순위 */
    ranking(rowData) {
      const {deptRanking, totalRanking} = rowData;
      if (deptRanking) {
        return `${deptRanking}/${totalRanking}`;
      }
      return '';
    },
    /** @description: 팝업 열기 공통 함수 */
    onOpenModal(componentNm, componentInitData, data) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.contentData = data;
      this.isOpenModal(true);
    },
    /** @description: 팝업 창 열때 이벤트 */
/*     onOpenModal(componentNm, componentInitData, contentData) {
      this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
      this.modal.initData = componentInitData; //set init modal templet
      this.isOpenModal(true);
    }, */
    /** @description: 팝업 오픈 체크 메서드 */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    /** @description: [ 조회 기준 ] 부서 선택 팝업 열기 */
    searchDeptConditionOpen() {
      if (!validationEngine.validateGroup('searchValidationGroup').isValid) {
        return;
      }
      if (!validationEngine.validateGroup('evalScheduleListValidGroup').isValid) {
        return;
      }
      const name = 'modalSelectTargetGroup';
      const modalInitData = {
        title: '부서 선택',
        buttons: {
          save: {text: '선택'},
          cancel: {text: '닫기'},
        },
        width: '500',
        height: '800',
      }
      this.onOpenModal(name, modalInitData, this.searchType.depts);
    },
    updateTagBox() {
      this.$set(this, 'tagBoxData', vm.tagBoxData);
      const selectedConditions = vm.tagBoxData.map(data => data.id);
      this.$set(this, 'selectedCondition', selectedConditions);
    },
    /** 선택되지 않는 부서 삭제 */
    removeUnSelectedDept(selectedDept) {
      const selectedConditions = selectedDept.map(data => data.codeId);
      const unselectedItems = vm.tagBoxData
          .filter(item => item.type === 'dept')
          .filter(item => !selectedConditions.includes(item.id));

      unselectedItems.some(uitem => {
        const index = vm.tagBoxData.indexOf(uitem);
        if (index > -1) {
          vm.tagBoxData.splice(index, 1);
        }
      });
    },
    addDeptInTagBox(selectedCode) {
      selectedCode.some(code => {
        const isExist = vm.tagBoxData.map(t => t.id).includes(code.codeId);
        if (!isExist) {
          vm.tagBoxData.push({
            id: code.codeId,
            name: code.codeNm,
            type: 'dept',
          });
        }
      });
      this.updateTagBox();
    },
    isDepthConsistent(list) {
      if (list.length === 0) return true; // 빈 배열은 모두 동일한 값으로 간주
      const firstDepth = list[0].depth;
      return list.every(item => item.depth === firstDepth);
    },
    /** 조회 기준 선택 */
    onSelectSearchCondition() {
      const deptCodeList = this.modal.contentData;
      // if (!this.isDepthConsistent(deptCodeList)) {
      //   this.$_Msg('같은 depth를 선택해 주세요.');
      //   return;
      // }
      this.removeUnSelectedDept(deptCodeList);
      this.addDeptInTagBox(deptCodeList);

      if (!this.modal.contentData.length) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
        return;
      }

      this.selectDataList();

      this.isOpenModal(false);
    },

    /** @description : 검색 조회 */
    async selectDataList() {
      if (!validationEngine.validateGroup('searchValidationGroup').isValid) {
        return;
      }
      if (!validationEngine.validateGroup('evalScheduleListValidGroup').isValid) {
        return;
      }

      // 선택 된 조회 기준이 없다면 센터를 default 로 조회 한다.
      if (this.searchType.depts.length === 0) {
        this.searchType.depts = this.codes.defaultDeptCd.dataSource.map(dept => dept.codeId);
      }

      const payload = {
        actionName: 'EWM_EVALUATION_REPORT',
        path: '/' + this.searchType.textboxTypes.id,
        data: {
          deptCds: this.searchType.depts,
        },
        loading: false,
        useErrorPopup: true,
      };
      const res = await vm.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        const resultData = res.data.data?.[0];
        const avgList = resultData.avgScoreList;
        this.groupReport.dataSource = avgList;
        this.addColumns(avgList);
        if (this.groupReport.dataSource.length > 0) {
          this.groupReport.dataSource.push({
            pathNmFull: '전체평균점수',
            avgScore: resultData.totalAvgScore,
          });
        }
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      }
    },
    /** @description : 전체 평균 조회 */
    //calculateAverage(numbers) {
    //  // 배열이 비어있는지 확인
    //  if (numbers.length === 0) {
    //    return 0; // 빈 배열의 경우 평균은 0
    //  }
    //  // 모든 숫자의 합 구하기
    //  const sum = numbers.reduce((acc, curr) => acc + curr, 0);
    //  // 평균 계산
    //  const average = sum / numbers.length;
    //  // 소수점 첫 번째 자리까지 반올림
    //  return Math.round(average * 10) / 10;
    //},
    /** @description: 마감 된 시험 게획 목록 조회 메서드 */
    async scheDataList() {
      if (!validationEngine.validateGroup('evalScheduleListValidGroup').isValid) {
        return;
      }
      const payload = {
        actionName: 'EWM_EVALUATION_SCHE_LIST',
        data: {
          frScheYmd: this.searchType.customTypes.dayStart,
          toScheYmd: this.searchType.customTypes.dayEnd,
        },
        loading: false,
        useErrorPopup: true,
      };
      const res = await vm.CALL_EWM_API(payload);
      if (isSuccess(res)) {
        this.scheList = res.data.data;
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
      }
    },
    /** @description : 평가 계획 선택 시 시험지 명 추가 */
    onAddSheetName(value) {
      const selectedSche = this.scheList.find(sche => sche.id === value.value);

      this.searchType.textboxTypes = selectedSche;
      if (selectedSche) {
        this.searchType.textboxTypes.evalDivisionCdNm = this.codes.evalDivisionCd.dataSource.find(
            c => c.codeId === selectedSche.evalDivisionCd,
        ).codeNm;
        this.searchType.textboxTypes.sheetNm = selectedSche.sheetNm;
      }
    },
    onDayStartChanged(e){
			const changed = e.value.slice(0, 6)+'01';
			this.searchType.customTypes.dayStart = changed;
      this.scheDataList();
		},
		onDayEndChanged(e){
			const changed = e.value.slice(0, 6)+'29';
			this.searchType.customTypes.dayEnd = changed;
      this.scheDataList();
		},
    /** @description : 셀렉트 박스 초기화 */
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division,root_ewm_hr_dept');
    },
    /** @description : 라이프사이클 created시 호출되는 메서드 */
    createdData() {
      vm = this;

      this.initCodeMap().then(() => {
        this.codes.evalDivisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
        this.codes.defaultDeptCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 2);
      });
    },
    /** @description: 라이프사이클 mounted시 호출되는 메서드 */
    mountedData() {
      this.scheDataList();
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
    this.mountedData();
  },
};
</script>
<style scoped>
.border-none * {
  border: none;
  width: auto;
}

.filter {
  background-color: #f9f9f9;
}

.filter-container {
  display: flex;
  align-items: center; /* 수직 가운데 정렬 */
  padding: 5px;
}

.item {
  padding: 15px;
}
</style>
