<!--
  PACKAGE_NAME : src/pages/report/config/query-management.vue
  FILE_NAME : query-management
  AUTHOR : kwmoon
  DATE : 2023-05-03
  DESCRIPTION : XML 쿼리 관리
-->
<template>
  <div class="page-container">
    <tabs ref="tabs" @selectedIndex="tabSelectedIndex">
      <tab :title="data.label" :key="data.value" v-for="(data, index) in xmlTypeList">
        <esp-dx-data-grid v-if="index === selectTabIndex" :data-grid="dataGrid" :ref="dataGrid.refName" />
      </tab>
    </tabs>

    <!-- xml 에디터 모달 -->
    <modal-xml-editor
      v-if="modal.visible"
      ref="xmlEditor"
      :is-open="modal.visible"
      :option="modal.option"
      :editorOption="modal.editorOption"
      @saveModal="handleSaveModal"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import EspDxButton from '@/components/devextreme/esp-dx-button.vue';
  import EspDeleteButton from '@/components/common/buttons/esp-delete-button.vue';
  import ModalXmlEditor from '@/pages/report/config/modal-xml-editor';
  import { isSuccess } from '@/utils/common-lib';
  import { mountComponent } from '@/utils/devextreme-util';

  export default {
    name: 'ReportXmlConfig',
    components: {
      Tabs,
      Tab,
      EspDxDataGrid,
      ModalXmlEditor,
    },
    props: {},
    watch: {},
    data() {
      return {
        xmlTypeList: this.$_enums.report.xmlType.values, // 탭 타입 리스트
        selectTabIndex: 0,
        selectTabValue: 'wizard-template',
        dataGrid: {
          callApi: 'CALL_REPORT_API',
          refName: this.selectTabValue,
          dataSource: [], //dataSource 설정 / 커스텀 조회를 위해 필요
          dataSourceDefaultSortColumn: '+subPath,-regDt', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          apiActionNm: {
            select: 'MASTER_QUERY_LIST',
          },
          searchParams: {
            // 조회 API 호출 시 필요한 파라미터 설정 / apiActionNm.select 설정 시 사용 가능
            type: this.selectTabValue,
          },
          showActionButtons: {
            select: true,
            delete: false,
            customButtons: [
              {
                template: (data, index, element) => {
                  mountComponent(
                    element,
                    EspDxButton,
                    {
                      i18n: 'COMPONENTS.REGISTER',
                      prefixIcon: 'add1',
                    },
                    {
                      handleClick: () => this.handleOpenModal(),
                    },
                    this,
                  );
                },
                location: 'before',
              },
              {
                template: (data, index, element) => {
                  mountComponent(
                    element,
                    EspDeleteButton,
                    {},
                    {
                      handleClick: () => this.onDeleteData(),
                    },
                    this,
                  );
                },
                location: 'before',
              },
            ],
          },
          editing: {
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false 설정
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowDeleting: false,
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              visible: false,
            },
            {
              caption: '솔루션 유형',
              dataField: 'solution',
              width: 120,
              cellTemplate: (container, item) => {
                container.append(item.value.toUpperCase());
              },
            },
            {
              caption: '서브 유형',
              dataField: 'subPath',
              width: 120,
              cellTemplate: (container, item) => {
                const uppercaseValue = item.value ? item.value.toUpperCase() : '';
                container.append(uppercaseValue);
              },
            },
            {
              caption: '이름',
              dataField: 'name',
              alignment: 'left',
              cellTemplate: (container, item) => {
                let aTag = document.createElement('a');
                aTag.innerText = item.value;
                aTag.addEventListener('click', () => {
                  this.handleOpenModal(item.data);
                });
                container.append(aTag);
              },
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'left', // left center right
            },
          ],
        },
        modal: {
          visible: false, // 모달 노출 여부
          selectedName: null, // 'name' attribute of sql tag
          selectedDescription: null, // 'description' attribute of sql tag
          selectedSolution: null, // 'solution' attribute of sql tag
          selectedSubPath: null, // 'subPath' attribute of sql tag
          option: {
            // 모달 옵션
            title: 'XML 보고서 등록',
            width: '80%',
            height: '90%',
            minWidth: null,
            minHeight: null,
          },
          editorOption: {
            // 에디터 옵션
            type: null,
            reportId: null, // historyId
            reportType: null, // reportType
            name: null, // name
            description: null, // ctiType
          },
        },
      };
    },
    computed: {},
    methods: {
      /**
       * @description 탭 선택 이벤트
       * @param index
       */
      tabSelectedIndex(index) {
        this.selectTabIndex = index;
        this.$set(this, 'selectTabValue', this.xmlTypeList[index].value);
        this.dataGrid.refName = this.selectTabValue;
        this.dataGrid.searchParams.type = this.selectTabValue;
      },
      /** @description: 팝업 열기 */
      handleOpenModal(targetData = null) {
        this.modal.option.width = targetData ? '80%' : '60%';
        this.modal.visible = true;
        this.setModalOption(targetData);
      },
      /** @description: 팝업 저장 */
      handleSaveModal() {
        this.modal.visible = false;
        this.$refs[this.dataGrid.refName][0].refreshData();
      },
      /**
       * @description : 팝업 닫기
       * */
      handleCloseModal() {
        this.modal.visible = false;
        this.modal.editorOption = {};
      },
      /** @description: 팝업 옵션 설정 */
      async setModalOption(targetData) {
        // 1. title 클릭 시에는 targetData 셋팅 O -> 수정
        // 2. 등록 버튼 클릭 시에는 targetData 셋팅 X -> 등록
        this.modal.option.title = `XML 보고서 ${targetData ? '수정' : '등록'}`;
        if (targetData) {
          // 수정
          const { id, solution, name, subPath, description } = targetData;
          this.modal.selectedName = name;
          this.modal.selectedSolution = solution;
          this.modal.selectedSubPath = subPath;
          this.modal.selectedDescription = description;
          this.setEditorOption('reportType', this.selectTabValue);
          this.setEditorOption('reportId', id);
          this.setEditorOption('name', name);
          this.setEditorOption('solution', solution);
          this.setEditorOption('subPath', subPath);
          this.setEditorOption('description', description);
        } else if (targetData === null) {
          // 등록
          this.setEditorOption('reportType', this.selectTabValue);
        }
      },
      /**
       * @description : 에디터 옵션 설정
       * @param key
       * @param value
       */
      setEditorOption(key, value) {
        this.modal.editorOption[key] = value;
      },
      /** @description: 마스터 쿼리 삭제 */
      async onDeleteData() {
        const selectedRowsData = this.$refs[this.dataGrid.refName][0].getInstance.getSelectedRowsData();
        if (selectedRowsData.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        const isDeleteMsg = this.$_lang('COMMON.MESSAGE.CMN_CFM_DELETE_SELECTED', {
          defaultValue: '선택한 데이터를 삭제하시겠습니까?',
        });

        if (await this.$_Confirm(isDeleteMsg)) {
          const params = { items: selectedRowsData };
          const actionName = 'MASTER_QUERY_SQL_DELETE';
          const res = await this.CALL_REPORT_API({
            actionName,
            data: params,
            loading: true,
          });

          if (isSuccess(res)) {
            const successDeleteMsg = this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', {
              defaultValue: '정상적으로 삭제되었습니다.',
            });
            if (await this.$_Msg(successDeleteMsg)) {
              this.$refs[this.dataGrid.refName][0].refreshData();
            }
          }
        }
      },
    },
    created() {
      this.dataGrid.refName = this.selectTabValue;
      this.dataGrid.searchParams.type = this.selectTabValue;
    },
  };
</script>

<style scoped>
  .page-container {
    background: #fff;
    border-radius: 10px 10px 0 0;
    z-index: 3;
  }

  .page-container ::v-deep .ecs-large-tab ul {
    padding: 0 20px;
  }

  .page-container ::v-deep .ecs-large-tab ul li.tb_tp:not(:first-child) {
    margin: 0 8px;
  }
</style>
