import EUCInfoPushAction from '@/pages/euc/info-push/action';
import EUCInfoPushActionUpdate from '@/pages/euc/info-push/action/update';

export default function InfoPushActionRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/action',
            name: 'EUCInfoPushAction',
            components: { default: EUCInfoPushAction, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/action/update',
            name: 'EUCInfoPushActionUpdate',
            components: { default: EUCInfoPushActionUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}