<template>
  <div class="locker_setting_list sub_new_style01 sub_ui_box1">
    <esp-dx-data-grid :data-grid="dataGrid" ref="endUserGrid"/>
    <DxPopup
        :show-title="true"
        :title="modal.title"
        :width="modal.width"
        :height="modal.height"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <ModalSmartCopy
          :isOpened="this.modal.isOpened"
          :rowInfo="this.modal.rowInfo"
          :devicePoolList="this.devicePoolList"
          :modelList="this.modelList"
          @closeModal="closeModal"
      ></ModalSmartCopy>
    </DxPopup>
  </div>
</template>

<script>
import {DxCheckBox} from 'devextreme-vue/check-box';
import {isSuccess} from '@/utils/common-lib';
import {DxPopup} from 'devextreme-vue/popup';
import ModalSmartCopy from './modal-smartcopy.vue';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import {getUCServerPath} from "@/pages/euc/common-uc";

export default {
  props: {
    cmList: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    DxPopup,
    ModalSmartCopy,
    EspDxDataGrid
  },
  data() {
    return {
      modal: {
        isOpened: false,
        rowInfo: {},
        title: 'Smart Copy',
        width: '70vw',
        height: '550',
      },
      stylingMode: 'outlined',
      devicePoolList: [],
      modelList: [],
      dataGrid: {
        disableTotalCount: true,
        excel: {
          title: this.$_lang('UC.WORD.END_USER_MANAGEMENT', { defaultValue: '최종 사용자 관리' }),
        },
        callApi: 'CALL_EUC_API',
        refName: 'endUserGrid',
        dataSourceDefaultSort: '+regDt,+id',
        apiActionNm: {
          select: 'EUC_END_USER_SELECT',
          update: 'EUC_END_USER_UPDATE',
        },
        page: {
          enabled: false,
        },
        showActionButtons: {
          excel: true,
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '사용자 동기화',
                elementAttr: {class: 'btn_XS default filled'},
                // width: 90,
                height: 30,
                onClick: async () => {
                  const configStr = this.$_getSystemData('use_long_time_confirm')?.configValue;
                  if (configStr) {
                    const config = JSON.parse(configStr);
                    if (config.value === 'Y') {
                      if (await this.$_Confirm(config.message, { confirmButtonText: '강제적용' })) {
                        await this.onSyncDevice();
                      }
                    }
                  } else {
                    await this.onSyncDevice();
                  }
                },
              },
              location: 'before',
            },
          ],
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '교환기',
            i18n: 'UC.WORD.EXCHANGER',
            dataField: 'cmNm',
            allowEditing: false,
            calculateCellValue: rowData => rowData?.cmNm || '',
          },
          {
            caption: '사번',
            i18n: 'UC.WORD.USER_NO',
            dataField: 'userid',
            allowEditing: false,
          },
          {
            caption: '이름',
            i18n: 'UC.WORD.FIRST_NAME',
            dataField: 'firstname',
            allowEditing: false,
          },
          {
            caption: '중간이름',
            i18n: 'UC.WORD.MIDDLE_NAME',
            dataField: 'middlename',
            allowEditing: false,
          },
          {
            caption: '성',
            i18n: 'UC.WORD.LAST_NAME',
            dataField: 'lastname',
            allowEditing: false,
          },
          {
            caption: '화면표시',
            i18n: 'UC.WORD.DISPLAY_NAME',
            dataField: 'displayname',
            allowEditing: false,
          },
          {
            caption: '부서',
            i18n: 'UC.WORD.DEPARTMENT',
            dataField: 'department',
            allowEditing: false,
          },
          {
            caption: '내선번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'telephonenumber',
            allowEditing: false,
          },
          {
            caption: 'JABBER',
            i18n: 'UC.WORD.CSF',
            dataField: 'csf',
            allowEditing: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              const divTag = document.createElement('div');
              divTag.classList.add('fl');
              const checkbox = new DxCheckBox({
                propsData: {
                  value: options.data?.csfFlag,
                  disabled: false,
                  onValueChanged: e => {
                    this.changeCheckBox(e, options.data, 'csf');
                  },
                },
              });
              checkbox.$mount();
              divTag.append(checkbox.$el);
              container.append(divTag);
              container.append(options.value != null ? options.value : '');
            },
          },
          {
            caption: '안드로이드',
            i18n: 'UC.WORD.BOT',
            dataField: 'bot',
            allowEditing: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              const divTag = document.createElement('div');
              divTag.classList.add('fl');
              const checkbox = new DxCheckBox({
                propsData: {
                  value: options.data?.botFlag,
                  disabled: false,
                  onValueChanged: e => {
                    this.changeCheckBox(e, options.data, 'bot');
                  },
                },
              });
              checkbox.$mount();
              divTag.append(checkbox.$el);
              container.append(divTag);
              container.append(options.value != null ? options.value : '');
            },
          },
          {
            caption: '아이폰',
            i18n: 'UC.WORD.TCT',
            dataField: 'tct',
            allowEditing: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              const divTag = document.createElement('div');
              divTag.classList.add('fl');
              const checkbox = new DxCheckBox({
                propsData: {
                  value: options.data?.tctFlag,
                  disabled: false,
                  onValueChanged: e => {
                    this.changeCheckBox(e, options.data, 'tct');
                  },
                },
              });
              checkbox.$mount();
              divTag.append(checkbox.$el);
              container.append(divTag);
              container.append(options.value != null ? options.value : '');
            },
          },
          {
            caption: '태블릿',
            i18n: 'UC.WORD.TAB',
            dataField: 'tab',
            allowEditing: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              const divTag = document.createElement('div');
              divTag.classList.add('fl');
              const checkbox = new DxCheckBox({
                propsData: {
                  value: options.data?.tabFlag,
                  disabled: false,
                  onValueChanged: e => {
                    this.changeCheckBox(e, options.data, 'tab');
                  },
                },
              });
              checkbox.$mount();
              divTag.append(checkbox.$el);
              container.append(divTag);
              container.append(options.value != null ? options.value : '');
            },
          },
          {
            caption: '스마트카피',
            i18n: 'UC.WORD.CREATE',
            dataField: 'smartcopy',
            allowEditing: false,
            allowSorting: false,
            cellTemplate: (container, options) => {
              const imgTag = document.createElement('img');
              imgTag.setAttribute('src', `${getUCServerPath()}/euc/api/v1/resource/DEVICE_CISCO_SMALL_IMAGE_DIR/supercopy.gif`);
              imgTag.style.cursor = 'pointer';
              imgTag.addEventListener('click', () => {
                this.isOpenModal(true, options.data);
              });
              container.append(imgTag);
            },
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(date) {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    /**@description device-pool 목록 가져오는 메서드 */
    async selectDevicePool(sort = '+devicePoolOrd') {
      const payload = {
        actionName: 'EUC_DEVICE_POOL_SELECT',
        data: {
          sort,
          viewFl: 'Y',
          smartFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.devicePoolList = res.data.data;
      }
    },
    /**@description model 목록 가져오는 메서드 */
    async selectModelList(sort = '+regDt,+id') {
      const payload = {
        actionName: 'EUC_MODEL_SELECT',
        data: {
          sort,
          viewFl: 'Y',
          smartAssignedModel: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.modelList = res.data.data;
      }
    },

    /**@description EndUser 동기화 */
    async onSyncDevice() {
      const requests = this.cmList.map(async (item) => {
        const payload = {
          actionName: 'EUC_END_USER_SYNC',
          data: {
            cmNm: item.cmNm,
            loginId: this.$store.getters.getLoginId
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (!isSuccess(res)) {
          const errorMessage = res.data.data?.length
              ? `교환기 : ${item.cmNm} ${res.data.header.resMsg}`
              : res.data.header.resMsg;
          await this.$_Msg(errorMessage);
        }
        return res;
      });

      const results = await Promise.all(requests);
      if (results.some(isSuccess)) {
        this.$refs.endUserGrid.getInstance.refresh();
      }
    },
    /**@description 그리드 내부 타입별 체크박스 변경 이벤트 */
    changeCheckBox(event, data, type) {
      data[`${type}Flag`] = event.value;
    },

    /**@description 팝업 Open/Close */
    isOpenModal(data, rowInfo) {
      if (data) {
        this.modal.rowInfo = rowInfo;
      }
      this.modal.isOpened = data;
    },
    /**@description 팝업 Close 콜백 */
    closeModal() {
      this.modal.isOpened = false;
      this.$refs.endUserGrid.getInstance.refresh();
    },
  },
  async created() {
    await this.selectDevicePool();
    await this.selectModelList();
  },
};
</script>