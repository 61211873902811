import EUCPickup from '@/pages/euc/device/pickup';

export default function PickupRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/pickup',
            name: 'EUCPickup',
            components: { default: EUCPickup, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}