<template>
  <div class="ewm page-sub-box">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="page_search_box line_bottom_1px mb-0">
        <div class="flex space-x-2 inner">
          <div class="mt-2">기준년월</div>
          <DxDateBox
            styling-mode="outlined"
            width="100"
            height="30"
            v-model="searchType.customTypes.frScheYmd"
            type="date"
            date-serialization-format="yyyyMMdd"
            date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
            display-format="yyyy-MM"
            invalid-date-message="입력 데이터가 유효하지 않습니다."
            :max="searchType.customTypes.toScheYmd"
            maxLength="7"
            :calendar-options="{
              zoomLevel: 'year',
              minZoomLevel: 'decade',
              maxZoomLevel: 'year',
            }"
            @value-changed="onDayStartChanged"
          >
            <DxValidator validation-group="validationSearch">
              <DxRequiredRule message="기준년월은 필수입니다." />
            </DxValidator>
          </DxDateBox>
          <div class="mt-1">~</div>
          <DxDateBox
            styling-mode="outlined"
            width="100"
            height="30"
            v-model="searchType.customTypes.toScheYmd"
            type="date"
            date-serialization-format="yyyyMMdd"
            date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
            display-format="yyyy-MM"
            invalid-date-message="입력 데이터가 유효하지 않습니다."
            :min="searchType.customTypes.frScheYmd"
            maxLength="7"
            :calendar-options="{
              zoomLevel: 'year',
              minZoomLevel: 'decade',
              maxZoomLevel: 'year',
            }"
            @value-changed="onDayEndChanged"
          >
            <DxValidator validation-group="validationSearch">
              <DxRequiredRule message="기준년월은 필수입니다." />
            </DxValidator>
          </DxDateBox>

          <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
        </div>
      </div>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName"> </esp-dx-data-grid>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData"></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
import { DxSwitch } from 'devextreme-vue/switch';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';
import { formatDate, getPastFromToday, isSuccess } from '@/utils/common-lib';

import ModalProcessHistoryList from '@/pages/ewm/evaluation/implementation/schedule/modal-process-history-list.vue';
import CustomStore from 'devextreme/data/custom_store';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

2;
let vm = this;

export default {
  name: 'EWMEvaluationSchedule',
  components: {
    EspDxDataGrid,
    DxButton,
    DxDateBox,
    DxPopup,
    DxPosition,
    DxRequiredRule,
    DxToolbarItem,
    DxValidator,

    ModalProcessHistoryList,
  },
  data() {
    return {
      codes: {
        evalDivisionCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
        evalProcessCd: {
          dataSource: [],
          displayExpr: 'codeNm',
          valueExpr: 'codeId',
        },
      },
      config: {
        pageSetting: {
          //pageData pageSetting 관련
          config: {},
        },
      },
      stylingMode: 'outlined', //outlined, underlined, filled
      searchType: {
        obj: {},
        defaultObj: {id: 'ROOT', codeValue: 'ROOT', codeNm: '전체'},
        defaultValue: 'ROOT',
        customTypes: {
          frScheYmd: getPastFromToday(11, 'months'),
          toScheYmd: getPastFromToday(0, 'days'),
        },
        paramsData: {},
      },
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      dataGrid: {
        //keyExpr: 'id',
        refName: 'evalScheduleGrid',
        allowColumnResizing: true, //컬럼 사이즈 허용
        columnResizingMode: 'widget',
        columnAutoWidth: true,
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        dataSource: [],
        scrolling: {
          mode: 'standard',
        },
        callApi: 'CALL_EWM_API',
        apiActionNm: {},
        customEvent: {
          //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
          cellHoverChanged: false,
          cellPrepared: false,
          checkDataBeforeSaving: false,
          initNewRow: true,
          rowInserted: false,
          rowInserting: false,
          saving: true,
        },
        showActionButtons: {
          copy: false,
          select: false,
          update: false,
          delete: false,
          excel: false,
          customButtons: [
            {
              widget: 'dxButton',
              sortIndex: 20,
              options: {
                icon: '',
                text: '복사생성',
                elementAttr: {class: 'btn_XS default filled'},
                height: 30,
                onClick: () => {
                  vm.onDuplicate();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              sortIndex: 30,
              options: {
                icon: '',
                text: '추가',
                elementAttr: {class: 'btn_XS default filled add1'},
                width: 60,
                height: 30,
                onClick: function () {
                  vm.onRegister();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '삭제',
                elementAttr: {class: 'btn_XS white light_filled trash'},
                width: 60,
                height: 30,
                onClick() {
                  vm.onDeleteData();
                },
              },
              location: 'before',
            },
          ],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick', // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple', // single multiple
        },
        remoteOperations: {
          // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: {
          enabled: false,
          pageSize: 10,
          pageIndex: 0, // 시작페이지
        },
        pager: {
          visible: false, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [],
          displayMode: 'compact', //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '평가구분',
            dataField: 'evalDivisionCd',
            width: 130,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: true,
            allowGrouping: false,
            lookup: {},
            requiredRule: {
              message: '필수 항목입니다.',
            },
            calculateSortValue: data => vm.calculateSortValue(data, 'evalDivisionCd'),
          },
          {
            caption: '평가계획명',
            dataField: 'scheNm',
            height: 40,
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            cellTemplate: (container, options) => {
              let aTag = document.createElement('a');
              aTag.innerText = options.value;
              aTag.addEventListener('click', () => {
                vm.onUpdateData(options.data);
              });
              container.append(aTag);
            },
            requiredRule: {
              message: '필수 항목입니다.',
            },
          },
          {
            caption: '평가상태',
            dataField: 'evalProcessCd',
            width: 180,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: true,
            allowGrouping: false,
            lookup: {
              dataSource: this.$_enums.ewm.evalProcess.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            cellTemplate: (container, options) => {
              const prevButton = new DxButton({
                propsData: {
                  template: '<span class="mdi mdi-chevron-left"></span>',
                  height: 30,
                  type: 'button',
                  hint: '이전 상태로 변경',
                  elementAttr: {class: 'btn_XS white outlined'},
                  disabled: this.$_enums.ewm.evalProcess.READY.value === options.data.evalProcessCd,
                  onClick: () => {
                    vm.onChangeProcess(options, 'PREV');
                  },
                },
              });

              prevButton.$mount();

              const valueDivTag = document.createElement('div');
              valueDivTag.innerText = options.displayValue;
              valueDivTag.setAttribute('class', 'pt-2');

              const nextButton = new DxButton({
                propsData: {
                  template: '<span class="mdi mdi-chevron-right"></span>',
                  height: 30,
                  type: 'button',
                  hint: '다음 상태로 변경',
                  elementAttr: {class: 'btn_XS white outlined mr-0'},
                  disabled: this.$_enums.ewm.evalProcess.FINISH.value === options.data.evalProcessCd,
                  onClick: () => {
                    vm.onChangeProcess(options, 'NEXT');
                  },
                },
              });

              nextButton.$mount();
              //container.append(nextButton.$el);

              const divTag = document.createElement('div');
              divTag.setAttribute('class', 'flex justify-between justify-items-center gap-x-2');
              divTag.appendChild(prevButton.$el);
              divTag.appendChild(valueDivTag);
              divTag.appendChild(nextButton.$el);
              container.append(divTag);
            },
          },
          {
            caption: '기준년월',
            dataField: 'scheYmd',
            width: 110,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            calculateDisplayCellValue: rowData => {
              return formatDate(rowData.scheYmd, 'YYYY-MM-DD', 'YYYY-MM');
            },
          },
          {
            caption: '평가기간',
            dataField: 'evalStartDt',
            width: 170,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            calculateDisplayCellValue: rowData => {
              return `${formatDate(rowData.evalStartDt, 'YYYY-MM-DD', 'YYYY-MM-DD')}
                                ~ ${formatDate(rowData.evalEndDt, 'YYYY-MM-DD', 'YYYY-MM-DD')}`;
            },
          },
          {
            caption: '이의제기기간',
            dataField: 'evalFeedbackStartDt',
            width: 170,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            calculateDisplayCellValue: rowData => {
              return `${formatDate(rowData.evalFeedbackStartDt, 'YYYY-MM-DD', 'YYYY.MM.DD')}
                                ~ ${formatDate(rowData.evalFeedbackEndDt, 'YYYY-MM-DD', 'YYYY.MM.DD')}`;
            },
          },
          {
            caption: '평가표',
            dataField: 'sheetNm',
            height: 40,
            alignment: 'left',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: true,
            allowGrouping: false,
          },
          {
            caption: '평가회차',
            dataField: 'evalRoundNum',
            width: 110,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
          },
          {
            caption: '표시여부',
            dataField: 'viewFl',
            width: 110,
            height: 40,
            alignment: 'center', // left center right
            visible: true,
            allowEditing: false,
            allowSorting: false,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const switchBtn = new DxSwitch({
                propsData: {
                  value: options.value === this.$_enums.common.stringUsedFlag.YES.value,
                  disabled: options.data.evalProcessCd !== this.$_enums.ewm.evalProcess.READY.value,
                  onValueChanged: () => vm.onChangeViewFl(options),
                },
              });
              switchBtn.$mount();
              container.append(switchBtn.$el);
            },
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
            requiredRule: {
              message: '필수 항목입니다.',
            },
          },
          {
            headerCellTemplate: '평가계획<br/>상세',
            width: 110,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowSorting: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const button = new DxButton({
                propsData: {
                  elementAttr: {class: 'btn_XS white light_filled mr-0'},
                  text: '수정',
                  width: 60,
                  height: 30,
                  onClick: () => {
                    vm.onUpdateDetail(options.data);
                  },
                },
              });

              button.$mount();
              container.append(button.$el);
            },
          },
          {
            caption: '변경이력',
            width: 110,
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: false,
            allowSorting: false,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            cellTemplate: (container, options) => {
              const button = new DxButton({
                propsData: {
                  elementAttr: {class: 'btn_XS white light_filled mr-0'},
                  text: '변경이력',
                  width: 80,
                  height: 30,
                  onClick: () => {
                    vm.showModalProcessHistoryList(options.data);
                  },
                },
              });

              button.$mount();
              container.append(button.$el);
            },
          },
        ],
      },
    };
  },
  computed: {},
  methods: {
    /** @description : 소트설정 */
    calculateSortValue(data, clsCd) {
      if (clsCd == 'evalDivisionCd') {
        return this.codes.evalDivisionCd.dataSource.find(e => data.evalDivisionCd === e.codeId).codeNm;
      }
    },
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(isOpened) {
      this.modal.isOpened = isOpened;
      if (!isOpened) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, propsData) {
      this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
      this.modal.initData = componentInitData; //set init modal templet
      this.modal.sendData = propsData;

      this.isOpenModal(true);
    },
    async onDuplicate() {
      const selectedRowsData = this.$refs.evalScheduleGrid.selectedRowsData;

      if (selectedRowsData.length < 1) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        return;
      }

      if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DUPLICATE_SELECTED'), {title: `<h2>알림</h2>`}))) {
        return;
      }

      const sendData = selectedRowsData.map(x => x.id);

      const payload = {
        actionName: 'EWM_EVALUATION_SCHE_DUPLICATE',
        data: sendData,
        loading: true,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        this.$refs.evalScheduleGrid.getInstance.refresh();
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
      }
    },
    onAdd() {
      this.$router.push('/ewm/evaluation/setting/sheet/register');
      //this.$router.push({ path: '/ewm/evaluation/setting/sheet/register' });
    },
    /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
    onInitNewRow(e) {
      e.data.sheetNm = '';
      e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value;
      e.data.useFl = this.$_enums.common.stringUsedFlag.NO.value;
      e.data.delFl = this.$_enums.common.stringUsedFlag.NO.value;
      //e.sheetScore = 0;
    },
    async onSave(e) {
      e.cancel = true;

      if (
        !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', {defaultValue: '저장하시겠습니까?'}), {
          title: `<h2>알림</h2>`,
        }))
      ) {
        return;
      }

      if (e.changes.length) {
        let data = [];

        if (e.changes && e.changes.length > 0) {
          e.changes.forEach(d => {
            this.dataGrid.keyExpr ? this.dataGrid.keyExpr : 'id';

            if (d.type === 'update') {
              d.data.editId = this.$store.getters.getLoginId; //수정자

              if (this.dataGrid.keyExpr) {
                let rowIndex = e.component.getRowIndexByKey(d.key);
                let row = e.component.getVisibleRows()[rowIndex];
                let updateData = row.data;
                data.push(updateData);
              } else {
                data.push({id: d.key, ...d.data});
              }
            } else if (d.type === 'insert') {
              if (Object.keys(d.data).length !== 0) {
                d.data.regId = this.$store.getters.getLoginId; //등록자
                if (this.dataGrid.keyExpr) {
                  let updateData = {...d.data};
                  updateData[this.dataGrid.keyExpr] = null;
                  //updateData['fileId'] = updateData['file']?.id;
                  data.push(updateData);
                } else {
                  data.push({id: null, ...d.data});
                }
              }
            }
          });
        }

        const payload = {
          actionName: 'EWM_EVALUATION_SHEET_SAVE',
          data: {data: data},
        };

        const res = await this.CALL_EWM_API(payload);
        e.component.cancelEditData();
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
          this.$refs.evalScheduleGrid.getInstance.refresh();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
        }
      }
    },
    async onDeleteData() {
      const selectedRowsData = this.$refs.evalScheduleGrid.selectedRowsData;

      if (!selectedRowsData?.length) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
        return;
      }

      const checkEvalProcessCd = selectedRowsData.findIndex(element => element.evalProcessCd !== 1182);

      if (checkEvalProcessCd > -1) {
        this.$_Msg(
          `평가상태가 [대기]인 데이터만 삭제 할 수 있습니다. '${selectedRowsData[checkEvalProcessCd].scheNm}' 평가표를 확인하세요.`,
        );
        return;
      }

      if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), {title: `<h2>알림</h2>`}))) {
        return;
      }

      const sendData = selectedRowsData.map(x => x.id);

      const payload = {
        actionName: 'EWM_EVALUATION_SCHE_DELETE',
        data: sendData,
        loading: true,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
        this.$refs.evalScheduleGrid.getInstance.refresh();
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
      }
    },
    /** @description : 등록 페이지 이동 */
    onRegister() {
      this.$store.commit('setDetailParams', {});
      this.$router.push({path: '/ewm/evaluation/implementation/schedule/register'});
    },
    /** @description : 상태 변경 */
    async onChangeProcess(options, type) {
      const curProcessIdx = this.$_enums.ewm.evalProcess.values.findIndex(element => element.value === options.row.data.evalProcessCd);
      const chgProcessCdIdx = curProcessIdx + (type === 'PREV' ? -1 : 1);

      if (chgProcessCdIdx < 0 || chgProcessCdIdx >= this.$_enums.ewm.evalProcess.values.length) {
        return;
      }

      if (type === 'PREV') {
        if (
          this.$_enums.ewm.evalProcess.values[chgProcessCdIdx].value === this.$_enums.ewm.evalProcess.PROGRESS.value &&
          !(await this.$_Confirm('평가 데이터가 초기화됩니다. 변경하시겠습니까?'))
        ) {
          return;
        }
        if (
          this.$_enums.ewm.evalProcess.values[chgProcessCdIdx].value === this.$_enums.ewm.evalProcess.FEEDBACK.value &&
          !(await this.$_Confirm('등록한 피드백정보가 초기화됩니다. 변경하시겠습니까?'))
        ) {
          return;
        }
      }

      const chgProcessCd = this.$_enums.ewm.evalProcess.values[chgProcessCdIdx].value;

      const data = {
        id: options.row.data.id,
        processCd: chgProcessCd,
      };

      const payload = {
        actionName: 'EWM_EVALUATION_SCHE_CHANGE_PROCESS',
        data: data,
      };

      const res = await this.CALL_EWM_API(payload);
      if (isSuccess(res)) {
        this.$set(options.row.data, 'evalProcessCd', chgProcessCd);
        options.component.repaintRows(options.rowIndex);

        this.$_Toast(this.$_lang('CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
      }
    },
    /** @description : 표시여부 변경 */
    async onChangeViewFl(options) {
      if (options.row.data.evalProcessCd !== this.$_enums.ewm.evalProcess.READY.value) return;

      const viewFl =
        options.value === this.$_enums.common.stringUsedFlag.YES.value
          ? this.$_enums.common.stringUsedFlag.NO.value
          : this.$_enums.common.stringUsedFlag.YES.value;

      this.$set(options.row.data, 'viewFl', viewFl);
      const payload = {
        actionName: 'EWM_EVALUATION_SCHE_SAVE',
        data: options.row.data,
      };

      const res = await this.CALL_EWM_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
      } else {
        this.$_Toast(this.$_lang('CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}));
      }
    },
    /** @description : 수정 페이지 이동 */
    onUpdateData(data) {
      this.$store.commit('setDetailParams', data);
      this.$router.push({path: '/ewm/evaluation/implementation/schedule/register'});
    },
    /** @description : 상세 페이지 이동 */
    onUpdateDetail(data) {
      this.$store.commit('setDetailParams', data);
      this.$router.push({path: '/ewm/evaluation/implementation/schedule/detail'});
    },
    /** @description : 변경이력 팝업 */
    showModalProcessHistoryList(data) {
      const popData = {
        title: '진행상태 변경이력',
        props: {
          scheId: data.id,
          evalDivisionCdDs: this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_eval_division']),
        },
      };

      this.onOpenModal(
        'ModalProcessHistoryList',
        {
          title: popData.title,
          buttons: {
            cancel: {text: '닫기'},
          },
          width: '700',
          height: '520',
        },
        popData.props,
      );
    },
    /** @description : 데이터 검색 메서드 */
    async selectDataList() {
      if (!validationEngine.validateGroup('validationSearch').isValid) {
        return;
      }

      this.$refs.evalScheduleGrid.getInstance.cancelEditData();

      this.dataGrid.dataSource = new CustomStore({
        key: 'id',
        async load() {
          vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.frScheYmd;
          vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.toScheYmd;

          const payload = {
            actionName: 'EWM_EVALUATION_SCHE_LIST',
            data: vm.searchType.paramsData,
            loading: false,
          };

          const res = await vm.CALL_EWM_API(payload);
          if (isSuccess(res)) {
            const rtnData = {
              data: res.data.data,
              totalCount: res.data.header.totalCount,
            };

            vm.$refs.evalScheduleGrid.totalCount = rtnData.totalCount;
            return rtnData;
          }
        },
        insert(values) {
          //return false;
        },
        update(key, values) {
          //return false;
        },
        totalCount: opts => {
          //데이터 없는 경우 totalCount 에러 발생하여 추가
          return new Promise((resolve, reject) => {
            resolve(0);
          });
        },
      });
    },
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(rowData) {
      if (rowData?.evalFeedbackStartDt) {
        return formatDate(rowData.evalFeedbackStartDt, 'YYYY-MM-DD', 'YYYY-MM-DD');
      } else if (rowData?.evalFeedbackEndDt) {
        return formatDate(rowData.evalFeedbackEndDt, 'YYYY-MM-DD', 'YYYY-MM-DD');
      }
    },
    onDayStartChanged(e) {
      this.searchType.customTypes.frScheYmd = e.value.slice(0, 6) + '01';
    },
    onDayEndChanged(e) {
      this.searchType.customTypes.toScheYmd = e.value.slice(0, 6) + '29';
    },
    /** @description : 셀렉트 박스 초기화 */
    async initCodeMap() {
      this.codeMap = await this.$_getCodeMapList('root_ewm_eval_division');
    },
    /** @description : 라이프사이클 created시 호출되는 메서드 */
    createdData() {
      vm = this;

      this.initCodeMap().then(() => {
        this.codes.evalDivisionCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_eval_division'], 2);
        this.$refs.evalScheduleGrid.getInstance.columnOption('evalDivisionCd', 'lookup', this.codes.evalDivisionCd);
      });
    },
    /** @description : 라이프사이클 mounted시 호출되는 메서드 */
    mountData() {
      this.selectDataList();
    },
  },
  created() {
    // register.vue에서 돌아올 때 데이터 새로고침
    this.$router.afterEach((to, from) => {
      if (from.path === '/ewm/evaluation/implementation/schedule/register') {
        this.selectDataList(); 
      }
    });
    this.createdData();
  },
  mounted() {
    this.mountData();
  },
};
</script>

<style scoped>
.page_search_box .inner div {
  display: inline-block;
}

.page_search_box .inner > div:not(.box-btn-search) {
  vertical-align: middle;
  margin-right: 10px;
}

.page_search_box .spec-type-box {
  display: inline-block;
}

.page_search_box .inner .box-btn-search {
  margin-right: 15px;
}

.page_search_box #searchTooltip {
  margin-right: 5px;
  font-size: 22px;
  color: #808080;
}
</style>
<style></style>
