import ReportEditDashboard from '@/pages/report/dashboard/edit.vue';
import ReportDashboardManage from '@/pages/report/dashboard/manage';
import ReportDashboard from '@/pages/report/dashboard/index';

export default function DashboardRoutes(commonComponents) {
  return [
    {
      path: '/dashboard/:menuId',
      name: 'ReportDashboard',
      components: { default: ReportDashboard, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/dashboard/manage',
      name: 'ReportDashboardManage',
      components: { default: ReportDashboardManage, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/dashboard/edit/:templateId',
      name: 'ReportEditDashboard',
      components: { default: ReportEditDashboard, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
  ];
}
