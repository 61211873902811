<!--
  PACKAGE_NAME : src\pages\report\config\select.vue
  FILE_NAME : select
  AUTHOR : kwmoon
  DATE : 2024-11-21
  DESCRIPTION : SELECT QUERY 결과 조회 페이지
-->
<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="flex justify-between gap-2 p-2">
        <div class="w-1/5">
          <div class="flex items-center justify-between mt-3 mb-2">
            <label for="queryTextArea" class="text-lg font-bold">쿼리 입력</label>
            <div>
              <DxCheckBox v-model="isUpdate" class="checkbox fl mr-5" text="업데이트 쿼리" :height="30" style="padding-top: 10px" />
              <DxButton text="실행" class="btn_XS default filled" type="button" :height="30" @click="handleClickExecuteQueryButton()" />
            </div>
          </div>
          <DxTextArea
            v-model="query"
            class="p-1"
            tabindex="4"
            height="calc(100vh - 260px)"
            :styling-mode="stylingMode"
            :value-change-event="'input'"
            :placeholder="placeholder"
            @key-down="handleKeydown"
          />
        </div>
        <div class="w-4/5">
          <DxDataGrid
            ref="grid"
            :columns="grid.columns"
            :data-source="grid.dataSource"
            :show-borders="false"
            :show-row-lines="true"
            :show-column-lines="true"
            :show-column-headers="true"
            :width="grid.width"
            :rowAlternationEnabled="true"
            :allow-column-resizing="true"
            :allow-column-reordering="true"
            :column-auto-width="false"
            class="report-grid-border"
            height="calc(100vh - 220px)"
            column-resizing-mode="widget"
            no-data-text="데이터가 존재하지 않습니다."
            @exporting="onExporting"
          >
            <DxPaging :enabled="false" />
            <DxScrolling mode="standard" />
            <DxExport :enabled="grid.export.enabled" :texts="grid.exportButtonText" />
          </DxDataGrid>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import { DxDataGrid, DxExport, DxPaging, DxScrolling } from 'devextreme-vue/data-grid';
  import { exportDataGrid } from 'devextreme/excel_exporter';
  import { formatDate, getResData, isSuccess } from '@/utils/common-lib';
  import ExcelJS from 'exceljs';
  import { DxCheckBox } from 'devextreme-vue/check-box';

  export default {
    name: 'ReportQueryExecutePage',
    components: { DxCheckBox, DxExport, DxButton, DxTextArea, DxDataGrid, DxPaging, DxScrolling },
    data() {
      return {
        query: null,
        placeholder: null,
        isUpdate: false,
        stylingMode: 'outlined',
        initColumns: ['쿼리를 작성 후 Ctrl(Cmd) + Enter 로 실행하시면 됩니다.'],
        grid: {
          columns: [],
          dataSource: [],
          export: {
            // 엑셀 다운로드 설정
            enabled: true, // 엑셀 다운로드 버튼 표시 여부
            title: 'select_query_result', // 엑셀 파일명
            allowExportSelectedData: true, // 선택한 데이터만 다운로드 허용 여부
            exportButtonText: {
              // 버튼 정보
              exportTo: this.$_lang('COMPONENTS.EXCEL_DOWNLOAD', { defaultValue: '엑셀 다운로드' }),
              exportAll: this.$_lang('COMPONENTS.ALL_DOWNLOAD', { defaultValue: '전체 다운로드' }),
              exportSelectedRows: this.$_lang('COMPONENTS.SELECTED_DATA_DOWNLOAD', { defaultValue: '선택한 데이터 다운로드' }),
            },
            cellwidth: 30, // 셀 너비
            autoFilterEnabled: false, // 자동 필터 사용 여부
          },
          width: '100%',
        },
      };
    },
    methods: {
      handleClickExecuteQueryButton() {
        this.onSubmit();
      },
      keydownExecute(e) {
        // Windows: Ctrl + Enter
        if (e.ctrlKey && e.key === 'Enter') {
          this.onSubmit();
        }

        // Mac: Cmd + Enter
        if (e.metaKey && e.key === 'Enter') {
          this.onSubmit();
        }
      },
      keydownTab(e) {
        if (e.key === 'Tab') {
          e.preventDefault(); // 기본 동작(포커스 이동) 방지
          this.query = this.query + '\t';
        }
      },
      handleKeydown(e) {
        e = e.event;
        this.keydownTab(e);
        this.keydownExecute(e);
      },
      async onSubmit() {
        const result = await this.fetchExecuteQuery({ query: this.query, isUpdate: this.isUpdate });
        const columns = this.getColumns(result);
        this.grid.dataSource = result;
        this.grid.columns = columns;
      },
      getColumns(result = []) {
        if (result.length > 0) {
          return Object.keys(result.at(0));
        }
        return this.initColumns;
      },
      async fetchExecuteQuery(payload, useLoading = true) {
        const res = await this.CALL_REPORT_API({
          actionName: 'REPORT_EXECUTE_QUERY',
          data: { ...payload },
          loading: useLoading,
          useErrorPopup: true,
        });

        if (isSuccess(res)) {
          return getResData(res);
        }
      },
      /** @description: 엑셀 다운로드 이벤트 */
      onExporting(e) {
        const title = this.grid.export.title;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGridConfig.export.cellwidth 값에 따라 결정(없으면 Default : 30)
        const columnsArr = [];
        this.grid.columns.forEach(() => {
          columnsArr.push({ width: this.grid.export.cellwidth });
        });
        worksheet.columns = columnsArr;

        const today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.grid.export.autoFilterEnabled, //자동필터 설정 여부
          topLeftCell: { row: 1, column: 1 },
          customizeCell: ({ gridCell, excelCell }) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C6EFCE' } };
              excelCell.alignment = { horizontal: 'center', vertical: 'middle' };
            } else {
              //data 영역 배경색 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: { argb: 'F2F2F2' },
                  bgColor: { argb: 'F2F2F2' },
                };
              }
            }

            const borderStyle = { style: 'thin', color: { argb: 'FF7E7E7E' } };
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
          .then(() => {
            const columnsRow = worksheet.getRow(1);
            columnsRow.height = 20;

            // 새로운 시트 추가 및 데이터 삽입
            const newSheet = workbook.addWorksheet('native_query');
            newSheet.addRow(['실행 쿼리']); // 헤더 추가
            newSheet.addRow([this.query]); // 데이터 추가

            // 새로운 시트의 스타일 설정 (선택적)
            newSheet.columns = [{ width: 200 }];
            newSheet.getRow(1).font = { bold: true };
            newSheet.getRow(1).alignment = { horizontal: 'center', vertical: 'middle' };
          })
          .then(() => {
            let fileName;
            if (e.format === 'csv') {
              fileName = `${title}_${today}.csv`;
              workbook.csv.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'text/csv' }), fileName);
              });
            } else {
              fileName = `${title}_${today}.xlsx`;
              workbook.xlsx.writeBuffer().then(buffer => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), fileName);
              });
            }
            return fileName;
          });
        e.cancel = true; // 기본 동작 취소
      },
    },
    mounted() {
      this.grid.columns = this.initColumns;
      this.placeholder = `
        쿼리를 작성 후 Ctrl(Cmd) + Enter 로 실행하시면 됩니다.
        해당 박스 내에서 tab 입력 시 들여쓰기가 가능합니다.
      `;
    },
  };
</script>

<style scoped></style>
