import EUCInfoPushContent from '@/pages/euc/info-push/content';
import EUCInfoPushContentUpdate from '@/pages/euc/info-push/content/update';

export default function InfoPushContentRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/content',
            name: 'EUCInfoPushContent',
            components: { default: EUCInfoPushContent, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/content/update',
            name: 'EUCInfoPushContentUpdate',
            components: { default: EUCInfoPushContentUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}