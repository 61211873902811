import EUCRequestType from '@/pages/euc/phone/request/type';
import EUCRequestTypeTable from '@/pages/euc/phone/request/type/table.vue'

export default function RequestTypeRoutes(commonComponents) {
    return [
        {
            path: '/euc/phone/request/type',
            name: 'EUCRequestType',
            components: { default: EUCRequestType, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/phone/request/type/table',
            name: 'EUCRequestTypeTable',
            components: { default: EUCRequestTypeTable, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ]
}