<!--
  PACKAGE_NAME : src/pages/euc/phone/request/worker
  FILE_NAME : index.vue
  AUTHOR : jhcho
  DATE : 2024-06-03
  DESCRIPTION :
-->
<template>
  <div class="main">
    <esp-dx-data-grid :data-grid="dataGrid" ref="workerGridRef" @init-new-row="onInitNewRow"/>

    <DxPopup
        v-model="modal.isOpened"
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :width="modal.initData ? modal.initData.width : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :hide-on-outside-click="false"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component
              ref="modalRef"
              v-if="modal.sendData"
              :is="modal.currentComponent"
              :modalData="modal.sendData"
              :isModal="modal.isModal"
          />
        </div>
      </template>

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: $_lang('COMPONENTS.SELECT', { defaultValue: '선택' }),
          width: '120',
          height: '40',
          onClick: () => onSelectedUser(),
        }"
      />

      <DxToolbarItem
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: $_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => isOpenModal(false),
        }"
      />
    </DxPopup>
  </div>
</template>
<script>
import {isSuccess} from '@/utils/common-lib';
import {DxButton} from 'devextreme-vue/button';
import Search from '@/components/common/search.vue';
import ModalUserSelect from '@/components/euc/modal-user-select.vue';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import {DxSelectBox} from 'devextreme-vue/select-box';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  name: 'EUCRequestWorker',
  components: {
    EspDxDataGrid,
    DxSelectBox,
    DxPopup,
    DxToolbarItem,
    Search,
    ModalUserSelect,
  },
  data() {
    return {
      detailList: [],
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'workerGridRef',
        dataSourceDefaultSortColumn: '-regDt',
        apiActionNm: {
          select: 'EUC_PHONE_REQUEST_WORKER_SELECT',
          update: 'EUC_PHONE_REQUEST_WORKER_UPDATE',
        },
        customEvent: {
          initNewRow: true,
        },
        columns: [
          {
            caption: '',
            dataField: 'selectUser',
            allowEditing: false,
            width: 80,
            headerIcon: {
              name: 'dx-icon-user',
              position: 'before',
            },
            cellTemplate: (container, options) => {
              const button = new DxButton({
                propsData: {
                  text: '선택',
                  elementAttr: {class: 'btn_XS default filled add1 m-0'},
                  width: 60,
                  height: 30,
                  value: options.value,
                  onClick: () => {
                    options.onSelectedUser = user => {
                      const rowIndex = options.component.getRowIndexByKey(options.row.key);
                      options.component.cellValue(rowIndex, 'userNo', user.userNo);
                      options.component.cellValue(rowIndex, 'userNm', user.userNm);
                    };
                    this.showUserModal(options);
                  },
                },
              });
              button.$mount();
              container.append(button.$el);
            },
          },
          {
            caption: '사번',
            dataField: 'userNo',
            allowEditing: false,
            requiredRule: {},
          },
          {
            caption: '이름',
            dataField: 'userNm',
            allowEditing: false,
            requiredRule: {},
          },
          {
            caption: '설명',
            dataField: 'description',
          },
          {
            caption: '유형',
            dataField: 'typeId',
            lookup: {
              dataSource: [],
              displayExpr: 'typeNm',
              valueExpr: 'id',
            },
            setCellValue: (newData, value) => {
              newData.typeId = value;
              newData.detailId = null;
            },
          },
          {
            caption: '상세유형',
            dataField: 'detailId',
            lookup: {
              dataSource: options => {
                const returnData = {
                  store: this.detailList,
                  sort: '+detailOrd',
                };

                if (options.data?.typeId) {
                  returnData.filter = ['typeId', '=', options.data.typeId];
                }
                return returnData;
              },
              displayExpr: 'detailNm',
              valueExpr: 'id',
            },
          },
          {
            caption: '사용여부',
            dataField: 'viewFl',
            requiredRule: {},
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
          {
            caption: '수정자',
            dataField: 'editId',
            allowEditing: false,
          },
          {
            caption: '수정일',
            dataField: 'editDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row.editDt),
          },
        ],
      },
    };
  },
  methods: {
    showUserModal(options) {
      this.onOpenModal(
          'ModalUserSelect',
          {
            title: this.$_lang('UC.WORD.WORKER_SELECT', {defaultValue: '작업자 선택'}),
            width: '50vw',
            height: 'calc(100vh - 100px)',
            buttons: {
              save: {text: this.$_lang('COMPONENTS.SAVE', {defaultValue: '저장'})},
              cancel: {text: this.$_lang('COMPONENTS.CLOSE', {defaultValue: '닫기'})},
            },
          },
          options,
      );
    },
    onSelectedUser() {
      const user = this.$refs.modalRef.getSelectUser();
      if (!user?.[0] || !user[0].isUser) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.REQUIRED_SELECT_USER', {defaultValue: '사용자를 선택해주세요.'}), {
          icon: 'info',
        });
        return;
      }
      this.modal.sendData.onSelectedUser(user[0]);
      this.isOpenModal(false);
    },
    onOpenModal(componentNm, componentInitData, sendData) {
      sendData.privilege = 'worker';
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    isOpenModal(bool) {
      if (!bool) {
        this.$refs.modalRef.clearSelection();
        this.modal.sendData = null;
      }
      this.modal.isOpened = bool;
    },
    onInitNewRow(e) {
      e.data.viewFl = 'Y';
    },
    formatDt(date, toFormat = 'YYYY.MM.DD HH:mm:ss') {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', toFormat);
      }
    },
    async selectTypeDataList(sort = '+typeOrd') {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_SELECT',
        data: {
          sort,
          viewFl: 'Y',
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.dataGrid.columns.find(column => column.dataField === 'typeId').lookup.dataSource = res.data.data;
      }
    },
    async selectDetailDataList(sort = '+detailOrd') {
      const payload = {
        actionName: 'EUC_PHONE_REQUEST_TYPE_DETAIL_SELECT',
        data: {
          sort,
          viewFl: 'Y',
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.detailList = res.data.data;
      }
    },
  },
  created() {
    this.selectTypeDataList();
    this.selectDetailDataList(); // 초기 데이터 생성 시 필요한 데이터 조회
  },
};
</script>
<style scoped></style>