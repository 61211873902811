<!--
  PACKAGE_NAME : src/pages/euc/ipt-file
  FILE_NAME : modal-ipt-file.vue
  AUTHOR : jhcho
  DATE : 2025-04-11
  DESCRIPTION :
-->
<template>

  <div class="pt-2">
    <dl
        class="-my-3 divide-y divide-gray-200 rounded border text-3xl border-gray-200"
    >
      <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
        <dt class="font-medium text-gray-900">그룹</dt>

        <dd class="text-gray-700 sm:col-span-2">
          <dx-select-box
              :items="modalData.groupList"
              placeholder="선택"
              display-expr="title"
              value-expr="id"
              v-model="formData.groupId"
              styling-mode="outlined"
          >
          </dx-select-box>
        </dd>
      </div>

      <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
        <dt class="font-medium text-gray-900">제목</dt>

        <dd class="text-gray-700 sm:col-span-2">
          <dx-text-box
              :value="formData.title"
              :placeholder="$_lang('COMPONENTS.PLACEHOLDER.TITLE', { defaultValue: '제목을 입력하세요.' })"
              styling-mode="outlined"
              @value-changed="(e) => formData.title = e.value"
          />
        </dd>
      </div>

      <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
        <dt class="font-medium text-gray-900">삭제 가능 여부</dt>

        <dd class="text-gray-700 sm:col-span-2">
          <dx-select-box
              :items="getStringPossible"
              placeholder="선택"
              display-expr="label"
              value-expr="value"
              v-model="formData.isDeletable"
              styling-mode="outlined"
          >
          </dx-select-box>
        </dd>
      </div>
      <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 ">
        <dt class="font-medium text-gray-900">파일명</dt>

        <dd class="text-gray-700 sm:col-span-2">{{ file.name }}</dd>
      </div>
      <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
        <dt class="font-medium text-gray-900">파일 크기</dt>

        <dd class="text-gray-700 sm:col-span-2">{{ formatFileSize(file.size) }}</dd>
      </div>
    </dl>
    <dx-file-uploader
        ref="fileUploaderRef"
        :select-button-text="$_lang('COMPONENTS.FILE_SELECT', { defaultValue: '파일 선택' })"
        upload-mode="useButtons"
        accept="accept"
        :multiple="false"
        :show-file-list="false"
        @value-changed="onValueChanged"
    />
  </div>
</template>
<script>
import {DxTextBox} from 'devextreme-vue/text-box';
import {DxSelectBox} from 'devextreme-vue/select-box';
import {DxFileUploader} from 'devextreme-vue/file-uploader';

export default {
  components: {
    DxTextBox,
    DxSelectBox,
    DxFileUploader
  },
  props: {
    modalData: {
      type: Object,
      default: () => ({}),
    }
  },
  data() {
    return {
      formData: {
        isDeletable: 'Y'
      },
      file: {},
    }
  },
  computed: {
    getStringPossible() {
      return this.$_enums.common.stringPossibleFlag.values;
    }
  },
  methods: {
    /**
     * 파일 선택 시 호출
     * @param {Object} e
     * */
    async onValueChanged(e) {
      const files = await Promise.all(e.value);
      this.file = files[0];
    },

    formatFileSize(size) {
      if (!size) {
        return '';
      }

      const units = ['B', 'KB', 'MB', 'GB'];
      let index = 0;

      while (size >= 1024 && index < units.length - 1) {
        size = size / 1024;
        index++;
      }

      return `${size.toFixed(index === 0 ? 0 : 2).toLocaleString()} ${units[index]}`;
    }
  },
  mounted() {
    if (this.modalData?.selectedData) {
      this.formData = this.modalData.selectedData;
      this.file.name = this.modalData.selectedData.fileNm;
      this.file.size = this.modalData.selectedData.fileSize;
    }
  }
}
</script>