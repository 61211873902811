import EUCIptFile from '@/pages/euc/ipt-file';

export default function SpIptRoutes(commonComponents) {
    return [
        {
            path: '/euc/ipt-file',
            name: 'EUCIptFile',
            components: { default: EUCIptFile, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}