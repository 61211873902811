import AIForecastingList from '@/pages/ai/forecasting/list.vue';
import AIForecastingReport from '@/pages/report/ewm/forecasting-report.vue';
import AIForecastingModelHistory from '@/pages/report/ewm/forecasting-model-history.vue';

export default function ConfigRoutes(commonComponents) {
  return [
    {
      path: '/ai/forecasting/list',
      name: 'AIForecastingList',
      components: { default: AIForecastingList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/ewm/forecasting-report',
      name: 'AIForecastingReport',
      components: { default: AIForecastingReport, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/ewm/forecasting-model-history',
      name: 'AIForecastingModelHistory',
      components: { default: AIForecastingModelHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
