<!--
  PACKAGE_NAME : src/pages/euc/device/avaya
  FILE_NAME : detail.vue.vue
  AUTHOR : jhcho
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="main">
    <p class="text-2xl font-medium">단말기 기본 정보</p>
    <div class="grid grid-flow-col grid-rows-6 gap-4">
      <div class="row-span-2 border-b border-gray-300">
        <div class="grid grid-cols-3 gap-4">
          <div>
            <img v-if="deviceImage" :src="deviceImage" alt="Device" />
          </div>
          <div>
            <dl
                class="-my-3 divide-y divide-gray-200 rounded border text-3xl border-gray-200"
            >
              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
                <dt class="font-medium text-gray-900">모델</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.model }}</dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
                <dt class="font-medium text-gray-900">교환기</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ cm.cmNm }}</dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
                <dt class="font-medium text-gray-900">내선번호</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.uniqueId }}</dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
                <dt class="font-medium text-gray-900">언어</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.lang }}</dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 ">
                <dt class="font-medium text-gray-900">Comcode</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.cc }}</dd>
              </div>
              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
                <dt class="font-medium text-gray-900">시리얼 넘버</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.sn }}</dd>
              </div>
              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
                <dt class="font-medium text-gray-900">RFS</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.rfsname }}</dd>
              </div>
            </dl>
          </div>
          <div>
            <dl
                class="-my-3 divide-y divide-gray-200 rounded border text-3xl border-gray-200"
            >
              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
                <dt class="font-medium text-gray-900">IP</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.ip }}</dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
                <dt class="font-medium text-gray-900">AES</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ aes.aesNm }}</dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
                <dt class="font-medium text-gray-900">파일 다운로드</dt>

                <dd class="text-gray-700 sm:col-span-2">
                  <button class="btn_XS white download" type="button" @click="onFileDownload">텍스트</button>
                </dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
                <dt class="font-medium text-gray-900">Phone Screen Width</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.phoneScreenWidth }}</dd>
              </div>

              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4">
                <dt class="font-medium text-gray-900">릴리즈 버전</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.release }}</dd>
              </div>
              <div class="grid grid-cols-1 gap-1 p-3 sm:grid-cols-3 sm:gap-4 bg-gray-50">
                <dt class="font-medium text-gray-900">펌웨어 버전</dt>

                <dd class="text-gray-700 sm:col-span-2">{{ deviceData.appname }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div class="row-span-2">
        <table class="table_form line-bin">
          <colgroup>
            <col class="w-1/3"/>
            <col class="w-1/3"/>
            <col class="w-1/3"/>
          </colgroup>
          <tbody>
          <tr>
            <td>
              <device-detail-grid
                  :data-grid="moduleDataGrid"
                  ref="moduleGrid"
                  @row-updating="handleSave"
              />
            </td>
            <td>
              <device-detail-grid
                  :data-grid="phoneLabelDataGrid"
                  ref="phoneLabelGrid"
                  @row-updating="handleSave"
                  @row-inserting="handleAdd"
              />
            </td>
            <td>
              <device-detail-grid
                  :data-grid="callHistoryDataGrid"
                  ref="callHistoryGrid"
                  style="margin-top: 40px"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <section class="row-span-1 flex items-center justify-center gap-4">
        <dx-button
            text="저 장"
            :width="120"
            :height="40"
            class="default filled txt_S medium"
            :on-click="() => onSave()"
        />
        <dx-button
            text="취 소"
            :width="120"
            :height="40"
            class="white filled txt_S medium"
            :on-click="() => $_goPrePage()"
        />
      </section>
    </div>
  </div>
</template>

<script>
import DxButton from 'devextreme-vue/button';
import DeviceDetailGrid from './device-grid.vue';
import { isSuccess } from "@/utils/common-lib";
import {getUCServerPath} from "@/pages/euc/common-uc";

export default {
  name: 'EUCAvayaDeviceDetail',
  components: {
    DxButton,
    DeviceDetailGrid,
  },
  props: {},
  watch: {},
  data() {
    return {
      config: {
        pageSetting: {
          //pageData pageSetting 관련
          config: {},
        },
      },
      deviceImage: '',
      deviceData: {
        model: '',
        uniqueId: '',
        lang: '',
        phoneScreenWidth: '',
        ip: '',
      },
      svr: {},
      aes: {},
      cm: {},
      fileData: [],
      moduleDataGrid: {
        refName: 'moduleGrid',
        visible: true,
        dataField: 'modules',
        dataSource: [],
        showBorders: true,
        showColumnHeaders: true,
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: false,
        allowColumnReordering: true,
        noDataText: '데이터가 없습니다.',
        width: '100%',
        height: 520,
        keyExpr: 'id',
        saveButton: true,
        revertButton: true,
        showActionButtons: {
          select: false,
          copy: false,
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '삭제',
                elementAttr: { class: 'btn_XS white light_filled trash' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.onDelete('moduleDataGrid');
                },
              },
              location: 'before',
            },
          ],
        },
        columns: [
          {
            dataField: 'id',
            alignment: 'center',
            visible: false,
          },
          {
            caption: '번호',
            dataField: 'num',
            width: 80,
            alignment: 'center',
            visible: true,
            editing: false,
            sortOrder: 'asc',
            dataType: 'number',
          },
          {
            caption: '한글 확장 모듈',
            dataField: 'display',
            alignment: 'center',
            visible: true,
            editing: true,
          },
        ],
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        scrolling: {
          mode: 'virtual',
        },
        editing: {
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
      },
      phoneLabelDataGrid: {
        refName: 'phoneLabelGrid',
        visible: true,
        dataField: 'phoneLabels',
        dataSource: [],
        showBorders: true,
        showColumnHeaders: true,
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: false,
        allowColumnReordering: true,
        noDataText: '데이터가 없습니다.',
        width: '100%',
        height: 520,
        keyExpr: 'id',
        saveButton: true,
        revertButton: true,
        showActionButtons: {
          select: false,
          copy: false,
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '삭제',
                elementAttr: { class: 'btn_XS white light_filled trash' },
                width: 60,
                height: 30,
                onClick: () => {
                  this.onDelete('phoneLabelDataGrid');
                },
              },
              location: 'before',
            },
          ],
        },
        columns: [
          {
            dataField: 'id',
            alignment: 'center',
            visible: false,
          },
          {
            caption: '번호',
            dataField: 'num',
            width: 80,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'asc',
            allowHeaderFiltering: false,
            allowGrouping: false,
            dataType: 'number',
            requiredRule: {
              message: '번호는 필수입니다.'
            },
          },
          {
            caption: '폰 라벨',
            dataField: 'display',
            alignment: 'center',
            visible: true,
            editing: true,
            requiredRule: {
              message: '라벨 내용은 필수입니다.'
            },
          },
        ],
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        scrolling: {
          mode: 'virtual',
        },
        editing: {
          allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
        },
      },
      callHistoryDataGrid: {
        refName: 'callHistoryGrid',
        visible: true,
        dataField: 'callHistory',
        dataSource: [],
        showBorders: true,
        showColumnHeaders: true,
        showColumnLines: false,
        showRowLines: true,
        rowAlternationEnabled: false,
        allowColumnReordering: true,
        noDataText: '데이터가 없습니다.',
        width: '100%',
        height: 480,
        keyExpr: 'num',
        saveButton: false,
        revertButton: false,
        showActionButtons: {
          select: false,
          copy: false,
          delete: false,
          customButtons: [],
        },
        columns: [
          {
            caption: '번호',
            dataField: 'num',
            width: 80,
            alignment: 'center',
            visible: false,
          },
          {
            caption: '전화 이력',
            dataField: 'display',
            width: 100,
            height: '100%',
            alignment: 'center',
            visible: true,
            cellTemplate: (container, options) => {
              const divEl = document.createElement('div');
              divEl.style.textAlign = 'left';
              divEl.innerText = options.value;
              container.append(divEl);
            },
          },
        ],
        scrolling: {
          mode: 'virtual',
        },
        editing: {
          allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
          allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
          startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
          selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
        },
      },
    };
  },
  computed: {
    /** @description: pageData -> pageSetting apiActionNm 가져오기 */
    getApiActionNm() {
      return this.config.pageSetting.config?.apiActionNm || null;
    },
  },
  methods: {
    /** @description: 폰 라벨 데이터 추가 메서드 */
    handleAdd(e) {
      const { num, display } = e.data;
      const index = this.phoneLabelDataGrid.dataSource.findIndex(item => item.num === num);

      if (index > -1) {
        this.$_Msg(`${num}번은 중복된 번호 입니다.`);
        e.cancel = true;
      } else {
        const key = `PHNLABEL${num < 10 ? '0' + num : num}`;
        e.data.id = key;
        this.handFileDataChange(key, display);
      }
    },
    /** @description: Data grid 변경 변경 메서드 */
    handleSave(e){
      const { oldData, newData } = e;
      this.handFileDataChange(oldData.id, newData.display);
    },
    /** @description: Data-grid 변경 후 Origin data 변경 메서드 */
    handFileDataChange(key, value) {
      const data = `${key}=${value}`;
      const index = this.fileData.findIndex(dataItem => dataItem.includes(key));

      if (index > -1) {
        // 기존에 fileData에 해당하는 데이터가 있었다면 찾아서 변경
        this.fileData[index] = data;
      } else {
        // 없다면 일단 push
        this.fileData.push(data);
      }

      // 한글 확장 모듈 값만 필터링
      const sbmData = this.fileData.filter(item => item.includes("SBMLABEL")).sort();
      // 폰 라벨 값만 필터링
      const labelData = this.fileData.filter(item => item.includes("PHNLABEL")).sort();

      const copyData = this.fileData.filter(item => !item.includes("SBMLABEL") && !item.includes("PHNLABEL"));
      this.fileData = [...new Set(copyData.concat(sbmData, labelData))];
    },
    /** @description: 최종 저장 시 해당 파일 변경 메서드 */
    async onSave() {
      const moduleChanged = this.$refs.moduleGrid.hasEditData();
      const phoneLabelChanged = this.$refs.phoneLabelGrid.hasEditData();

      if (moduleChanged || phoneLabelChanged) {
        this.$_Msg("아직 저장하지 않은 설정값이 있습니다.");
        return false;
      }

      const { uniqueId, appuser } = this.deviceData;
      const efgwPort = this.$_getSystemData('efgw_port')?.configValue;
      const efgwUrl = `${this.svr?.svrIp}:${efgwPort}`;
      const payload = {
        actionName: 'EUC_DEVICE_AVAYA_DETAIL_UPDATE',
        data: {
          uniqueId,
          url: efgwUrl,
          fileData: this.fileData,
        },
        loading: true,
      };

      const saveResp = await this.CALL_EUC_API(payload);
      if (isSuccess(saveResp)) {
        const isRestart = this.$_getSystemData('use_device_restart')?.configValue === 'Y';
        const msg = isRestart ? `설정값 저장에 성공하였습니다.<br /><br />${uniqueId} 내선을 재시작 하시겠습니까?<br />재시작되면 통화중인 전화는 끊어집니다.` : '설정값 저장에 성공하였습니다.';

        if (await this.$_Confirm(msg)) {
          if(isRestart) {
            const adimPort = this.$_getSystemData('adim_port')?.configValue;
            const adimUrl = `${this.svr?.svrIp}:${adimPort}`;
            const restartPayload = {
              actionName: 'EUC_DEVICE_AVAYA_RESTART',
              data: {
                url: adimUrl,
                interval: '5',
                stations: [uniqueId],
                cm: this.cm.cmNm,
                appuser: appuser,
              },
              loading: false,
            };

            const restartResp = await this.CALL_EUC_API(restartPayload);
            if (isSuccess(restartResp)) {
              this.$_Toast("전화기 재시작 요청이 성공하였습니다.", { icon: 'success' })
            } else {
              this.$_Toast("전화기 재시작 요청이 실패하였습니다.", { icon: 'error' })
            }
          }
        }
      } else {
        this.$_Toast("데이터 저장에 실패하였습니다.", { position: 'center', timer: 1500 })
      }
    },
    /** @description: 최종 저장 시 해당 파일 변경 메서드 */
    async onDelete(dataGrid) {
      const refName = this[dataGrid].refName;
      const selectedRowsData = this.$refs[refName]?.selectedRowsData;

      if (selectedRowsData?.length) {
        if (await this.$_Confirm("선택한 데이터를 삭제하시겠습니까?")) {
          selectedRowsData.forEach((data) => {
            const index = this[dataGrid].dataSource.findIndex((item) => item.id === data.id);
            this[dataGrid].dataSource.splice(index, 1);

            if (dataGrid === 'moduleDataGrid') {
              const newData = {
                id: data.id,
                num: data.num,
                display: ''
              };
              this[dataGrid].dataSource.push(newData);
              this.$refs[refName].clearSelection();
            }

            this.fileData = this.fileData.filter((item) => !item.includes(data.id));
          });

          this.$refs[refName].selectedRowsData = [];
        }
      } else {
        this.$_Msg('삭제할 데이터를 선택해주세요.');
        return false;
      }
    },
    /** @description: 단말장치 데이터 상세 조회 메서드 */
    async selectData() {
      if(this.deviceData?.model) {
        this.deviceImage = `${getUCServerPath()}/euc/api/v1/resource/DEVICE_AVAYA_BIG_IMAGE_DIR/${this.deviceData.model}.png`;
      }

      const modulsData = Array.from({ length: 99 }, (_, i) => {
        const num = i + 1;
        const id = `SBMLABEL${num < 10 ? '0' + num : num}`;
        return { id, num, display: '' };
      });

      const phoneData = [];
      let callStr = '';

      for (const data of this.fileData) {
        const [key, value] = data.split('=');
        if (key.startsWith('SBMLABEL')) {
          const dataIndex = modulsData.findIndex((data) => data.id === key);
          if (dataIndex !== -1) {
            modulsData[dataIndex].display = value;
          }
        } else if (key.startsWith('PHNLABEL')) {
          const index = Number(key.replace(/[^0-9]/g, ''));
          const id = `PHNLABEL${index < 10 ? '0' + index : index}`;
          phoneData.push({ id, num: index, display: value });
        } else if (key.startsWith('CL')) {
          callStr += `${key}=${value}\n`;
        } else if (key.includes('Language')) {
          this.deviceData.lang = value;
        } else if (key.includes('Phone Screen Width')) {
          this.deviceData.phoneScreenWidth = value;
        }
      }

      modulsData.sort((a, b) => a.num - b.num);
      this.moduleDataGrid.dataSource = modulsData;
      this.phoneLabelDataGrid.dataSource = phoneData;
      this.callHistoryDataGrid.dataSource = [{ num: 1, display: callStr }];
    },

    /**@description : 단말기 상세 정보 파일 다운로드 */
    async onFileDownload() {
      const fileData = this.fileData.join("\n");
      const blob = new Blob([fileData], { type: 'text/plain' });
      const downloadUrl = URL.createObjectURL(blob);
      const fileName = `${this.deviceData.uniqueId}_96xxdata.txt`;

      const downloadLink = document.createElement('a');
      downloadLink.href = downloadUrl;
      downloadLink.download = fileName;
      downloadLink.style.display = 'none';

      document.body.appendChild(downloadLink);
      downloadLink.click();

      URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(downloadLink);
    },
    /**
     * @description : 단말기 SVR/AES 맵핑 정보 조회 메서드
     * */
    async fetchSvrAesData() {
      const payload = {
        actionName: 'EUC_SVR_AES_SELECT_ONE',
        data: {
          id: this.deviceData.svraesId
        }
      }

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        const svrAes = res.data.data;
        const {svrId, aesId} = svrAes;
        await Promise.all([this.fetchSvrData(svrId), this.fetchAesData(aesId)]);
      } else {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_FAIL', { defaultValue: '단말기 상세정보 조회에 실패하였습니다.' }));
      }
    },
    /**
     * @description : 단말기 SVR 정보 조회 메서드
     * */
    async fetchSvrData(svrId) {
      const payload = {
        actionName: 'EUC_SVR_SELECT_ONE',
        data: {
          id: svrId
        }
      }
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.svr = res.data.data;
      } else {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_FAIL', { defaultValue: '단말기 상세정보 조회에 실패하였습니다.' }));
      }
    },
    /**
     * @description : 단말기 AES 정보 조회 메서드
     * */
    async fetchAesData(aesId) {
      const payload = {
        actionName: 'EUC_AES_SELECT_ONE',
        data: {
          id: aesId
        }
      }
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.aes = res.data.data;
        await this.fetchCmData(this.aes.cmId);
      } else {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_FAIL', { defaultValue: '단말기 상세정보 조회에 실패하였습니다.' }));
      }
    },
    async fetchCmData(cmId) {
      const payload = {
        actionName: 'EUC_CM_SELECT_ONE',
        data: {
          id: cmId
        }
      }
      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.cm = res.data.data;
      } else {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_FAIL', { defaultValue: '단말기 상세정보 조회에 실패하였습니다.' }));
      }
    },
    /**@description : 라이프사이클 created시 호출되는 메서드 */
    async createdData() {
      const { deviceData, fileData } = this.$store.getters.getDetailParams;
      this.deviceData = deviceData;
      this.fileData = fileData;

      await this.fetchSvrAesData();
    },
    /**@description : 라이프사이클 mounted시 호출되는 메서드 */
    async mountedData() {
      await this.selectData();
    },
    /**@description : 라이프사이클 destroyed시 호출되는 메서드 */
    destroyedData() {
      this.$store.commit('setDetailParams', null);
    },
  },
  async created() {
    await this.createdData();
  },
  async mounted() {
    await this.mountedData();
  },
  destroyed() {
    this.destroyedData();
  },
};
</script>

<style scoped>
.device-img > img {
  align-self: center;
}

.fl {
  border-right: 0 !important;
}

tbody {
  display: table-row-group;
  vertical-align: top;
}
</style>