<template>
	<div class="container">
		<!-- 1차 -->
		<div style="height: calc(100vh - 250px); ">
			<div class="space-y-4 m-4 p-6">
				<div class="sub_title_txt my-4">
					<h2>설문 상세</h2>
				</div>
				<div class="border-2 border-black-700 rounded-3xl">
					<div class="m-6 space-y-6">
						<div class="space-x-2">
							<span>설문 제목 : </span>
							<span>{{ formData.surNm }}</span>
						</div>
						<div class="space-x-2">
							<span>설문 기간 : </span>
							<span>{{ formData.surStartDt }} ~ {{ formData.surEndDt }}</span>
						</div>
						<div class="space-x-2">
							<span>결과 공개 여부 : </span>
							<span v-if="formData.resultOpenFl == 'Y'">공개</span>
							<span v-else>비공개</span>
						</div>
						<div class="space-x-2">
							<span>진행률 : </span>
							<span
								>{{ config.checkAnswerLength }} / {{ formData.surveyQuestions.length }} ( {{ config.answerPercent }}%
								)</span
							>
						</div>
						<div>교육결과에 대한 설문조사 입니다. 안내에 따라 설문 진행을 부탁드립니다.</div>
					</div>
				</div>
			</div>

			<div class="h-4/6 space-y-4 mx-4 px-6">
				<div class="sub_title_txt my-4">
					<h2>설문 진행</h2>
				</div>
				<div class="h-2/3 overflow-auto border-2 border-black-700 rounded-3xl">
					<div v-for="(item1, index1) in formData.surveyQuestions" :key="index1" class="m-6 space-y-4">
						<div class="space-x-2" v-if="item1.delFl === 'N'">
							<span>{{ index1 + 1 }}.</span>
							<span>{{ item1.questionNm }}</span>
							<span v-if="item1.importantAnswerFl == 'Y'" class="text-red-600">[ 필수 ]</span>
							<span v-else></span>
						</div>
						<div v-for="(item2, index2) in item1.surveyQuestionRegs" :key="index2" class="space-y-2">
							<template v-if="item2.delFl === 'N'">
								<div v-if="item1.questionGroupCd === 1089 && item1.questionTypeCd === 1092" class="flex space-x-4">
									<!-- 단수 -->
									<DxCheckBox
										:text="item2.questionView"
										:value="item1.answer && item1.answer == String(item2.id)"
										@value-changed="e => checkAnswerDan(e, index1, index2)"
									/>
								</div>

								<div v-else-if="item1.questionGroupCd === 1089 && item1.questionTypeCd === 1093" class="flex space-x-4">
									<!-- 복수 -->
									<DxCheckBox
										:enable-three-state-behavior="false"
										:text="item2.questionView"
										:value="item2.isChecked"
										@value-changed="e => checkAnswerBok(e, index1, index2)"
									/>
								</div>

								<div v-else-if="item1.questionGroupCd === 1090 && item1.questionTypeCd === 1090">
									<!-- 서술 -->
									<DxTextBox
										v-model="item1.answer"
										:styling-mode="config.stylingMode"
										:width="600"
										class="mar_ri10"
										@valueChanged="checkAnswer('서', item1, item2)"
									>
									</DxTextBox>
								</div>

								<div v-else-if="item1.questionGroupCd === 1091">
									<!-- 점수3 -->
									<DxRadioGroup
										:items="getCheckScoreLength(item1.questionTypeCd)"
										value-expr="id"
										display-expr="text"
										class="radiobox"
										layout="horizontal"
										v-model="item1.answer"
										@valueChanged="checkAnswer('점3', item1, item2)"
									/>
								</div>
							</template>
						</div>
					</div>
				</div>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="bottom-btn-wrap">
				<DxButton
					text="제출"
					:width="100"
					:height="40"
					class="default filled txt_S medium"
					:use-submit-behavior="true"
					@click="onSaveData"
				/>
				<DxButton text="취소" :width="100" :height="40" class="white filled txt_S medium" @click="onCancelFormData" />
			</div>
		</section>
	</div>
</template>

<script>
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxButton } from 'devextreme-vue/button';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxCheckBox } from 'devextreme-vue/check-box';
import { DxRadioGroup } from 'devextreme-vue/radio-group';
import { DxTextArea } from 'devextreme-vue/text-area';
import {cloneObj, isSuccess} from '@/utils/common-lib';
import { DxDataGrid, DxColumn, DxLoadPanel, DxScrolling, DxSorting } from 'devextreme-vue/data-grid';
import { DxValidator, DxCustomRule, DxPatternRule, DxRangeRule, DxRequiredRule } from 'devextreme-vue/validator';
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';

export default {
  name: 'EWMPersonalSurveyDetail',
	components: {
		DxDateBox,
		DxButton,
		DxTextBox,
		DxSelectBox,
		DxCheckBox,
		DxRadioGroup,
		DxTextArea,
		DxColumn,
		DxDataGrid,
		DxLoadPanel,
		DxScrolling,
		DxSorting,
		ModalAddAgent,

		DxValidator,
		DxCustomRule,
		DxPatternRule,
		DxRangeRule,
		DxRequiredRule,
	},
	props: {},
	watch: {},
	data() {
		return {
			config: {
				checkAnswerLength: 0,
				answerPercent: 0,
				checkScoreLength: [
					{ id: '1', text: '1점' },
					{ id: '2', text: '2점' },
					{ id: '3', text: '3점' },
					{ id: '4', text: '4점' },
					{ id: '5', text: '5점' },
					{ id: '6', text: '6점' },
					{ id: '7', text: '7점' },
					{ id: '8', text: '8점' },
					{ id: '9', text: '9점' },
					{ id: '10', text: '10점' },
				],
			},
			surveyAnswerData: [],
			formData: {
				id: null, //id
				surNm: '', //설문 제목
				surProcessCd: null, //진행상태
				surStartDt: '', //설문 시작기간
				surEndDt: '', //설문 끝기간
				resultOpenFl: '', //결과 공개 여부
				sheetId: null, //설문 안내 id
				sheetName: '', //설문 안내 문구
				surveyTargets: [
					//설문 대상자
					{
						id: null, //대상자 id
						agtId: '', //대상자 사번
						agtNm: '',
						deptNmPath: '',
						delFl: 'N', //flag
					},
				],
				surveyQuestions: [
					//질문
					{
						id: null, //질문 id
						questionNm: '', //질문 제목
						questionTypeCd: null, //설문 문항 타입 하위
						questionGroupCd: null, //설문 문항 타입 상위
						importantAnswerFl: false, //필수답변 체크
						delFl: 'N', //flag
						surveyQuestionRegs: [
							{
								answerOrd: 1,
								id: null, //보기 id
								questionView: '', //보기 제목
								delFl: 'N', //flag
							},
						],
					},
				],
			},
		};
	},
	computed: {},
	methods: {
		checkScoreLength(num) {
			return this.config.checkScoreLength.slice(0, num);
		},
		getCheckScoreLength(questionTypeCd) {
			let num = 3;
			switch (questionTypeCd) {
				case 1094:
					num = 3;
					break;

				case 1095:
					num = 5;
					break;

				case 1096:
					num = 7;
					break;

				case 1097:
					num = 10;
					break;
			}

			return this.config.checkScoreLength.slice(0, num);
		},
		checkAnswerDan(e, index1, index2) {
			if (!e.event) return; //클릭으로 이벤트가 발생했을 때마 실행

			const newAnswer = e.value ? String(this.formData.surveyQuestions[index1].surveyQuestionRegs[index2].id) : '';
			const checkQuestion = cloneObj(this.formData.surveyQuestions[index1]);
			checkQuestion.answer = newAnswer;

			this.$set(this.formData.surveyQuestions, index1, checkQuestion);

			this.calAnswerPercent();
		},
		checkAnswerBok(e, index1, index2) {
			if (!e.event) return; //클릭으로 이벤트가 발생했을 때마 실행

			const newSurveyQuestion = this.formData.surveyQuestions[index1];
			newSurveyQuestion.surveyQuestionRegs[index2].isChecked = e.value;

			this.formData.surveyQuestions[index1] = newSurveyQuestion;

			const newAnsersArry = newSurveyQuestion.surveyQuestionRegs
				.filter(questionReg => questionReg.isChecked == true)
				.map(questionReg => String(questionReg.id));

			if (newAnsersArry.length < 1) {
				newSurveyQuestion.answer = '';
			} else if (newAnsersArry.length == 1) {
				newSurveyQuestion.answer = newAnsersArry[0];
			} else {
				newSurveyQuestion.answer = newAnsersArry.join(', ');
			}

			this.$set(this.formData.surveyQuestions, index1, newSurveyQuestion);

			this.calAnswerPercent();
		},
		checkAnswer(string, value1, value2) {
			if (string == '복') {
				console.log('복수');
				if (!value2.isChecked || value2.isChecked == false) {
					value2.isChecked = true;
				} else {
					value2.isChecked = false;
				}
				if (value1.answer) {
					if (typeof value1.answer === 'string') {
						let answerArray = value1.answer.split(',').map(i => Number(i.trim()));
						answerArray.forEach(a => {
							if (a == value2.id) {
								answerArray = answerArray.filter(i => i !== value2.id);
								value1.answer = answerArray.join(', ');
							} else {
								value1.answer = `${value1.answer}, ${value2.id}`;
							}
						});
					} else {
						value1.answer = value2.id === value1.answer ? null : `${value1.answer}, ${value2.id}`;
					}
				} else {
					value1.answer = value2.id;
				}
				console.log(value1.answer);
			} else if (string == '서') {
				console.log('서술형');
			} else if (string == '점3') {
				console.log('점수형3');
			} else if (string == '점5') {
				console.log('점수형5');
			} else if (string == '점7') {
				console.log('점수형7');
			} else if (string == '점10') {
				console.log('점수형10');
			}

			this.calAnswerPercent();
		},
		calAnswerPercent() {
			this.config.checkAnswerLength = this.formData.surveyQuestions.filter(c => c.answer).length;

			if (this.config.checkAnswerLength === this.formData.surveyQuestions.length) {
				this.config.answerPercent = 100;
			} else {
				this.config.answerPercent = Math.round((this.config.checkAnswerLength / this.formData.surveyQuestions.length) * 100);
			}
		},
		/** @description: 데이터 조회 메서드 */
		async selectDataList() {
			const payload = {
				actionName: 'EWM_SURVEY_SCHEDULE_ITEM',
				data: {
					scheId: this.reqParams.data.id,
				},
				loading: false,
				useErrorPopup: true,
			};

			const res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
        this.formData = res.data.data[0];
			}

			const payload2 = {
				actionName: 'EWM_PERSONAL_SURVEY_ANSWER',
				data: {
					scheId: this.reqParams.data.id,
					sheetId: this.reqParams.data.sheetId,
					agtId: this.reqParams.data.agtId,
				},
				loading: false,
				useErrorPopup: true,
			};

			const res2 = await this.CALL_EWM_API(payload2);

			if (isSuccess(res2)) {
        this.surveyAnswerData = res2.data.data;

        this.formData.surveyQuestions = this.formData.surveyQuestions.map(item => {
          const matchingAnswer = this.surveyAnswerData.find(s => s.questionId === item.id);
          return matchingAnswer ? { ...item, ...matchingAnswer } : item;
        });
        this.formData.surveyQuestions.forEach(i => {
          if (i.questionGroupCd === 1089 && i.questionTypeCd === 1093 && i.answer) {
            let answerArray = i.answer.split(',').map(a => Number(a.trim()));
            console.log('복수답안 나누기', answerArray);
            i.surveyQuestionRegs.forEach(j => {
              j.isChecked = answerArray.findIndex(a => a === j.id) > -1;
            });
          }
        });
			}
		},
		/** @description: 설문작성 저장 메서드 */
		async onSaveData() {
			const hasMissingAnswer = this.formData.surveyQuestions.some(f => {
				if (f.importantAnswerFl == 'Y' && !f.answer) {
					return true;
				}
				return false;
			});
			if (hasMissingAnswer) {
				this.$_Msg('필수항목을 체크해주세요.');
				return;
			}

			let surveyQuestionsAnswer = this.formData.surveyQuestions.map(item => ({
				id: item.answerId,
				questionId: item.id,
				answer: String(item.answer),
			}));
			let params = {
				scheId: this.reqParams.data.id,
				sheetId: this.reqParams.data.sheetId,
				agtId: this.reqParams.data.agtId,
				surveyQuestionsAnswer: surveyQuestionsAnswer,
			};

			const payload = {
				actionName: 'EWM_PERSONAL_SURVEY_SAVE',
				data: params,
				loading: true,
			};

			if (await this.$_Confirm('설문작성 정보를 저장하시겠습니까?')) {
				const res = await this.CALL_EWM_API(payload);
				if (isSuccess(res)) {
					this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
					this.$router.push({ path: '/ewm/personal/survey' });
				} else {
					this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
				}
			} else {
				return false;
			}
		},
		/** @description: 설문작성 취소 메서드 */
		onCancelFormData() {
			this.$router.push({ path: '/ewm/personal/survey' });
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		createdData() {
			this.reqParams = this.$store.getters.getDetailParams;
			if (!this.reqParams) {
				this.$_goPrePage();
				return;
			}
			this.selectDataList();
		},
		/** @description: 라이프사이클 mounted시 호출되는 메서드 */
		mountedData() {},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>

<style scoped>
.ui-glid-box {
	padding: 20px 20px 20px 20px;
}

.ui-glid-box > div.fl,
.ui-glid-box > div.fr {
	border-right: 0;
}

.table_form td > div {
	display: inline-block;
	vertical-align: middle;
}
.table_form td .empty-box {
	width: 10px;
}
.tableThi {
	border: 1px solid #ced4da;
	border-radius: 40px;
	padding: 10px 0px 10px 20px;
}

.itemAdd {
	text-align: center;
}
</style>
