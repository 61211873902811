import EUCInfoPushHistory from '@/pages/euc/info-push/history';
import EUCInfoPushHistoryChart from '@/pages/euc/info-push/history/chart.vue';

export default function InfoPushHistoryRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/history',
            name: 'EUCInfoPushHistory',
            components: { default: EUCInfoPushHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/history/chart',
            name: 'EUCInfoPushHistoryChart',
            components: { default: EUCInfoPushHistoryChart, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}