import EUCGroup from '@/pages/euc/device/group.vue';

export default function GroupRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/group',
            name: 'EUCGroup',
            components: { default: EUCGroup, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}