<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px pb-0 mb-0">
          <div class="flex space-x-2 inner items-center">
            <div>기준년월 (최대 1년)</div>
            <DxDateBox
                styling-mode="outlined"
                width="100"
                height="30"
                v-model="searchType.customTypes.startDt"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy-MM"
                date-out-of-range-message="시작일은 종료일보다 작거나 같아야 합니다."
                :max="searchType.customTypes.endDt"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
                @value-changed="onDayStartChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div>~</div>
            <DxDateBox
                styling-mode="outlined"
                width="100"
                height="30"
                v-model="searchType.customTypes.endDt"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy-MM"
                date-out-of-range-message="종료일은 시작일보다 크거나 같아야 합니다."
                :min="searchType.customTypes.startDt"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                zoomLevel: 'year',
                minZoomLevel: 'year',
                maxZoomLevel: 'year',
              }"
                @value-changed="onDayEndChanged"
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="pl-2">대상자 선택 (최대 {{ popupOptions.maximumSelectionLength }}명)</div>
            <DxTextBox styling-mode="filled" :width="200" :read-only="true" v-model="firstTarget">
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="대상자는 필수입니다." />
              </DxValidator>
            </DxTextBox>
            <DxButton icon="search" text="" class="btn-search" type="button" :height="30" @click="onOpen" />
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
          </div>
        </div>
      </div>

      <div class="flex flex-col gap-y-6 divide-y">
        <div class="flex gap-4 mt-4">
          <div class="w-5/6 border">
            <DxChart id="performResultChart" :data-source="performResultChart.dataSource">
              <DxSize :height="350" />
              <DxMargin :top="14" :bottom="14" :left="14" :right="14" />
              <DxCommonSeriesSettings type="line" argument-field="scheYmd" />
              <DxCommonAxisSettings>
                <DxGrid :visible="true" />
              </DxCommonAxisSettings>
              <DxSeries
                  v-for="(perform, index) in performResultChart.series"
                  :key="index"
                  :value-field="perform.agtid"
                  :name="perform.agtNm"
                  :ignore-empty-points="true"
              >
                <DxLabel :visible="false" />
                <DxPoint :size="10" />
              </DxSeries>
              <DxMargin :bottom="0" />
              <DxArgumentAxis :allow-decimals="false" :axis-division-factor="60">
                <DxLabel>
                  <DxFormat type="decimal" />
                </DxLabel>
              </DxArgumentAxis>
              <DxLegend vertical-alignment="bottom" horizontal-alignment="center" item-text-position="bottom" />
              <DxExport :enabled="false" />
              <DxTooltip :enabled="true" />
            </DxChart>
          </div>
          <div class="w-1/6">
            <DxDataGrid
                :data-source="getEventDivisions"
                ref="eventType"
                :allow-column-resizing="true"
                :column-resizing-mode="'nextColumn'"
                :show-borders="false"
                :show-column-headers="true"
                :show-column-lines="true"
                :show-row-lines="true"
                :row-alternation-enabled="false"
                :hover-state-enabled="true"
                :word-wrap-enabled="true"
                :no-data-text="this.$_lang('CMN_NO_DATA')"
                width="100%"
                @selection-changed="onSelectionChanged"
            >
              <DxSelection mode="multiple" show-check-boxes-mode="always" />

              <DxColumn
                  caption="이벤트구분"
                  alignment="center"
                  :allowEditing="false"
                  :allow-sorting="false"
                  :calculate-cell-value="rowData => `${rowData.eventDivisionDescription}`"
              />
              <!--
            <DxColumn
                caption="이벤트구분"
                data-field="performEvalDivision"
                :allowEditing="false"
                :calculate-cell-value="getPerformEvalDivision"
            />
            -->
            </DxDataGrid>
          </div>
        </div>

        <div>
          <DxDataGrid
              ref="metricsResultGrid"
              :data-source="metricsResultGrid.dataSource"
              :allow-column-resizing="true"
              :column-resizing-mode="'nextColumn'"
              :show-borders="false"
              :show-column-headers="true"
              :show-column-lines="true"
              :show-row-lines="true"
              :row-alternation-enabled="false"
              :hover-state-enabled="true"
              :word-wrap-enabled="true"
              :no-data-text="this.$_lang('CMN_NO_DATA')"
              height="calc(100vh - 720px)"
              @exporting="onExportingCheck"
              @row-click="metricsResultGridRowClick"
              @toolbar-preparing="onToolbarPreparing"
          >
            <DxFilterRow
                :visible="true"
                :operationDescriptions="{
                contains: '포함',
              }"
            />
            <DxHeaderFilter :visible="true" />
            <DxLoadPanel :enabled="true" />
            <DxScrolling mode="standard" />
            <DxColumn
                caption="기준년월"
                data-field="scheYmd"
                alignment="center"
                width="140"
                :allow-editing="false"
                :allow-sorting="true"
                :calculate-cell-value="rowData => formatDate(rowData.scheYmd, 'YYYY-MM-DD', 'YYYY-MM')"
            >
            </DxColumn>
            <DxColumn
                caption="상담사"
                data-field="agtid"
                alignment="center"
                width="200"
                :allow-editing="false"
                :allow-sorting="true"
                :calculate-cell-value="rowData => `${rowData.agtNm}[${rowData.agtid}]`"
            >
            </DxColumn>

            <DxColumn
                caption="면담"
                :visible="isInterViewVisible"
                width="80"
                data-field="isInterview"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
                :calculate-cell-value="getIsInterview"
            />

            <DxColumn
                caption="코칭"
                :visible="isCoachingVisible"
                width="80"
                data-field="isCoaching"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
                :calculate-cell-value="getIsCoaching"
            />

            <DxColumn
                caption="교육"
                :visible="isEduVisible"
                width="80"
                data-field="isEdu"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
                :calculate-cell-value="getIsEdu"
            />

            <DxColumn
                caption="시험"
                :visible="isExamVisible"
                width="80"
                data-field="isExam"
                alignment="center"
                :allow-editing="false"
                :allow-sorting="false"
                :calculate-cell-value="getIsExam"
            />

            <DxColumn
                caption="성과계획명"
                data-field="scheNm"
                alignment="left"
                :allow-editing="false"
                :allow-header-filtering="false"
                :allow-sorting="true"
            />
            <DxColumn
                caption="실제점수(총점)"
                data-field="totalScore"
                alignment="center"
                width="140"
                :allow-editing="false"
                :allow-header-filtering="false"
                :allow-sorting="true"
                :calculate-display-value="
                rowData => {
                  return `${rowData.totalScore}(${rowData.totalMerticsScore})`;
                }
              "
            />
            <DxColumn
                caption="환산점수(100점)"
                data-field="totalConvertScore"
                alignment="center"
                width="140"
                :allow-editing="false"
                :allow-header-filtering="false"
                :allow-sorting="true"
            />
            <DxColumn
                caption="점수변화"
                data-field="gap"
                alignment="center"
                width="140"
                :allow-editing="false"
                :allow-header-filtering="false"
                :allow-sorting="true"
                cell-template="gapTemplate"
            />
            <DxExport
                v-if="showActionButtons.excel"
                :enabled="showActionButtons.excel.enabled === undefined ? true : showActionButtons.excel.enabled"
                :allow-export-selected-data="
                  showActionButtons.excel.allowExportSelectedData === undefined ? true : showActionButtons.excel.allowExportSelectedData
                "
                :texts="showActionButtons.excel.exportButtonText ? showActionButtons.excel.exportButtonText : exportButtonText"
            />
            <template #gapTemplate="{ data }">
              <div v-if="data.value > 0" class="text-red-500">+{{ data.value }}</div>
              <div v-else-if="data.value < 0" class="text-blue-500">
                {{ data.value }}
              </div>
              <div v-else>
                {{ data.value }}
              </div>
            </template>
          </DxDataGrid>
        </div>
      </div>

      <modal-add-agent
          :isOpen="popupOptions.visible"
          :selectedIdList="popupOptions.selectedIdList"
          :maximumSelectionLength="popupOptions.maximumSelectionLength"
          saveBtnTxt="선택"
          @closeModal="onClose(false)"
          @saveModal="onSelectedTargets"
      />

      <DxPopup
          v-model="modal.isOpened"
          :show-title="true"
          :title="modal.initData ? modal.initData.title : null"
          :width="modal.initData ? modal.initData.width : null"
          :height="modal.initData ? modal.initData.height : null"
          :drag-enabled="true"
          :resize-enabled="true"
          :show-close-button="false"
          :hide-on-outside-click="false"
          :visible="modal.isOpened"
          @hiding="isOpenModal(false)"
      >
        <template #content>
          <div>
            <component
                ref="reasonModalRef"
                v-if="modal.sendData"
                :is="modal.currentComponent"
                :modalData="modal.sendData"
                :isModal="modal.isModal"
            />
          </div>
        </template>

        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
          width: '120',
          height: '40',
          onClick: () => this.isOpenModal(false),
        }"
        />
        <DxToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="center"
            :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          width: '120',
          height: '40',
          onClick: () => this.onDownloadReason(),
        }"
        />
      </DxPopup>
    </div>
  </div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import { DxDateBox } from 'devextreme-vue/date-box';
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import validationEngine from 'devextreme/ui/validation_engine';

import { DxColumn, DxDataGrid, DxFilterRow, DxHeaderFilter, DxLoadPanel, DxScrolling, DxSelection, DxExport }
  from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import {
  DxArgumentAxis,
  DxChart,
  DxCommonAxisSettings,
  DxCommonSeriesSettings,
  DxFormat,
  DxGrid,
  DxLabel,
  DxLegend,
  DxMargin,
  DxPoint,
  DxSeries,
  DxSize,
  DxTooltip,
} from 'devextreme-vue/chart';
import { formatDate, getPastFromToday, isSuccess, setCalculateDate } from '@/utils/common-lib';
import ModalAddAgent from '@/components/ewm/hr/modal-add-agent.vue';
import ModalDownloadReason from '@/components/common/esp-modal-download-reason.vue';
import {DxPopup, DxToolbarItem} from "devextreme-vue/popup";


let vm = null;

export default {
  name: 'EWMMetricsResult',
  components: {
    DxPopup, DxToolbarItem,
    DxButton,
    DxDateBox,
    DxTextBox,
    DxRequiredRule,
    DxValidator,
    DxColumn,
    DxDataGrid,
    DxFilterRow,
    DxHeaderFilter,
    DxSelection,
    DxLoadPanel,
    DxScrolling,
    DxChart,
    DxMargin,
    DxSeries,
    DxSize,
    DxArgumentAxis,
    DxCommonSeriesSettings,
    DxCommonAxisSettings,
    DxExport,
    DxGrid,
    DxLabel,
    DxLegend,
    DxPoint,
    DxTooltip,
    DxFormat,
    ModalAddAgent,
    ModalDownloadReason
  },
  props: {},
  watch: {},
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      isInterViewVisible: false,
      isCoachingVisible: false,
      isEduVisible: false,
      isExamVisible: false,
      firstTarget: null,
      targets: [],
      popupOptions: {
        visible: false,
        selectedIdList: [],
        maximumSelectionLength: 10,
      },
      searchType: {
        customTypes: {
          startDt: getPastFromToday(11, 'months'),
          endDt: getPastFromToday(0, 'months'),
        },
      },
      metricsResultGrid: {
        dataSource: null,
        selectedRowsData: [],
        selectedRowKeys: [],
      },
      performEvalDivision: {
        dataSource: null,
      },
      performResultChart: {
        dataSource: [],
        series: [],
      },
      showActionButtons: {
        excel:{
          enabled: true,
          allowExportSelectedData: false
        },
        customButtons: [],
      },
      dataGrid: {
        excel: {
          title: '상담사성과 추이보고서',
          // autoFilterEnabled: true,
        },
        columns: [
          {
            caption: "기준년월",
            dataField: "scheYmd",
            alignment: "center",
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          },
          {
            width: "140"
          }
        ],
      },
      exportButtonText: {
        //엑셀 다운로드 정보
        exportTo: '엑셀다운로드',
        exportAll: '전체 다운로드',
        exportSelectedRows: '선택한 데이터 다운로드',
      },
    };
  },
  computed: {
    getEventDivisions() {
      return [
        { eventDivisionDescription: '면담' },
        { eventDivisionDescription: '코칭' },
        { eventDivisionDescription: '교육' },
        { eventDivisionDescription: '시험' },
      ];
    },
  },
  methods: {
    formatDate,
    getMinStartDt() {
      const endDt = formatDate(this.searchType.customTypes.endDt, 'YYYYMMDD', 'YYYY-MM-DD');
      const minStartDt = setCalculateDate('month', endDt, -12);
      return minStartDt;
    },
    getMaxEndDt() {
      const strDt = formatDate(this.searchType.customTypes.startDt, 'YYYYMMDD', 'YYYY-MM-DD');
      const maxEndDt = setCalculateDate('month', strDt, 12);
      return maxEndDt;
    },
    getIsCoaching(rowData) {
      const { isCoaching } = rowData;
      return isCoaching == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
    },
    getIsEdu(rowData) {
      const { isEdu } = rowData;
      return isEdu == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
    },
    getIsExam(rowData) {
      const { isExam } = rowData;
      return isExam == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
    },
    getIsInterview(rowData) {
      const { isInterview } = rowData;
      return isInterview == this.$_enums.common.stringUsedFlag.YES.value ? 'O' : '';
    },
    getMetricsResultGridInstance() {
      return this.$refs.metricsResultGrid.instance;
    },
    onSelectionChanged(e) {
      /* if (this.targets.length === 0) {
      this.$_Msg('대상자를 선택해 주세요.');
      e.component.deselectRows(e.currentSelectedRowKeys);
      return;
    } */

      const visibilityMap = {
        면담: 'isInterViewVisible',
        코칭: 'isCoachingVisible',
        교육: 'isEduVisible',
        시험: 'isExamVisible',
      };

      const setVisibility = (rows, isVisible) => {
        rows.forEach(row => {
          const key = visibilityMap[row.eventDivisionDescription];
          if (key) {
            vm[key] = isVisible;
          }
        });
      };

      setVisibility(e.selectedRowsData, true);
      setVisibility(e.currentDeselectedRowKeys, false);
    },
    metricsResultGridRowClick(e) {
      this.$_dxUtil.setGridSingleSelection(e);
    },
    getChartData(items) {
      return items.map(item => {
        const { totalConvertScore, scheYmd } = item;
        return {
          [item.agtid]: totalConvertScore,
          scheYmd: scheYmd,
        };
      });
    },
    getSeries() {
      return this.targets.map(target => {
        return {
          agtid: target.agtid,
          agtNm: target.agtNm,
        };
      });
    },
    convertScore(score, maxScore, newMaxScore = 100) {
      return Math.round((score / maxScore) * newMaxScore);
    },
    async onSearch() {
      if (!validationEngine.validateGroup('validationSearch').isValid) {
        return;
      }

      const selectedDivisionRows = this.$refs.eventType.instance.getSelectedRowsData();
      const oneYearAgo = setCalculateDate('year', formatDate(vm.searchType.customTypes.endDt, 'YYYYMMDD', 'YYYY-MM-DD'), -1);
      const validStrDate = setCalculateDate('day', oneYearAgo, -1);
      const cvtStrDate = new Date(formatDate(vm.searchType.customTypes.startDt, 'YYYYMMDD', 'YYYY-MM-DD'));

      if (validStrDate > cvtStrDate) {
        this.$_Msg('최대 1년간 검색이 가능합니다.');
        return;
      }

      /* if (this.targets.length === 0) {
      this.$_Msg('대상자를 선택해 주세요.');
      return;
    } */

      let agtids = [];
      this.targets.forEach(target => {
        agtids.push(target.agtid);
      });

      const payload = {
        actionName: 'EWM_PERFORMANCE_REPORT_SELECT',
        data: {
          startDt: vm.searchType.customTypes.startDt,
          endDt: vm.searchType.customTypes.endDt,
          agtids: agtids,
          performEvalDivisions: selectedDivisionRows.map(select => select.performEvalDivision),
        },
        loading: false,
      };

      const res = await this.CALL_EWM_API(payload);

      if (isSuccess(res)) {
        const items = res.data.data;

        const groupedItems = items.reduce((acc, item) => {
          const key = `${item.agtid}-${item.scheYmd}`;

          if (!acc[key]) {
            acc[key] = { ...item, totalScoreSum: 0, totalMerticsScoreSum: 0 };
          }
          //acc[key].totalScore += item.totalScore;
          //acc[key].totalMerticsScore += item.totalMerticsScore;
          acc[key].totalScoreSum += item.totalScore;
          acc[key].totalMerticsScoreSum += item.totalMerticsScore;
          acc[key].totalConvertScore = vm.convertScore(acc[key].totalScoreSum, acc[key].totalMerticsScoreSum);

          return acc;
        }, {});

        const aggregatedData = Object.values(groupedItems);

        const groupedById = aggregatedData.reduce((acc, item) => {
          if (!acc[item.agtid]) {
            acc[item.agtid] = [];
          }
          acc[item.agtid].push(item);
          return acc;
        }, {});

        for (let agtid in groupedById) {
          groupedById[agtid].sort((a, b) => new Date(a.scheYmd) - new Date(b.scheYmd));
        }

        const gapResultArray = aggregatedData.map(item => {
          const group = groupedById[item.agtid];
          const index = group.findIndex(i => i.scheYmd === item.scheYmd);
          let gap = null;

          if (index > 0) {
            const previousScore = group[index - 1].totalConvertScore;
            gap = item.totalConvertScore - previousScore;
          }

          return { ...item, gap };
        });

        const result = gapResultArray.reduce((acc, item) => {
          const key = `${item.agtid}-${item.scheYmd}`;
          acc[key] = item;
          return acc;
        }, {});

        items.map(item => {
          const key = `${item.agtid}-${item.scheYmd}`;

          //item.totalScore = result[key].totalScore;
          //item.totalMerticsScore = result[key].totalMerticsScore;
          item.totalConvertScore = result[key].totalConvertScore;
          item.gap = result[key].gap;

          return item;
          //return { ...item, score: groupedItems[key].score };
        });

        // binding chart-grid
        const charts = vm.getChartData(gapResultArray);
        this.$set(this.performResultChart, 'dataSource', charts);

        // binding chart series
        let chartSeries = vm.getSeries();
        this.$set(this.performResultChart, 'series', chartSeries);

        // binding data-grid
        //const tableDataGrid = vm.getTableDataGrid(items);
        this.$set(this.metricsResultGrid, 'dataSource', items);
      }
    },
    onOpen() {
      this.popupOptions.visible = true;
      this.popupOptions.selectedIdList = [];
    },
    onClose() {
      this.popupOptions.visible = false;
      this.popupOptions.selectedIdList = [];
    },
    onSelectedTargets(selDataList) {
      if (!selDataList || selDataList.length === 0) return;

      this.firstTarget = `${selDataList[0].agtNm}[${selDataList[0].agtid}] 외 ${selDataList.length - 1}명`;

      this.targets = selDataList.map(item => ({
        deptNmPath: item.deptNmPath,
        agtid: item.agtid,
        agtNm: item.agtNm,
        regId: this.$store.getters.getLoginId,
        regNm: this.$store.getters.getLoginNm,
        regDt: new Date(),
      }));

      this.popupOptions.visible = false;
    },
    onDayStartChanged(e) {
      this.searchType.customTypes.startDt = e.value.slice(0, 6) + '01';
    },
    onDayEndChanged(e) {
      this.searchType.customTypes.endDt = e.value.slice(0, 6) + '29';
    },
    /** @description : 커스텀이벤트의 object key 값 체크 */
    checkObjKeyByCustomEvent(objectKey) {
      if (!this.dataGrid || typeof this.dataGrid !== 'object') {
        return false; // dataGrid 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
      }

      const customEvent = this.dataGrid.customEvent;
      if (typeof customEvent !== 'object') {
        return false; // customEvent 가 undefined 이거나 null, 또는 객체가 아닐 시, false 반환
      }

      const hasObjectKey = Object.prototype.hasOwnProperty.call(customEvent, objectKey);
      return hasObjectKey ? customEvent[objectKey] : false; // objectKey가 customEvent에 존재하면 그 값을 반환하고, 그렇지 않으면 false 반환
    },
    makeSaveHistory(event, reason = '') {
      const user = {
        userNo: this.$store.getters.getUserInfo?.userNo || this.$store.getters.getLoginId,
        userNm: this.$store.getters.getUserInfo?.userNm || this.$store.getters.getLoginNm,
        deptNm: this.$store.getters.getUserInfo?.deptNm || '',
        gradeNm: this.$store.getters.getUserInfo?.gradeNm || '',
      };

      const payload = {
        actionName: 'FILE_DOWNLOAD_HISTORY_UPDATE',
        data: [
          {
            ...user,
            reason,
            fileType: 'EXCEL',
            fileNm: this.dataGrid.excel?.title,
          },
        ],
        loading: false,
      };

      event.onSaveHistory = async fileNm => {
        payload.data[0].fileNm = fileNm || this.dataGrid.excel?.title;
        return await this.CALL_API(payload);
      };
      return event;
    },

    onExportingCheck(e) {
      const useDownReason = this.$_getSystemData('use_excel_download_reason')?.configValue === 'Y';
      console.log(useDownReason)
      e = this.makeSaveHistory(e);
      if (useDownReason) {
        e.cancel = true;
        this.onOpenModal(
            'ModalDownloadReason',
            {
              title: this.$_lang('COMPONENTS.DOWNLOAD_REASON', {defaultValue: '다운로드 사유'}),
              width: '600',
              height: '400',
            },
            e,
        );
      } else {
        this.onExporting(e);
      }
    },
    /** @description: 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
        this.modal.contentData = null;
      }
    },
    /** @description: 팝업 창 열때 이벤트 */
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
      this.modal.initData = componentInitData; //set init modal templet
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    /**
     * @description: 엑셀 다운로드 사유 입력 모달 이벤트
     */
    onDownloadReason() {
      let event = this.modal.sendData;
      const reason = this.$refs.reasonModalRef.reason;
      if (reason.trim() === '') {
        this.$_Msg(
            this.$_lang('COMMON.MESSAGE.REQUIRED_DOWNLOAD_REASON', {
              defaultValue: '다운로드 사유를 입력하세요.',
            }),
        );
        event.cancel = true;
      } else {
        event = this.makeSaveHistory(event, reason);
        event.cancel = false;
        this.onExporting(event);
        this.isOpenModal(false);
      }
    },
    /** @description: 엑셀 다운로드 이벤트 */
    onExporting(e) {
      if (this.checkObjKeyByCustomEvent('exporting')) {
        //커스텀시 해당 페이지의 이벤트 호출
        // 커스텀 시 파일 다운로드 이력은 직접 구현해야함. 성공 유무를 확인할 수 없음
        // e.onSaveHistory();
        this.$emit('exporting', e);
      } else {
        //Grid Excel Export
        const title = this.dataGrid.excel.title;
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet(title);

        //Excel Width 값 설정 dataGrid.excel.cellwidth 값에 따라 결정(없으면 Default : 30)
        let columnsArr = [];
        this.dataGrid.columns.forEach(() => {
          columnsArr.push({width: this.dataGrid.excel.cellwidth ? this.dataGrid.excel.cellwidth : 30});
        });
        worksheet.columns = columnsArr;

        let today = formatDate(new Date(), 'YYYYMMDDHHmmss', 'YYYYMMDDHHmmss');

        exportDataGrid({
          component: e.component,
          worksheet: worksheet,
          keepColumnWidths: false,
          autoFilterEnabled: this.dataGrid.excel.autoFilterEnabled ? this.dataGrid.excel.autoFilterEnabled : false, //자동필터 설정 여부
          topLeftCell: {row: 4, column: 1},
          customizeCell: ({gridCell, excelCell}) => {
            if (gridCell.rowType === 'header') {
              //header 영역 설정
              excelCell.fill = {type: 'pattern', pattern: 'solid', fgColor: {argb: 'C6EFCE'}};
              excelCell.alignment = {horizontal: 'center', vertical: 'middle'};
            } else {
              //data 영역 배경색 설정
              if (excelCell.fullAddress.row % 2 === 0) {
                excelCell.fill = {
                  type: 'pattern',
                  pattern: 'solid',
                  fgColor: {argb: 'F2F2F2'},
                  bgColor: {argb: 'F2F2F2'},
                };
              }
            }

            const borderStyle = {style: 'thin', color: {argb: 'FF7E7E7E'}};
            excelCell.border = {
              bottom: borderStyle,
              left: borderStyle,
              right: borderStyle,
              top: borderStyle,
            };
          },
        })
            .then(() => {
              const titleRow = worksheet.getRow(2);
              titleRow.height = 40;
              if (e.format === 'xlsx') {
                worksheet.mergeCells(2, 1, 2, this.dataGrid.columns.length);
              }
              titleRow.getCell(1).value = title;
              titleRow.getCell(1).font = {size: 22, bold: true};
              titleRow.getCell(1).alignment = {horizontal: 'center', vertical: 'middle'};

              const hearderRow = worksheet.getRow(4);
              hearderRow.height = 30;
            })
            .then(() => {
              let fileName;
              if (e.format === 'csv') {
                fileName = `${title}_${today}.csv`;
                workbook.csv.writeBuffer().then(buffer => {
                  saveAs(new Blob([buffer], {type: 'text/csv'}), fileName);
                });
              } else {
                fileName = `${title}_${today}.xlsx`;
                workbook.xlsx.writeBuffer().then(buffer => {
                  saveAs(new Blob([buffer], {type: 'application/octet-stream'}), fileName);
                });
              }
              return fileName;
            })
            .then(fileName => {
              // 다운로드 이력 저장
              e.onSaveHistory(fileName);
            });
        e.cancel = true;
      }
    },
    /** @description: 그리드 상단 툴바 버튼 관련 이벤트 */
    onToolbarPreparing(e) {
      if (this.checkObjKeyByCustomEvent('toolbarPreparing')) {
        //커스텀시 해당 페이지의 이벤트 호출
        this.$emit('toolbar-preparing', e);
      } else {
        const toolbarItems = e.toolbarOptions.items;
        let vm = this;

        if (this.showActionButtons?.excel) {
          toolbarItems.forEach(d => {
            if (d.name === 'exportButton') {
              d.options.icon = 'export';
              d.location = 'before';
              d.sortIndex = 100;
              if (this.showActionButtons?.csv) {
                e.format = 'csv';
                d.options.items = d.options.items.concat([
                  {
                    icon: 'txtfile',
                    text: `csv ${this.$_lang('COMPONENTS.DOWNLOAD')}`,
                    onClick: () => {
                      this.onExportingCheck(e);
                    },
                  },
                ]);
              }
            }
          });
        }
      }
    },
    async initCodeMap() {
    },
    async createdData() {
      vm = this;
      await this.initCodeMap().then(() => {
      });
    },
  },
  created() {
    this.createdData();
  },
  mounted() {
  },
};
</script>
