<!--
  PACKAGE_NAME : src\pages\cc\emc\config\skl.vue
  FILE_NAME : skl
  AUTHOR : ycnam
  DATE : 2024-11-11
  DESCRIPTION : EMC 상담그룹 설정
-->
<template>
  <div>
    <div class="page-sub-box locker_setting_list sub_new_style01 sub_ui_box1">
      <div class="cusmain-table-wrap">
        <esp-dx-data-grid
          :data-grid="dataGrid"
          ref="emcSkl"
          @init-new-row="onInitNewRow"
          @saving="onSaveData"
        ></esp-dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import {isSuccess} from "@/utils/common-lib";
import CustomStore from "devextreme/data/custom_store";
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid.vue";

export default {
  name: 'EMCSkillGroupConfig',
  components: {
    EspDxDataGrid,
  },
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_EMC_API',
        refName: 'emcSkl',
        keyExpr: 'skillId',
        allowColumnResizing: true, //컬럼 사이즈 허용
        allowReordering: true,//inline속성 느낌
        showBorders: false, //border 유무
        showColumnHeaders: true, //컬럼 헤더 유무
        showColumnLines: false, //컬럼 세로선 유무
        showRowLines: true, //컬럼 가로선 유무
        rowAlternationEnabled: false,
        repaintChangesOnly: true,
        dataSourceDefaultSortColumn: '+skillId',
        dataSource: [],
        apiActionNm: {
          select: 'EMC_SKL_LIST',
          update: 'EMC_SKL_MERGE',
          delete: 'EMC_SKL_DELETE',
        },
        customEvent: {
          initNewRow: true,          
          saving: true,
          reorder: true,
        },
        showActionButtons: {
          select: false,
          update: true,
          delete: true,
          customButtons: [],
        },
        grouping: {
          contextMenuEnabled: false,
          autoExpandAll: false,
          allowCollapsing: true,
          expandMode: 'rowClick' // rowClick or buttonClick
        },
        groupPanel: {
          visible: false,
        },
        columnChooser: {
          enabled: false, // 컬럼 Chooser 버튼 사용유무
        },
        loadPanel: {
          enabled: true, // 로딩바 표시 유무
        },
        sorting: {
          mode: 'multiple' // single multiple
        },
        remoteOperations: { // 서버사이드 여부
          filtering: false,
          sorting: false,
          grouping: false,
          paging: false,
        },
        paging: { // scrolling 미사용시만 적용됨
          enabled: true,
          pageSize: 10,//page.size,
          pageIndex: 0 // 시작페이지
        },
        pager: {
          visible: true, //페이저 표시 여부
          showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
          allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
          displayMode: "compact", //표시 모드 : ['full', 'compact']
          showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
          showNavigationButtons: true //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
        },
        filterRow: {
          visible: true,
        },
        headerFilter: {
          visible: true,
        },
        editing: {
          allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
          allowDeleting: false,
          allowAdding: true,
          changes: [],//null값으로 두면 에러남
          mode: 'batch', //수정 모드: ['row', 'cell', 'batch'] - batch에서는 inserted 필요없음
        },
        selecting: {
          mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
          selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
          showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
        },
        columns: [
          {
            caption: '스킬',
            i18n: 'EMC.WORD.SKL',
            dataField: 'skillName',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            fixed: true,
            requiredRule: {
              messege: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: this.$_lang('EMC.WORD.SKL', {defaultValue: '스킬'})}),
            },            
          },             
          {
            caption: '스킬ID',
            i18n: 'EMC.WORD.SKL_ID',
            dataField: 'skillId',
            alignment: 'center', // left center right
            visible: true,
            allowEditing: true,
            sortOrder: 'none', // asc desc none
            allowHeaderFiltering: false,
            allowGrouping: false,
            fixed: true, // 컬럼 fix 시 사용
            requiredRule: {
              messege: this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', {value: this.$_lang('EMC.WORD.SKL', {defaultValue: '스킬'})}),
            },              
          },          
          {
            caption: '타입',
            i18n: 'EMC.WORD.TYPE',
            dataField: 'contactType',
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            fixed: true,
          },       
          {
            caption: '우선순위',
            i18n: 'EMC.WORD.PRIORITY',
            dataField: 'priority',
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            fixed: true,
          },       
          {
            caption: '스킬명',
            i18n: 'EMC.WORD.SKL_NAME',
            dataField: 'description',
            height: 40,
            alignment: 'center',
            visible: true,
            allowEditing: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
            allowGrouping: false,
            fixed: true,
          },
          {
            caption: '등록자',
            i18n: 'COMPONENTS.REGISTRANT',
            dataField: 'regId',
            alignment: 'center', // left center right
            visible: true,
            sortOrder: 'none', // acs desc none
            allowHeaderFiltering: false,
            fixed: false, // 컬럼 fix 시 사용
            allowEditing: false,
            fixedPosition: 'center', // left or right
          },
          {
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
            dataField: 'editId',
            alignment: 'center',
            visible: true,
            sortOrder: 'none',
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '등록시간',
            i18n: 'COMPONENTS.REGISTRATION_DATE_TIME',
            dataField: 'regDate',
            alignment: 'center',
            visible: true,
            sortOrder: 'none',
            allowEditing: false,
            allowHeaderFiltering: false,
          },
          {
            caption: '수정시간',
            i18n: 'COMPONENTS.MODIFY_DATE_TIME',
            dataField: 'editDate',
            alignment: 'center',
            visible: true,
            sortOrder: 'none',
            allowHeaderFiltering: false,
          },    
        ]
      },
    }
  },
  computed: {},
  methods: {
    /** @description: 그리드 행 추가시 초기 설정 */
    onInitNewRow(e) {
      e.data.viewFl = 'Y';
      e.data.regId = this.$store.getters.getLoginId;
    },    
    /** @description: 데이터 조회 메서드 */
    async selectDataList(sort = '+skillId') {
      const vm = this;

      vm.dataGrid.dataSource = new CustomStore({
        key: 'skillId',
        async load(loadOptions) {
          const params = vm.$_getDxDataGridParam(loadOptions);

          if (!params.sort) {
            params.sort = sort;
          }

          let payload = {
            actionName: 'EMC_SKL_LIST',
            data: params,
            useErrorPopup: true,
          }

          const res = await vm.CALL_EMC_API(payload);

          let rtnData = {
            data: [],
            totalCount: 0,
          }
          if (isSuccess(res)) {
            rtnData = {
              data: res.data.data,//.filter( d => d.id),
              totalCount: res.data.header.totalCount,
            }
            vm.$refs.emcSkl.totalCount = rtnData.totalCount;
          }
          return rtnData;
        }
      });
    },
    /** @description: 데이터 저장 메서드 */
    async onSaveData(e) {
      e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

      // 변경된 값이 없으면 메시지 출력
      if (e?.changes.length === 0) {
        this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', {defaultValue: '변경된 데이터가 없습니다.'}));
      }

      let data = [];

      e.changes.forEach(d => {
        let dataKey = d.key; // ex) 1
        let dataMap = d.data; // ex) { value: 100, name: 'test' }
        d.data.memberId = this.$store.getters.getLoginId;

        //표시여부
        if (d.data?.viewFl) {
          d.data.viewFl = d.data.viewFl === 'Y' ? 'Y' : 'N';
        }

        // 수정/신규/병합 타입 확인 후 데이터 맵에 등록자/수정자 정보 추가
        if (d.type === 'update') {

          // 병합은 ROW 전체 데이터로 dataMap 설정
          e.component
            .byKey(dataKey)
            .then(data => {
              dataMap = Object.assign(data, d.data); // Object.assign() 으로 기존 데이터에 변경된 데이터를 덮어씌움
            })
            .catch(error => {
              this.$log.error(error);
            });
        } else { //추가

        }

        data.push(dataMap); // ex) [{ id: 1, value: 100, name: 'test' }]
      });

      const payload = {
        actionName: 'EMC_SKL_MERGE',
        data: data,
      }

      const res = await this.CALL_EMC_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        e.component.cancelEditData();
        this.refreshData();
      }
    },
    /** @description : 그리드 refesh 메서드 */
    refreshData() {
      this.$refs[this.dataGrid.refName].refreshData();
    },
    /** @description: 표시여부 출력 */
    viewFl(rowData) {
      return Number(rowData.viewFl) === 'Y';
    },
  },
  created() {
    this.selectDataList();
  },
}
</script>