<!--
  PACKAGE_NAME : src/pages/euc/device/setting/
  FILE_NAME : index.vue
  AUTHOR : jhsim
  DATE : 2024-04-19
  DESCRIPTION :
-->
<template>
  <div class="main">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <template v-for="(item, index) in getComponent">
        <Tab :title="item.title" :key="`Tab${index}`">
          <div v-if="tabIndex === index" class="locker_setting_list sub_new_style01 sub_ui_box1">
            <div class="cusmain-table-wrap">
              <component :is="item.component" :cmList="cmList"/>
            </div>
          </div>
        </Tab>
      </template>
    </Tabs>
  </div>
</template>
<script>
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import HuntList from '@/pages/euc/device/hunt/hunt-list.vue';
import HuntManage from '@/pages/euc/device/hunt/hunt-manage.vue';
import {isSuccess} from '@/utils/common-lib';

export default {
  name: 'EUCHunt',
  components: {
    Tabs,
    Tab,
    HuntList,
    HuntManage,
  },
  props: {},
  watch: {},
  data() {
    return {
      tabIndex: 0,
      cmList: [],
      tabs: {
        selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
      },
      tabItems: [
        {
          title: '헌트 관리',
          component: HuntManage,
          vender: ['CISCO'],
        },
        {
          title: '헌트 정보',
          component: HuntList,
          vender: ['CISCO'],
        },
      ],
    };
  },
  computed: {
    getVender() {
      return this.$_getSystemData('vender')?.configValue || 'CISCO';
    },
    getComponent() {
      return this.tabItems.filter(item => item.vender.includes(this.getVender));
    },
  },
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
    async selectCmList(sort = '+cmOrd') {
      const payload = {
        actionName: 'EUC_CM_SELECT',
        data: {
          sort,
          viewFl: 'Y'
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.cmList = res.data.data?.map((item) => {
          return {
            cmNm: item.cmNm,
            cmOrd: item.cmOrd,
            description: item.description,
            editId: item.editId,
            editDt: item.editDt,
            regId: item.regId,
          };
        });
      }
    },
  },
  async created() {
    await this.selectCmList();
  },
};
</script>

<style scoped></style>
