<template>
	<div>
		<div class="page-sub-box tree-box tree-contents pad_bo80">
			<div class="pt-6 flex gap-x-8 justify-start">
				<div style="font-size: 16px">평가표명: {{ this.evalSheetInfo.sheetNm }}</div>
				<div style="font-size: 16px">평가이력: {{ this.evalSheetInfo.useFl === 'Y' ? '사용' : '미사용' }}</div>
			</div>

			<div class="head-btn-box01m mar_to20">
				<div class="head-btn-left">
					<div>
						<DxButton
							text="상위분류추가"
							type="button"
							class="btn_XS default filled add1"
							:height="30"
							:disabled="evalSheetInfo.useFl === 'Y'"
							@click="addQuestionGroup(1)"
						/>
					</div>
					<div>
						<DxButton
							template="<span class='mdi mdi-folder-open'></span>"
							type="button"
							hint="목록 펼치기"
							class="btn_XS white outlined"
							:height="30"
							@click="onOpenTree"
						/>
						<DxButton
							template="<span class='mdi mdi-folder'></span>"
							type="button"
							hint="목록 접기"
							class="btn_XS white outlined"
							:height="30"
							@click="onFoldTree"
						/>
						<DxButton
							template="<span class='mdi mdi-chevron-double-up'></span>"
							:height="30"
							type="button"
							hint="맨 위로 이동"
							class="btn_XS white outlined"
							:disabled="evalSheetInfo.useFl === 'Y'"
							@click="onSetOrder(0)"
						/>
						<DxButton
							template="<span class='mdi mdi-chevron-up'></span>"
							:height="30"
							type="button"
							hint="위로 이동"
							class="btn_XS white outlined"
							:disabled="evalSheetInfo.useFl === 'Y'"
							@click="onSetOrder(1)"
						/>
						<DxButton
							template="<span class='mdi mdi-chevron-down'></span>"
							:height="30"
							type="button"
							hint="아래로 이동"
							class="btn_XS white outlined"
							:disabled="evalSheetInfo.useFl === 'Y'"
							@click="onSetOrder(2)"
						/>
						<DxButton
							template="<span class='mdi mdi-chevron-double-down'></span>"
							:height="30"
							type="button"
							hint="맨 아래로 이동"
							class="btn_XS white outlined"
							:disabled="evalSheetInfo.useFl === 'Y'"
							@click="onSetOrder(3)"
						/>
						<DxButton
							template="순서 저장"
							:height="30"
							type="button"
							class="btn_XS white outlined"
							:disabled="evalSheetInfo.useFl === 'Y'"
							@click="onSaveSort"
						/>
					</div>
				</div>
			</div>
			<div class="layout-cut-box clearfix tree-box00 pt-1 overflow-auto" style="height: calc(100vh - 370px)">
				<div class="layout-cut-left tree-box01 fl">
					<div class="tree-grid">
						<DxTreeList
							id="evalQuestionGrid"
							ref="evalQuestionGrid"
							:data-source="tree.evalQuestionList"
							:expanded-row-keys="tree.expandedRowKeys"
							:selected-row-keys="tree.selectedRowkeys"
							:root-value="null"
							key-expr="id"
							parent-id-expr="parentId"
							:auto-expand-all="true"
							:show-column-headers="true"
							:show-row-lines="true"
							:show-column-lines="true"
							:show-borders="true"
							:column-auto-width="true"
							:on-row-click="onClickRow"
							@selection-changed="onSelectionChanged"
							:no-data-text="this.$_lang('CMN_NO_DATA')"
							width="100%"
							height="calc(100vh - 373px)"
						>
							<DxEditing
								:allow-updating="false"
								:allow-deleting="false"
								:allow-adding="false"
								:confirm-delete="false"
								mode="row"
							/>

							<DxLoadPanel :enabled="true" />

							<DxRowDragging
								alignment="center"
								:allow-drop-inside-item="false"
								:allow-reordering="true"
								drag-direction="vertical"
								:on-drag-change="onDragChange"
								:on-reorder="onReorder"
								:show-drag-icons="true"
								:width="55"
							/>

							<DxScrolling mode="standard" />
							<DxSelection mode="single" />

							<DxColumn caption="평가표구성" data-field="questionGroupNm" :allowEditing="false" :allow-sorting="false" />
							<DxColumn caption="문항" data-field="questionNm" :allowEditing="false" :allow-sorting="false" />
							<DxColumn
								caption="배점"
								data-field="questionScore"
								:allowEditing="false"
								:allow-sorting="false"
								alignment="center"
							/>
							<DxColumn
								caption=""
								data-field="id"
								cell-template="removeTemplate"
								:allowEditing="false"
								:allow-sorting="false"
								alignment="center"
								:visible="true"
								:width="45"
							/>

							<template #removeTemplate="{ data }">
								<DxButton
									text=""
									class="btn-icon trash"
									:disabled="evalSheetInfo.useFl === 'Y'"
									@click="onDeleteData($event, data)"
								/>
							</template>
						</DxTreeList>
					</div>
				</div>

				<div class="layout-cut-right tree-box02 fr" style="flex-wrap: nowrap; height: calc(100vh - 370px)">
					<template v-if="evalQuestionData.formData.questionTypeCd === 1149 || evalQuestionData.formData.questionTypeCd === 1150">
						<table class="table_form line-bin">
							<colgroup>
								<col style="width: 120px" />
								<col style="width: auto" />
							</colgroup>
							<thead class="sub_title_txt inner-header">
								<tr>
									<td colspan="2"><h2>평가표 정보</h2></td>
								</tr>
							</thead>
							<tbody>
								<tr v-if="evalQuestionData.formData.questionTypeCd === 1150">
									<th scope="row"><label for="label2">상위구성</label> <span class="icon_require">필수항목</span></th>
									<td>
										<DxSelectBox
											:data-source="
												tree.evalQuestionList.filter(c =>
													evalQuestionData.formData.questionTypeCd === 1150
														? c.questionTypeCd == 1149
														: c.questionTypeCd == 1150,
												)
											"
											:input-attr="{ 'aria-label': '상위구성' }"
											:styling-mode="config.stylingMode"
											v-model="evalQuestionData.formData.parentId"
											display-expr="questionGroupNm"
											value-expr="id"
											placeholder="상위구성 선택"
											:read-only="evalSheetInfo.useFl === 'Y' ? true : false"
											:width="250"
											class="mar_ri10"
										>
											<DxValidator>
												<DxRequiredRule message="상위구성은 필수입니다." />
											</DxValidator>
										</DxSelectBox>
									</td>
								</tr>
								<tr>
									<th scope="row"><label for="label3">구성명</label> <span class="icon_require">필수항목</span></th>
									<td>
										<DxTextBox
											placeholder="구성명을 입력해주세요."
											v-model="evalQuestionData.formData.questionNm"
											:max-length="evalQuestionData.limitLength.questionNm"
											:show-clear-button="true"
											:styling-mode="config.stylingMode"
											:width="250"
											class="mar_ri10"
										>
											<DxValidator>
												<DxRequiredRule message="구성명은 필수입니다." />
											</DxValidator>
										</DxTextBox>
										{{
											evalQuestionData.formData.questionNm != null ? evalQuestionData.formData.questionNm.length : 0
										}}/{{ evalQuestionData.limitLength.questionNm }}자
									</td>
								</tr>
								<tr v-if="evalSheetInfo.useFl === 'N'">
									<th scope="row"><label for="label3">평가표구성</label></th>
									<td>
										<div
											class="center"
											v-if="evalQuestionData.formData.questionTypeCd === 1149"
											style="margin-bottom: 1em"
										>
											<DxButton
												text="하위분류추가"
												:visible="true"
												type="button"
												class="btn_XS white light_filled add2"
												:height="30"
												@click="addQuestionGroup(2)"
											/>
										</div>

										<div class="center" v-if="evalQuestionData.formData.questionTypeCd !== 1151">
											<DxButton
												text="문항추가"
												:visible="true"
												type="button"
												class="btn_XS white light_filled add2"
												:height="30"
												@click="addQuestion"
											/>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<div class="center mar_to20">
							<DxButton
								text="구성저장"
								:width="120"
								:height="40"
								class="default filled txt_S medium"
								:use-submit-behavior="false"
								:disabled="evalSheetInfo.useFl === 'Y'"
								@click="onSaveFormData($event)"
							/>
						</div>
					</template>
					<template v-else-if="evalQuestionData.formData.questionTypeCd === 1151">
						<table class="table_form line-bin">
							<colgroup>
								<col style="width: 120px" />
								<col style="width: auto" />
							</colgroup>
							<thead class="sub_title_txt inner-header">
								<tr>
									<td colspan="2"><h2>문항 정보</h2></td>
								</tr>
							</thead>
							<tbody>
								<tr>
									<th scope="row"><label for="label3">구성</label></th>
									<td>
										<span>{{ evalQuestionData.questionGroupPath }}</span>
									</td>
								</tr>
								<tr>
									<th scope="row"><label for="label2">문항명</label> <span class="icon_require">필수항목</span></th>
									<td>
										<DxTextBox
											placeholder="문항을 입력해주세요."
											v-model="evalQuestionData.formData.questionNm"
											:max-length="evalQuestionData.limitLength.questionNm"
											:show-clear-button="true"
											:styling-mode="config.stylingMode"
											:width="'80%'"
											class="mar_ri10"
										>
											<DxValidator>
												<DxRequiredRule message="문항은 필수입니다." />
											</DxValidator>
										</DxTextBox>
										{{
											evalQuestionData.formData.questionNm !== null ? evalQuestionData.formData.questionNm.length : 0
										}}/{{ evalQuestionData.limitLength.questionNm }}자
									</td>
								</tr>
								<tr>
									<th scope="row"><label for="label3">배점</label></th>
									<td>
										<span>{{ evalQuestionData.formData.questionScore }}</span>
									</td>
								</tr>
							</tbody>
						</table>

						<div class="page-bin lin1 mar_to20 mar_b10"></div>

						<div class="sub_title_txt clearfix">
							<h2 style="padding-left: 3px; padding-top: 5px; padding-bottom: 10px" class="fl">보기 정보</h2>
							<div class="fr">
								<DxButton
									text="보기추가"
									type="button"
									class="btn_XS white light_filled add2"
									:height="30"
									:disabled="evalSheetInfo.useFl === 'Y'"
									@click="addQuestionOption"
								/>
							</div>
						</div>
						<div style="overflow: auto; height: 480px; border-top: 1px solid #efefef; border-bottom: 1px solid #efefef">
							<table class="table_list">
								<colgroup>
									<col style="width: 70px" />
									<col style="width: auto" />
									<col style="width: 120px" />
									<col style="width: 70px" />
								</colgroup>
								<thead class="inner-header">
									<tr>
										<th scope="col">No</th>
										<th scope="col">문항 <span class="icon_require">필수항목</span></th>
										<th scope="col">배점 <span class="icon_require">필수항목</span></th>
										<th scope="col"></th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(questionOption, idx) in evalQuestionData.questionOptions"
										:key="questionOption.id"
										:style="questionOption.delFl === 'Y' ? 'background:#E0E0E0;' : ''"
									>
										<td>{{ idx + 1 }}</td>
										<td>
											<DxTextBox
												placeholder="보기를 입력해주세요."
												v-model="questionOption.questionNm"
												:max-length="evalQuestionData.limitLength.questionNm"
												:show-clear-button="true"
												:styling-mode="config.stylingMode"
												:width="'80%'"
												class="mar_ri10"
											>
												<DxValidator>
													<DxRequiredRule message="보기는 필수입니다." />
												</DxValidator>
											</DxTextBox>
											{{ questionOption.questionNm !== null ? evalQuestionData.formData.questionNm.length : 0 }}/{{
												evalQuestionData.limitLength.questionNm
											}}자
										</td>
										<td>
											<DxNumberBox
												v-model="questionOption.questionScore"
												format="#,##0"
												:min="0"
												:max="100"
												:setp="1"
												:show-spin-buttons="true"
												:input-attr="{ 'aria-label': '배점' }"
												@value-changed="changeQuestionScore($event)"
											>
												<DxValidator>
													<DxRequiredRule message="배점은 필수입니다.[0~100]" />
												</DxValidator>
											</DxNumberBox>
										</td>
										<td>
											<DxButton
												text=""
												:class="`btn-icon ${
													questionOption.delFl === 'Y' ? 'return' : 'trash'
												} dx-button dx-button-normal dx-button-mode-contained dx-widget`"
												:disabled="evalSheetInfo.useFl === 'Y'"
												@click="removeQuestionOption(idx)"
											/>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div class="center mar_to20">
							<DxButton
								text="문항저장"
								:width="120"
								:height="40"
								class="default filled txt_S medium"
								:use-submit-behavior="false"
								:disabled="evalSheetInfo.useFl === 'Y'"
								@click="onSaveFormData($event)"
							/>
						</div>
					</template>

					<template v-else>
						<div class="noformdata">평가표 구성을 선택하세요.</div>
					</template>
				</div>
			</div>
		</div>

		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<h2 class="hidden">일반 버튼</h2>
				<div class="bottom-btn-wrap">
					<DxButton text="닫 기" class="btn_XS white filled txt_S medium" :width="120" :height="40" @click="onCancelFormData" />
				</div>
			</div>
		</section>

		<DxPopup
			:show-title="true"
			:title="modal.initData ? modal.initData.title : null"
			:min-width="modal.initData ? modal.initData.width : null"
			:width="modal.initData ? modal.initData.width : null"
			:min-height="modal.initData ? modal.initData.height : null"
			:height="modal.initData ? modal.initData.height : null"
			:drag-enabled="true"
			:resize-enabled="true"
			:show-close-button="true"
			:hide-on-outside-click="false"
			v-model="modal.isOpened"
			:visible="modal.isOpened"
			@hiding="isOpenModal(false)"
		>
			<template #content>
				<div>
					<component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData"></component>
				</div>
			</template>

			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.hasOwnProperty('save')
							: !modal.initData.buttons.hasOwnProperty('save')
						: false
				"
				:options="{
					elementAttr: {
						class: 'default filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('save')
							? modal.initData.buttons.save.text
							: ''
						: '',
					//type: 'default',
					width: '120',
					height: '40',
					useSubmitBehavior: true,
					onClick: e => {
						onConfirmModal(e);
					},
				}"
			/>
			<DxToolbarItem
				widget="dxButton"
				toolbar="bottom"
				location="center"
				:visible="
					modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.hasOwnProperty('cancel')
							: !modal.initData.buttons.hasOwnProperty('cancel')
						: false
				"
				:options="{
					elementAttr: {
						class: 'white filled txt_S medium',
					},
					text: modal.initData.hasOwnProperty('buttons')
						? modal.initData.buttons.hasOwnProperty('cancel')
							? modal.initData.buttons.cancel.text
							: ''
						: '',
					width: '120',
					height: '40',
					onClick: () => {
						isOpenModal(false);
					},
				}"
			/>
		</DxPopup>
	</div>
</template>

<script>
import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';

import {
	DxDataGrid,
	DxColumn,
	DxPaging,
	DxPager,
	DxEditing,
	DxLoadPanel,
	DxScrolling,
	DxSelection,
	DxColumnChooser,
	DxColumnFixing,
} from 'devextreme-vue/data-grid';

import { DxTreeList, DxRowDragging } from 'devextreme-vue/tree-list';
import { DxValidator, DxRequiredRule, DxAsyncRule } from 'devextreme-vue/validator';

import { DxButton } from 'devextreme-vue/button';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxTextBox } from 'devextreme-vue/text-box';

import ModalQuestionlType from '@/pages/ewm/evaluation/setting/sheet/modal-question-type.vue';
import {cloneObj, isSuccess} from "@/utils/common-lib";

let vm = null;

export default {
  name: 'EWMEvaluationSheetRegister',
	components: {
		DxPopup,
		DxPosition,
		DxToolbarItem,
		DxColumn,
		DxColumnChooser,
		DxColumnFixing,
		DxDataGrid,
		DxEditing,
		DxLoadPanel,
		DxPager,
		DxPaging,
		DxRowDragging,
		DxScrolling,
		DxSelection,
		DxTreeList,
		DxValidator,
		DxRequiredRule,
		DxAsyncRule,
		DxTextBox,
		DxButton,
		DxNumberBox,
		DxSelectBox,
		ModalQuestionlType,
	},
	props: {},
	watch: {},
	data() {
		return {
			config: {
				stylingMode: 'outlined', //[outlined, filled, underlined]
			},
			modal: {
				isOpened: false,
				currentComponent: null,
				initData: {},
				contentData: null,
			},
			evalSheetInfo: {
				sheetId: null,
				sheetNm: null,
				useFl: null,
			},
			evalQuestionData: {
				level: null,
				questionGroupPath: null,
				formData: {
					id: null,
					sheetId: null,
					questionNm: '',
					questionTypeCd: null,
					parentId: null,
					questionGroupNm: null,
					questionOrder: null,
					questionScore: null,
					delFl: 'N',
					regId: null,
					regDt: null,
					editId: null,
					editDt: null,
				},
				defaultData: {
					id: null,
					sheetId: null,
					questionNm: '',
					questionTypeCd: null,
					parentId: null,
					questionGroupNm: null,
					questionOrder: null,
					questionScore: null,
					delFl: 'N',
					regId: null,
					regDt: null,
					editId: null,
					editDt: null,
				},
				questionOptions: [],
				limitLength: {
					questionGroupNm: 85,
					questionNm: 85,
				},
			},
			tree: {
				evalQuestionList: [],
				expandedRowKeys: [],
				selectedRowkeys: [],
				selectedRowsData: [],
				sortList: [],
				createdRowKeys: [],
			},
		};
	},
	computed: {
		getEvalQuestionGridInstance: () => {
			return vm.$refs['evalQuestionGrid'].instance;
		},
	},
	methods: {
		/** @description : 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
		isOpenModal(isOpened) {
			this.modal.isOpened = isOpened;
			if (!isOpened) {
				this.modal.currentComponent = null;
				this.modal.initData = {};
			}
		},
		/** @description : 팝업 창 열때 이벤트 */
		onOpenModal(componentNm, componentInitData, propsData) {
			this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
			this.modal.initData = componentInitData; //set init modal templet
			this.modal.sendData = propsData;

			this.isOpenModal(true);
		},
		/** @description : 모달 팝업 저장 버튼 이벤트 */
		async onConfirmModal(e) {
			//click modal save btn : set formData get modal body slot componet data
			//this.$log.debug(this.modalContentData);
			//해당 모달 컴포넌트에서 데이터 저장
			let promise = new Promise((resolve, reject) => {
				this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
			});

			promise
				.then(res => {
					// use res
					if (isSuccess(res)) {
						this.tree.createdRowKeys = res.data.data;
						this.createdData();
					} else {
						this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
					}
				})
				.catch(err => {
					// handle error
					this.$_Toast(this.$_lang(`CMN_ERROR:${err}`));
				});
		},
		/** @description: 상위/하위분류 추가 모달 팝업 */
		addQuestionGroup(level) {
      // FIXME : 하드코딩, EWM U_CODE 하드코딩
			const popData = {};

			if (level === 1) {
				const questionOrder = Math.max(
					...this.tree.evalQuestionList.filter(z => z.questionTypeCd === 1149).map(z => z.questionOrder),
					...[0],
				);

				popData.title = '상위분류 등록';
				popData.props = {
					sheetId: this.evalSheetInfo.sheetId,
					parentId: null,
					questionTypeCd: 1149,
					questionOrder: questionOrder + 1,
				};
			} else {
				const questionOrder = Math.max(
					...this.tree.evalQuestionList
						.filter(z => z.questionTypeCd === 1150 && z.parentId === this.evalQuestionData.formData.id)
						.map(z => z.questionOrder),
					...[0],
				);

				popData.title = '하위분류 등록';
				popData.props = {
					sheetId: this.evalSheetInfo.sheetId,
					parentId: this.evalQuestionData.formData.id,
					questionTypeCd: 1150,
					questionOrder: questionOrder + 1,
					questionParentGroup: this.tree.evalQuestionList.filter(z => z.questionTypeCd === 1149),
				};
			}

			this.onOpenModal(
				'ModalQuestionlType',
				{
					title: popData.title,
					buttons: {
						save: { text: '저장' },
						cancel: { text: '취소' },
					},
					width: '550',
					height: '320',
				},
				popData.props,
			);
		},
		/** @description: 트리 노드 펼치기 */
		onOpenTree() {
			const rootIds = this.tree.evalQuestionList.map(d => d.id);
			this.tree.expandedRowKeys = rootIds;
		},
		/** @description: 트기 노드 닫기 */
		onFoldTree() {
			this.tree.expandedRowKeys = [];
		},
		/** @description: 트리 노드 순서 변경 */
		onSetOrder(type) {
			const selectedRowsData = this.tree.selectedRowsData;

			if (selectedRowsData.length < 1) {
				return this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
			} else if (selectedRowsData.length > 1) {
				return this.$_Msg(this.$_lang('CMN_ONE_SELECT'));
			}

			let selectedRowData = selectedRowsData[0];
			// 0 :first 1:up 2: down 3:last 3
			let item = this.tree.evalQuestionList.find(d => d.id === selectedRowData.id),
				groupList,
				preItemCopy,
				nextItemCopy,
				preItemIndex,
				preItem,
				nextItemIndex,
				nextItem,
				realPreItemIndex,
				realNextItemIndex,
				lastItemIndex;
			const itemIndex = this.tree.evalQuestionList.indexOf(item);

			switch (type) {
				case 0:
					preItemCopy = JSON.parse(JSON.stringify(this.tree.evalQuestionList)).find(
						d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId,
					);

					if (preItemCopy) {
						let firstItemIndex = this.tree.evalQuestionList.findIndex(d => d.id === preItemCopy.id);

						this.tree.evalQuestionList.splice(itemIndex, 1);
						this.tree.evalQuestionList.splice(firstItemIndex, 0, item);
					}
					break;

				case 1:
					groupList = JSON.parse(JSON.stringify(this.tree.evalQuestionList)).filter(
						d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId,
					);
					preItemIndex = groupList.findIndex(d => d.id === selectedRowData.id) - 1;

					if (0 > preItemIndex) return;

					preItem = groupList[preItemIndex];

					realPreItemIndex = this.tree.evalQuestionList.findIndex(d => d.id === preItem.id);
					this.tree.evalQuestionList.splice(itemIndex, 1);
					this.tree.evalQuestionList.splice(realPreItemIndex, 0, item);

					break;
				case 2:
					groupList = JSON.parse(JSON.stringify(this.tree.evalQuestionList)).filter(
						d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId,
					);
					nextItemIndex = groupList.findIndex(d => d.id === selectedRowData.id) + 1;

					if (groupList.length - 1 < nextItemIndex) return;

					nextItem = groupList[nextItemIndex];

					realNextItemIndex = this.tree.evalQuestionList.findIndex(d => d.id === nextItem.id);
					this.tree.evalQuestionList.splice(itemIndex, 1);
					this.tree.evalQuestionList.splice(realNextItemIndex, 0, item);

					break;
				case 3:
					nextItemCopy = JSON.parse(JSON.stringify(this.tree.evalQuestionList))
						.reverse()
						.find(d => d.depth === selectedRowData.depth && d.parentId === selectedRowData.parentId);

					if (nextItemCopy) {
						lastItemIndex = this.tree.evalQuestionList.findIndex(d => d.id === nextItemCopy.id);
						this.tree.evalQuestionList.splice(itemIndex, 1);
						this.tree.evalQuestionList.splice(lastItemIndex + 1, 0, item);
					}

					break;
				default:
					break;
			}

			//순서 처리 및 세팅
			this.setSort(this.tree.evalQuestionList, selectedRowData);
		},
		/** @description: 순서 처리 및 세팅 */
		setSort(list, targetData) {
			//순서 처리
			let cloneTreeList = cloneObj(list);
			//해당 depth의 순서 처리
			let sortTreeList = cloneTreeList
				.filter(d => d.parentId === targetData.parentId)
				.map((item, index) => {
					item.index = index + 1;
					return item;
				});
			//this.tree.sortList에 변경된 순서 담기
			sortTreeList.forEach(item => {
				let index = this.tree.sortList.findIndex(el => el.id === item.id);
				if (index !== -1) {
					//이미 한번 순서를 변경했으면 기존꺼를 변경
					this.tree.sortList[index] = item;
				} else {
					//순서를 변경한적이 없으면 추가
					this.tree.sortList.push(item);
				}
			});
		},
		/** @description: 순서 저장 메서드 */
		onSaveSort() {
			//변경된 순서 데이터의 order 설정
			this.tree.sortList.forEach(item => {
				item.questionOrder = item.index;
			});
			this.saveOrder(this.tree.sortList);
		},
		/** @description: 드래그 이벤트 */
		onDragChange(e) {
			this.tree.selectedRowkeys = [];
			const visibleRows = e.component.getVisibleRows();
			const sourceNode = e.component.getNodeByKey(e.itemData.id);
			let targetNode = visibleRows[e.toIndex].node;

			while (targetNode && targetNode.data) {
				if (targetNode.data.id === sourceNode.data.id) {
					e.cancel = true;
					break;
				}
				targetNode = targetNode.parent;
			}
		},
		/** @description: 드래그 순서 변경 이벤트  */
		onReorder(e) {
			let visibleRows = e.component.getVisibleRows(),
				sourceData = e.itemData,
				targetData = visibleRows[e.toIndex].node.data,
				treeList = [...this.tree.evalQuestionList];
			if (e.dropInsideItem) {
				e.itemData.Head_ID = targetData.id;
				e.component.refresh();
			} else {
				let sourceIndex = treeList.indexOf(sourceData),
					targetIndex = treeList.indexOf(targetData);

				if (sourceData.Head_ID !== targetData.Head_ID) {
					sourceData.Head_ID = targetData.Head_ID;
					if (e.toIndex > e.fromIndex) {
						targetIndex++;
					}
				}

				treeList.splice(sourceIndex, 1);
				treeList.splice(targetIndex, 0, sourceData);
				this.tree.evalQuestionList = treeList;

				//순서 처리 및 세팅
				this.setSort(treeList, sourceData);
			}
		},
		/** @description : 트리 로우 데이터 클릭 시 셀렉션 변경 이벤트 */
		onSelectionChanged(e) {
			if (e.selectedRowsData.length < 1) {
				return;
			}

			const data = e.selectedRowsData[0];
			this.tree.selectedRowkeys = [data.id];
			this.tree.selectedRowsData = [data];

			this.evalQuestionData.formData = cloneObj(data);
			this.evalQuestionData.questionOptions = [];
			this.evalQuestionData.clickedRowData = cloneObj(data);

      // FIXME : 하드코딩, EWM U_CODE 하드코딩
			if (this.evalQuestionData.formData.questionTypeCd === 1149 || this.evalQuestionData.formData.questionTypeCd === 1150) {
				this.evalQuestionData.formData.questionNm = this.evalQuestionData.formData.questionGroupNm;
			} else if (this.evalQuestionData.formData.questionTypeCd === 1151) {
				this.selectOptionDataList();
			}
		},
		/** @description : 트리 로우 데이터 클릭 이벤트 */
		onClickRow(row) {
			let parentCodeNmArr = this.getAllParentNode(row.node, [])
				.map(d => d.questionGroupNm)
				.reverse();

			if (parentCodeNmArr.length > 0) {
				this.evalQuestionData.questionGroupPath = this.toStringParentCodeNmArr(parentCodeNmArr);
        // FIXME : 하드코딩, EWM U_CODE 하드코딩
				if (row.data.questionTypeCd !== 1151) {
					this.evalQuestionData.questionGroupPath += ` > ${row.data.questionGroupNm}`;
				}
			} else {
				this.evalQuestionData.questionGroupPath = row.data.questionGroupNm;
			}
		},
		/** @description : 부모 노드 가져오는 메서드 */
		getAllParentNode(obj, parentNodeArr) {
			for (let key in obj) {
				if (key === 'parent') {
					//if( obj[key].hasOwnProperty('parent') ){
					if (Object.prototype.hasOwnProperty.call(obj[key], 'parent')) {
						parentNodeArr.push(obj[key].data);
						this.getAllParentNode(obj[key], parentNodeArr);
					}
				}
			}
			return parentNodeArr;
		},
		/** @description : 상위코드 이름 배열 사이에 > 붙여서 스트링으로 변환 */
		toStringParentCodeNmArr(arr) {
			return arr.join(' > ');
		},
		/** @description : 문항 추가 */
		addQuestion() {
			const parentId = this.evalQuestionData.formData.id;

			this.evalQuestionData.formData = cloneObj(this.evalQuestionData.defaultData);
			this.evalQuestionData.formData.sheetId = this.evalSheetInfo.sheetId;
			this.evalQuestionData.formData.questionTypeCd = 1151;
			this.evalQuestionData.formData.parentId = parentId;
			this.evalQuestionData.formData.questionOrder =
				Math.max(...this.tree.evalQuestionList.filter(z => z.parentId === parentId).map(z => z.questionOrder), ...[0]) + 1;
			this.evalQuestionData.formData.delFl = 'N';
		},
		/** @description : 문항 보기 추가 */
		addQuestionOption() {
			const addOption = cloneObj(this.evalQuestionData.defaultData);

			addOption.sheetId = this.evalSheetInfo.sheetId;
			addOption.questionTypeCd = 1198;
			addOption.parentId = this.evalQuestionData.formData.id;
			addOption.questionOrder = Math.max(...this.evalQuestionData.questionOptions.map(z => z.questionOrder), ...[0]) + 1;
			addOption.questionScore = 0;
			addOption.delFl = 'N';

			this.evalQuestionData.questionOptions.push(addOption);
		},
		/** @description : 문항 보기 삭제 설정 */
		removeQuestionOption(idx) {
			if (this.evalQuestionData.questionOptions[idx].id == null) {
				this.evalQuestionData.questionOptions.splice(idx, 1);
			} else {
				this.evalQuestionData.questionOptions[idx].delFl = this.evalQuestionData.questionOptions[idx].delFl === 'Y' ? 'N' : 'Y';
			}

			this.changeQuestionScore();
		},
		/** @description : 문항 배점 설정 */
		changeQuestionScore() {
			const questionScores = this.evalQuestionData.questionOptions
				.filter(x => x.questionScore >= 0 && x.delFl === 'N')
				.map(x => x.questionScore);
			const maxQuestionScore = Math.max(...questionScores, ...[0]);

			this.evalQuestionData.formData.questionScore = maxQuestionScore >= 0 ? maxQuestionScore : 0;
		},
		/** @description : 트리 데이터 가져오는 메서드 */
		async selectDataList(sort = '+id') {
			let paramsData = { sheetId: this.evalSheetInfo.sheetId, questionTypeCd: '1149,1150,1151' };
			paramsData.sort = sort;

			let payload = {
				actionName: 'EWM_EVALUATION_SHEET_QUESTION_LIST',
				data: paramsData,
				loading: false,
			};

			let res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
        this.tree.evalQuestionList = res.data.data;

        this.onOpenTree();

        this.getEvalQuestionGridInstance.clearSelection();
        this.tree.selectedRowkeys = this.tree.createdRowKeys;
			}
		},
		/** @description : 문항 데이터 조회 */
		async selectOptionDataList(sort = '+id') {
			let paramsData = {
				sheetId: this.evalQuestionData.formData.sheetId,
				parentId: this.evalQuestionData.formData.id,
				questionTypeCd: 1198,
			};
			paramsData.sort = sort;

			let payload = {
				actionName: 'EWM_EVALUATION_SHEET_QUESTION_LIST',
				data: paramsData,
				loading: false,
			};

			let res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
        this.evalQuestionData.questionOptions = res.data.data;
			}
		},
		/** @description: 데이터 저장하는 메서드 */
		async onSaveFormData(e) {
			if (!e.validationGroup.validate().isValid) {
				return false;
			}

			if (!(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' })))) {
				return false;
			}

			const data = this.evalQuestionData.formData;
			data.editId = this.$store.getters.getLoginId;
			data.questionOptions = this.evalQuestionData.questionOptions;

			let payload = {
				actionName: 'EWM_EVALUATION_SHEET_QUESTION_SAVE',
				data: data,
				loading: true,
			};

			let res = await this.CALL_EWM_API(payload);

			if (isSuccess(res)) {
        this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));

        this.tree.createdRowKeys = res.data.data;
        await this.createdData();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description: 저장 메서드 */
		async saveOrder(dataList) {
			if (!(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' })))) {
				return false;
			}

			let data = dataList.map(z => ({ id: z.id, questionOrder: z.questionOrder }));

			let payload = {
				actionName: 'EWM_EVALUATION_SHEET_QUESTION_ORDER_UPDATE',
				data: { data: data },
				loading: true,
			};

			let res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
				this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
				this.createdData();
			}
		},
		/** @description : 트리 로우 데이터 삭제 메서드 */
		async onDeleteData(e, data) {
			let msgContents;
			if (data.row.node.children.length > 0) {
				msgContents = `삭제 시 하위 구성이 모두 일괄 삭제됩니다.<br/>${this.$_lang('CMN_CFM_DELETE')}`;
			} else {
				msgContents = this.$_lang('CMN_CFM_DELETE');
			}

			let childrenArr = this.getChildrenById([data.row.node], data.row.node.key);

			let deletedIds = [childrenArr.id, ...childrenArr.childs];

			let payload;
			let res;

			if (!(await this.$_Confirm(msgContents))) {
				return;
			}

			payload = {
				actionName: 'EWM_EVALUATION_SHEET_QUESTION_DELETE',
				data: deletedIds,
				loading: true,
			};

			res = await this.CALL_EWM_API(payload);
			if (isSuccess(res)) {
        this.tree.createdRowKeys = [];
        this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
        await this.createdData();
			} else {
				this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
			}
		},
		/** @description: id로부터 자식 obj 배열을 가져오는 메서드  */
		getChildrenById(arr, key) {
			return findId(arr, key);

			function findId(arr, key) {
				let output = {};
				arr.forEach(o => {
					if (o.data.id === key) {
						output.id = o.data.id;
						output.childs = (o.children && getArrayOfChildren(o.children, [])) || [];
					} else if (o.children && o.children.length) {
						output = findId(o.children, o.data.id);
					}
				});

				return output;
			}

			function getArrayOfChildren(arr, existingChildren) {
				arr.forEach(o => {
					existingChildren.push(o.data.id);
					o.children && getArrayOfChildren(o.children, existingChildren);
				});

				return existingChildren;
			}
		},
		/** @description : 닫기 버튼 클릭 메서드 */
		onCancelFormData() {
			this.$router.push('/ewm/evaluation/setting/sheet');
			//this.createdData();
		},
		/** @description: 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			vm = this;

			this.evalQuestionData.formData = cloneObj(this.evalQuestionData.defaultData);
			this.isOpenModal(false);

			this.reqParams = this.$store.getters.getDetailParams;
			if (!this.reqParams) {
				this.$_goPrePage();
				return;
			}
			this.evalSheetInfo = this.reqParams;
			await this.selectDataList();
		},
	},
	created() {
		this.createdData();
	},
	mounted() {},
};
</script>

<style scoped>
main .bottom-btn-box {
	position: absolute !important;
	margin-left: 0;
}

.tree-grid {
	min-width: 400px;
	max-width: 700px;
}

.center {
	margin: 0 auto;
}
</style>
