import ESPAuthList from '@/pages/esp/auth/list.vue';

export default function AuthRoutes(commonComponents) {
  return [
    {
      path: '/esp/auth/list',
      name: 'ESPAuthList',
      components: { default: ESPAuthList, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
