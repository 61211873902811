import ReportWizardReportList from '@/pages/report/config/wizard/list';
import ReportWizardReportDetail from '@/pages/report/config/wizard/detail';
import ReportXmlConfig from '@/pages/report/config/query-management';
import ReportQueryExecutePage from '@/pages/report/config/select';
import ReportDashboardWidgetConfig from '@/pages/report/config/dash-board';

export default function ConfigRoutes(commonComponents) {
  return [
    {
      path: '/report/config/wizard/list',
      name: 'ReportWizardReportList',
      components: { default: ReportWizardReportList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/config/wizard/detail',
      name: 'ReportWizardReportDetailNew',
      components: { default: ReportWizardReportDetail, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/report/config/wizard/detail/:id',
      name: 'ReportWizardReportDetail',
      components: { default: ReportWizardReportDetail, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/report/config/query-management',
      name: 'ReportXmlConfig',
      components: { default: ReportXmlConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/config/select',
      name: 'ReportQueryExecutePage',
      components: { default: ReportQueryExecutePage, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/report/config/dash-board',
      name: 'ReportDashboardWidgetConfig',
      components: { default: ReportDashboardWidgetConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
