import EWMSurveyConfig from '@/pages/ewm/surveys/implementation/config.vue';
import EWMSurveyDetail from '@/pages/ewm/surveys/implementation/detail.vue';
import EWMSurveyList from '@/pages/ewm/surveys/implementation/list.vue';

export default function SurveyRoutes(commonComponents) {
  return [
    {
      path: '/ewm/surveys/implementation/config',
      name: 'EWMSurveyConfig',
      components: { default: EWMSurveyConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/surveys/implementation/detail',
      name: 'EWMSurveyDetail',
      components: { default: EWMSurveyDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/surveys/implementation/list',
      name: 'EWMSurveyList',
      components: { default: EWMSurveyList, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}