import EUCMcid from '@/pages/euc/device/log/mcid.vue';

export default function LogRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/log/mcid',
            name: 'EUCMcid',
            components: { default: EUCMcid, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}