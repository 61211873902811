<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">시험일</div>
            <DxDateBox
              :edit-enabled="false"
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.dayStart"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy-MM-dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              :max="searchType.customTypes.dayEnd"
              maxLength="10"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <div class="mt-1">~</div>
            <DxDateBox
              :styling-mode="stylingMode"
              width="110"
              height="30"
              v-model="searchType.customTypes.dayEnd"
              type="date"
              dateSerializationFormat="yyyyMMdd"
              display-format="yyyy-MM-dd"
              dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
              maxLength="10"
              :min="searchType.customTypes.dayStart"
              invalid-date-message="입력 데이터가 유효하지 않습니다."
            >
              <DxValidator validation-group="validationSearch">
                <DxRequiredRule message="기준년월은 필수입니다." />
              </DxValidator>
            </DxDateBox>
            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxDataGrid, DxSelection, DxColumn } from 'devextreme-vue/data-grid';
  import {formatDate, getPastFromToday, isSuccess} from '@/utils/common-lib';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  const columnDataSource = {
    processCd: [
      { id: 'Y', codeNm: '제출완료' },
      { id: 'N', codeNm: '미참석' },
    ],
  };

  export default {
    name: 'EWMPersonalExam',
    components: {
      EspDxDataGrid,
      DxDateBox,
      DxButton,
      DxDataGridx: DxDataGrid,
      DxSelection,
      DxColumn,

      DxRequiredRule,
      DxValidator,
    },
    props: {},
    watch: {},
    data() {
      return {
        codes: {
          examTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          examProcessCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(11, 'months'),
            dayEnd: getPastFromToday(0, 'days'),
          },
          paramsData: null,
        },
        dataGrid: {
          keyExpr: 'scheId',
          refName: 'examProgress',
          showMenuColumns: true, //컬럼 표시
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false, //행 배경색
          hoverStateEnabled: true, //마우스커서 행 강조
          dataSource: [],
          showActionButtons: {},
          customEvent: {
            cellHoverChanged: true,
            cellPrepared: false,
          },
          callApi: 'CALL_EWM_API',
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            // 미사용시 주석처리
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            // scrolling 미사용시만 적용됨
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'No',
              dataField: 'examOrd',
              alignment: 'center',
              width: 40,
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
            },
            {
              caption: '시험구분',
              dataField: 'examSheetType',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'examSheetType'),
              lookup: {
                dataSource: this.$_enums.ewm.examSheetType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '시험계획명',
              dataField: 'scheNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '시험기간',
              dataField: 'examStartDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              width: 180,
              calculateDisplayCellValue: rowData => {
                return `${formatDate(
                  rowData.examStartDt,
                  'YYYY-MM-DDTHH:mm:ss',
                  'YYYY-MM-DD',
                )} ~ ${formatDate(rowData.examEndDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')}`;
              },
            },
            {
              caption: '시험시간',
              dataField: 'examDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              width: 120,
              calculateDisplayCellValue: rowData => {
                return `${this.formatTime(rowData.examStartTime)} ~ ${this.formatTime(rowData.examEndTime)}`;
              },
            },
            {
              caption: '이의제기기간',
              dataField: 'examFeedbackStartDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              width: 180,
              calculateDisplayCellValue: rowData => {
                return `${formatDate(
                  rowData.examFeedbackStartDt,
                  'YYYY-MM-DDTHH:mm:ss',
                  'YYYY-MM-DD',
                )} ~ ${formatDate(rowData.examFeedbackEndDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD')}`;
              },
            },
            {
              caption: '진행상태',
              dataField: 'examProcess',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'examProcess'),
              lookup: {
                dataSource: this.$_enums.ewm.examProcess.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '시험점수',
              dataField: 'totalScore',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              cellTemplate: (container, options) => {
                if (
                  options.data.examProcess == 'MARKING_COMPLETE' ||
                  options.data.examProcess == 'FEEDBACK' ||
                  options.data.examProcess == 'FEEDBACK_COMPLETE' ||
                  options.data.examProcess == 'FINISH'
                ) {
                  container.innerHTML = options.data.totalScore;
                } else {
                  container.innerHTML = ' ';
                }
              },
            },
            {
              caption: '순위',
              dataField: 'totalTarget',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              cellTemplate: (container, options) => {
                if (
                  options.data.examProcess == 'MARKING_COMPLETE' ||
                  options.data.examProcess == 'FEEDBACK' ||
                  options.data.examProcess == 'FEEDBACK_COMPLETE' ||
                  options.data.examProcess == 'FINISH'
                ) {
                  container.innerHTML = `${options.data.targetRank}/${options.data.totalTarget}`;
                } else {
                  container.innerHTML = ' ';
                }
              },
            },
            {
              caption: '참여정보',
              dataField: 'joinCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              lookup: {
                dataSource: columnDataSource.processCd,
                displayExpr: 'codeNm',
                valueExpr: 'id',
              },
            },
            {
              caption: '시험',
              dataField: 'viewResult',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowFiltering: false,
              width: 60,
              cellTemplate: (container, options) => {
                let eduButton = new DxButton({
                  propsData: {
                    text: '',
                    icon: 'search',
                    elementAttr: { class: 'white light_filled txt_S' },
                    value: options.data,
                    onClick: () => {
                      if (options.data.examProcess === 'PROGRESS') {
                        vm.onExamSheet(options.data, '시험진행');
                      } else if (options.data.examProcess === 'FEEDBACK') {
                        vm.onExamSheet(options.data, '피드백진행');
                      } else {
                        //  if (options.data.examProcess === 'FEEDBACK_COMPLETE') {
                        vm.onExamSheet(options.data, '피드백완료');
                      }
                    },
                  },
                });
                eduButton.$mount();

                if (
                  options.data.examProcess == 'PROGRESS_READY' || //대기
                  options.data.examProcess == 'COMPLETE' || //시험완료
                  options.data.examProcess == 'MARKING' || //채점진행
                  (options.data.examProcess != 'PROGRESS' && options.data.joinCd == 'N')
                ) {
                  container.innerHTML = '-';
                } else {
                  container.append(eduButton.$el);
                }
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd == 'examSheetType') {
          return this.$_enums.ewm.examSheetType.values.find(e => data.examSheetType == e.value).label;
        } else if (clsCd == 'examProcess') {
          return this.$_enums.ewm.examProcess.values.find(e => data.examProcess == e.value).label;
        }
      },
      /** @description : 시험 */
      async onExamSheet(data, string) {
        if (string === '시험진행') {
          //시험진행
          const currentDateTime = new Date();
          const examStartDateTime = new Date(data.examStartDt); //시작
          const examStartHours = Math.floor(parseInt(data.examStartTime) / 10000);
          const examStartMinutes = Math.floor(parseInt(data.examStartTime) % 10000) / 100;
          examStartDateTime.setHours(examStartHours);
          examStartDateTime.setMinutes(examStartMinutes);
          examStartDateTime.setSeconds(0);

          const examEndDateTime = new Date(data.examStartDt); //끝
          const examEndHours = Math.floor(parseInt(data.examEndTime) / 10000);
          const examEndMinutes = Math.floor(parseInt(data.examEndTime) % 10000) / 100;
          examEndDateTime.setHours(examEndHours);
          examEndDateTime.setMinutes(examEndMinutes);
          examEndDateTime.setSeconds(0);

          if (currentDateTime < examEndDateTime) {
            //아직 시험이 안끝났으면
            if (await this.$_Confirm('시험에 참석 하시겠습니까?', { title: `<h2>시험진행정보</h2>` })) {
              let params = { data: data };
              this.$store.commit('setDetailParams', params);
              if (currentDateTime < examStartDateTime) {
                await this.$router.push({path: '/ewm/personal/exam/target-sheet'});
              } else {
                await this.$router.push({path: '/ewm/personal/exam/target-exam-sheet'});
              }
            } else {
              return false;
            }
          } else {
            this.$_Msg('시험시간이 끝났습니다. 입장이 불가능합니다.', { title: `<h2>시험종료</h2>` });
          }
        } else if (string === '피드백진행') {
          let params = { data: data, string: string };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/exam/target-exam-sheet-result' });
        } else if (string === '피드백완료') {
          let params = { data: data, string: string };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/exam/target-exam-sheet-result' });
        }
      },
      onChangeSearchDay() {
        const regDt = this.searchType.customTypes.dayStart + '000000' + '~' + this.searchType.customTypes.dayEnd + '235959';
        this.searchType.paramsData = { ...this.searchType.paramsData, regDt };

        this.$_setSearchHistsCustomType(this.searchType.paramsData);
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.surDt) {
          return formatDate(rowData.surDt, 'YYYYMMDDHHmmssSSS', 'YYYY-MM-DD HH:mm:ss');
        }
      },
      formatTime(time) {
        return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-scheId') {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          key: 'scheId',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }
            vm.searchType.paramsData['frScheYmd'] = vm.searchType.customTypes.dayStart;
            vm.searchType.paramsData['toScheYmd'] = vm.searchType.customTypes.dayEnd;
            params = { ...params, ...vm.searchType.paramsData };
            const payload = {
              actionName: 'EWM_PERSONAL_EXAM',
              data: params,
              loading: false,
              useErrorPopup: true,
            };
            const res = await vm.CALL_EWM_API(payload);
            // console.log(res)
            if (isSuccess(res)) {
              let data = res.data.data;
              // data = data.filter(item => item.examProcessCd !== 1028);
              data.forEach((d, index) => {
                d.examOrd = index + 1;
                d.joinCd = d.joinFl == 0 ? 'N' : 'Y';
              });
              console.log(data);
              const rtnData = {
                data: data,
                totalCount: res.data.header.totalCount,
              };
              vm.$refs.examProgress.totalCount = rtnData.totalCount;
              return rtnData;
            } else {
              return () => {
                throw 'Data Loading Error';
              };
            }
          },
        });
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
        this.onChangeSearchDay();
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>

<style scoped>
  .sub_new_style01 .page_search_box .inner div {
    display: inline-block;
  }
  .sub_new_style01 .page_search_box .inner > div {
    vertical-align: middle;
    margin-right: 10px;
  }
  .page_search_box .inner .box-btn-search {
    margin-right: 15px;
  }
</style>
