import EUCHerSetting from '@/pages/euc/her/setting.vue';
import EUCHerHistory from '@/pages/euc/her/history.vue';

export default function HerRoutes(commonComponents) {
    return [
        {
            path: '/euc/her/setting',
            name: 'EUCHerSetting',
            components: { default: EUCHerSetting, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/her/history',
            name: 'EUCHerHistory',
            components: { default: EUCHerHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}