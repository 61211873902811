import EUCMyDevice from '@/pages/euc/user/my-device.vue';

export default function MyDeviceRoutes(commonComponents) {
    return [
        {
            path: '/euc/user/my-device',
            name: 'EUCMyDevice',
            components: { default: EUCMyDevice, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}