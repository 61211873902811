import EUCLine from '@/pages/euc/device/line';

export default function LineRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/line',
            name: 'EUCLine',
            components: { default: EUCLine, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}