import EUCInfoPushText from '@/pages/euc/info-push/text.vue';

export default function InfoPushTextRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/text',
            name: 'EUCInfoPushText',
            components: { default: EUCInfoPushText, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}