import EWMEvaluationSchedule from '@/pages/ewm/evaluation/implementation/schedule/index.vue';
import EWMEvaluationScheduleDetail from '@/pages/ewm/evaluation/implementation/schedule/detail.vue';
import EWMEvaluationScheduleRegister from '@/pages/ewm/evaluation/implementation/schedule/register.vue';
import EWMEvaluationStatus from '@/pages/ewm/evaluation/implementation/status/index.vue';
import EWMEvaluationStatusEvaluation from '@/pages/ewm/evaluation/implementation/status/evaluation.vue';
import EWMEvaluationReportDepartment from '@/pages/ewm/evaluation/report/department/index.vue';
import EWMEvaluationReportPersonal from '@/pages/ewm/evaluation/report/personal/index.vue';
import EWMEvaluationReportQuestion from '@/pages/ewm/evaluation/report/question/index.vue';
import EWMEvaluationSheetSetting from '@/pages/ewm/evaluation/setting/sheet/index.vue';
import EWMEvaluationSheetRegister from '@/pages/ewm/evaluation/setting/sheet/register.vue';

export default function EvaluationRoutes(commonComponents) {
  return [
    {
      path: '/ewm/evaluation/implementation/schedule',
      name: 'EWMEvaluationSchedule',
      components: { default: EWMEvaluationSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/implementation/schedule/detail',
      name: 'EWMEvaluationScheduleDetail',
      components: { default: EWMEvaluationScheduleDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/implementation/schedule/register',
      name: 'EWMEvaluationScheduleRegister',
      components: { default: EWMEvaluationScheduleRegister, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/implementation/status',
      name: 'EWMEvaluationStatus',
      components: { default: EWMEvaluationStatus, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/implementation/status/evaluation',
      name: 'EWMEvaluationStatusEvaluation',
      components: { default: EWMEvaluationStatusEvaluation, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/report/department',
      name: 'EWMEvaluationReportDepartment',
      components: { default: EWMEvaluationReportDepartment, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/report/personal',
      name: 'EWMEvaluationReportPersonal',
      components: { default: EWMEvaluationReportPersonal, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/report/question',
      name: 'EWMEvaluationReportQuestion',
      components: { default: EWMEvaluationReportQuestion, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/setting/sheet',
      name: 'EWMEvaluationSheetSetting',
      components: { default: EWMEvaluationSheetSetting, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/evaluation/setting/sheet/register',
      name: 'EWMEvaluationSheetRegister',
      components: { default: EWMEvaluationSheetRegister, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}