<template>
  <div>
    <div class="page-sub-box ewm">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <div class="flex space-x-2 inner">
            <div class="mt-2">근무일</div>
            <div>
              <DxDateBox
                styling-mode="outlined"
                width="120"
                height="30"
                v-model="searchType.customTypes.dayStart"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy-MM-dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.dayEnd"
                maxLength="10"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>
            <div class="mt-1">~</div>
            <div>
              <DxDateBox
                styling-mode="outlined"
                width="120"
                height="30"
                v-model="searchType.customTypes.dayEnd"
                type="date"
                dateSerializationFormat="yyyyMMdd"
                display-format="yyyy-MM-dd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                maxLength="10"
                :min="searchType.customTypes.dayStart"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
              >
                <DxValidator validation-group="validationSearch">
                  <DxRequiredRule message="기준일은 필수입니다." />
                </DxValidator>
              </DxDateBox>
            </div>

            <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
          </div>
        </div>
      </div>

      <div>
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" @selection-changed="onSelectionChanged" />
      </div>
      <div class="space-y-4">
        <div class="sub_title_txt">
          <h2>대직자</h2>
        </div>
        <div>
          <table class="table_list">
            <colgroup>
              <col style="width: 15%" />
              <col style="width: 20%" />
              <col style="width: 15%" />
              <col style="width: auto" />
              <col style="width: auto" />
              <col style="width: auto" />
              <col style="width: auto" />
            </colgroup>
            <thead class="inner-header">
              <tr>
                <th scope="col">대직일</th>
                <th scope="col">부서</th>
                <th scope="col">대직자</th>
                <th scope="col">대직근무유형</th>
                <th scope="col">대직근무시간</th>
                <th scope="col">대직시간외근무유형</th>
                <th scope="col">대직시간외근무시간</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{{ replacementInfo.replacementWorkDt }}</td>
                <td>{{ replacementInfo.replacementDeptNmPath }}</td>
                <td v-if="replacementInfo.replacementAgtid">
                  {{ replacementInfo.replacementAgtNm }} [{{ replacementInfo.replacementAgtid }}]
                </td>
                <td v-else></td>
                <td>{{ replacementInfo.replacementWorkBasicNm }}</td>
                <td>
                  {{ replacementInfo.replacementWorkBasicStartTime }} -
                  {{ replacementInfo.replacementWorkBasicEndTime }}
                </td>
                <td>{{ replacementInfo.replacementWorkOverTimeNm }}</td>
                <td>
                  {{ replacementInfo.replacementWorkOverTimeStartTime }} -
                  {{ replacementInfo.replacementWorkOverTimeEndTime }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import validationEngine from 'devextreme/ui/validation_engine';
  import { getPastFromToday, isSuccess } from '@/utils/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    name: 'EWMPersonalWorkSchedule',
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,

      DxRequiredRule,
      DxValidator,
    },
    data() {
      return {
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            dayStart: getPastFromToday(1, 'months'),
            dayEnd: getPastFromToday(-1, 'months'),
          },
          paramsData: null,
        },
        replacementInfo: [],
        dataGrid: {
          keyExpr: 'id',
          refName: 'personalWorkScheGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: '500', // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            selectionChanged: true,
            rowClick: true,
          },
          showActionButtons: {
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '결재요청',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 75,
                  height: 30,
                  onClick() {
                    vm.onChangeWorkSche();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '재기안',
                  elementAttr: { class: 'btn_XS default filled' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onReExamination();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '근무변경구분',
              dataField: 'revTypeCd',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.revTypeCd === 'UPDATE_REQUEST') {
                  return `대직`;
                } else if (rowData.revTypeCd === 'DELETE_REQUEST') {
                  return `삭제`;
                } else {
                  return `-`;
                }
              },
            },
            {
              caption: '근무일',
              dataField: 'workDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '근무유형',
              dataField: 'workBasicNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '근무시간',
              dataField: 'workBasicStartTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.workBasicStartTime) {
                  return `${rowData.workBasicStartTime} ~ ${rowData.workBasicEndTime}`;
                }
              },
            },
            {
              caption: '시간외근무유형',
              dataField: 'workOverTimeNm',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '시간외근무시간',
              dataField: 'workOverTimeStartTime',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                if (rowData.workOverTimeStartTime) {
                  return `${rowData.workOverTimeStartTime} ~ ${rowData.workOverTimeEndTime}`;
                }
              },
            },
            {
              caption: '최종승인여부',
              dataField: 'finalApprovalStatus',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              // lookup: {
              // 	dataSource: [
              // 		{
              // 			codeId: 'PENDING',
              // 			codeNm: '대기',
              // 		},
              // 		{
              // 			codeId: 'IN_PROGRESS',
              // 			codeNm: '진행중',
              // 		},
              // 		{
              // 			codeId: 'APPROVAL',
              // 			codeNm: '승인',
              // 		},
              // 		{
              // 			codeId: 'APPROVAL_CANCEL',
              // 			codeNm: '승인취소',
              // 		},
              // 		{
              // 			codeId: 'RE_SUBMISSION',
              // 			codeNm: '재결재',
              // 		},
              // 		{
              // 			codeId: 'FORCE_APPROVAL',
              // 			codeNm: '전결',
              // 		},
              // 		{
              // 			codeId: 'REJECTED',
              // 			codeNm: '반려',
              // 		},
              // 		{
              // 			codeId: 'REVOKED',
              // 			codeNm: '결재취소',
              // 		},
              // 	],
              // 	displayExpr: 'codeNm',
              // 	valueExpr: 'codeId',
              // 	allowClearing: true,
              // },
              lookup: {
                dataSource: this.$_enums.ewm.approvalStatus.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 결재요청 */
      onChangeWorkSche() {
        const selectedRowsData = this.$refs.personalWorkScheGrid.getInstance.getSelectedRowsData();
        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        const selectedDate = new Date(selectedRowsData[0].workDt);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (selectedRowsData[0].approvalId) {
          let params = { updateYn: true, reSubmission: true, formData: selectedRowsData[0] };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/work-schedule/schedule-detail' });
        } else {
          if (selectedDate < today) {
            this.$_Msg('지난 근무는 선택할 수 없습니다.');
            return;
          }
          let params = { updateYn: false, reSubmission: true, formData: selectedRowsData[0] };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/work-schedule/schedule-detail' });
        }
      },
      /** @description : row Click */
      async onSelectionChanged(row) {
        this.replacementInfo = row.selectedRowsData[0];
      },
      /** @description : 재기안 */
      onReExamination() {
        const selectedRowsData = this.$refs.personalWorkScheGrid.getInstance.getSelectedRowsData();
        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }
        if (
          selectedRowsData[0].finalApprovalStatus === 'APPROVAL_CANCEL' ||
          selectedRowsData[0].finalApprovalStatus === 'REJECTED' ||
          selectedRowsData[0].finalApprovalStatus === 'REVOKED' ||
          selectedRowsData[0].finalApprovalStatus === 'PENDING'
        ) {
          let params = { updateYn: true, reSubmission: false, formData: selectedRowsData[0] };
          this.$store.commit('setDetailParams', params);
          this.$router.push({ path: '/ewm/personal/work-schedule/schedule-detail' });
        } else {
          this.$_Msg('대기, 반려, 취소일 경우에만 재기안이 가능합니다.');
          return;
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        if (!validationEngine.validateGroup('validationSearch').isValid) {
          return;
        }

        const payload = {
          actionName: 'EWM_PERSONAL_WORK_SCHEDULE',
          data: {
            startDt: this.searchType.customTypes.dayStart,
            endDt: this.searchType.customTypes.dayEnd,
          },
          loading: false,
        };

        let rtnData = [];
        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          rtnData = res.data.data;
        }
        this.dataGrid.dataSource = rtnData;
      },
      /** @description : 셀렉트 박스 초기화 */
      async initCodeMap() {
        // this.codeMap = await this.$_getCodeMapList('root_ewm_hr_agent_interview');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;

        this.initCodeMap().then(() => {
          // this.codes.interviewTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_agent_interview'], 2);
          // this.$refs.interviewGrid.getInstance.columnOption('interviewTypeCd', 'lookup', this.codes.interviewTypeCd);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
