import EWMEducationImplementationSchedule from '@/pages/ewm/education/implementation/schedule/index.vue';
import EWMEducationImplementationStatus from '@/pages/ewm/education/implementation/status/index.vue';
import EWMEducationReportCompletion from '@/pages/ewm/education/report/completion/index.vue';
import EWMEducationSettingSubject from '@/pages/ewm/education/setting/subject/index.vue';

export default function EducationRoutes(commonComponents) {
  return [
    {
      path: '/ewm/education/implementation/schedule',
      name: 'EWMEducationImplementationSchedule',
      components: { default: EWMEducationImplementationSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/education/implementation/status',
      name: 'EWMEducationImplementationStatus',
      components: { default: EWMEducationImplementationStatus, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/education/report/completion',
      name: 'EWMEducationReportCompletion',
      components: { default: EWMEducationReportCompletion, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/education/setting/subject',
      name: 'EWMEducationSettingSubject',
      components: { default: EWMEducationSettingSubject, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}