<!--
  PACKAGE_NAME : src\pages\esp\system\setting\operation.vue
  FILE_NAME : operation.vue
  AUTHOR : supark
  DATE : 2021-07-05
  DESCRIPTION : 시스템 운영 설정
-->
<template>
	<div class="content-themes">
		<tabs>
			<tab :title="data.items[0].configMenuNm" :key="index" v-for="(data, index) in getTabData">
				<table class="table_form line-bin">
					<colgroup>
						<col style="width: 250px" />
						<col style="width: auto" />
					</colgroup>
					<tbody>
						<tr v-for="(item, index) in data.items" :key="index">
							<th scope="row">
								<label for="label01">{{ item.description }} </label>
							</th>
							<td>
								<DxTextBox
									stylingMode="outlined"
									v-if="item.configComponentCd === 'TEXT'"
									class="mar_ri10 alB"
									:width="325"
									:value="item.configValue"
									@value-changed="valueChange($event, item)"
									placeholder="입력해주세요."
								></DxTextBox>
								<DxNumberBox
									v-else-if="item.configComponentCd === 'NUMBER'"
									stylingMode="outlined"
									:value="parseInt(item.configValue)"
									:min="0"
									:max="1000"
									:width="325"
									:show-spin-buttons="true"
									@value-changed="valueChange($event, item)"
									mode="number"
								/>
								<DxSwitch
									v-else-if="item.configComponentCd === 'BOOLEAN'"
									stylingMode="outlined"
									:value="JSON.parse(item.configValue)"
									@value-changed="valueChange($event, item)"
								/>
								<div v-else>
									{{ item.configValue }}
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</tab>
		</tabs>
		<section class="terms bottom-btn-box">
			<div class="page-sub-box">
				<h2 class="hidden">일반 버튼</h2>
				<div class="bottom-btn-wrap">
					<DxButton
						text="저장"
						:width="120"
						:height="40"
						class="default filled txt_S medium"
						type="default"
						@click="onSaveData"
					/>
					<DxButton text="취소" :width="120" :height="40" class="white filled txt_S medium" type="normal" @click="onCancelData" />
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { DxTextBox } from 'devextreme-vue/text-box';
import { DxNumberBox } from 'devextreme-vue/number-box';
import { DxButton } from 'devextreme-vue/button';
import { isSuccess } from '@/utils/common-lib';
import DxSwitch from 'devextreme-vue/switch';

import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import Query from 'devextreme/data/query';

export default {
  name: 'ESPSystemSettingOperationList',
	components: {
		DxTextBox,
		DxNumberBox,
		DxButton,
		DxSwitch,
		Tab,
		Tabs,
	},
	data() {
		return {
			systemOperationList: [],
			selectedIndex: 0,
			loop: false,
			animationEnabled: false,
			swipeEnabled: false,
			changedDataList: [],
			systemTabRefName: 'systemTabRefName',
			formRefName: 'formRefName',
		};
	},
	computed: {
		getTabData() {
      // configMenuCd로 그룹핑
			return Query(this.systemOperationList).sortBy('configMenuCd').groupBy('configMenuCd').toArray();
		},
	},
	methods: {
		async onSaveData() {
			if (this.changedDataList.length === 0) {
				this.$_Msg('변경된 데이터가 없습니다.');
				return;
			}

			let payload = {
				actionName: 'SYSTEM_SETTING_MERGE',
				data: this.changedDataList,
        useErrorPopup: true,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				await this.$_Toast('저장이 완료되었습니다.');
				await this.getSystemOperationList();
				this.changedDataList = [];
			}
		},
		onCancelData() {
			this.getSystemOperationList();
		},
		valueChange(e, item) {
			const systemData = this.systemOperationList.find(d => d.configKey === item.configKey);

			if (systemData.configValue !== String(e.value)) {
				let newData = {
					configKey: item.configKey,
					configValue: String(e.value),
				};

				let changedData = this.changedDataList.find(d => d.configKey === item.configKey);
				if (changedData) {
					this.changedDataList.splice(this.changedDataList.indexOf(changedData), 1, newData);
				} else {
					this.changedDataList.push(newData);
				}
			} else {
				let changedData = this.changedDataList.find(d => d.configKey === item.configKey);
				if (changedData) {
					this.changedDataList.splice(this.changedDataList.indexOf(changedData), 1);
				}
			}
		},
		/**
		 * 시스템 운영 설정 목록 조회
		 * @returns {Promise<void>}
		 */
		async getSystemOperationList() {
			let payload = {
				actionName: 'SYSTEM_OPERATION_ALL_LIST',
				data: {},
        useErrorPopup: true,
			};

			const res = await this.CALL_API(payload);
			if (isSuccess(res)) {
				this.systemOperationList = res.data.data;
			}
		},
	},
	created() {
		this.getSystemOperationList();
	},
	mounted() {},
};
</script>

<style scoped>
.widget-container {
	width: 300px;
	margin-left: 0;
	padding: 30px 0;
}
</style>
