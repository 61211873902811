<!--
  PACKAGE_NAME : src/pages/ui/components/tree-demo.vue
  FILE_NAME : tree-demo
  AUTHOR : hmlee
  DATE : 2024-11-29
  DESCRIPTION : 코드 에디터와 미리보기를 통한 실시간 뷰 컴포넌트 렌더링
-->
<template>
  <div class="page-sub-box">
    <div class="real-time-editor">
      <div class="editor sub_title_txt">
        <h2>코드 에디터</h2>
        <code-editor v-model="code" beautyType="html" />
      </div>
      <div class="preview sub_title_txt">
        <h2>미리보기</h2>
        <div ref="previewContainer" class="preview-container" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import CodeEditor from "@/pages/ui/components/code-editor.vue";
import EspDxTreeList from '@/components/devextreme/esp-dx-tree-list-v2.vue';

const demoTemplate = `
    <template>
      <div class="page-sub-box">
        <esp-dx-tree-list :tree-list="treeList" :ref="treeList.refName" />
      </div>
    <\/template>

    <script>
    import EspDxTreeList from '@/components/devextreme/esp-dx-tree-list-v2.vue';

    export default {
      components: {
        EspDxTreeList,
      },
      props: {},
      data() {
        return {
          treeList: {
            //callApi: 'CALL_CC_API', // Core 제외한 API 호출 설정 / Core API 호출은 생략 가능
            refName: 'codeRefName', // 트리 참조명
            keyExpr: 'codeId', // 기본: 'id'
            rowHeight: 30, // 행 높이 설정 / 30px 기본
            dataSource: [], // dataSource 설정
            dataSourceDefaultSortColumn: '+codeOrd,+codeNm', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
            sortKey: 'codeOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
            showColumnLines: true, // 컬럼 라인 표시 여부
            apiActionNm: {
              select: 'CODE_LIST_ALL',
              update: 'CODE_LIST_MERGE',
              delete: 'CODE_LIST_DELETE',
            },
            searchParams: { // 조회 API 호출 시 필요한 파라미터 설정 / apiActionNm.select 설정 시 사용 가능
              useFl: 'Y',
              //codeNm: '%검색%',
            },
            title: '공통코드', // 트리 타이틀
            toolbarOptions: {
              visible: true, // 툴바 노출 여부 / true가 기본
              //title: '공통코드', // 툴바 영역 타이틀 / 타이틀 설정할 경우 툴바 버튼 비노출
            },
            showActionButtons: { // 상단 버튼 노출 설정값
              //update: true, // 추가/저장/취소 한번에 설정 / true이거나 생략시 add, save, cancel 개별 설정 사용 가능 / true가 기본
              add: true,  // 추가 개별 설정 / true가 기본
              save: true, // 저장 개별 설정 / true가 기본
              cancel: true, // 취소 개별 설정 / true가 기본
              /*add: { // 추가 개별 설정 / 상세 옵션 설정시 객체로 설정 / true가 기본
                enabled: true,
              },
              save: {  // 저장 개별 설정 / 상세 옵션 설정시 객체로 설정 / true가 기본
                enabled: true,
              },
              cancel: { // 취소 개별 설정 / 상세 옵션 설정시 객체로 설정 / true가 기본
                enabled: true,
              },*/
              delete: true, // 삭제 / false가 기본 / 버튼 노출시 멀티 셀렉션 모드로 변경
              sort: true, // 순서 저장 / true가 기본
              toggleExpand: true, // 목록 펼치기/접기 / true가 기본
              move: false, // 이동 / false가 기본
              customButtons: [ // 커스텀 버튼 / []가 기본
                {
                  widget: 'dxButton',
                  options: {
                    icon: '',
                    text: '커스텀',
                    elementAttr: { class: 'btn_XS white light_filled' },
                    height: 30,
                    onClick: () => {
                      console.log('커스텀 버튼 클릭');
                    },
                  },
                  location: 'before',
                },
              ],
            },
            filterUseItem: { // 사용중인 항목만 보기 설정 값
              enabled: true, // 사용중인 항목만 보기 / false가 기본
              key: 'delFl', //사용중인 항목만 보기 컬럼 / viewFl가 기본
            },
            //columnChooser: {}, //항목 선택 / false가 기본
            filterRow: { // 행 검색 필터 / true가 기본
              //visible: false,
            },
            //headerFilter: {}, // 헤더 필터 / false가 기본
            //KeyboardNavigation: {}, // 키보드 네비게이션 / false가 기본
            //loadPanel: {}, //로딩 패널 / true가 기본
            //remoteOperations: {}, // 서버사이드 설정 / false가 기본
            //rowDragging: {}, //드래깅 설정 / false가 기본
            //scrolling: {}, //스크롤 설정
            //searchPanel: {}, //검색 패널 / false가 기본
            //selection: {}, //선택 설정
            //sorting: {}, //정렬 설정
            columns: [
              {
                caption: '코드명', // 타이틀명
                dataField: 'codeNm', // 컬럼명
                alignment: 'left', // 정렬
                //headerIcon: 'dx-icon-user', // 컬럼 아이콘 설정
                headerIcon: {
                  name: 'dx-icon-info',
                  position: 'before', // 아이콘 위치 / before, after
                },
                /* headerCellTemplate: container => { // 항목 타이틀 템플릿 설정
                  const icon = document.createElement('i');
                  icon.className = 'dx-icon dx-icon-info';
                  container.appendChild(icon);
                }, */
                requiredRule: {}, // 필수값 체크 / 필수값 메시지 디폴트 설정
              },
              { // 멀티 헤더
                multiHeaderNm: '코드',
                columns: [
                  {
                    caption: '값',
                    dataField: 'codeValue',
                  },
                  {
                    caption: '키',
                    dataField: 'codeKey',
                  },
                ],
              },
              {
                caption: '코드값',
                dataField: 'codeValue',
                tooltip: '툴팁 표시합니다.', // 항목 툴팁 표시
                requiredRule: {},
              },
              {
                caption: '코드키',
                dataField: 'codeKey',
                requiredRule: {},
              },
              {
                caption: '비고',
                dataField: 'description',
              },
            ],
          },
        };
      },
      computed: {},
      methods: {},
      created() {},
      mounted() {},
    };
    <\/script>
  `;

export default {
  name: 'ESPCOMPTreeDemo',
  components: {
    CodeEditor,
    EspDxTreeList,
  },

  watch: {
  },
  data() {
    return {
      // 초기 코드 설정
      code: demoTemplate,
    };
  },
  methods: {
    /** @description: 코드 에디터의 내용을 초기화 */
    handleResetCode() {
      this.code = demoTemplate;
      this.handleApplyCode(this.code);
    },
    /**
     * @description 코드 에디터의 내용을 파싱하여 프리뷰 영역에 컴포넌트를 렌더링
     * @throws {Error} 컴포넌트 생성 또는 마운트 중 발생하는 에러
     */
    handleApplyCode() {
      try {
        // 기존 컴포넌트 초기화
        if (this.currentComponent) {
          this.currentComponent.$destroy();
          this.$refs.previewContainer.innerHTML = '';
        }

        // 코드 파싱 및 컴포넌트 생성
        const { template, data } = this.parseVueComponent(this.code);
        const ComponentClass = this.createNewComponent(template, data?.treeList);

        // 새로운 마운트 포인트 생성
        const mountPoint = document.createElement('div');
        mountPoint.className = 'preview-mount-point';
        this.$refs.previewContainer.appendChild(mountPoint);

        // Vue 컴포넌트의 새 인스턴스 생성
        this.currentComponent = new ComponentClass({
          parent: this, // 현재 컴포넌트를 부모로 설정
        });

        this.currentComponent.$mount(mountPoint);

      } catch (error) {
        this.$log.error('Error:', error);
        this.$refs.previewContainer.innerHTML = `
          <div class="error">
            <h3>Error:</h3>
            <pre>${error.message}</pre>
          </div>
        `;
      }
    },
    /**
     * @description 컴포넌트 문자열을 파싱하여 template과 data 객체로 분리
     * @param {string} code - Vue 컴포넌트 전체 코드 문자열
     * @returns {Object} 파싱된 컴포넌트 구조
     *         - template: 컴포넌트의 template 부분
     *         - data: 실행된 data 객체
     * @throws {Error} 스크립트나 data 함수를 찾을 수 없는 경우
     */
    parseVueComponent(code) {
      try {
        const templateMatch = code.match(/<template>([\s\S]*?)<\/template>/);
        const template = templateMatch ? templateMatch[1].trim() : '';

        const scriptMatch = code.match(/<script>([\s\S]*?)<\/script>/);
        if (!scriptMatch) {
          throw new Error('스크립트를 찾을 수 없습니다.');
        }

        const dataMatch = scriptMatch[1].match(/data\s*\(\s*\)\s*{\s*return\s*({[\s\S]*?});?\s*}/);
        if (!dataMatch) {
          throw new Error('data 함수를 찾을 수 없습니다.');
        }

        const dataStr = dataMatch[1].replace(/this\./g, 'context.'); // this를 context로 변경
        const dataObj = this.executeComponentData(dataStr); // data 함수 실행

        return {
          template,
          data: dataObj
        };
      } catch (error) {
        this.$log.error('컴포넌트 파싱 에러:', error);
        throw error;
      }
    },
    /**
     * @description Vue 컴포넌트의 data 문자열을 실행하여 실제 data 객체로 변환
     * @param {string} dataStr - Vue 컴포넌트의 data 부분 문자열
     * @param {Object} context - Vue 컴포넌트의 this
     * @returns {Object} 실제 data 객체
     */
    executeComponentData(dataStr, context = this) {
      const evalFn = new Function('context', `
        with(context) {
          return ${dataStr};
        }
      `);

      try {
        return evalFn(context);
      } catch (error) {
        this.$log.error('data 실행 중 에러::', error);
        return {};
      }
    },
    /**
     * @description Vue 컴포넌트를 동적으로 생성하는 메서드
     *              template, components, data를 영역으 컴포넌트 생성
     * @param {string} template - 컴포넌트의 템플릿 문자열
     * @param {Object} data - 컴포넌트의 data 객체
     * @returns {VueComponent} Vue.extend로 생성된 컴포넌트 클래스
     */
    createNewComponent(template, demoTemplate) {
      const baseOptions = {
        template,
        components: { EspDxTreeList },
        data() {
          return {
            treeList: demoTemplate,
          };
        },
      };

      return Vue.extend(baseOptions);
    },
  },
  created() {},
  async mounted() {
    // 초기 코드로 컴포넌트 생성
    this.handleApplyCode(this.code);

    this.$store.commit('setPagingHists',  null);
  },
};
</script>

<style scoped>
.real-time-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor, .preview {
  width: 100%;
  padding: 15px 5px;
}

.preview-container {
  min-height: 400px;
}

@media (min-width: 768px) {
  .real-time-editor {
    flex-direction: row;
  }
  .editor {
    width: 35%;
  }
  .preview {
    width: 65%;
  }
}

.error {
  color: red;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
  background-color: #fff5f5;
}

.error pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}
</style>