<template>
	<div class="container">
		<!-- 1차 -->
		<div class="clearfix ui-glid-box p-10">
			<table class="table_form line-bin border-2">
				<colgroup>
					<col style="width:100px;" />
					<col style="width:auto;" />
					<col style="width:100px;" />
					<col style="width:auto;" />
					<col style="width:100px;" />
					<col style="width:auto;" />
				</colgroup>

				<tbody>
					<tr class="border-2">
						<th class="border-2">
							<div class="text-center text-xl font-semibold">시험계획</div>
						</th>
						<td colspan="5">
							<div class="text-xl font-semibold">{{ initData.scheNm }}</div>
						</td>
					</tr>
					<tr class="border-2">
						<th class="border-2">
							<div class="text-center text-xl font-semibold">시험일</div>
						</th>
						<td class="border-2">
							<div class="text-center text-xl font-semibold">
								{{ formatDate(initData.examStartDt, 'YYYY-MM-DDTHH:mm:ss', 'YYYY-MM-DD') }}
							</div>
						</td>
						<th class="border-2">
							<div class="text-center text-xl font-semibold">시험시간</div>
						</th>
						<td class="border-2">
							<div class="text-center text-xl font-semibold">
								{{ formatTime(initData.examStartTime) }} ~ {{ formatTime(initData.examEndTime) }}
							</div>
						</td>
						<th class="border-2">
							<div class="text-center text-xl font-semibold">현재시간</div>
						</th>
						<td class="border-2">
							<div class="text-center text-xl font-semibold">{{ serverTime }}</div>
						</td>
					</tr>
					<tr>
						<td colspan="6">
							<div class="space-y-24 pt-20 pb-20">
								<div class="block text-center">
									<img src="@/assets/images/ewm/time_check.png" class="h-1/4 w-1/5" />
								</div>
								<div class="block text-center space-x-6">
									<span class="text-5xl font-bold text-black tracking-wide">남은시간:</span>
									<span class="text-5xl font-bold text-red-500 tracking-widest">{{ countDownTime }}</span>
								</div>
								<div class="block text-center text-3xl font-semibold">
									응시자 {{ initData.agtNm }} [ {{ initData.targetId }} ] 잠시 기다려 주십시오.<br />
									자동으로 입장이 됩니다.
								</div>
								<div class="block text-center">
									<DxButton
										text="닫기"
										:width="100"
										:height="40"
										class="btn_XS default filled txt_S medium"
										@click="closeExamSheet"
									/>
								</div>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { DxButton } from 'devextreme-vue/button';
import {formatDate} from "@/utils/common-lib";

export default {
  name: 'EWMTargetSheet',
	components: {
		DxButton,
	},
	props: {},
	watch: {},
	data() {
		return {
			initData: [], //초기
			serverTime: null,
			countDownTime: '00:00:00',
		};
	},
	computed: {},
	methods: {
    formatDate,
		/** @description : 닫기 */
		closeExamSheet() {
			this.$router.push({ path: '/ewm/personal/exam' });
			// this.$router.push({ path: '/ewm/personal/exam/target-exam-sheet' });
		},
		formatTime(time) {
			return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
		},
		/** @description : 서버 시간 가져오기 */
		getServerTime() {
			const currentDate = new Date();
			const hours = currentDate
				.getHours()
				.toString()
				.padStart(2, '0');
			const minutes = currentDate
				.getMinutes()
				.toString()
				.padStart(2, '0');
			const seconds = currentDate
				.getSeconds()
				.toString()
				.padStart(2, '0');

			this.serverTime = `${hours}:${minutes}:${seconds}`;
		},
		/** @description : 남은 시간 카운트 */
		getCountDownTime() {
			// const currentDate = new Date();
			// const currentHours = currentDate.getHours();
			// const currentMinutes = currentDate.getMinutes();
			// const currentSeconds = currentDate.getSeconds();
			// const examStartDateTime = new Date(this.initData.examStartDt);
			// const examStartHours = Math.floor(parseInt(this.examStartTime) / 10000);
			// const examStartMinutes = Math.floor((parseInt(this.examStartTime) % 10000) / 100);
			// let remainingHours = examStartHours - currentHours;
			// let remainingMinutes = examStartMinutes - currentMinutes;
			// const remainingSeconds = 60 - currentSeconds;
			// remainingHours = remainingHours !== 0 ? remainingHours - 1 : remainingHours;
			const currentDateTime = new Date();
			const examStartDateTime = new Date(this.initData.examStartDt);
			const examStartHours = Math.floor(parseInt(this.initData.examStartTime) / 10000);
			const examStartMinutes = Math.floor(parseInt(this.initData.examStartTime) % 10000) / 100;
			examStartDateTime.setHours(examStartHours);
			examStartDateTime.setMinutes(examStartMinutes);
			examStartDateTime.setSeconds(0);

			const remainingDate = examStartDateTime - currentDateTime;
			const remainingHours = Math.floor((remainingDate / (1000 * 60 * 60)) % 24);
			const remainingMinutes = Math.floor((remainingDate / (1000 * 60)) % 60);
			const remainingSeconds = Math.floor((remainingDate / 1000) % 60);

			const pad = num => num.toString().padStart(2, '0');
			this.countDownTime = `${pad(remainingHours)}:${pad(remainingMinutes)}:${pad(remainingSeconds)}`;
		},
		isCountDownNeeded() {
			const currentDateTime = new Date();
			const examStartDateTime = new Date(this.initData.examStartDt);
			const examStartHours = Math.floor(parseInt(this.initData.examStartTime) / 10000);
			const examStartMinutes = Math.floor(parseInt(this.initData.examStartTime) % 10000) / 100;
			examStartDateTime.setHours(examStartHours);
			examStartDateTime.setMinutes(examStartMinutes);
			examStartDateTime.setSeconds(0);

			return currentDateTime < examStartDateTime;
		},
		/** @description : 라이프사이클 created시 호출되는 메서드 */
		async createdData() {
			this.reqParams = this.$store.getters.getDetailParams;
			this.initData = this.reqParams.data;
		},
	},
	created() {
		this.createdData();
	},
	mounted() {
		this.getServerTime();
		const interval = setInterval(() => {
			this.getServerTime();
			if (this.isCountDownNeeded()) {
				this.getCountDownTime();
			} else {
				clearInterval(interval);
				let params = { data: this.initData };
				this.$store.commit('setDetailParams', params);
				this.$router.push({ path: '/ewm/personal/exam/target-exam-sheet' });
			}
		}, 1000);
	},
};
</script>
