import EUCInfoPushImages from '@/pages/euc/info-push/images';
import EUCInfoPushImagesUpdate from '@/pages/euc/info-push/images/update';

export default function InfoPushImagesRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/images',
            name: 'EUCInfoPushImages',
            components: { default: EUCInfoPushImages, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/info-push/images/update',
            name: 'EUCInfoPushImagesUpdate',
            components: { default: EUCInfoPushImagesUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}