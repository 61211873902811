<!--
  PACKAGE_NAME : src/pages/euc/ipt-file
  FILE_NAME : ipt-file.vue
  AUTHOR : jhcho
  DATE : 2025-04-11
  DESCRIPTION :
-->
<template>
  <div class="main">
    <esp-dx-data-grid :data-grid="dataGrid" ref="iptFileGrid" @saving="onSaving"/>
    <dx-popup
        :show-title="true"
        :title="modal.initData ? modal.initData.title : null"
        :min-width="modal.initData ? modal.initData.width : null"
        :width="modal.initData ? modal.initData.width : null"
        :min-height="modal.initData ? modal.initData.height : null"
        :height="modal.initData ? modal.initData.height : null"
        :drag-enabled="true"
        :resize-enabled="true"
        :show-close-button="true"
        :close-on-outside-click="false"
        v-model="modal.isOpened"
        :visible="modal.isOpened"
        @hiding="isOpenModal(false)"
    >
      <template #content>
        <component
            ref="modalExcelUploadRef"
            :is="modal.currentComponent"
            :modal-data="modal.sendData"
            v-model="modal.contentData"
        />
      </template>
      <dx-toolbar-item
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'white filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.CLOSE', { defaultValue: '닫기' }),
						width: '120',
						height: '40',
						onClick: () => this.isOpenModal(false)
					}"
      />
      <dx-toolbar-item
          widget="dxButton"
          toolbar="bottom"
          location="center"
          :options="{
						elementAttr: {
							class: 'default filled txt_S medium',
						},
						text: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
						width: '120',
						height: '40',
						onClick: () => this.onSaveModal()
					}"
      />
    </dx-popup>
  </div>
</template>
<script>
import {DxSelectBox} from 'devextreme-vue/select-box';
import {isSuccess} from '@/utils/common-lib';
import {DxPopup, DxToolbarItem} from 'devextreme-vue/popup';
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";
import {DxButton} from "devextreme-vue/button";
import ModalIptFile from './modal-ipt-file.vue';

export default {
  components: {
    DxPopup,
    DxToolbarItem,
    DxSelectBox,
    EspDxDataGrid,
    ModalIptFile
  },
  data() {
    return {
      modal: {
        isOpened: false,
        currentComponent: null,
        initData: {},
        contentData: null,
      },
      stylingMode: 'outlined',
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'iptFileGrid',
        dataSourceDefaultSortColumn: '-regDt',
        apiActionNm: {
          select: 'EUC_IPT_FILE_SELECT',
          delete: 'EUC_IPT_FILE_DELETE',
        },
        showActionButtons: {
          delete: true,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                text: this.$_lang('COMPONENTS.ADD', {defaultValue: '추가'}),
                elementAttr: {
                  class: 'btn_XS default filled add1',
                },
                onClick: () => {
                  this.handleOpenModal();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                text: this.$_lang('COMPONENTS.EDIT', {defaultValue: '수정'}),
                elementAttr: {
                  class: 'btn_XS default filled modify',
                },
                onClick: () => {
                  this.handleOpenModal(true);
                },
              },
              location: 'before',
            },
          ]
        },
        editing: {
          allowUpdating: false,
          allowAdding: false,
        },
        columns: [
          {
            caption: '그룹',
            i18n: 'COMPONENTS.GROUP',
            dataField: 'groupId',
            width: 180,
            allowEditing: false,
            lookup: {
              dataSource: [],
              displayExpr: 'title',
              valueExpr: 'id',
              allowClearing: true,
            },
          },
          {
            caption: '제목',
            i18n: 'COMPONENTS.TITLE',
            dataField: 'title',
            allowEditing: false,
          },
          {
            caption: '파일명',
            dataField: 'fileNm',
            allowEditing: false,
          },
          {
            caption: '파일 크기',
            dataField: 'fileSize',
            width: 130,
            allowEditing: false,
            calculateCellValue: row => this.formatFileSize(row?.fileSize),
          },
          {
            caption: '다운로드',
            i18n: 'download',
            dataField: '',
            width: 120,
            allowEditing: false,
            cellTemplate: async (container, options) => {
              if (options.data?.fileNm && options.data?.fileSize > 0) {
                const divTag = document.createElement('div');
                divTag.className = 'inline-block';

                const downBtn = new DxButton({
                  propsData: {
                    icon: 'download',
                    hint: '다운로드',
                    onClick: e => {
                      e.event.stopPropagation();
                    },
                  },
                });
                downBtn.$mount();
                divTag.appendChild(downBtn.$el);
                container.append(divTag);
              }
            },
          },
          {
            caption: '삭제 가능 여부',
            dataField: 'isDeletable',
            width: 130,
            allowEditing: false,
            lookup: {
              dataSource: this.$_enums.common.stringPossibleFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            }
          },
          {
            caption: '수정자',
            i18n: 'COMPONENTS.MODIFIER',
            dataField: 'editId',
            width: 120,
            allowEditing: false,
          },
          {
            caption: '수정일시',
            i18n: 'COMPONENTS.MODIFY_DATE_TIME',
            dataField: 'editDt',
            width: 180,
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row?.editDt),
          },
          {
            caption: '등록자',
            i18n: 'COMPONENTS.REGISTRANT',
            dataField: 'regId',
            width: 120,
            allowEditing: false,
          },
          {
            caption: '등록일시',
            i18n: 'COMPONENTS.REGISTRANT_DATE_TIME',
            dataField: 'regDt',
            width: 180,
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row?.regDt),
          },
        ],
      },
      groupList: []
    }
  },
  watch: {
    groupList: {
      handler: function (val) {
        const column = this.dataGrid.columns.find((column) => column.dataField === 'groupId');
        column.lookup.dataSource = val;
      },
      deep: true
    }
  },
  methods: {
    async onSaving(e) {
      if (e.changes?.length) {
        e.cancel = true;
        const changeData = e.changes.map((item) => {
          const returnData = {
            ...item.data,
            id: item.type === 'insert' ? null : item.key,
          }
          if (returnData?.number1) {
            returnData.number1 = returnData.number1.replace(/[^0-9]/g, '');
          }
          if (returnData?.number2) {
            returnData.number2 = returnData.number2.replace(/[^0-9]/g, '');
          }
          return returnData;
        });

        const payload = {
          actionName: 'EUC_CONTACT_UPDATE',
          data: changeData,
          loading: true,
        }
        const res = await this.CALL_EUC_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}), {icon: 'success'});
          e.component.cancelEditData();
          this.$refs.iptFileGrid.refreshData();
        } else {
          this.$_Msg(res.data?.header?.resMsg ?? this.$_lang('COMMON.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}), {icon: 'error'});
        }
      }
    },
    async onSaveModal() {
      const data = await this.$refs.modalExcelUploadRef.getExcelData();
      let cnt = 0;
      while (cnt < data.length) {
        this.$refs.iptFileGrid.getInstance.addRow();
        cnt++;
      }

      const notFoundGroup = Array.from(new Set(data
          .filter(item => this.groupList.findIndex(group => group.groupNm === item.groupNm) === -1)
          .map(item => item.groupNm)))
          .map(groupNm => ({id: null, groupNm, viewFl: 'Y', groupOrd: 0}));

      if (notFoundGroup.length) {
        await this.onSageGroup(notFoundGroup);
      }

      data.forEach((item, index) => {
        if (item?.groupNm) {
          const group = this.groupList.find((group) => group.groupNm === item.groupNm);
          if (group) {
            item.groupId = group.id;
          }
        }

        const fields = this.dataGrid.columns.map((column) => column.dataField);
        fields.forEach(field => {
          this.$refs.iptFileGrid.getInstance.cellValue(index, field, item[field]);
        });
      });

      this.isOpenModal(false);
    },
    isOpenModal(data) {
      this.modal.isOpened = data;
      if (!data) {
        this.modal.currentComponent = null;
        this.modal.initData = {};
        this.modal.contentData = null;
      }
    },
    onOpenModal(componentNm, componentInitData, sendData) {
      this.modal.currentComponent = componentNm;
      this.modal.initData = componentInitData;
      this.modal.sendData = sendData;
      this.isOpenModal(true);
    },
    handleOpenModal(isEditMode = false) {
      let sendData = { isEditMode, groupList: this.groupList };
      if(isEditMode) {
        const selectedRowsData = this.$refs.iptFileGrid.getInstance.getSelectedRowsData();
        if(!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', {defaultValue: '대상이 선택되어 있지 않습니다.'}));
          return;
        } else if (selectedRowsData.length > 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_ONE_SELECT', {defaultValue: '하나의 대상만 선택해 주세요.'}));
          return;
        }

        sendData = {
          ...sendData,
          selectedData: selectedRowsData[0]
        }
      }

      const fileStr = this.$_lang('COMPONENTS.FILE', { defaultValue: '파일' });
      const title = isEditMode
          ? `${fileStr} ${this.$_lang('COMPONENTS.EDIT', {defaultValue: '수정'})}`
          : `${fileStr} ${this.$_lang('COMPONENTS.ADD', {defaultValue: '추가'})}`
      this.onOpenModal(
          'modal-ipt-file',
          {
            title: title,
            width: 700,
            height: 550,
          },
          sendData
      );
    },
    async selectGroupDataList(loading = false) {
      const payload = {
        actionName: 'EUC_IPT_FILE_GROUP_SELECT',
        data: {},
        loading,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.groupList = res.data.data;
      }
    },
    formatDt(date) {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    formatFileSize(size) {
      if (!size) {
        return '-';
      }

      const units = ['B', 'KB', 'MB', 'GB'];
      let index = 0;

      while (size >= 1024 && index < units.length - 1) {
        size = size / 1024;
        index++;
      }

      return `${size.toFixed(index === 0 ? 0 : 2).toLocaleString()} ${units[index]}`;
    }
  },
  created() {
    this.selectGroupDataList();
  }
}
</script>
<style scoped>
</style>