import EWMEvaluationCriteria from '@/pages/ewm/productivity/implementation/evaluation-criteria/index.vue';
import EWMEvaluationCriteriaRegister from '@/pages/ewm/productivity/implementation/evaluation-criteria/register.vue';
import EWMEvaluationSchedule from '@/pages/ewm/productivity/implementation/evaluation-schedule/index.vue';
import EWMReportCase from '@/pages/ewm/productivity/report/case/index.vue';
import EWMReportResult from '@/pages/ewm/productivity/report/result/index.vue';
import EWMReportTime from '@/pages/ewm/productivity/report/time/index.vue';

export default function ProductivityRoutes(commonComponents) {
  return [
    {
      path: '/ewm/productivity/implementation/evaluation-criteria',
      name: 'EWMEvaluationCriteria',
      components: { default: EWMEvaluationCriteria, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/implementation/evaluation-criteria/register',
      name: 'EWMEvaluationCriteriaRegister',
      components: { default: EWMEvaluationCriteriaRegister, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/implementation/evaluation-schedule',
      name: 'EWMEvaluationSchedule',
      components: { default: EWMEvaluationSchedule, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/report/case',
      name: 'EWMReportCase',
      components: { default: EWMReportCase, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/report/result',
      name: 'EWMReportResult',
      components: { default: EWMReportResult, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/productivity/report/time',
      name: 'EWMReportTime',
      components: { default: EWMReportTime, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}