import EUCScheduling from '@/pages/euc/scheduling/task';
import EUCSchedulingHistory from '@/pages/euc/scheduling/task/history';

export default function SchedulingRoutes(commonComponents) {
    return [
        {
            path: '/euc/scheduling/task',
            name: 'EUCScheduling',
            components: { default: EUCScheduling, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/scheduling/task/history',
            name: 'EUCSchedulingHistory',
            components: { default: EUCSchedulingHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}