<!--
  PACKAGE_NAME : src/pages/euc/phone/request/list
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-05-28
  DESCRIPTION :
-->
<template>
  <div class="main">
    <esp-dx-data-grid :data-grid="dataGrid" ref="requestGridRef"/>
  </div>
</template>
<script>
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

export default {
  name: 'EUCRequestList',
  components: {
    EspDxDataGrid,
  },
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'requestGridRef',
        dataSourceDefaultSortColumn: '-regDt',
        apiActionNm: {
          select: 'EUC_PHONE_REQUEST_SELECT',
        },
        searchParams: {
          userNo: this.$store.getters.getLoginId
        },
        showActionButtons: {
          delete: false,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: '등록',
                elementAttr: {class: 'btn_XS default filled add1'},
                width: 60,
                height: 30,
                onClick: () => {
                  this.onAddData();
                },
              },
              location: 'before',
            },
          ],
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '제목',
            i18n: 'COMPONENTS.TITLE',
            dataField: 'title',
            allowEditing: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.addEventListener('click', () => {
                this.$store.commit('setDetailParams', {request: options.data, isEditMode: false});
                this.$router.push({path: '/euc/phone/request/update', query: {requestId: options.data.id}});
              });
              aTag.innerText = options.data.title;
              container.appendChild(aTag);
            },
          },
          {
            caption: '신청자',
            i18n: 'UC.WORD.REQUESTER',
            dataField: 'userNm',
            allowEditing: false,
            cellTemplate: (container, options) => {
              const data = options.data;
              container.innerText = `${data?.deptNm || ''} ${data?.userNm || ''} ${data?.gradeNm || ''}`;
            },
          },
          {
            caption: '완료 희망일',
            i18n: 'UC.WORD.DUE_DATE',
            dataField: 'dueDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row.dueDt, 'YYYY.MM.DD'),
          },
          {
            caption: '승인여부',
            i18n: 'UC.WORD.APPROVAL_FL',
            dataField: 'approverFl',
            allowEditing: false,
            requiredRule: {},
            cellTemplate: (container, options) => {
              container.innerText = options.data?.approverFl === 'Y' ? '승인' : '미승인';
            },
          },
          {
            caption: '승인일',
            i18n: 'UC.WORD.APPROVAL_DATE',
            dataField: 'approverDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row.approverDt),
          },
          {
            caption: '승인자',
            i18n: 'UC.WORD.APPROVER',
            dataField: 'approverUserNm',
            allowEditing: false,
            cellTemplate: (container, options) => {
              const data = options.data;
              container.innerText = `${data?.approverDeptNm || ''} ${data?.approverUserNm || ''} ${data?.approverGradeNm || ''}`;
            },
          },
          {
            caption: '진행상태',
            i18n: 'UC.WORD.PROGRESS_STATUS',
            dataField: '',
            allowEditing: false,
            cellTemplate: (container, options) => {
              let result = '';
              if (options.data.requestItems?.length) {
                const totalCnt = options.data.requestItems.length;
                const progressCnt = options.data.requestItems.filter(item => item.status === 'COMPLETE')?.length || 0;
                if (progressCnt >= totalCnt) {
                  result = '완료';
                } else if (totalCnt > progressCnt) {
                  result = `${progressCnt} / ${totalCnt}`;
                }
              } else {
                result = '0 / 0';
              }
              container.innerText = result;
            },
          },
          {
            caption: '신청일',
            i18n: 'COMPONENTS.REGISTRATION_DATE',
            dataField: 'regDt',
            allowEditing: false,
            calculateCellValue: row => this.formatDt(row.regDt),
          },
        ],
      },
    };
  },
  methods: {
    onAddData() {
      this.$store.commit('setDetailParams', {isEditMode: true});
      this.$router.push({path: '/euc/phone/request/update'});
    },
    formatDt(date, toFormat = 'YYYY.MM.DD HH:mm:ss') {
      if (date) {
        return this.$_commonlib.formatDate(date, 'YYYYMMDDHHmmssSSS', toFormat);
      }
    },
  },
};
</script>
<style scoped></style>