<template>
  <div class="main">
    <div class="locker_setting_list sub_new_style01 sub_ui_box1">
      <Search
        codeKey="search_infopush_site"
        :searchObj="searchType.obj"
        :useKeywordType="true"
        @change-search-type="$_changeSearchType"
        @onSearchClick="$_searchData($event, 'search_infopush_site')"
      >
        <template v-slot:before>
          <DxSelectBox
            v-model="searchType.customTypes.siteId"
            placeholder="사이트 선택"
            :items="getSiteList"
            display-expr="site"
            value-expr="id"
            :styling-mode="stylingMode"
            :width="120"
            :height="30"
            @value-changed="$_changeSearchCustomType('siteId', $event)"
          />
        </template>
      </Search>
    </div>

    <esp-dx-data-grid :data-grid="dataGrid" ref="infoPushSiteGrid" @init-new-row="onInitNewRow" @saving="e => onCheckDataBeforeSaving(e)" />
  </div>
</template>

<script>
  import Search from '@/components/common/search.vue';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxSwitch } from 'devextreme-vue/switch';
  import { isSuccess } from '@/utils/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    name: 'EUCInfoPushSite',
    components: {
      EspDxDataGrid,
      DxSelectBox,
      Search,
    },
    data() {
      return {
        config: {
          pageSetting: {
            //pageData pageSetting 관련
            config: {},
          },
        },
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {
            siteNm: '',
          },
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            siteId: null,
          },
          paramsData: null,
        },
        dataGrid: {
          keyExpr: 'siteId',
          refName: 'infoPushSiteGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          // height:'500',    // 주석처리시 100%
          apiActionNm: {
            select: 'INFOPUSH_SITE_LIST_ALL',
            // update: 'INFOPUSH_SITE_UPDATE',
            // delete: 'INFOPUSH_SITE_DELETE'
          },
          customEvent: {
            //그리드 컴포넌트의 이벤트를 해당 페이지에서 사용할 수 있도록 처리 [ 사용: true, 미사용: false(생략 가능) ]
            saving: true,
            initNewRow: true,
            cellPrepared: true,
            checkDataBeforeSaving: true,
          },
          showActionButtons: {
            delete: true,
            customButtons: [
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: false,
          },
          headerFilter: {
            visible: false,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '사이트',
              dataField: 'siteId',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              editorType: 'dxSelectBox',
              cellTemplate: (container, options) => {
                if (options.data?.siteObj?.id) {
                  container.append(options.data?.siteObj?.siteNm);
                } else {
                  const selectbox = new DxSelectBox({
                    propsData: {
                      placeholder: '선택',
                      items: this.$store.getters.getSiteList,
                      displayExpr: 'siteNm',
                      valueExpr: 'id',
                      value: options.value,
                      stylingMode: this.stylingMode,
                      onValueChanged: e => {
                        this.$refs.infoPushSiteGrid.getInstance.cellValue(options.rowIndex, options.columnIndex, e.value);
                      },
                    },
                  });
                  selectbox.$mount();
                  container.append(selectbox.$el);
                }
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '사이트명',
              dataField: 'siteNm',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowGrouping: false,
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '순서',
              dataField: 'siteOrd',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: true,
              sortOrder: 'asc',
              allowHeaderFiltering: false,
              allowGrouping: false,
            },
            {
              caption: '사용 여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              }
            },
            {
              caption: '등록일',
              dataField: 'regDt',
              height: 40,
              alignment: 'center',
              visible: true,
              allowEditing: false,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: this.formatDt,
            },
          ],
        },
      };
    },
    computed: {
      /** @description: U_CODE svr_type 가져오기 */
      getSiteList() {
        const result = this.$store.getters.getSiteList;
        const currentPath = this.$router.currentRoute.path;
        const store = this.$store.getters.getSearchHists[currentPath] || [];
        const params = store[0]?.search;

        if (params?.siteId) {
          this.setCustomTypes('siteId', params.siteId);
        } else {
          this.setCustomTypes('siteId', 0);
        }

        return [{ id: 0, site: '전체', siteNm: '전체' }, ...result];
      },
    },
    methods: {
      async onDeleteData() {
        const selectedRowsData = this.$refs.infoPushSiteGrid.selectedRowsData;

        if (!selectedRowsData?.length) {
          this.$_Msg('대상이 선택되어 있지 않습니다.');
          return;
        } else {
          if (
            await this.$_Confirm('관련 설정이 모두 삭제됩니다.', {
              title: `<h2 class="red">주의</h2>`,
            })
          ) {
            const payload = {
              actionName: 'INFOPUSH_SITE_DELETE',
              data: {
                data: {
                  data: selectedRowsData,
                },
              },
              loading: true,
            };

            const res = await this.CALL_API(payload);

            if (isSuccess(res)) {
              await this.selectDataList();
            } else {
              this.$_Msg('삭제 실패');
            }
          }
        }
      },
      async onSave(e) {
        if (e.changes.length) {
          const payload = {
            actionName: 'INFOPUSH_SITE_UPDATE',
            data: {
              data: e.changes.map(change => {
                const siteId = change.data?.siteId || change?.key;
                const item = this.$store.getters.getSiteList.find(item => item.id === siteId);
                return { ...change.data, site: item.site, siteId };
              }),
            },
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$refs.infoPushSiteGrid.getInstance.refresh();
          }
        }
      },
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.viewFl = this.$_enums.common.stringUsedFlag.YES.value
      },
      /** @description: 시스템설정 값 관리 저장 전 데이터 체크 메서드 */
      async onCheckDataBeforeSaving(e) {
        const arrId = [];
        e.changes.forEach(d => {
          vm.$refs.infoPushSiteGrid.getInstance.byKey(d.key).done(async item => {
            if (d.type === 'insert' || (d.type === 'update' && d.data.siteId)) {
              arrId.push(item.siteId);
            }
            e.data = item;
          });
        });

        if (arrId?.length) {
          const payload = {
            actionName: 'INFOPUSH_SITE_LIST_ALL',
            data: {
              params: {
                siteId: arrId.join(','),
              },
            },
          };

          const res = await vm.CALL_API(payload);
          if (isSuccess(res)) {
            if (res.data.data?.length) {
              vm.$_Msg('동일한 사이트가 존재하여 저장할 수 없습니다.');
              return;
            }
          }
        }

        await this.onSave(e);
      },
      /** @description : 라이프사이클 computed에서 this data를 변경하기 위한 메서드 */
      setCustomTypes(key, value) {
        this.searchType.customTypes[key] = value;
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList(sort = '-regDt') {
        this.dataGrid.dataSource = new CustomStore({
          key: 'siteId',
          load: async loadOptions => {
            let params = this.$_getDxDataGridParam(loadOptions);
            if (!params.sort) {
              params.sort = sort;
            }

            params = { ...params, ...this.searchType.paramsData };

            const payload = {
              actionName: 'INFOPUSH_SITE_LIST_ALL',
              data: {
                params,
              },
              loading: false,
            };

            const rtnData = {
              data: [],
              totalCount: 0,
            };

            const res = await this.CALL_API(payload);
            if (isSuccess(res)) {
              rtnData.data = res.data.data;
              rtnData.totalCount = res.data.header.totalCount;
            }
            return rtnData;
          },
          insert() {
            //return false;
          },
          update() {
            return false;
          },
          totalCount: opts => {
            //데이터 없는 경우 totalCount 에러 발생하여 추가
            return new Promise((resolve, reject) => {
              resolve(0);
            });
          },
        });
      },
      /** @description : 날짜 형식 변환 메서드 */
      formatDt(rowData) {
        if (rowData?.regDt) {
          return this.$_commonlib.formatDate(rowData.regDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
        }
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.searchType.paramsData = null;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>