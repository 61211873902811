<template>
  <div>
    <div class="ewm page-sub-box">
      <div class="flex flex-col divide-y">
        <div>
          <table class="table_form line-bin min-w-max max-w-screen-xl">
            <colgroup>
              <col style="width: 100px" />
              <col style="width: auto" />
              <col style="width: 100px" />
              <col style="width: auto" />
              <col style="width: 120px" />
              <col style="width: auto" />
            </colgroup>
            <tbody>
              <tr>
                <th scope="row">
                  <label for="label01">평가계획:</label>
                </th>
                <td>
                  {{ evalScheduleInfo.scheNm }}
                </td>
                <th scope="row">
                  <label for="label01">진행상태:</label>
                </th>
                <td>
                  {{ evalScheduleInfo.evalProcessNm }}
                </td>
                <th scope="row">
                  <label for="label01">평가표:</label>
                </th>
                <td>
                  {{ evalScheduleInfo.sheetNm }}
                </td>
              </tr>
              <tr>
                <th scope="row">
                  <label for="label01">평가구분:</label>
                </th>
                <td>
                  {{ evalScheduleInfo.evalDivisionNm }}
                </td>
                <th scope="row">
                  <label for="label01">평가기간:</label>
                </th>
                <td>
                  {{ evalScheduleInfo.evalStartDt }}
                  ~
                  {{ evalScheduleInfo.evalEndDt }}
                </td>
                <th scope="row">
                  <label for="label01">이의제기 기간:</label>
                </th>
                <td>
                  {{ evalScheduleInfo.evalFeedbackStartDt }}
                  ~
                  {{ evalScheduleInfo.evalFeedbackEndDt }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="flex gap-x-6">
          <div class="w-1/2 flex flex-col gap-y-3 divide-y">
            <div v-if="evalScheduleInfo.evalDivisionNm !== '일반평가'">
              <h3 class="text-xl font-medium py-2">콜정보</h3>
              <div>
                <div class="flex justify-between">
                  <div>
                    <DxButton
                      text="콜가져오기"
                      type="button"
                      class="btn_XS default filled"
                      :height="30"
                      :disabled="evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.PROGRESS.label"
                      @click="onPopEvalRecord"
                    />
                    <DxButton
                      text="삭제"
                      type="button"
                      class="btn_XS white light_filled trash"
                      :height="30"
                      :disabled="evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.PROGRESS.label"
                      @click="onRoundRecordDeleteData"
                    />
                  </div>
                  <div>
                    <DxButton text="우수콜등록" type="button" class="btn_XS default filled mr-0" :height="30" @click="onAddBestCall" />
                  </div>
                </div>
                <div class="pt-2">
                  <DxDataGrid
                    key-expr="id"
                    class="grid-box eval-grid"
                    ref="evalRoundRecordGrid"
                    :data-source="roundRecordGridData.dataSource"
                    :allow-column-resizing="true"
                    :column-resizing-mode="'nextColumn'"
                    :show-borders="false"
                    :show-column-headers="true"
                    :show-column-lines="true"
                    :show-row-lines="true"
                    :row-alternation-enabled="false"
                    :hover-state-enabled="true"
                    :word-wrap-enabled="true"
                    :no-data-text="this.$_lang('CMN_NO_DATA')"
                    :selected-row-keys="roundRecordGridData.selectedRowKeys"
                    width="100%"
                    height="230"
                  >
                    <DxLoadPanel :enabled="true" />

                    <DxScrolling mode="virtual" />
                    <DxSelection mode="multiple" :allow-select-all="true" show-check-boxes-mode="always" select-all-mode="allPages" />

                    <DxColumn caption="구분" data-field="recTypeCd" alignment="center" :allow-sorting="true">
                      <DxLookup :data-source="this.$_enums.ewm.evalCallYype.values" value-expr="value" display-expr="label" />
                    </DxColumn>
                    <DxColumn caption="통화일" data-field="recDate" alignment="center" :allow-sorting="true" />
                    <DxColumn
                      caption="통화시간"
                      data-field="recStartTime"
                      alignment="center"
                      :allow-sorting="true"
                      :calculate-display-value="calDisplayValRecElapsedTime"
                    />
                    <DxColumn caption="콜ID" data-field="callId" alignment="center" :allow-sorting="true" />
                    <DxColumn
                      caption="청취"
                      data-field="id"
                      cell-template="playTemplate"
                      :allow-sorting="false"
                      alignment="center"
                      :visible="true"
                      :width="55"
                    />
                    <template #playTemplate="{ data }">
                      <DxButton
                        text=""
                        :element-attr="{ class: 'btn_XS white outlined mr-0' }"
                        height="30"
                        hint="청취"
                        template="<span class='mdi mdi-volume-high'></span>"
                        @click="onRoundRecordPlay($event, data.data)"
                      />
                    </template>
                  </DxDataGrid>
                </div>
              </div>
            </div>
            <div>
              <h3 class="text-xl font-medium py-2">평가표 진행 현황</h3>
              <div>
                <DxDataGrid
                  key-expr="questionId"
                  class="grid-box eval-grid"
                  ref="evalAnswerStatusGrid"
                  :data-source="answerStatusGridData.dataSource"
                  :allow-column-resizing="true"
                  :column-resizing-mode="'nextColumn'"
                  :show-borders="false"
                  :show-column-headers="true"
                  :show-column-lines="true"
                  :show-row-lines="true"
                  :row-alternation-enabled="false"
                  :hover-state-enabled="true"
                  :word-wrap-enabled="true"
                  :no-data-text="this.$_lang('CMN_NO_DATA')"
                  :selected-row-keys="answerStatusGridData.selectedRowKeys"
                  width="100%"
                  :height="evalScheduleInfo.evalDivisionNm !== '일반평가' ? '100%' : '50%'"
                  @selection-changed="onAnswerStatusGridSelectionChanged"
                >
                  <DxLoadPanel :enabled="true" />

                  <DxScrolling mode="virtual" />
                  <DxSelection mode="single" :allow-select-all="false" show-check-boxes-mode="always" select-all-mode="allPages" />

                  <DxColumn caption="평가구성" data-field="questionParentGroup" alignment="center" :allow-sorting="true" />
                  <DxColumn caption="하위구성" data-field="questionChildGroup" alignment="center" :allow-sorting="true" disabled="true" />
                  <DxColumn caption="문항" data-field="questionNm" :allow-sorting="true" cell-template="questionNmTemplate" />
                  <template #questionNmTemplate="{ data }">
                    <div>
                      <span class="inline-block w-3.5 h-3.5 mt-1 mr-2 rounded-sm" :class="getQuestionNmStatusBg(data.data)"></span>
                      <span>{{ data.data.questionNm }}</span>
                    </div>
                  </template>
                  <DxColumn caption="배점" data-field="questionScore" alignment="center" :allow-sorting="true" width="60" />
                  <DxColumn caption="평가" data-field="answerScore" alignment="center" :allow-sorting="true" width="60" />
                </DxDataGrid>
              </div>
            </div>
          </div>
          <div class="w-2/3">
            <h3 class="text-xl font-medium py-2">평가표</h3>
            <div>
              <div class="overflow-auto border p-2" style="height: 430px" id="questionAnswerContainer">
                <table
                  v-for="questionAnswer in evalSheetData.questionAnswer.questionList"
                  :key="questionAnswer.questionId"
                  :id="`questionAnswer_${questionAnswer.questionId}`"
                  class="border-collapse table-fixed mb-6"
                >
                  <thead>
                    <tr>
                      <th class="border text-left py-2 bg-gray-100">
                        <DxButton
                          :template="
                            evalSheetData.questionAnswer.isVisible.question[`${questionAnswer.questionId}`]
                              ? '<span class=\'mdi mdi-chevron-down\'></span>'
                              : '<span class=\'mdi mdi-chevron-up\'></span>'
                          "
                          type="button"
                          class="bg-gray-100 w-14 inline-block text-center"
                          :active-state-enabled="false"
                          :focus-state-enabled="false"
                          :hover-state-enabled="false"
                          height="20"
                          @click="
                            evalSheetData.questionAnswer.isVisible.question[`${questionAnswer.questionId}`] =
                              !evalSheetData.questionAnswer.isVisible.question[`${questionAnswer.questionId}`]
                          "
                        />
                        <span>{{ questionAnswer.questionNm }}</span>
                      </th>
                      <th class="border text-center py-2 bg-gray-100 w-20">배점</th>
                      <th class="border text-center py-2 bg-gray-100 w-20">평가</th>
                      <th class="border text-center py-2 bg-gray-100 w-20">이의제기</th>
                    </tr>
                  </thead>
                  <tbody :class="{ hidden: !evalSheetData.questionAnswer.isVisible.question[questionAnswer.questionId] }">
                    <tr v-for="questionOption in questionAnswer.questionOptions" :key="questionOption.optionId">
                      <td class="border px-8 py-2">{{ questionOption.optionNm }}</td>
                      <td class="border text-center py-2">{{ questionOption.optionScore }}</td>
                      <td class="border text-center py-2">
                        <DxCheckBox
                          :value="questionAnswer.answerId === questionOption.optionId"
                          :disabled="
                            evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.PROGRESS.label &&
                            evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.FEEDBACK.label
                          "
                          @value-changed="
                            e => {
                              if (e.value) {
                                questionAnswer.answerId = questionOption.optionId;
                                questionAnswer.answerScore = questionOption.optionScore;
                              } else {
                                if (questionAnswer.answerId === questionOption.optionId) {
                                  questionAnswer.answerId = null;
                                  questionAnswer.answerScore = null;
                                }
                              }
                            }
                          "
                        />
                      </td>
                      <td class="border text-center py-2">
                        <DxCheckBox
                          :value="questionAnswer.feedbackAnswerId === questionOption.optionId"
                          :disabled="true"
                          @value-changed="
                            e => {
                              if (e.value) {
                                questionAnswer.feedbackAnswerId = questionOption.optionId;
                                questionAnswer.feedbackScore = questionOption.optionScore;
                              } else {
                                if (questionAnswer.feedbackAnswerId === questionOption.optionId) {
                                  questionAnswer.feedbackAnswerId = null;
                                  questionAnswer.feedbackScore = null;
                                }
                              }
                            }
                          "
                        />
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th colspan="4" class="border text-left py-2 bg-gray-100">
                        <DxButton
                          :template="
                            evalSheetData.questionAnswer.isVisible.comment[`${questionAnswer.questionId}`]
                              ? '<span class=\'mdi mdi-chevron-down\'></span>'
                              : '<span class=\'mdi mdi-chevron-up\'></span>'
                          "
                          type="button"
                          class="bg-gray-100 w-14 inline-block text-center"
                          :active-state-enabled="false"
                          :focus-state-enabled="false"
                          :hover-state-enabled="false"
                          height="20"
                          @click="
                            evalSheetData.questionAnswer.isVisible.comment[questionAnswer.questionId] =
                              !evalSheetData.questionAnswer.isVisible.comment[`${questionAnswer.questionId}`]
                          "
                        />
                        <span>평가의견</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody :class="{ hidden: !evalSheetData.questionAnswer.isVisible.comment[questionAnswer.questionId] }">
                    <tr>
                      <td colspan="4" class="border p-2">
                        <div style="min-height: 30px" class="eval-comment-box">
                          <DxTextArea
                            :height="90"
                            :element-attr="{ class: 'border p-2' }"
                            v-model="questionAnswer.evalComment"
                            :read-only="
                              evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.PROGRESS.label &&
                              evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.FEEDBACK.label
                            "
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="flex justify-between p-3 gap-4 py-2 bg-gray-100 eval-comment-box" style="height: 140px">
                <div class="w-full">
                  <div class="text-center text-xl">평가의견</div>
                  <div class="bg-white border rounded">
                    <DxTextArea
                      :height="90"
                      :element-attr="{ class: 'p-2' }"
                      v-model="evalSheetData.evalTargetComment.evalComment"
                      :read-only="evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.PROGRESS.label"
                      styling-mode="'outlined'"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-center text-xl">이의제기의견</div>
                  <div class="bg-white border rounded">
                    <DxTextArea
                      :height="90"
                      :element-attr="{ class: 'p-2' }"
                      v-model="evalSheetData.evalTargetComment.feedback"
                      :read-only="evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.FEEDBACK.label"
                      styling-mode="'outlined'"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <div class="text-center text-xl">최종평가의견</div>
                  <div class="bg-white border rounded">
                    <DxTextArea
                      :height="90"
                      :element-attr="{ class: 'p-2' }"
                      v-model="evalSheetData.evalTargetComment.finalComment"
                      :read-only="evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.FEEDBACK.label"
                      styling-mode="'outlined'"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-sub-box">
      <div class="eval-bottom-box">
        <DxButton
          text="저 장"
          class="default filled txt_S medium"
          :width="120"
          :height="40"
          :disabled="
            evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.PROGRESS.label &&
            evalScheduleInfo.evalProcessNm !== ewmCodesEvalProcess.FEEDBACK.label
          "
          @click="onSaveFormData"
        />
        <DxButton text="닫 기" class="btn_XS white filled txt_S medium" :width="120" :height="40" @click="onCancelFormData" />
      </div>
    </div>

    <DxPopup
      :show-title="true"
      :title="modal.initData ? modal.initData.title : null"
      :min-width="modal.initData ? modal.initData.width : null"
      :width="modal.initData ? modal.initData.width : null"
      :min-height="modal.initData ? modal.initData.height : null"
      :height="modal.initData ? modal.initData.height : null"
      :drag-enabled="true"
      :resize-enabled="true"
      :show-close-button="true"
      :hide-on-outside-click="false"
      v-model="modal.isOpened"
      :visible="modal.isOpened"
      @hiding="isOpenModal(false)"
    >
      <template #content>
        <div>
          <component :is="modal.currentComponent" :modalData="modal.sendData" v-model="modal.contentData"></component>
        </div>
      </template>

      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.hasOwnProperty('save')
              : !modal.initData.buttons.hasOwnProperty('save')
            : false
        "
        :options="{
          elementAttr: {
            class: 'default filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('save')
              ? modal.initData.buttons.save.text
              : ''
            : '',
          width: '120',
          height: '40',
          useSubmitBehavior: true,
          onClick: e => {
            onConfirmEvalRecordModal(e);
          },
        }"
      />
      <DxToolbarItem
        widget="dxButton"
        toolbar="bottom"
        location="center"
        :visible="
          modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.hasOwnProperty('cancel')
              : !modal.initData.buttons.hasOwnProperty('cancel')
            : false
        "
        :options="{
          elementAttr: {
            class: 'white filled txt_S medium',
          },
          text: modal.initData.hasOwnProperty('buttons')
            ? modal.initData.buttons.hasOwnProperty('cancel')
              ? modal.initData.buttons.cancel.text
              : ''
            : '',
          width: '120',
          height: '40',
          onClick: () => {
            isOpenModal(false);
          },
        }"
      />
    </DxPopup>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxCheckBox } from 'devextreme-vue/check-box';
  import { DxDataGrid, DxColumn, DxLoadPanel, DxLookup, DxScrolling, DxSelection } from 'devextreme-vue/data-grid';
  import { DxPopup, DxPosition, DxToolbarItem } from 'devextreme-vue/popup';
  import { DxTextArea } from 'devextreme-vue/text-area';
  import ModalEvalRecordList from '@/pages/ewm/evaluation/implementation/status/modal-eval-record-list.vue';
  import { calculateElapsedTime, cloneObj, formatDate, isSuccess } from '@/utils/common-lib';

  export default {
    name: 'EWMEvaluationStatusEvaluation',
    components: {
      DxPopup,
      DxPosition,
      DxToolbarItem,
      DxButton,
      DxCheckBox,
      DxTextArea,
      DxDataGrid,
      DxColumn,
      DxLoadPanel,
      DxLookup,
      DxScrolling,
      DxSelection,
      ModalEvalRecordList,
    },
    data() {
      return {
        codeMap: {},
        modal: {
          isOpened: false,
          currentComponent: null,
          initData: {},
          contentData: null,
        },
        dataGridLoopup: {
          recTypeCd: [],
        },
        ewmCodesEvalProcess: this.$_enums.ewm.evalProcess,
        config: {
          stylingMode: 'outlined', //[outlined, filled, underlined]
        },
        roundRecordGridData: {
          dataSource: null,
          selectedRowsData: [],
          selectedRowKeys: [],
        },
        answerStatusGridData: {
          dataSource: null,
          selectedRowsData: [],
          selectedRowKeys: [],
        },
        evalSheetData: {
          questionAnswer: {
            questionList: [],
            isVisible: {
              question: {},
              comment: {},
            },
            isCheckSetting: false,
          },
          evalTargetComment: {},
        },
        evalScheduleInfo: {
          id: null,
          evalDivisionCd: null,
          evalDivisionNm: null,
          scheNm: null,
          evalSheetId: null,
          sheetNm: null,
          scheYmd: null,
          evalStartDt: null,
          evalEndDt: null,
          evalFeedbackStartDt: null,
          evalFeedbackEndDt: null,
          evalProcessCd: null,
          evalProcessNm: null,
          viewFl: this.$_enums.common.stringUsedFlag.YES.value,
          delFl: this.$_enums.common.stringUsedFlag.NO.value,
          roundId: null,
          targetAgtId: null,
          targetId: null,
          canModify: true,
        },
      };
    },
    computed: {
      ewmCodes() {
        return this.$_enums.ewm;
      },
    },
    methods: {
      /** @description : 팝업이 열렸는지 체크하는 메서드(true: 열림/false: 닫힘) */
      isOpenModal(isOpened) {
        this.modal.isOpened = isOpened;
        if (!isOpened) {
          this.modal.currentComponent = null;
          this.modal.initData = {};
        }
      },
      /** @description : 팝업 창 열때 이벤트 */
      onOpenModal(componentNm, componentInitData, propsData) {
        this.modal.currentComponent = componentNm; //set dynamic component name in modal body slot
        this.modal.initData = componentInitData; //set init modal templet
        this.modal.sendData = propsData;

        this.isOpenModal(true);
      },

      /** @description : 콜정보 조회 */
      async selectRoundRecordDataList() {
        if (!this.evalScheduleInfo.id) {
          return;
        }

        const payload = {
          actionName: 'EWM_EVALUATION_STATUS_ROUND_RECORD',
          data: {
            scheId: this.evalScheduleInfo.id,
            roundId: this.evalScheduleInfo.roundId,
            agtId: this.evalScheduleInfo.targetAgtId,
          },
          loading: false,
        };

        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          this.roundRecordGridData.dataSource = res.data.data;
        }
      },
      /** @description : 평가표 진행 현황 조회 */
      async selectAnswerStatusData() {
        if (!this.evalScheduleInfo.id) {
          return;
        }

        const payload = {
          actionName: 'EWM_EVALUATION_STATUS_ANSWER_STATUS_LIST',
          data: {
            scheId: this.evalScheduleInfo.id,
            roundId: this.evalScheduleInfo.roundId ? this.evalScheduleInfo.roundId : '',
            targetAgtId: this.evalScheduleInfo.targetAgtId,
            sheetId: this.evalScheduleInfo.evalSheetId,
            targetId: this.evalScheduleInfo.targetId,
          },
          loading: false,
        };

        const res = await this.CALL_EWM_API(payload);
        if (isSuccess(res)) {
          //questionOptions중복제거
          const removeDuplicates = (data) => {
            return data.map(obj => ({
              ...obj,
              questionOptions: Array.from(
                new Map(obj.questionOptions.map(option => [option.optionId, option])).values()
              )
            }));
          };
          
          this.answerStatusGridData.dataSource = removeDuplicates(res.data.data[0].questionAnswers);

          this.evalSheetData.questionAnswer.questionList = cloneObj(this.answerStatusGridData.dataSource);
          this.evalSheetData.evalTargetComment = cloneObj(res.data.data[0].evalTargetComment);

          const questionAnswerVisible = {};
          res.data.data[0].questionAnswers.forEach(z => (questionAnswerVisible[z.questionId] = true));

          this.evalSheetData.questionAnswer.isVisible.question = questionAnswerVisible;
          this.evalSheetData.questionAnswer.isVisible.comment = cloneObj(questionAnswerVisible);
        }
      },
      /** @description : 통화시간 계산 */
      calDisplayValRecElapsedTime(rowData) {
        const startTime = `${rowData.recDate}T${formatDate(rowData.recStartTime, 'HHmmSS', 'HH:mm:SS')}`;
        const endTime = `${rowData.recDate}T${formatDate(rowData.recEndTime, 'HHmmSS', 'HH:mm:SS')}`;

        return calculateElapsedTime(startTime, endTime, ':');
      },
      /** @description : 콜가져오기 버튼 클릭 */
      onPopEvalRecord() {
        const popData = {};

        popData.title = '녹취콜 리스트';
        popData.props = {
          scheId: this.evalScheduleInfo.id,
          roundId: this.evalScheduleInfo.roundId,
          agtId: this.evalScheduleInfo.targetAgtId,
          savedRecordIds: this.roundRecordGridData.dataSource.map(z => z.recordId),
        };

        this.onOpenModal(
          'ModalEvalRecordList',
          {
            title: popData.title,
            buttons: {
              save: { text: '저장' },
              cancel: { text: '취소' },
            },
            width: '950',
            height: '620',
          },
          popData.props,
        );
      },
      /** @description : 모달 팝업 저장 버튼 이벤트 */
      async onConfirmEvalRecordModal(e) {
        //해당 모달 컴포넌트에서 데이터 저장
        let promise = new Promise((resolve, reject) => {
          this.$_eventbus.$emit(`${this.modal.currentComponent}:onSaveData`, e, resolve, reject);
        });

        promise
          .then(res => {
            if (isSuccess(res)) {
              this.selectRoundRecordDataList();
              this.isOpenModal(false);
            } else {
              this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
            }
          })
          .catch(err => {
            // handle error
            this.$_Toast(this.$_lang(`CMN_ERROR:${err}`));
          });
      },
      /** @description : 콜정보 삭제 */
      async onRoundRecordDeleteData() {
        const selectedRowKeys = this.$refs.evalRoundRecordGrid.instance.getSelectedRowKeys();

        if (!selectedRowKeys?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const payload = {
          actionName: 'EWM_EVALUATION_STATUS_ROUND_RECORD_DELETE',
          data: selectedRowKeys,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(
            this.$_lang('COMMON.MESSAGE.CMN_SUC_DELETE', {
              defaultValue: '정상적으로 삭제되었습니다.',
            }),
          );
          await this.selectRoundRecordDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 우수콜등록 */
      async onAddBestCall() {
        const selectedRowsData = this.$refs.evalRoundRecordGrid.instance.getSelectedRowsData();

        if (!selectedRowsData?.length) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm('우수콜로 등록하시겠습니까?', { title: `<h2>알림</h2>` }))) {
          return;
        }

        const payload = {
          actionName: 'EWM_EVALUATION_STATUS_BW_CALL_SAVE',
          data: { data: selectedRowsData },
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 콜정보 청취 */
      onRoundRecordPlay() {
        this.$_Msg('준비중');
      },
      /** @description : 평가표진행현황 문항별 상태 class 설정 */
      getQuestionNmStatusBg(data) {
        if (data.feedbackAnswerId) {
          return ' bg-purple-500';
        } else if (data.answerId) {
          return ' bg-green-500';
        } else {
          return ' bg-gray-300';
        }
      },
      /** @description : 평가표진행현황 선택 변경 */
      onAnswerStatusGridSelectionChanged(e) {
        if (e.selectedRowsData.length) {
          const questionAnswerContainer = document.getElementById('questionAnswerContainer');
          const questionItem = document.getElementById(`questionAnswer_${e.selectedRowsData[0].questionId}`);

          questionAnswerContainer.scrollTop = questionItem.offsetTop;
        }
      },
      /** @description : 저장 버튼 클릭 */
      async onSaveFormData() {
        if (!(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' })))) {
          return;
        }

        const data = {
          targetAnswer: [],
          evalComment: this.evalSheetData.evalTargetComment.evalComment,
          feedback: this.evalSheetData.evalTargetComment.feedback,
          finalComment: this.evalSheetData.evalTargetComment.finalComment,
        };

        data.targetAnswer = this.evalSheetData.questionAnswer.questionList.map(z => {
          return {
            id: z.evalTargetAnswerId,
            targetId: this.evalScheduleInfo.targetId,
            questionId: z.questionId,
            answerId: z.answerId,
            score: z.answerScore,
            evalComment: z.evalComment,
            feedbackAnswerId: z.feedbackAnswerId,
            feedbackScore: z.feedbackScore,
          };
        });

        const payload = {
          actionName: 'EWM_EVALUATION_STATUS_EVAL_TARGET_ANSWER_SAVE',
          data: data,
          loading: true,
          useErrorPopup: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          await this.selectAnswerStatusData();
        }
      },
      /** @description : 닫기 버튼 클릭 메서드 */
      onCancelFormData() {
        const params = { ...this.evalScheduleInfo };
        this.$store.commit('setDetailParams', params);
        this.$router.push('/ewm/evaluation/implementation/status');
      },
    },
    mounted() {
      this.reqParams = this.$store.getters.getDetailParams;
      if (!this.reqParams) {
        this.$_goPrePage();
        return;
      }

      this.evalScheduleInfo = this.reqParams;
      this.evalScheduleInfo.canModify = this.reqParams.evalProcessNm === this.$_enums.ewm.evalProcess.READY.label;

      if (this.evalScheduleInfo.evalDivisionNm !== '일반평가') {
        this.selectRoundRecordDataList();
      }

      this.selectAnswerStatusData();
    },
  };
</script>
<style lang="scss" scoped>
  .eval-bottom-box {
    margin: 0;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding-top: 5px;
  }
  .eval-comment-box .dx-texteditor.dx-editor-filled {
    background-color: white; /* 배경색을 여기에 원하는 색상으로 변경 */
  }

  .eval-comment-box .dx-texteditor.dx-editor-filled.dx-state-disabled,
  .dx-texteditor.dx-editor-filled.dx-state-readonly {
    background-color: rgba(0, 0, 0, 0.12);
  }
</style>
