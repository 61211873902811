<!--
  PACKAGE_NAME : src/pages/euc/ipt-file
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2025-04-11
  DESCRIPTION :
-->
<template>
  <div class="container">
    <tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tab-type="1">
      <tab title="파일관리">
        <ipt-file v-if="tabIndex === 0" />
      </tab>
      <tab title="그룹관리">
        <group v-if="tabIndex === 1" />
      </tab>
    </tabs>
  </div>
</template>
<script>
import Tab from '@/components/common/tab.vue';
import Tabs from '@/components/common/tabs.vue';
import IptFile from './ipt-file.vue';
import Group from './group.vue';

export default {
  name: 'EUCIptFile',
  components: {
    Tabs,
    Tab,
    IptFile,
    Group
  },
  data() {
    return {
      tabIndex: 0,
    }
  },
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
  },
}
</script>