<!--
  PACKAGE_NAME : src/pages/ui/components/grid-demo.vue
  FILE_NAME : grid-demo
  AUTHOR : hmlee
  DATE : 2024-11-12
  DESCRIPTION : 코드 에디터와 미리보기를 통한 실시간 뷰 컴포넌트 렌더링
-->
<template>
  <div class="page-sub-box">
    <div class="real-time-editor">
      <div class="editor sub_title_txt">
        <h2>코드 에디터</h2>
        <code-editor v-model="code" beautyType="html" />
      </div>
      <div class="preview sub_title_txt">
        <h2>미리보기</h2>
        <div ref="previewContainer" class="preview-container" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import CodeEditor from "@/pages/ui/components/code-editor.vue";
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
import EspAddButton from '@/components/common/buttons/esp-add-button.vue';
import EspDeleteButton from '@/components/common/buttons/esp-delete-button.vue';
import { mountComponent } from "@/utils/devextreme-util.js";

const demoTemplate = `
    <template>
      <div class="component-demo">
        <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />

        <div class="title"> 그리드 목록 개수 최소/최대 값 체크 </div>
        최소 값 체크 : this.$refs[this.dataGrid.refName].validateMinSelection();
        <br/>
        최대 값 체크 : this.$refs[this.dataGrid.refName].validateMaxSelection();
      </div>
    <\/template>

    <script>
    import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
    import EspAddButton from '@/components/common/esp-add-button.vue';
    import EspDeleteButton from '@/components/common/esp-delete-button.vue';
    import { mountComponent } from "@/plugins/devextreme-util.js";

    export default {
      components: {
        EspDxDataGrid,
        EspAddButton,
        EspDeleteButton,
      },
      props: {},
      data() {
        return {
          dataGrid: {
            callApi: 'CALL_CC_API', // Core 제외한 API 호출 설정 / Core API 호출은 생략 가능
            refName: 'ivrServerRefName', // 그리드 참조명
            //keyExpr: 'id', // 기본: 'id'
            rowHeight: 30, // 행 높이 설정 / 30px 기본
            initialDataSource: true, // 초기 데이터 조회 여부
            dataSource: [], // dataSource 설정
            dataSourceDefaultSortColumn: '+svrOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
            apiActionNm: {
              select: 'CC_IVR_SVR_LIST',
              update: 'CC_IVR_SVR_MERGE',
              delete: 'CC_IVR_SVR_DELETE',
            },
            searchParams: { // 조회 API 호출 시 필요한 파라미터 설정 / apiActionNm.select 설정 시 사용 가능
              viewFl: 'Y',
            },
            title: 'IVR 서버 목록', // 그리드 타이틀
            toolbarOptions: {
              visible: true, // 툴바 노출 여부
              //title: 'IVR 서버 목록', // 툴바 영역 타이틀 / 타이틀 설정한 경우 툴바 버튼 비노출
            },
            showActionButtons: { // 상단 버튼 노출 설정값
              select: true, // 조회 / false가 기본
              //update: true, // 추가/저장/취소 한번에 설정 / true이거나 생략시 add, save, cancel 개별 설정 사용 가능 / true가 기본
              add: true, // 추가 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
              save: false, // 저장 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
              cancel: true, // 취소 개별 설정 / update 옵션이 true이거나 생략시 사용 가능 / true가 기본
              //delete: true, // 삭제 / true가 기본
              //copy: true, // 복사 / false가 기본
              excel: true, // 엑셀 다운로드 / false가 기본
              //csv: true, // CSV 다운로드 / excel: true 시 사용 가능 / false가 기본
              customButtons: [ // 커스텀 버튼 / []가 기본
                // ESP 버튼 컴포넌트 사용 예시 (데모에서는 실행 안됨)
                /* {
                  template: (data, index, element) => {
                    mountComponent(
                      element,
                      EspAddButton,
                      {},
                      {
                        handleClick: () => console.log('추가 버튼 클릭')
                      },
                      this,
                    );
                  },
                  location: 'before',
                }, */
                /* {
                  template: (data, index, element) => {
                    mountComponent(
                      element,
                      EspDeleteButton,
                      {},
                      {
                        handleClick: () => console.log('삭제 버튼 클릭')
                      },
                      this,
                    );
                  },
                  location: 'before',
                }, */
              ],
            },
            showContextMenuItems: { // 컨텍스트 메뉴 노출 설정값 / false가 기본
              //insert: true, // 행 추가
              //copy: true, // 행 복사
              //rowClipboard: true, // 행 클립보드
              //cellClipboard: true, // 셀 클립보드
              //excel: true, // 엑셀 다운로드
            },
            //columnChooser: {}, //항목 선택 / false가 기본
            export: { //엑셀 다운로드 설정 / showActionButtons.excel: true 일때만 동작
                //title: '엑셀 타이틀', // 엑셀 다운로드 시 파일명
                //allowExportSelectedData: true, // 선택한 데이터만 다운로드 허용 여부
            },
            filterRow: { // 행 검색 필터 / true가 기본
              //visible: false,
            },
            //grouping: {}, //그룹핑 설정 / false가 기본
            //groupPanel: {}, //그룹핑 패널 / false가 기본
            //headerFilter: {}, // 헤더 필터 / false가 기본
            //loadPanel: {}, //로딩 패널 / ture가 기본
            page: { // paging, pager 설정(하나라 합침) / true가 기본
              enabled: true,
            },
            //disableTotalCount: false, // 검색 결과 표시 여부 / false가 기본
            //remoteOperations: {}, // 서버사이드 설정 / false가 기본
            dragging: { //드래깅 설정 / false가 기본
              sortKey: 'svrOrd', // 드래그 정렬 컬럼
            },
            //scrolling: {}, //스크롤 설정 / paging 설정시 사용 불가
            //searchPanel: {}, //검색 패널 / false가 기본
            selecting: { //선택 설정 / true가 기본
              minSelectionLength: 1, // 최소 선택 행 수 / 0이 기본
              maxSelectionLength: 100, // 최대 선택 행 수 / 0이 기본
              allowDragSelection: true, // 드래그 선택 허용 여부 / false가 기본
            },
            //summary: {}, //합계 설정 / false가 기본
            columns: [
              {
                caption: 'ID',
                dataField: 'id',
                visible: false,
              },
              {
                caption: '사이트명', // 타이틀
                i18n: 'COMMON.WORD.SITE_NM', // 다국어 처리
                dataField: 'siteId', // 컬럼명
                width: 120, // 컬럼 넓이
                lookup: { // SelectBox
                  dataSource: this.$store.getters.getSiteList,
                  displayExpr: 'siteNm',
                  valueExpr: 'id',
                },
                requiredRule: {}, // 필수값 옵션 / 필수값 메시지 디폴트 설정
                tooltip: '사이트 툴팁 표시합니다.', // 항목 툴팁 표시
              },
              {
                caption: '센터명',
                i18n: 'COMMON.WORD.TENANT_NM',
                dataField: 'tenantId',
                lookup: {
                  dataSource: this.$store.getters.getTenantList,
                  displayExpr: 'tenantNm',
                  valueExpr: 'id',
                },
                requiredRule: {},
              },
              {
                caption: 'IVR명',
                i18n: 'CC.WORD.IVR_NAME',
                dataField: 'svrNm',
                //width: '200px',
                //headerIcon: 'dx-icon-info', // 항목 타이틀 아이콘 설정
                headerIcon: { // 항목 타이틀 아이콘 설정
                  name: 'dx-icon-info', // DevExtreme 아이콘 클래스명
                  position: 'before', // 아이콘 위치: ['before', 'after'] / before 기본
                },
                /* headerCellTemplate: container => { // 항목 타이틀 템플릿 설정
                  const icon = document.createElement('i');
                  icon.className = 'dx-icon dx-icon-info';
                  container.appendChild(icon);
                }, */
                requiredRule: {},
              },
              {
                caption: 'IVR No',
                dataField: 'sysNo',
                requiredRule: {},
              },
              {
                caption: 'IP',
                dataField: 'ip',
                requiredRule: {},
              },
              { // 멀티 헤더
                multiHeaderNm: '서버계정',
                columns: [
                  {
                    caption: 'svrUser',
                    width: 100,
                    dataField: 'svrUser',
                    //allowAdding: true, // 추가 허용 여부 / 기본 true
                    allowUpdating: false, // 수정 허용 여부 / 기본 true
                  },
                  {
                    caption: 'svrPasswd',
                    width: 120,
                    dataField: 'svrPasswd',
                    //allowAdding: true, // 추가 허용 여부 / 기본 true
                    allowUpdating: false, // 수정 허용 여부 / 기본 true
                  },
                ],
              },
              {
                caption: 'svrUser',
                dataField: 'svrUser',
                //allowAdding: true, // 추가 허용 여부 / 기본 true
                allowUpdating: false, // 수정 허용 여부 / 기본 true
              },
              {
                caption: 'svrPasswd',
                dataField: 'svrPasswd',
                //allowAdding: true, // 추가 허용 여부 / 기본 true
                allowUpdating: false, // 수정 허용 여부 / 기본 true
              },
              {
                caption: 'svrMethod',
                dataField: 'svrMethod',
                allowSearch: false, // searchPanel 설정시 사용 가능 / false로 설정시 allowFiltering도 false로 설정해야 됨
                allowFiltering: false,
              },
              {
                caption: 'Port',
                dataField: 'svrPort',
                allowSearch: false,
                //allowFiltering: false,
              },
              {
                caption: '엑셀항목제외',
                dataField: 'excludedItem',
                excel: {
                  visibleCell: false, // 엑셀 다운로드 시 노출 여부
                },
              },
              {
                caption: '순서',
                i18n: 'COMPONENTS.ORDER',
                dataField: 'svrOrd',
              },
              {
                caption: '사용여부',
                i18n: 'COMPONENTS.USE_STATUS',
                dataField: 'viewFl',
                lookup: {
                  dataSource: this.$_enums.common.stringUsedFlag.values,
                  displayExpr: 'label',
                  valueExpr: 'value',
                },
              },
            ],
          },
        };
      },
      computed: {},
      methods: {},
      created() {},
      mounted() {},
    };
    <\/script>
  `;

export default {
  name: 'ESPCOMPGridDemo',
  components: {
    CodeEditor,
    EspDxDataGrid,
    EspAddButton,
    EspDeleteButton,
  },

  watch: {
  },
  data() {
    return {
      // 초기 코드 설정
      code: demoTemplate,
    };
  },
  methods: {
    /** @description: 코드 에디터의 내용을 초기화 */
    handleResetCode() {
      this.code = demoTemplate;
      this.handleApplyCode(this.code);
    },
    /**
     * @description 코드 에디터의 내용을 파싱하여 프리뷰 영역에 컴포넌트를 렌더링
     * @throws {Error} 컴포넌트 생성 또는 마운트 중 발생하는 에러
     */
     handleApplyCode() {
      try {
        // 기존 컴포넌트 초기화
        if (this.currentComponent) {
          this.currentComponent.$destroy();
          this.$refs.previewContainer.innerHTML = '';
        }

        // 코드 파싱 및 컴포넌트 생성
        const { template, data } = this.parseVueComponent(this.code);
        const ComponentClass = this.createNewComponent(template, data?.dataGrid);

        // 새로운 마운트 포인트 생성
        const mountPoint = document.createElement('div');
        mountPoint.className = 'preview-mount-point';
        this.$refs.previewContainer.appendChild(mountPoint);

        // Vue 컴포넌트의 새 인스턴스 생성
        this.currentComponent = new ComponentClass({
          parent: this, // 현재 컴포넌트를 부모로 설정
        });

        this.currentComponent.$mount(mountPoint);

      } catch (error) {
        this.$log.error('Error:', error);
        this.$refs.previewContainer.innerHTML = `
          <div class="error">
            <h3>Error:</h3>
            <pre>${error.message}</pre>
          </div>
        `;
      }
    },
    /**
     * @description 컴포넌트 문자열을 파싱하여 template과 data 객체로 분리
     * @param {string} code - Vue 컴포넌트 전체 코드 문자열
     * @returns {Object} 파싱된 컴포넌트 구조
     *         - template: 컴포넌트의 template 부분
     *         - data: 실행된 data 객체
     * @throws {Error} 스크립트나 data 함수를 찾을 수 없는 경우
     */
     parseVueComponent(code) {
      try {
        const templateMatch = code.match(/<template>([\s\S]*?)<\/template>/);
        const template = templateMatch ? templateMatch[1].trim() : '';

        const scriptMatch = code.match(/<script>([\s\S]*?)<\/script>/);
        if (!scriptMatch) {
          throw new Error('스크립트를 찾을 수 없습니다.');
        }

        const dataMatch = scriptMatch[1].match(/data\s*\(\s*\)\s*{\s*return\s*({[\s\S]*?});?\s*}/);
        if (!dataMatch) {
          throw new Error('data 함수를 찾을 수 없습니다.');
        }

        const dataStr = dataMatch[1].replace(/this\./g, 'context.'); // this를 context로 변경
        const dataObj = this.executeComponentData(dataStr); // data 함수 실행

        return {
          template,
          data: dataObj
        };
      } catch (error) {
        this.$log.error('컴포넌트 파싱 에러:', error);
        throw error;
      }
    },
    /**
     * @description Vue 컴포넌트의 data 문자열을 실행하여 실제 data 객체로 변환
     * @param {string} dataStr - Vue 컴포넌트의 data 부분 문자열
     * @param {Object} context - Vue 컴포넌트의 this
     * @returns {Object} 실제 data 객체
     */
    executeComponentData(dataStr, context = this) {
      const evalFn = new Function('context', `
        with(context) {
          return ${dataStr};
        }
      `);

      try {
        return evalFn(context);
      } catch (error) {
        this.$log.error('data 실행 중 에러::', error);
        return {};
      }
    },
    /**
     * @description Vue 컴포넌트를 동적으로 생성하는 메서드
     *              template, components, data를 영역으 컴포넌트 생성
     * @param {string} template - 컴포넌트의 템플릿 문자열
     * @param {Object} data - 컴포넌트의 data 객체
     * @returns {VueComponent} Vue.extend로 생성된 컴포넌트 클래스
     */
    createNewComponent(template, demoTemplate) {
      const baseOptions = {
        template,
        components: { EspDxDataGrid, EspAddButton, EspDeleteButton },
        data() {
          return {
            dataGrid: demoTemplate,
            // customButtons의 onClick 핸들러를 여기서 정의
          };
        },
        methods: {
        },
      };

      return Vue.extend(baseOptions);
    },
  },
  created() {},
  async mounted() {
    // 초기 코드로 컴포넌트 생성
    this.handleApplyCode(this.code);

    this.$store.commit('setPagingHists',  null);
  },
};
</script>

<style scoped>
::v-deep .component-demo .title {
  font-size: 16px;
  font-weight: 500;
  padding-top: 10px;
}

.real-time-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor, .preview {
  width: 100%;
  padding: 15px 5px;
}

.preview-container {
  min-height: 400px;
}

@media (min-width: 768px) {
  .real-time-editor {
    flex-direction: row;
  }
  .editor {
    width: 35%;
  }
  .preview {
    width: 65%;
  }
}

.error {
  color: red;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
  background-color: #fff5f5;
}

.error pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}
</style>