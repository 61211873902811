import EUCJabber from '@/pages/euc/device/jabber/index.vue';

export default function JabberRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/jabber',
            name: 'EUCJabber',
            components: { default: EUCJabber, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}