<!--
  PACKAGE_NAME : src\pages\esp\history\download-file.vue
  FILE_NAME : download-file
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 다운로드 이력
-->
<template>
  <div class="container">
    <div class="page-sub-box">
      <div class="page_search_box line_bottom_1px">
        <div class="inner alL">
          <date-range-box ref="dateRangeBox" label="등록 일자" :start-dt="dayStart" :end-dt="dayEnd" />
          <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="onSearch" />
        </div>
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
    </div>
  </div>
</template>

<script>
  import DateRangeBox from '@/components/devextreme/esp-dx-date-range-box.vue';
  import { DxButton } from 'devextreme-vue/button';
  import { getPastFromToday, isSuccess } from '@/utils/common-lib';
  import moment from 'moment/moment';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  export default {
    name: 'ESPDownloadFile',
    components: {
      EspDxDataGrid,
      DxButton,
      DateRangeBox,
    },
    data() {
      return {
        dayStart: getPastFromToday(7, 'days'),
        dayEnd: moment().format('YYYYMMDD'),
        dataGrid: {
          refName: 'excelRefName',
          allowColumnResizing: true, //컬럼 사이즈 허용
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          columnAutoWidth: false, // 컬럼 사이즈 자동 조절 유무
          columnMinWidth: 80, // 컬럼 최소 사이즈
          dataSource: [],
          showActionButtons: {
            select: false,
            excel: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: false, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          scrolling: {
            mode: 'standard', //스크롤 모드 : ['infinite', 'standard', 'virtual']
          },
          remoteOperations: {
            filtering: false,
            sorting: true,
            grouping: false,
            paging: true,
          },
          paging: {
            enabled: true,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: true, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [5, 10, 15, 20], //페이지 사이즈 선택 박스
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: false, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: false, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false,
            allowDeleting: false,
            allowAdding: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: true, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'page', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'none', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '메뉴명',
              dataField: 'menuNm',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              allowSorting: false,
            },
            {
              caption: '사용자ID',
              dataField: 'userNo',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '사용자명',
              dataField: 'userNm',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '다운로드 유형',
              dataField: 'fileType',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
              calculateCellValue: rowData => {
                if (this.$_enums.common.fileHistoryType.EXCEL.equals(rowData.fileType)) {
                  return this.$_enums.common.fileHistoryType.EXCEL.label;
                }
                return this.$_enums.common.fileHistoryType.ATTACHMENT.label;
              },
            },
            {
              caption: '파일명',
              dataField: 'fileNm',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              caption: '다운로드 사유',
              dataField: 'reason',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
            {
              dataType: 'date',
              dataField: 'regDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              caption: '등록일시',
              alignment: 'center',
              visible: true,
              sortOrder: 'none',
              allowHeaderFiltering: false,
            },
          ],
        },
      };
    },
    methods: {
      async onSearch() {
        let vm = this;
        this.dataGrid.dataSource = new CustomStore({
          key: 'historyId',
          async load(loadOptions) {
            let params = vm.$_getDxDataGridParam(loadOptions);
            params = { ...params };

            const payload = {
              actionName: 'FILE_DOWNLOAD_HISTORY_SELECT',
              data: {
                ...params,
                regDt: vm.$refs.dateRangeBox.getStringDateTime(),
              },
            };

            const res = await vm.CALL_API(payload);
            if (isSuccess(res)) {
              return {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };
            } else {
              vm.$_Toast(vm.$_lang('COMMON.MESSAGE.CMN_ERROR_INTERNAL_SERVER', { defaultValue: '서버 오류 입니다.' }));
            }
          },
        });
      },
    },
    mounted() {
      this.onSearch();
    },
  };
</script>
<style scoped></style>
