import EUCCdr from '@/pages/euc/device/cdr.vue';

export default function CdrRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/cdr',
            name: 'EUCCdr',
            components: { default: EUCCdr, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}