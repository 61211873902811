<!--
  PACKAGE_NAME : src/pages/euc/phone/request/items
  FILE_NAME : index.vue.vue
  AUTHOR : jhcho
  DATE : 2024-05-21
  DESCRIPTION :
-->
<template>
  <div class="container">
    <Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
      <Tab :title="$_lang('UC.WORD.TYPE_MANAGEMENT', { defaultValue: '유형 관리' })">
        <div
            v-if="tabIndex === 0"
            class="locker_setting_list sub_new_style01 sub_ui_box1"
        >
          <Type />
        </div>
      </Tab>
      <Tab :title="$_lang('UC.WORD.DETAILED_TYPE_MANAGEMENT', { defaultValue: '상세 유형 관리' })">
        <div
            v-if="tabIndex === 1"
            class="locker_setting_list sub_new_style01 sub_ui_box1"
        >
          <TypeDetail />
        </div>
      </Tab>
      <Tab :title="$_lang('UC.WORD.AUTO_PROCESS_MANAGEMENT', { defaultValue: '자동화 관리' })">
        <div
            v-if="tabIndex === 2"
            class="locker_setting_list sub_new_style01 sub_ui_box1"
        >
          <Automation />
        </div>
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import Tab from "@/components/common/tab.vue";
import Tabs from "@/components/common/tabs.vue";
import Type from './type.vue';
import TypeDetail from './type-detail.vue';
import Automation from './automation.vue';

export default {
  name: 'EUCRequestType',
  components: {
    Tabs,
    Tab,
    Type,
    TypeDetail,
    Automation
  },
  data() {
    return {
      tabIndex: 0,
      tabs: {
        selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path] || 0,
      },
    }
  },
  methods: {
    async tabSelectedIndex(index) {
      this.tabIndex = index;
    },
  },
}
</script>