<!--
  PACKAGE_NAME : src\pages\esp\user\management\config.vue
  FILE_NAME : config
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 계정 등록/수정 페이지
-->
<template>
  <div class="form-container">
    <div class="page-sub-box clearfix ui-glid-box top-box">
      <div class="fl per50">
        <table class="table_form line-bin">
          <caption>
            <strong>계정관리</strong>
          </caption>
          <colgroup>
            <col style="width: 250px" />
            <col style="width: auto" />
          </colgroup>

          <thead class="sub_title_txt">
            <tr>
              <td colspan="2">
                <h2>계정 정보</h2>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr>
              <th scope="row">
                <label for="label5">
                  <span>아이디</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  placeholder="사용자ID"
                  v-model="formData.loginId"
                  :disabled="isUpdateMember"
                  :show-clear-button="true"
                  :max-length="50"
                  width="80%"
                  :height="30"
                  styling-mode="outlined"
                  class="mar_ri10"
                >
                  <dx-validator ref="loginIdValidationRef" validation-group="memberFormValidationGroup">
                    <dx-async-rule :validation-callback="checkValidLoginId" message="이미 사용하고 있는 ID 입니다." />
                    <dx-required-rule message="사용자ID는 필수입니다." />
                    <dx-custom-rule :validation-callback="checkEngAndNumber" message="영문, 숫자로만 구성되어야 합니다. 다시 입력하세요." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">
                  <span>이름</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  v-model="formData.loginNm"
                  placeholder="사용자명"
                  :show-clear-button="true"
                  :max-length="50"
                  width="80%"
                  :height="30"
                  styling-mode="outlined"
                  class="mar_ri10"
                >
                  <dx-validator ref="loginNmValidationRef" validation-group="memberFormValidationGroup">
                    <dx-required-rule message="사용자명은 필수입니다." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT' && config?.memberId && formData.changedPwdFl">
              <th scope="row">
                <label for="label5">
                  <span>패스워드 변경 유형</span>
                  <span class="icon_require">필수항목</span>
                  <button
                    class="btn-question"
                    id="tooltips1"
                    @mouseenter="setTooltips('changePwdType')"
                    @mouseleave="setTooltips('changePwdType')"
                  />
                  <dx-popover target="#tooltips1" :visible="config.isTooltip.changePwdType" :hide-on-outside-click="false">
                    <span
                      >‘새 패스워드’는 사용자가 수정된 패스워드를 계속 사용하도록 허용합니다.<br />
                      ‘1회용 임시 패스워드’는 사용자의 로그인 직후 패스워드 변경이 반드시 필요합니다.
                    </span>
                  </dx-popover>
                </label>
              </th>
              <td class="clearfix">
                <dx-select-box
                  v-model="formData.passwordType"
                  placeholder="패스워드 변경 유형"
                  :items="config.passwordTypeList"
                  display-expr="title"
                  value-expr="value"
                  @value-changed="handlePasswordType"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                >
                  <dx-validator ref="passwordTypeValidationRef" validation-group="memberFormValidationGroup">
                    <dx-required-rule message="패스워드 변경 유형은 필수값입니다." />
                  </dx-validator>
                </dx-select-box>
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT'">
              <th scope="row">
                <label for="label5">
                  <span>패스워드</span>
                  <span class="icon_require">필수항목</span>
                  <button
                    v-if="formData.changedPwdFl"
                    class="btn-question"
                    id="tooltips2"
                    @mouseenter="setTooltips('changePwd')"
                    @mouseleave="setTooltips('changePwd')"
                  />
                  <dx-popover
                    v-if="formData.changedPwdFl"
                    target="#tooltips2"
                    :visible="config.isTooltip.changePwd"
                    :close-on-outside-click="false"
                  >
                    <span v-html="config.passwordPolicyToolTip" />
                  </dx-popover>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  v-model="formData.loginPwd"
                  placeholder="패스워드"
                  mode="password"
                  :disabled="formData.isDisabledPwd"
                  :show-clear-button="true"
                  :max-length="50"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                  style="vertical-align: middle"
                >
                  <dx-validator ref="passwordValidateRef" validation-group="memberFormValidationGroup">
                    <dx-required-rule message="패스워드는 필수값입니다." />
                  </dx-validator>
                </dx-text-box>
                <dx-button
                  v-if="isUpdateMember"
                  :text="config.passwdChangeBtnName"
                  style="margin-left: 10px; vertical-align: middle"
                  :width="100"
                  :height="30"
                  class="default filled txt_S medium"
                  :use-submit-behavior="false"
                  @click="handlePasswordChangeClick"
                />
              </td>
            </tr>

            <tr v-if="getAuthType === 'DEFAULT'">
              <th scope="row">
                <label for="label5">
                  <span>패스워드 확인</span>
                  <span class="icon_require">필수항목</span>
                </label>
              </th>
              <td class="clearfix">
                <dx-text-box
                  v-model="formData.loginPwdConfirm"
                  placeholder="패스워드 확인"
                  mode="password"
                  :show-clear-button="true"
                  :disabled="formData.isDisabledPwd"
                  :max-length="50"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                >
                  <dx-validator ref="passwordConfirmValidateRef" validation-group="memberFormValidationGroup">
                    <dx-required-rule message="패스워드 확인값은 필수값입니다." />
                    <dx-compare-rule :comparison-target="passwordComparison" message="패스워드값과 일치하지 않습니다." />
                  </dx-validator>
                </dx-text-box>
              </td>
            </tr>

            <tr>
              <th scope="row">
                <label for="label5">
                  <span>계정 권한</span>
                  <template>
                    <button
                      class="btn-question"
                      id="tooltips2"
                      @mouseenter="setTooltips('selectAuth')"
                      @mouseleave="setTooltips('selectAuth')"
                    ></button>

                    <dx-popover target="#tooltips2" :visible="config.isTooltip.selectAuth" :hide-on-outside-click="false">
                      <span>권한을 선택하여 메뉴와 검색 대상 노출을 자동 선택할 수 있습니다.</span>
                    </dx-popover>
                  </template>
                </label>
              </th>
              <td class="clearfix">
                <dx-drop-down-box
                  ref="authIdDropDown"
                  v-model="formData.authId"
                  :data-source="config.authList"
                  display-expr="authNm"
                  value-expr="id"
                  placeholder="계정 권한 선택"
                  styling-mode="outlined"
                  width="80%"
                  :height="30"
                  class="mar_ri10"
                  :input-attr="{ 'aria-label': 'Owner' }"
                  :defer-rendering="false"
                  :show-clear-button="false"
                >
                  <template #content="{}">
                    <dx-tree-view
                      :data-source="config.authList"
                      data-structure="plain"
                      key-expr="id"
                      display-expr="authNm"
                      parent-id-expr="parentId"
                      :expand-all-enabled="true"
                      :select-by-click="true"
                      selection-mode="single"
                      @item-selection-changed="handleAuthSelectionChange"
                    />
                  </template>
                  <dx-validator ref="authValidationRef" validation-group="memberFormValidationGroup">
                    <dx-compare-rule :comparison-target="compareAuth" message="계정권한은 필수값입니다." />
                  </dx-validator>
                </dx-drop-down-box>
              </td>
            </tr>

            <tr v-if="isUpdateMember">
              <th scope="row">
                <label for="label5">
                  <span>계정 상태</span>
                </label>
              </th>
              <td>
                {{ formData.memberStateNm }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="toolbar-bottom">
      <div class="toolbar-item">
        <dx-button
          :text="$_lang('COMPONENTS.SAVE', { defaultValue: '저장' })"
          @click="handleSaveButtonClick"
          class="default filled txt_S medium"
          width="120"
          height="40"
          validation-group="memberFormValidationGroup"
        />
        <dx-button
          :text="$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' })"
          @click="handleCancelButtonClick"
          class="white filled txt_XS medium"
          width="120"
          height="40"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { DxAsyncRule, DxCompareRule, DxCustomRule, DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import { DxButton } from 'devextreme-vue/button';
  import { DxPopover } from 'devextreme-vue/popover';
  import DxTreeView from 'devextreme-vue/tree-view';
  import DxDropDownBox from 'devextreme-vue/drop-down-box';
  import { encryptPassword, isSuccess } from '@/utils/common-lib';
  import { resetRouter } from '@/router';
  import validationEngine from 'devextreme/ui/validation_engine';

  const routePath = '/esp/user/management/list'; // 리스트 페이지 경로

  export default {
    name: 'ESPUserManagementConfig',
    components: {
      DxDropDownBox,
      DxValidator,
      DxRequiredRule,
      DxCompareRule,
      DxAsyncRule,
      DxCustomRule,
      DxTextBox,
      DxSelectBox,
      DxButton,
      DxPopover,
      DxTreeView,
    },
    data() {
      return {
        config: {
          memberId: null, //
          saveBtnName: this.$_lang('COMPONENTS.SAVE', { defaultValue: '저장' }),
          cancelBtnName: this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' }),
          passwdChangeBtnName: this.$_lang('COMPONENTS.PASSWORD_CHANGE', { defaultValue: '패스워드 변경' }),
          authList: [], // 권한 리스트
          isTooltip: {
            changePwdType: false, // 패스워드 변경 유형 여부
            changePwd: false, // 패스워드 툴팁
            selectAuth: false, // 권한선택 여부
          },
          passwordPolicyToolTip: '', // 패스워드 정책 문구
          passwordTypeList: [
            //패스워드 변경 유형 여부
            {
              title: '새 패스워드',
              value: 'NEW',
            },
            {
              title: '1회용 임시 패스워드',
              value: 'TEMP',
            },
          ],
        },
        formData: {
          id: null, // Member Entity Id
          loginId: '', // 사용자ID
          loginNm: '', // 사용자명
          loginPwd: '', // 패스워드 필드
          loginPwdConfirm: '', // 패스워드 확인 필드
          prePwd: '', // 패스워드 변경 전 패스워드
          authId: -1, // NumberFormatException 때문에 -1로 선언
          changedPwdFl: true, // 패스워드 변경 여부
          isDisabledPwd: true, // 패스워드 입력창 비활성화 여부
          memberStateCd: null, // 계정 상태 코드
          memberStateNm: null, // 계정 상태 명
          passwordType: null, // 패스워드 변경 유형
        },
      };
    },
    watch: {
      '$route.query': {
        async handler(newQuery) {
          if (!newQuery) {
            this.$_goPrePage();
            return;
          }
          this.config.memberId = newQuery?.id ? newQuery?.id : null;
          await this.initData();
        },
        immediate: true,
        deep: true,
      },
    },
    computed: {
      /**
       * @description 시스템설정 사용자 인증 방식 가져오기
       * @return {*|string|string}
       */
      getAuthType() {
        return this.$_getSystemData('auth_type')?.configValue || 'DEFAULT';
      },
      /**
       * @description 업데이트 여부 확인
       * @return {boolean} null 이면 Falsy
       */
      isUpdateMember() {
        return !!this.config.memberId;
      },
      /**
       * @description 시스템설정 기본 패스워드 가져오기
       * @return {*|string}
       */
      getSystemDefaultPwd() {
        return this.$_getSystemData('default_pwd').configValue;
      },
    },
    methods: {
      /** @description : 패스워드 변경 버튼 클릭 이벤트 */
      handlePasswordChangeClick() {
        // 패스워드 변경 버튼 클릭시 패스워드 변경 여부에 따라 패스워드 입력창 활성화/비활성화
        if (!this.formData.changedPwdFl) {
          this.setPasswordChangeCancelBtnData();
        } else {
          this.setPasswordChangeBtnData();
        }

        this.$nextTick(() => {
          this.$refs.passwordValidateRef?.instance.reset(); // 패스워드 validate 초기화
          this.$refs.passwordConfirmValidateRef?.instance.reset(); // 패스워드 확인 validate 초기화
        });
      },
      /**
       * @description 패스워드 변경 취소 버튼 관련 데이터 초기화
       */
      setPasswordChangeCancelBtnData() {
        this.config.passwdChangeBtnName = this.$_lang('COMPONENTS.CANCEL', { defaultValue: '취소' });
        this.formData.passwordType = null;
        this.formData.changedPwdFl = true;
        this.formData.isDisabledPwd = true;
        this.formData.loginPwd = '';
        this.formData.loginPwdConfirm = '';
      },
      /**
       * @description 패스워드 변경 버튼 관련 데이터 초기화
       */
      setPasswordChangeBtnData() {
        this.config.passwdChangeBtnName = this.$_lang('COMPONENTS.PASSWORD_CHANGE', { defaultValue: '패스워드 변경' });
        this.formData.passwordType = null;
        this.formData.changedPwdFl = false;
        this.formData.isDisabledPwd = true;
        this.formData.loginPwd = '';
        this.formData.loginPwdConfirm = '';
      },
      /** @description: 패스워드 변경 유형 이벤트 */
      handlePasswordType(e) {
        if (e.value === 'NEW') {
          // 새 패스워드
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = false;
          this.formData.loginPwd = '';
          this.formData.loginPwdConfirm = '';
        } else if (e.value === 'TEMP') {
          // 1회용 임시 패스워드
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = true;
          this.formData.loginPwd = this.getSystemDefaultPwd;
          this.formData.loginPwdConfirm = this.getSystemDefaultPwd;
        }
      },
      /** @description: 툴팁 이벤트 */
      setTooltips(key) {
        this.config.isTooltip[key] = !this.config.isTooltip[key];
      },
      /** @description : 아이디 체크 true/false 리턴 이벤트 */
      checkValidLoginId(e) {
        return this.checkLoginId(e.value);
      },
      /** @description : 아이디 체크 API 호출 메서드  */
      async checkLoginId(loginId) {
        const payload = {
          actionName: 'MEMBER_LIST_ALL',
          data: { loginId: loginId },
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          return res.data.data.length === 0;
        }
        return false;
      },
      /** @description: 영문,숫자인지 체크하는 메서드 */
      checkEngAndNumber(e) {
        return !!this.$_commonlib.regexPatterns.isEngAndNumber.pattern.test(e.value);
      },
      /** @description : 패스워드 일치하는지 체크하는 이벤트 */
      passwordComparison() {
        return this.formData.loginPwd;
      },
      /** @description: 권한 선택 여부 validate */
      compareAuth() {
        return this.formData.authId < 0 ? 'false' : this.formData.authId;
      },
      /** @description : 권한 변경 이벤트 핸들링
       */
      handleAuthSelectionChange(e) {
        if (e.itemData) {
          this.formData.authId = e.itemData.id;
        }
      },
      /** @description : 저장/수정 버튼 클릭시 데이터 저장 */
      async handleSaveButtonClick(e) {
        // 입력폼 유효성 검사 체크
        if (!e.validationGroup.validate().isValid) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.VALIDATION_ERROR', { defaultValue: '유효성 검사 오류' }));
          return;
        }

        let loginPwd = this.formData.loginPwd;
        const loginId = this.formData.loginId;
        const loginNm = this.formData.loginNm;

        // 패스워드 유효성 체크
        if (this.formData.changedPwdFl && !this.validatePassword(loginPwd, loginId, loginNm)) {
          return;
        }

        // 아이디 중복 체크
        if (!this.isUpdateMember && !(await this.checkLoginId(loginId))) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.DUPLICATE_ID', { defaultValue: '이미 사용하고 있는 ID 입니다.' }));
          return;
        }

        if (await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }))) {
          //패스워드 hash 설정
          if (this.formData.changedPwdFl) {
            loginPwd = encryptPassword(loginId, loginPwd, this.$store.getters.getEncryptionType);
          }

          const payload = {
            actionName: 'MEMBER_LIST_INSERT',
            data: [
              {
                id: this.formData?.id,
                authId: this.formData?.authId,
                loginId: loginId,
                loginNm: loginNm,
                loginPwd: loginPwd,
                passwordType: this.formData.passwordType, // 패스워드 변경 유형
                memberState:
                  this.formData.passwordType === 'TEMP' || !this.isUpdateMember
                    ? this.$_enums.common.memberState.TEMPORARY.value
                    : this.formData.memberStateCd,
              },
            ],
            useErrorPopup: true,
          };

          const res = await this.CALL_API(payload);
          if (isSuccess(res)) {
            this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
            await resetRouter(); // 라우터 초기화
            await this.$_setStoreSiteTenant(); //사이트,센터 store 재설정
            await this.$router.push(routePath);
          }
        }
      },
      /**
       * @description 패스워드 유효성 체크
       * @param loginPwd
       * @param loginId
       * @param loginNm
       * @return {boolean}
       */
      validatePassword(loginPwd, loginId, loginNm) {
        const passwordValidCheckMessage = this.$_validatePasswordMessage(loginPwd, loginId, loginNm);

        // 패스워드 유효성 체크
        if (passwordValidCheckMessage !== '') {
          this.$_Msg(passwordValidCheckMessage);
          return false;
        }
        return true;
      },
      /** @description : 취소 버튼 클릭시 리스트 페이지로 이동 */
      handleCancelButtonClick() {
        this.$router.push(routePath);
      },
      /**
       * @description 패스워드 정책 툴팁 내용 설정
       */
      setPasswordPolicyToolTip() {
        this.config.passwordPolicyToolTip = this.$_getPasswordPolicyToHtml();
      },
      /**
       * @description 데이터 초기화
       */
      initFormData() {
        this.formData = {
          id: null, // Member Entity Id
          loginId: '', // 사용자ID
          loginNm: '', // 사용자명
          loginPwd: '', // 패스워드 필드
          loginPwdConfirm: '', // 패스워드 확인 필드
          prePwd: '', // 패스워드 변경 전 패스워드
          authId: -1, // NumberFormatException 때문에 -1로 선언
          changedPwdFl: true, // 패스워드 변경 여부
          isDisabledPwd: true, // 패스워드 입력창 비활성화 여부
          memberStateCd: null, // 계정 상태 코드
          memberStateNm: null, // 계정 상태 명
          passwordType: null, // 패스워드 변경 유형
        };

        // DOM 렌더링 이후 호출을 위해 사용
        this.$nextTick(() => {
          validationEngine.resetGroup('memberFormValidationGroup');
        });
      },
      /**
       * @description 데이터 초기화
       * @return {Promise<void>}
       */
      async initData() {
        this.initFormData();
        await this.setAuthList(); // 권한 정보 설정
        this.setPasswordPolicyToolTip(); // 패스워드 정책 툴팁 내용 설정

        // 수정일 경우 멤버 데이터 조회
        if (this.isUpdateMember) {
          const member = await this.getMemberById(Number(this.config.memberId));

          if (!member) {
            console.error('not found member data');
            this.$_goPrePage();
            return;
          }

          this.formData.changedPwdFl = false;
          this.formData.isDisabledPwd = true;
          this.formData.id = member?.id;
          this.formData.loginNm = member?.loginNm;
          this.formData.loginId = member?.loginId;
          this.formData.authId = member?.authId !== null ? Number(member?.authId) : null;
          this.formData.prePwd = member?.prePwd;
          this.formData.memberStateCd = member?.memberStateCd;

          // 계정 상태 명 설정
          this.$_enums.common.memberState.values.filter(item => {
            if (item.value === member?.memberStateCd) {
              this.formData.memberStateNm = item.label;
            }
          });
        } else {
          this.setPasswordChangeBtnData();
          this.formData.changedPwdFl = true;
          this.formData.isDisabledPwd = false;
        }
      },
      /**
       * @description 멤버 데이터 조회 (id 조건)
       * @param id
       * @return {Promise<null>}
       */
      async getMemberById(id) {
        const payload = {
          actionName: 'MEMBER_LIST_ALL',
          data: { memberId: id },
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          return res.data.data[0];
        }
        return null;
      },
      /** @description: 권한 정보 가져오기 */
      async setAuthList() {
        const payload = {
          actionName: 'AUTH_MENU_SUB_LIST',
          data: { authId: this.$store.getters.getAuthId },
        };

        const res = await this.CALL_API(payload);
        if (isSuccess(res)) {
          this.config.authList = res.data.data;
        }
      },
    },
  };
</script>

<style scoped>
  .ui-glid-box > div.fl,
  .ui-glid-box > div.fr {
    border-right: 0;
  }

  .top-box {
    height: auto;
  }

  .top-box > div {
    height: auto;
  }

  .table_form td > div {
    display: inline-block;
    vertical-align: middle;
  }

  .table_form td .empty-box {
    width: 10px;
  }

  .form-container {
    display: flex;
    flex-direction: column; /* 위에서 아래로 정렬 */
    height: 100%; /* 컨테이너가 부모에 꽉 차도록 설정 */
  }

  .toolbar-bottom {
    margin-top: auto; /* 푸터 영역처럼 항상 맨 아래로 위치 */
    padding-top: 10px; /* 추가 여백 */
    display: flex; /* Flexbox 적용 */
    justify-content: center; /* 자식 요소(버튼들)를 수평 중앙 정렬 */
  }

  .toolbar-item {
    gap: 8px;
    display: flex;
    justify-content: center; /* 수평 가운데 정렬 */
    align-items: center; /* 수직 가운데 정렬 */
  }
</style>
