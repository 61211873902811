import AILocalModelConfig from '@/pages/ai/llm-tester/local-model-config.vue';
import AILLMTesterList from '@/pages/ai/llm-tester/list.vue';
import AILLMWorkingConfigList from '@/pages/ai/llm-tester/work-config/list.vue';
import AILLMTestWorkList from '@/pages/ai/llm-tester/test-work/list.vue';
import AILLMPlaygroundList from '@/pages/ai/llm-tester/playground/list.vue';
import AILLMPlaygroundRunning from '@/pages/ai/llm-tester/playground/running-playground.vue';

export default function ConfigRoutes(commonComponents) {
  return [
    {
      path: '/ai/llm-tester/local-model-config',
      name: 'AILocalModelConfig',
      components: { default: AILocalModelConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ai/llm-tester/list',
      name: 'AILLMTesterList',
      components: { default: AILLMTesterList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ai/llm-tester/work-config/list',
      name: 'AILLMWorkingConfigList',
      components: { default: AILLMWorkingConfigList, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/ai/llm-tester/test-work/list',
      name: 'AILLMTestWorkList',
      components: { default: AILLMTestWorkList, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
    {
      path: '/ai/llm-tester/playground/list',
      name: 'AILLMPlaygroundList',
      components: { default: AILLMPlaygroundList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ai/llm-tester/playground/running-playground',
      name: 'AILLMPlaygroundRunning',
      components: { default: AILLMPlaygroundRunning, ...commonComponents },
      meta: { requiresAuth: true, isDetailPage: true },
    },
  ];
}
