<!--
  PACKAGE_NAME : src\pages\cc\ivr\ivr-ment.vue
  FILE_NAME : ivr-ment
  AUTHOR : hmlee
  DATE : 2025-03-10
  DESCRIPTION : IVR 멘트 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid
      :data-grid="dataGrid"
      :ref="dataGrid.refName"
      @init-new-row="handleInitNewRow"
      @cell-prepared="handleCellPrepared"
      @saving="handleSaving"
      @edit-canceled="handleEditCanceled"
    />

    <!-- 듣기 모달 -->
    <modal-listening
      v-if="modal.visible"
      ref="listeningModal"
      :is-open="modal.visible"
      :ment-id="modal.mentId"
      @closeModal="handleCloseModal"
    />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
  import ModalListening from "@/pages/cc/ivr/ment/modal-listen.vue";
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";
  import {DxButton} from "devextreme-vue/button";
  import {convertGridChangeEventToMergedData, mountComponent} from "@/utils/devextreme-util";
  import {isSuccess, formatDate} from "@/utils/common-lib";

  export default {
    name: 'CCIVRMent',
    components: {
      EspDxDataGrid,
      ModalListening,
    },
    props: {},
    data() {
      return {
        fileList: [], // 파일 리스트
        modal: {
          visible: false,
          mentId: {},
        },
        disabledSaving: false, // 저장 버튼 비활성화 여부
        dataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'ivrMentRefName',
          dataSource: [], //dataSource 설정 / 커스텀 조회를 위해 필요
          dataSourceDefaultSortColumn: '+mentOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          apiActionNm: {
            select: 'CC_IVR_MENT_LIST',
            update: 'CC_IVR_MENT_MERGE',
            delete: 'CC_IVR_MENT_DELETE',
          },
          showActionButtons: {
            select: true, // 조회 버튼
            excel: true, // 엑셀 다운로드
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              visible: false,
            },
            {
              caption: '이전 멘트 경로',
              dataField: 'previousPath',
              visible: false,
            },
            {
              caption: '멘트 경로', // 타이틀
              i18n: 'CC.WORD.MENT_PATH', // 다국어 처리
              dataField: 'pathCd', // 컬럼명
              lookup: { // SelectBox
                dataSource: this.$_getCode('cc_ivr_ment_path'),
                displayExpr: 'codeNm',
                valueExpr: 'codeValue'
              },
              tooltip: 'WAV 유형인 경우 파일업로드시에만 수정 가능합니다.', // 툴팁
              requiredRule: {}, //필수값 옵션 / 필수값 메시지 디폴트 설정
            },
            {
              caption: '파일업로드',
              i18n: 'CC.WORD.FILE_UPLOAD',
              dataField: 'upload',
              allowEditing: false,
              allowFiltering: false,
              excel: {
                visibleCell: false, // 엑셀 다운로드 시 노출 여부
              },
              cellTemplate: async (container, options) => {
                const uploadBtn = new DxButton({
                  propsData: {
                    elementAttr: {
                      class: `cc-ivr-ment-file-upload-${options.row.key}`,
                    },
                    icon: 'upload',
                    hint: '업로드',
                  },
                });
                uploadBtn.$mount();
                container.append(uploadBtn.$el);

                // 파일 입력 관련 생성(파일 업로드 컴포넌트 dialogTrigger 옵션이 안되어 처리)
                const fileInput = document.createElement('input');
                fileInput.type = 'file';
                fileInput.accept = 'audio/wav';
                fileInput.style.display = 'none';
                container.appendChild(fileInput);

                // 파일 선택 이벤트 처리
                fileInput.addEventListener('change', (e) => {
                  if (e.target.files && e.target.files.length > 0) {
                    const file = e.target.files[0];
                    const fileNm = file.name;
                    const currentRowIndex = options.rowIndex;

                    // 그리드 업데이트
                    options.component.cellValue(options.rowIndex, 'fileType', 'wav');
                    options.component.cellValue(options.rowIndex, 'originFileNm', fileNm);
                    options.component.cellValue(options.rowIndex, 'fileNm', fileNm);
                    options.component.cellValue(options.rowIndex, 'isWavFile', true);

                    // 현재 행의 인덱스 확인
                    const existingFileIndex = this.fileList.findIndex(
                      item => item.rowIndex === currentRowIndex
                    );

                    // 파일 객체
                    const fileObj = {
                      rowIndex: currentRowIndex,
                      file: file,
                      fileName: fileNm,
                    };

                    // 기존 파일이 있으면 교체, 없으면 추가
                    if (existingFileIndex !== -1) {
                      // 기존 파일 교체
                      this.fileList[existingFileIndex] = fileObj;
                    } else {
                      // 새 파일 추가
                      this.fileList.push(fileObj);
                    }

                    // 입력 초기화 (같은 파일 다시 선택 가능하도록)
                    e.target.value = '';
                  }
                });

                // 버튼 클릭 이벤트 - 파일 선택 대화상자 직접 열기
                uploadBtn.$el.addEventListener('click', () => {
                  fileInput.click();
                });
              }
            },
            {
              caption: '파일유형',
              i18n: 'CC.WORD.FILE_TYPE',
              dataField: 'fileType',
              lookup: { // SelectBox
                dataSource: this.$_enums.cc.ivrMentFileType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '이전파일명',
              dataField: 'previousFileNm',
              visible: false,
            },
            {
              caption: '업로드원본파일명',
              dataField: 'originFileNm',
              visible: false,
            },
            {
              caption: '파일명',
              dataField: 'fileNm',
              width: '10%',
              tooltip: 'WAV 유형인 경우 파일업로드시에만 수정 가능합니다.', // 툴팁
              requiredRule: {},
            },
            {
              caption: '멘트 내용',
              dataField: 'contents',
              i18n: 'CC.WORD.MENT_CONTENTS',
              width: '40%',
              alignment: 'left',
              requiredRule: {},
            },
            {
              caption: 'wav파일',
              dataField: 'isWavFile',
              visible: false,
            },
            {
              caption: '듣기',
              dataField: 'listening',
              i18n: 'COMPONENTS.LISTENING',
              allowEditing: false,
              allowFiltering: false,
              excel: {
                visibleCell: false, // 엑셀 다운로드 시 노출 여부
              },
              cellTemplate: async (container, options) => {
                if (options.row.isNewRow) { // 신규행인 경우 듣기 버튼 미노출
                  return;
                }

                mountComponent(
                  container,
                  EspDxButton,
                  {
                    prefixIcon: 'listen',
                    i18n: 'COMPONENTS.LISTENING',
                    color: 'white',
                    mode: 'filled',
                    size: 'xs',
                    height: '25',
                    value: options.data.id
                  },
                  {
                    handleClick: () => {
                      this.handleOpenModal(options.data.id)
                    }
                  },
                  this // Vue 인스턴스 컨텍스트 전달
                );
              },
            },
            {
              caption: '파일 다운',
              dataField: 'download',
              i18n: 'CC.WORD.FILE_DOWNLOAD',
              allowEditing: false,
              allowFiltering: false,
              excel: {
                visibleCell: false, // 엑셀 다운로드 시 노출 여부
              },
              cellTemplate: async (container, options) => {
                if (options.row.isNewRow) { // 신규행인 경우 파일 다운 버튼 미노출
                  return;
                }

                const divTag = document.createElement('div');
                divTag.className = 'inline-block';

                const downBtn = new DxButton({
                  propsData: {
                    icon: 'download',
                    hint: '다운로드',
                    onClick: e => {
                      this.downloadAudio(options.data);
                    },
                  },
                });
                downBtn.$mount();
                divTag.appendChild(downBtn.$el);
                container.append(divTag);
              },
            },
            {
              caption: '수정자',
              i18n: 'COMPONENTS.MODIFIER',
              dataField: 'editId',
              allowEditing: false,
            },
            {
              caption: '수정일시',
              i18n: 'COMPONENTS.MODIFY_DATE_TIME',
              dataField: 'editDt',
              allowEditing: false,
              calculateCellValue: this.formatRegDt,
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              visible: false,
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'mentOrd',
              visible: false,
            },
          ],
        },
      };
    },
    methods: {
      /** @description: 팝업 열기
       * @param 모달로 넘길 설정 데이터 */
      handleOpenModal(id) {
        this.modal.visible = true;
        this.modal.mentId = id;
      },
      /**
       * @description : 팝업 저장
       */
      async handleSaveModal() {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));

        this.modal.visible = false;
        this.modal.file = {};
        this.$refs[this.dataGrid.refName].refreshData();
      },
      /** @description : 팝업 닫기
       **/
      handleCloseModal() {
        this.modal.visible = false;
        this.modal.mentId = {};
      },
      /** @description: 멘트 파일 다운로드 */
      async downloadAudio(data) {
        const payload = {
          actionName: 'CC_IVR_MENT_AUDIO_DOWNLOAD',
          data: { id: data.id },
          responseType: 'blob',
          useErrorPopup: false,
        };

        const res = await this.CALL_CC_API(payload);
        if (res.status === 200) {
          const blob = new Blob([res.data], {type: res.headers['content-type']});
          const url = window.URL.createObjectURL(blob);

          // 다운로드 링크 생성 및 클릭
          const link = document.createElement('a');
          link.href = url;
          link.download = data.fileNm;
          link.click();

          // URL 리소스 해제
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
        } else {
          this.$_Msg(this.$_lang('CC.MESSAGE.FAIL_IVR_MENT_AUDIO_DOWNLOAD', { defaultValue: 'IVR 멘트 오디오 다운로드 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description: 수정일시 데이터 출력 */
      formatRegDt(rowData) {
        if (rowData?.editDt) {
          return formatDate(rowData?.editDt, 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss');
        }
      },
      /** @description: 신규행 초기 설정 이벤트 */
      handleInitNewRow(e) {
        e.data.fileType = 'tts';
        e.data.viewFl = 'Y';
        e.data.mentOrd = this.getMaxMentOrd() + 1;
      },
      /** @description: 최대 mentOrd 값 찾기 */
      getMaxMentOrd() {
        const items = this.$refs[this.dataGrid.refName].getItems;

        if (!items || items.length === 0) {
          return 0;
        }

        // 최대 mentOrd 값 찾기
        return Math.max(...items.map(item => item.mentOrd || 0));
      },
      /** @description: 셀 준비 이벤트 */
      handleCellPrepared(e) {
        if (e.rowType === 'data') {
          // 이전 경로/파일명 설정
          if (!e.data._initialValuesStored) {
            e.data.previousPath = e.data.pathCd ? JSON.parse(JSON.stringify(e.data.pathCd)) : null;
            e.data.previousFileNm = e.data.fileNm ? JSON.parse(JSON.stringify(e.data.fileNm)) : null;
            // 플래그 설정
            e.data._initialValuesStored = true;
          }

          // 멘트 경로와 파일명은 wav파일인 경우에는 파일 업로드시에만 수정 가능
          if (!e.row.isNewRow && e.row.data.fileType === 'wav' && !e.row.data.isWavFile) {
            if (e.column.dataField === 'pathCd' || e.column.dataField === 'fileNm') {
              e.cellElement.style.pointerEvents = 'none'; // 수정 불가
              e.cellElement.style.backgroundColor = '#f5f5f5';
              e.cellElement.style.color = '#888';
            }
          }

          // 파일유형은 수정 불가능
          if (e.column.dataField === 'fileType') {
            e.cellElement.style.pointerEvents = 'none'; // 수정 불가
            e.cellElement.style.backgroundColor = '#f5f5f5';
            e.cellElement.style.color = '#888';
          }
        }
      },
      /** @description: 저장 이벤트 */
      async handleSaving(e) {

        if (this.disabledSaving) { // 저장 버튼 비활성화 여부 체크
          return false;
        }
        this.disabledSaving = true;

        e.cancel = true; // false 셋팅하면 grid에 binding된 data가 변경되어버림

        // 변경된 값이 없으면 메시지 출력
        if (e?.changes.length === 0) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NO_CHANGED', { defaultValue: '변경된 데이터가 없습니다.' }));
        }

        // WAV 파일 업로드 체크
        const checkUploadWavFile = e.changes.some(item => {
          return item.data.fileType === 'wav' && !item.data.isWavFile;
        });
        if (checkUploadWavFile) { // WAV 파일인 경우 파일 업로드 체크
          this.$_Msg(this.$_lang('CC.MESSAGE.PLEASE_WAV_FILE_UPLOAD', { defaultValue: 'WAV 파일을 업로드해주세요.' }));
          return;
        }

        // 변경된 값 존재 여부 체크 && 데이터 그리드 액션명 설정 여부 체크
        const gridChanges = convertGridChangeEventToMergedData(e, 'id', false);
        gridChanges.forEach(item => {
          if (!item.fileType && !item.isWavFile) { // 파일업로드를 안한 경우에는 기본 tts 설정
            item.fileType = 'tts';
          }
          delete item.regId;
          delete item.regDt;
          delete item.editId;
          delete item.editDt;
        });

        const formData = new FormData();
        formData.append('listData', JSON.stringify(gridChanges));

        if (Array.isArray(this.fileList)) {
          this.fileList.forEach(fileObj => {
            formData.append('file', fileObj.file);
          });
        }

        const payload = {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          actionName: 'CC_IVR_MENT_MERGE',
          data: formData,
        };

        const res = await this.CALL_CC_API(payload);
        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          e.component.cancelEditData();
          this.$refs[this.dataGrid.refName].refreshData();
          this.fileList = []; // 파일 리스트 초기화
          this.disabledSaving = false;
        } else {
          e.component.cancelEditData();
        }
      },
      /** @description: 수정 취소 이벤트 */
      handleEditCanceled(e) {
        this.fileList = []; // 파일 리스트 초기화
      },
    },
    mounted() {},
  };
</script>