<!--
  PACKAGE_NAME : src\pages\ai\llm-tester\playground
  FILE_NAME : running-playground
  AUTHOR : hpmoon
  DATE : 2024-12-04
  DESCRIPTION : AI > LLM > Playground Chat
-->
<template>
  <div ref="runningPlayground" class="page-sub-box flex">
    <div class="page-left-box">
      <div class="head-btn-left">
        <esp-dx-button
          i18n="LLM_TESTER.WORD.RETURN"
          text="돌아가기"
          prefix-icon="return"
          color="white"
          class="mar_ri10"
          @click="handleGoToList"
        />
        <esp-dx-button
          :disabled="queryList.length === 0 || !validSession || loading"
          i18n="COMMON.WORD.INIT_SESSION"
          text="세션 초기화"
          prefix-icon="refresh"
          color="white"
          @click="initPlayGround"
        />
        <esp-dx-button
          :disabled="queryList.length === 0 || !validSession || loading"
          i18n="COMPONENTS.EXCEL"
          text="엑셀"
          prefix-icon="excel"
          color="green"
          class="mar_la mar_ri10"
          @click="handleExcelDownload"
        />
      </div>

      <dx-scroll-view :height="configScrollViewHeight">
        <table id="configTable" class="table_form line-bin">
          <colgroup>
            <col style="width: 140px" />
            <col style="width: auto" />
          </colgroup>

          <tbody>
          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.SESSION_ID', { defaultValue: '세션 아이디' }) }}</label>
            </th>
            <td>
              {{ formData.session_id }}
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.MODEL', { defaultValue: '모델' }) }}</label>
            </th>
            <td>
              {{ model.codeNm }}
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.SYSTEM_PROMPT', { defaultValue: '시스템 프롬프트' }) }}</label>
            </th>
            <td>
              <dx-text-area
                v-model="formData.system_prompt"
                :max-length="limitNumberTexts.maxLengths.system_prompt"
                :styling-mode="stylingMode"
                class="mar_ri10 alB"
                :height="200"
                @key-up="$_checkLimitTextLength($event, formData, limitNumberTexts, 'system_prompt')"
              />

              <div class="fr">
              <span>
                {{
                  limitNumberTexts.textLengths.system_prompt
                    ? limitNumberTexts.textLengths.system_prompt
                    : formData.system_prompt
                      ? formData.system_prompt.length
                      : 0
                }}
              </span>/{{ limitNumberTexts.maxLengths.system_prompt }}
              </div>
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">Temperature</label>
            </th>
            <td>
              <dx-select-box
                v-model="formData.temperature"
                :styling-mode="stylingMode"
                :style="{ marginRight: '9px' }"
                :height="30"
                width="100"
                :items="lookUp.zeroToOne"
                value-expr="key"
                display-expr="value"
              />
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">Max Tokens</label>
            </th>
            <td>
              <dx-number-box
                v-model="formData.max_tokens"
                :min="1"
                :max="2000"
                :show-spin-buttons="true"
                validation-message-position="right"
                class="mar_ri10"
                :width="100"
                :styling-mode="stylingMode"
                format="#"
              />
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">Top P</label>
            </th>
            <td>
              <dx-select-box
                v-model="formData.top_p"
                :styling-mode="stylingMode"
                :style="{ marginRight: '9px' }"
                :height="30"
                width="100"
                :items="lookUp.zeroToOne"
                value-expr="key"
                display-expr="value"
              />
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">Frequency penalty</label>
            </th>
            <td>
              <dx-select-box
                v-model="formData.frequency_penalty"
                :styling-mode="stylingMode"
                :style="{ marginRight: '9px' }"
                :height="30"
                width="100"
                :items="lookUp.zeroToTwo"
                value-expr="key"
                display-expr="value"
              />
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">Presence penalty</label>
            </th>
            <td>
              <dx-select-box
                v-model="formData.presence_penalty"
                :styling-mode="stylingMode"
                :style="{ marginRight: '9px' }"
                :height="30"
                width="100"
                :items="lookUp.zeroToTwo"
                value-expr="key"
                display-expr="value"
              />
            </td>
          </tr>

          <tr>
            <th scope="row">
              <label for="label5">{{ $_lang('LLM_TESTER.WORD.SEARCH_FLAG', { defaultValue: '검색 여부' }) }}</label>
            </th>
            <td class="search-container">
              <dx-switch v-model="formData.search_flag" class="mar_ri10" />
              <dx-select-box
                :disabled="!formData.search_flag"
                :placeholder="$_lang('LLM_TESTER.WORD.SELECT_PROJECT', { defaultValue: '프로젝트 선택' })"
                v-model="formData.project_id"
                :styling-mode="stylingMode"
                :style="{ marginRight: '9px' }"
                :height="30"
                width="200"
                :items="projectList"
                value-expr="id"
                display-expr="name"
              >
                <dx-validator validation-group="validationGroupName">
                  <dx-required-rule v-if="formData.search_flag"
                                    :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS',
                                { value: $_lang('LLM_TESTER.WORD.PROJECT', {defaultValue: '프로젝트'}), defaultValue: '[프로젝트] 은/는 필수값 입니다' })"
                  />
                </dx-validator>
              </dx-select-box>
            </td>
          </tr>
          </tbody>
        </table>
      </dx-scroll-view>
    </div>

    <div class="page-right-box">
      <dx-scroll-view id="resultScrollView" ref="resultScrollView" width="100%" :height="queryListScrollViewHeight" class="mar_b10 right-box-back">
        <div v-if="queryList.length === 0">
          <div class="notice-box" :style="{height: queryListScrollViewHeight}">
            <span>{{ $_lang('LLM_TESTER.MESSAGE.DO_ENTER_QUERY', { defaultValue: '질의를 입력하여 Playground를 이용하세요' }) }}</span>
          </div>
        </div>
        <div v-else>
          <query-result
            v-for="(query, index) in queryList"
            :form-data="formData"
            :query="query"
            :search_flag="formData.search_flag"
            :key="index"
            @loading="loading = $event"
            @disconnectSession="validSession = false"
          />
        </div>
      </dx-scroll-view>

      <div class="chat-area pad_le15">
        <dx-text-area
          id="queryArea"
          :placeholder="$_lang('COMMON.WORD.ENTER_QUERY', { defaultValue: '질의 입력' })"
          v-model="formData.query"
          width="100%"
          :styling-mode="stylingMode"
          :auto-resize-enabled="true"
          value-change-event="input"
          @value-changed="setScrollViewHeight"
          @key-down="handleTextAreaKeyDown"
          max-height="300px"
          class="mar_ri10"
        />

        <dx-button
          :disabled="!enableEnter"
          class="btn-icon default filled send"
          :height="44"
          :width="44"
          @click="enterQuery"
        />
      </div>

    </div>

  </div>
</template>

<script>
  import { DxButton } from "devextreme-vue/button";
  import { DxScrollView } from "devextreme-vue/scroll-view";
  import DxTextArea from "devextreme-vue/text-area";
  import QueryResult from "@/pages/ai/llm-tester/playground/query-result.vue";
  import { DxRequiredRule, DxValidator } from "devextreme-vue/validator";
  import { DxNumberBox } from "devextreme-vue/number-box";
  import { DxSelectBox } from "devextreme-vue/select-box";
  import DxSwitch from "devextreme-vue/switch";
  import { isEmpty } from "@/utils/common-lib";
  import validationEngine from "devextreme/ui/validation_engine";
  import EspDxButton from "@/components/devextreme/esp-dx-button.vue";

  export default {
    name: 'AILLMPlaygroundRunning',
    components: {
      EspDxButton,
      DxSwitch,
      DxSelectBox,
      DxNumberBox,
      DxRequiredRule, DxValidator,
      DxButton,
      DxScrollView,
      DxTextArea,
      QueryResult,
    },

    watch: {
      /** @description 답변 대기 loading 감지 */
      loading(loadingFlag) {
        if (!loadingFlag) {
          this.$nextTick(() => {
            const resultScrollView = this.$refs.resultScrollView.instance;
            resultScrollView.scrollTo(resultScrollView.scrollHeight());
          });
        }
      },

      /** @description Max Tokens 값 변경 감지 */
      formData: {
        handler(newValue) {
          if (isEmpty(newValue.max_tokens)) {
            this.$nextTick(() => {
              newValue.max_tokens = 512;
            });
          }
        },
        deep: true,
      },
    },

    data() {
      return {
        stylingMode: 'outlined',
        model: Object,
        scopedAttribute: '', // CSS 스코프 식별자
        configScrollViewHeight: '0px', // 설정 영역 ScrollView 높이
        queryListScrollViewHeight: '0px', // 질의 결과 영역 ScrollView 높이
        projectList: [], // 프로젝트 리스트
        formData: {
          session_id: null, // 세션 아이디
          system_prompt: '',  // 시스템 프롬프트
          temperature: 1.0,
          max_tokens: 512,
          top_p: 1.0,
          frequency_penalty: 0.0,
          presence_penalty: 0.0,
          search_flag: false, // 검색 여부
          project_id: null, // 선택 프로젝트
          query: '',  // 질의 내용
        },
        queryList: [], // 질의 리스트
        loading: false, // 로딩중 Flag
        validSession: false, // 세션 유효 여부
        deleteSessionFlag: false, // 세션 삭제 여부
        lookUp: {
          zeroToOne: [],
          zeroToTwo: [],
        },
        limitNumberTexts: {
          textLengths: {},
          maxLengths: {
            system_prompt: 2000,
          },
        },
      };
    },

    computed: {
      /** @description 입력 버튼 활성화 여부 */
      enableEnter() {
        return this.formData.query.trim() !== '' && !this.loading && this.validSession;
      },
    },

    methods: {
      /** @description TextArea KeyDown 이벤트 처리 메서드 */
      handleTextAreaKeyDown(e) {
        if (e.event.key === 'Enter') {
          e.event.preventDefault(); // 기본 Enter 키 동작 방지
          if (e.event.shiftKey) {
            // Shift + Enter일 경우 줄바꿈 처리
            const textArea = e.event.target;
            const cursorPosition = textArea.selectionStart;
            this.formData.query = `${ this.formData.query.slice(0, cursorPosition) }\n${ this.formData.query.slice(cursorPosition) }`;
            this.$nextTick(() => {
              // 커서를 줄바꿈 후 위치로 설정
              textArea.selectionStart = textArea.selectionEnd = cursorPosition + 1;
            });

          } else {
            // 단순 Enter일 경우 enterQuery 메서드 실행
            this.enterQuery();
          }
        }
      },

      /** @description 질의 입력 메서드 */
      enterQuery() {
        if (this.enableEnter) {
          const validationResult = validationEngine.validateGroup('validationGroupName');
          if (!validationResult.isValid) {
            this.$_Msg(this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR', { defaultValue: '필수값을 입력해주세요.' }));
            return;
          }

          this.queryList.push(this.formData.query);
          this.formData.query = '';
          this.loading = true;

          this.$nextTick(() => {
            const resultScrollView = this.$refs.resultScrollView.instance;
            resultScrollView.scrollTo(resultScrollView.scrollHeight());
          });
        }
      },

      /** @description session 삭제 메서드 */
      async deleteSession() {
        if (this.validSession && !this.deleteSessionFlag) {
          if (!isEmpty(this.formData.session_id)) {
            const payload = {
              actionName: 'LLM_TESTER_PLAYGROUND_DELETE',
              data: {
                id: this.formData.session_id,
              },
              loading: true,
            };
            const res = await this.CALL_LLM_TESTER_API(payload);
            if (res.status === 200) {
              this.deleteSessionFlag = true;
            } else {
              this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
              return false;
            }
          }
        }
      },

      /** @description 돌아가기 클릭 */
      async handleGoToList() {
        await this.deleteSession();
        await this.$router.push('/ai/llm-tester/playground/list');
      },

      /** @description 세션 초기화 클릭 */
      async initPlayGround() {
        if (await this.$_Confirm(this.$_lang('LLM_TESTER.MESSAGE.INIT_SESSION_ALERT', { defaultValue: '세션 초기화시 현재의 질의및 답변이 모두 삭제됩니다.<br/> 정말 초기화 하시겠습니까?' }))) {
          const payload = {
            actionName: 'LLM_TESTER_PLAYGROUND_REFRESH',
            data: {
              id: this.formData.session_id
            },
            loading: true,
          };
          const res = await this.CALL_LLM_TESTER_API(payload);
          if (res.status === 200) {
            this.formData.session_id = res.data;
            this.loading = false;

            this.formData.query = '';
            this.queryList = [];
          } else {
            if (res.data.detail === 'Data is not found') {
              this.validSession = false;
              this.$_Msg(this.$_lang('LLM_TESTER.MESSAGE.DISCONNECT_SESSION_ALERT', { defaultValue: 'Playground 세션이 종료되었습니다. <br/>사용하시려면 페이지를 재진입 해주세요.' }));
            } else {
              this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
              return false;
            }
          }
        }
      },

      /** @description 엑셀 다운로드 클릭 */
      async handleExcelDownload() {
        const payload = {
          actionName: 'LLM_TESTER_PLAYGROUND_MESSAGES',
          data: {
            session_id: this.formData.session_id,
          },
          responseType: 'blob',
          loading: false,
        };
        const res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          const disposition = res.headers['content-disposition'];
          const filenameRegex = /filename\*=UTF-8''([^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          let filename = '';
          if (matches && matches[1]) {
            filename = decodeURIComponent(matches[1]);
          }

          const blob = new Blob([res.data]);

          const reader = new FileReader();
          reader.onload = function () {
            const downloadLink = reader.result.toString();
            const link = document.createElement('a');
            link.href = downloadLink;
            link.setAttribute('download', filename);
            link.click();
          };
          reader.readAsDataURL(blob);
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }
      },

      /** @description ScrollView 높이 Setting 메서드 */
      setScrollViewHeight() {
        this.$nextTick(() => {
          this.queryListScrollViewHeight = `calc(100vh - ${ this.topElement(`#resultScrollView[${this.scopedAttribute}]`) }px - ${ this.heightElement(`#queryArea[${this.scopedAttribute}]`) }px - 30px)`;
          this.$nextTick(() => {
            const resultScrollView = this.$refs.resultScrollView.instance;

            const scrollOffset = resultScrollView.scrollOffset(); // 현재 스크롤 위치 가져오기
            const scrollHeight = resultScrollView.scrollHeight(); // 전체 스크롤 가능한 높이
            const clientHeight = this.heightElement(`#resultScrollView[${this.scopedAttribute}]`); // 보이는 영역 높이

            if (Math.ceil(scrollOffset.top + clientHeight + 25) >= scrollHeight) {
              resultScrollView.scrollTo(resultScrollView.scrollHeight());
            }
          });
        });
      },

      /** @description Element Top 높이 계산 메서드 */
      topElement(e) {
        const divElement = document.querySelector(e);
        const rect = divElement.getBoundingClientRect();
        return rect.top;
      },

      /** @description Element 높이 계산 메서드 */
      heightElement(e) {
        const divElement = document.querySelector(e);
        const computedStyle = window.getComputedStyle(divElement);
        const divHeight = divElement.offsetHeight;
        const marginTop = parseFloat(computedStyle.marginTop);
        const marginBottom = parseFloat(computedStyle.marginBottom);
        return divHeight + marginTop + marginBottom;
      },
    },

    /** @description 라이프사이클 created 시 호출되는 메서드 */
    async created() {
      this.model = this.$route.query.model;

      if (isEmpty(this.model.codeValue)) {
        await this.handleGoToList();
      } else {
        let payload = {
          actionName: 'LLM_TESTER_PLAYGROUND_RUN',
          data: {
            person_id: this.$store.getters.getLoginId,
            language_model_name: this.model.codeValue,
          },
          loading: true,
        };
        let res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.formData.session_id = res.data;
          this.validSession = true;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }

        payload = {
          actionName: 'LLM_TESTER_PROJECT_LIST',
          data: {},
          loading: false,
        };
        res = await this.CALL_LLM_TESTER_API(payload);
        if (res.status === 200) {
          this.projectList = res.data;
        } else {
          this.$_Msg(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
          return false;
        }

        for (let i = 0; parseFloat(i.toFixed(1)) <= 2; i += 0.1) {
          if (parseFloat(i.toFixed(1)) <= 1) {
            this.lookUp.zeroToOne.push({ key: parseFloat(i.toFixed(1)), value: i.toFixed(1) });
          }
          this.lookUp.zeroToTwo.push({ key: parseFloat(i.toFixed(1)), value: i.toFixed(1) });
        }
      }
    },

    /** @description 라이프사이클 mounted 시 호출되는 메서드 */
    mounted() {
      this.scopedAttribute = this.$refs.runningPlayground.attributes[0].name;
      this.setScrollViewHeight();
      this.configScrollViewHeight = `calc(100vh - ${ this.topElement('#configTable') }px - 20px)`;
    },

    /** @description 라이프사이클 인스턴스 해제직전 호출되는 메서드 */
    async beforeDestroy() {
      await this.deleteSession();
    },
  };
</script>

<style lang="scss" scoped>
  .page-left-box {
    width: 40%;
    padding-top: 10px;
  }

  .head-btn-left {
    display: flex;
    margin-bottom: 10px;
  }

  .mar_la {
    margin-left: auto;
  }

  .page-right-box {
    width: 60%;
    padding-top: 10px;
    padding-bottom: 20px;
  }

  .right-box-back {
    background-color: #FAFAFA;
  }

  .chat-area {
    display: flex;
    align-items: center;
  }

  .search-container {
    display: flex;
    align-items: center;
  }

  .dx-switch {
    top: unset;
    position: unset;
    transform: unset;
  }

  .dx-button.send:before {
    left: 11px;
  }

  .notice-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  ::v-deep {
    #queryArea .dx-placeholder {
      top: 50%;
      transform: translate(0, -50%);
    }
  }
</style>