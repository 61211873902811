import CCSkillAssignList from '@/pages/cc/skl/assign/list';
import CCSkillGroupList from '@/pages/cc/skl/group/list';
import CCSkillAssignmentHistory from '@/pages/cc/skl/history/skl-assignment-hist';

export default function SkillRoutes(commonComponents) {
  return [
    {
      path: '/cc/skl/assign/list',
      name: 'CCSkillAssignList',
      components: { default: CCSkillAssignList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/skl/group/list',
      name: 'CCSkillGroupList',
      components: { default: CCSkillGroupList, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/skl/history/skl-assignment-hist',
      name: 'CCSkillAssignmentHistory',
      components: { default: CCSkillAssignmentHistory, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
