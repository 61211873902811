import EUCChineseWall from '@/pages/euc/chinese-wall';

export default function EUCChineseWallRoutes(commonComponents) {
    return [
        {
            path: '/euc/chinese-wall',
            name: 'EUCChineseWall',
            components: { default: EUCChineseWall, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}