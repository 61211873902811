<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
import EspDxDataGrid from "@/components/devextreme/esp-dx-data-grid-v2.vue";

export default {
  name: 'CCSkillGroupConfig',
  components: {
    EspDxDataGrid,
  },
  props: {},
  watch: {},
  data() {
    return {
      dataGrid: {
        callApi: 'CALL_CC_API',
        refName: 'sklGrid',
        dataSourceDefaultSortColumn: '+sklOrd',
        dataSource: [],
        apiActionNm: {
          select: 'CC_SKL_LIST',
          update: 'CC_SKL_MERGE',
        },
        showActionButtons: {
          select: true,
          add: false,
          save: true,
          delete: false,
        },
        dragging: { // 드래그 설정
          sortKey: 'sklOrd', // 정렬 컬럼
        },
        columns: [
          {
            caption: '사이트명',
            i18n: 'COMMON.WORD.SITE_NM',
            dataField: 'siteId',
            allowEditing: false,
            lookup: {
              dataSource: this.$store.getters.getSiteList,
              displayExpr: "siteNm",
              valueExpr: "id"
            },
          },
          {
            caption: '센터명',
            i18n: 'COMMON.WORD.TENANT_NM',
            dataField: 'tenantId',
            lookup: {
              dataSource: this.$store.getters.getTenantList,
              displayExpr: "tenantNm",
              valueExpr: "id"
            },
            requiredRule: {},
          },
          {
            caption: '그룹 ID',
            i18n: 'COMPONENTS.GROUP' + 'ID',
            dataField: 'id',
            allowEditing: false,
          },
          {
            caption: '상담그룹명',
            i18n: 'CC.WORD.SKL_NAME',
            dataField: 'sklNm',
            requiredRule: {},
          },
          {
            caption: 'CTI 이름',
            i18n: 'CC.WORD.CTI_NAME',
            dataField: 'skl',
            allowEditing: false,
          },
          {
            caption: '순서',
            i18n: 'COMPONENTS.ORDER',
            dataField: 'sklOrd',
          },
          {
            caption: '사용여부',
            i18n: 'COMPONENTS.USE_STATUS',
            dataField: 'viewFl',
            lookup: {
              dataSource: this.$_enums.common.stringUsedFlag.values,
              displayExpr: 'label',
              valueExpr: 'value',
            },
          },
        ]
      },
    }
  },
}
</script>