import EUCRequestList from '@/pages/euc/phone/request/list';
import EUCRequestUpdate from '@/pages/euc/phone/request/update';
import EUCRequestWorker from '@/pages/euc/phone/request/worker';
import EUCRequestHistory from '@/pages/euc/phone/request/history';
import RequestTypeRoutes from "@/router/euc/phone/request/type";

export default function RequestRoutes(commonComponents) {
    return [
        {
            /**
             * @description 전화 신청 목록 페이지
             * */
            path: '/euc/phone/request/list',
            name: 'EUCRequestList',
            components: { default: EUCRequestList, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 전화 신청 등록 및 수정 상세 페이지
             * */
            path: '/euc/phone/request/update',
            name: 'EUCRequestUpdate',
            components: { default: EUCRequestUpdate, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 작업자 관리 페이지
             * */
            path: '/euc/phone/request/worker',
            name: 'EUCRequestWorker',
            components: { default: EUCRequestWorker, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 작업 이력 목록 페이지
             * */
            path: '/euc/phone/request/history',
            name: 'EUCRequestHistory',
            components: { default: EUCRequestHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
        ...RequestTypeRoutes(commonComponents)
    ]
}