<!--
  PACKAGE_NAME : src\pages\esp\auth\list.vue
  FILE_NAME : list
  AUTHOR : devyoon91
  DATE : 2024-08-29
  DESCRIPTION : 권한관리 페이지
-->
<template>
  <div class="page-container">
    <div class="left-box">
      <auth-tree-list
        ref="authTreeList"
        @auth-tree-selection-changed="handleAuthTreeSelectionChanged"
        :prop-refresh-auth-page="sendAuthPageRefresh"
      />
      <auth-member-list ref="authMemberList" :select-auth-info="authInfo" />
    </div>
    <div class="right-box">
      <template>
        <div class="tabs-container">
          <tabs id="tabs" ref="tabs" :tabType="2" tab-css-class="tabInnerClass" @selectedIndex="handleTabSelectedIndex">
            <tab title="메뉴 권한">
              <menu-auth-tree-list ref="menuAuthTreeList" />
            </tab>
            <tab title="검색 대상 권한">
              <search-target-page ref="searchTargetPage" />
            </tab>
          </tabs>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import Tabs from '@/components/common/tabs.vue';
  import Tab from '@/components/common/tab.vue';
  import MenuAuthTreeList from './menu-auth-tree-list.vue';
  import AuthTreeList from './auth-tree-list.vue';
  import AuthMemberList from './auth-member-list.vue';
  import SearchTargetPage from './search-target-page.vue';
  import { resetRouter } from '@/router';

  export default {
    name: 'ESPAuthList',
    components: {
      SearchTargetPage,
      Tab,
      Tabs,
      MenuAuthTreeList,
      AuthTreeList,
      AuthMemberList,
    },
    data() {
      return {
        authInfo: {
          // 권한 정보 입력 폼 prop
          id: null,
          authNm: null,
          site: '',
          tenant: '',
          authMenuList: [],
        },
      };
    },
    methods: {
      /**
       * @description 권한트리리스트 선택 이벤트
       * @param e
       * @return {Promise<void>}
       */
      async handleAuthTreeSelectionChanged(e) {
        if (e.selectedRowsData.length <= 0) return;

        const row = { data: e.selectedRowsData[0] };
        this.authInfo.id = row.data.id;
        this.authInfo.authNm = row.data.authNm;

        const selectedAuthId = this.$refs.authTreeList.getSelectedId();

        // 메뉴권한 초기화
        await this.$refs.menuAuthTreeList.setAuthMenuTreeList(selectedAuthId);
        // 사용자 리스트 조회
        await this.$refs.authMemberList.setAuthMemberList(selectedAuthId);
        // 검색 대상 페이지 초기화
        await this.$refs.searchTargetPage.setAuthGroup(selectedAuthId);
      },
      /**
       * @description 탭 클릭 이벤트
       * @param index {number} - 탭 인덱스
       */
      async handleTabSelectedIndex(index) {
        switch (index) {
          case 0:
            this.$refs.menuAuthTreeList.getInstance()?.refresh();
            break;
          case 1:
            await this.$refs.searchTargetPage.setAuthGroup(this.$refs.authTreeList.getSelectedId());
            break;
          default:
            break;
        }
      },
      /**
       * 권한 페이지 새로고침
       *
       * @return {Promise<void>}
       */
      async sendAuthPageRefresh() {
        await resetRouter(); // 라우터 초기화
        await this.$_setStoreSiteTenant(); // 사이트,센터 store 재설정
        this.$refs.authTreeList.initData();
      },
    },
    created() {},
    mounted() {},
  };
</script>
<style lang="scss" scoped>
  .page-container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 146px);
    background: #fff;
    padding: 20px 20px 0 20px;
    border-radius: 10px 10px 0 0;
    z-index: 3;
  }

  .left-box {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .left-box > * {
    flex: 1;
  }

  .right-box {
    flex: 4;
    padding-left: 10px;
  }
</style>
