import EMCAgentConfig from '@/pages/cc/emc/config/agent';
import EMCNotReadyReasonConfig from '@/pages/cc/emc/config/nreason';
import EMCSkillGroupConfig from '@/pages/cc/emc/config/skl';
import EMCAgentSkillAssign from '@/pages/cc/emc/skl/assign/list';

export default function EMCConfigRoutes(commonComponents) {
  return [
    {
      path: '/cc/emc/config/agent',
      name: 'EMCAgentConfig',
      components: { default: EMCAgentConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/emc/config/nreason',
      name: 'EMCNotReadyReasonConfig',
      components: { default: EMCNotReadyReasonConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/emc/config/skl',
      name: 'EMCSkillGroupConfig',
      components: { default: EMCSkillGroupConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/emc/skl/assign/list',
      name: 'EMCAgentSkillAssign',
      components: { default: EMCAgentSkillAssign, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
