<template>
  <div class="main">
    <esp-dx-data-grid :data-grid="dataGrid" ref="deviceGrid"/>
  </div>
</template>

<script>
import {isSuccess} from '@/utils/common-lib';
import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';
import {getUCServerPath} from '@/pages/euc/common-uc';

export default {
  name: 'EUCCiscoDevice',
  components: {
    EspDxDataGrid,
  },
  data() {
    return {
      cmList: [],
      stylingMode: 'outlined', //outlined, underlined, filled
      dataGrid: {
        callApi: 'CALL_EUC_API',
        refName: 'deviceGrid',
        dataSourceDefaultSortColumn: '+regDt,+id',
        apiActionNm: {
          select: 'EUC_DEVICE_CISCO_LIST_ALL',
          delete: 'EUC_DEVICE_CISCO_DELETE'
        },
        excel: {
          title: this.$_lang('UC.WORD.DEVICE_INFO', {defaultValue: '전화 정보'}),
        },
        showActionButtons: {
          excel: true,
          customButtons: [
            {
              widget: 'dxButton',
              options: {
                text: this.$_lang('UC.WORD.DEVICE_SYNC', {defaultValue: '장치 동기화'}),
                elementAttr: {class: 'btn_XS default filled'},
                width: 90,
                height: 30,
                onClick: () => {
                  this.onSyncDevice();
                },
              },
              location: 'before',
            },
            {
              widget: 'dxButton',
              options: {
                icon: '',
                text: this.$_lang('UC.WORD.LABEL_SYNC', {defaultValue: '레이블 동기화'}),
                elementAttr: {class: 'btn_XS default filled'},
                height: 30,
                onClick: async () => {
                  const configStr = this.$_getSystemData('use_long_time_confirm')?.configValue;
                  if (configStr) {
                    const config = JSON.parse(configStr);
                    if (config.value === 'Y') {
                      if (await this.$_Confirm(config.message, { confirmButtonText: '강제적용' })) {
                        await this.onSyncLabel();
                      }
                    }
                  } else {
                    await this.onSyncLabel();
                  }
                },
              },
              location: 'before',
            }
          ]
        },
        editing: {
          allowAdding: false,
          allowUpdating: false,
        },
        columns: [
          {
            caption: '모델',
            i18n: 'UC.WORD.MODEL',
            dataField: 'model',
            width: 80,
            allowEditing: false,
            cellTemplate: async (container, options) => {
              const imgTag = document.createElement('img');
              imgTag.setAttribute('src', `${getUCServerPath()}/euc/api/v1/resource/DEVICE_CISCO_SMALL_IMAGE_DIR/${options.data.product}.gif`);
              container.append(imgTag);

            },
          },
          {
            caption: '장치이름',
            i18n: 'UC.WORD.DEVICE_NAME',
            dataField: 'name',
            allowEditing: false,
            cellTemplate: (container, options) => {
              const aTag = document.createElement('a');
              aTag.innerText = options.value;
              aTag.addEventListener('click', async () => {
                const type = 'device';
                await this.onSelectData(options.data, type);
              });
              container.append(aTag);
            },
          },
          {
            caption: '교환기',
            i18n: 'UC.WORD.EXCHANGER',
            dataField: 'cmNm',
            width: 120,
            allowEditing: false,
            calculateDisplayCellValue: rowData => this.getCmNm(rowData.cmNm),
          },
          {
            caption: '설명',
            i18n: 'COMPONENTS.DESCRIPTION',
            dataField: 'description',
            allowEditing: false,
          },
          {
            caption: '장치풀',
            i18n: 'UC.WORD.DEVICEPOOL',
            dataField: 'devicepool',
            width: 120,
            allowEditing: false,
          },

          {
            caption: '상태',
            i18n: 'UC.WORD.STATUS',
            dataField: 'status',
            width: 120,
            allowEditing: false,
          },
          {
            caption: 'IP',
            dataField: 'ipaddress',
            width: 120,
            allowEditing: false,
          },
          {
            caption: '내선번호',
            i18n: 'UC.WORD.NUMBER',
            dataField: 'number',
            width: 120,
            allowEditing: false,
          },
          {
            caption: '레이블',
            i18n: 'UC.WORD.LABEL',
            dataField: 'label',
            allowEditing: false,
          },
          {
            caption: '수정일시',
            i18n: 'COMPONENTS.MODIFY_DATE',
            dataField: 'editDt',
            width: 150,
            calculateCellValue: this.formatDt,
          },
          {
            caption: '복사',
            i18n: 'COMPONENTS.COPY',
            dataField: 'supercopy',
            width: 80,
            allowEditing: false,
            cellTemplate: async (container, options) => {
              const imgTag = document.createElement('img');
              imgTag.setAttribute('src', `${getUCServerPath()}/euc/api/v1/resource/DEVICE_CISCO_SMALL_IMAGE_DIR/supercopy.gif`);
              imgTag.style.cursor = 'pointer';
              imgTag.addEventListener('click', async () => {
                await this.onSelectData(options.data, 'supercopy');
              });
              container.append(imgTag);
            },
          },
        ],
      },
    };
  },
  methods: {
    /** @description : 날짜 형식 변환 메서드 */
    formatDt(rowData) {
      if (rowData?.editDt) {
        return this.$_commonlib.formatDate(rowData.editDt, 'YYYYMMDDHHmmssSSS', 'YYYY.MM.DD HH:mm:ss');
      }
    },
    async onSyncLabel() {
      const payload = {
        actionName: 'EUC_LABEL_SYNC',
        data: {},
        loading: true
      }

      const res = await this.CALL_EUC_API(payload);
      if(isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUCCESS', {defaultValue: '정상적으로 처리되었습니다.'}), {icon: 'success'});
      } else {
        this.$_Msg(res.data.header?.resMsg || this.$_lang('COMMON.MESSAGE.CMN_ERROR', {defaultValue: '데이터 처리 중 오류가 발생하였습니다.'}), {icon: 'error'});
      }
    },
    /** @description : 단말 정보 상세 페이지 이동 메서드 */
    onSelectData(data, type) {
      const path = `/euc/device/cisco/${type === 'device' ? '/detail' : 'supercopy'}`;
      this.$store.commit('setDetailParams', {deviceData: data});
      this.$router.push({path});
    },
    /**@description 교환기 목록 가져오는 메서드 */
    async selectCmList(sort = '+cmOrd') {
      const payload = {
        actionName: 'EUC_CM_SELECT',
        data: {
          sort,
          loginId: this.$store.getters.getLoginId,
          viewFl: 'Y',
        },
        loading: false,
      };

      const res = await this.CALL_EUC_API(payload);
      if (isSuccess(res)) {
        this.cmList = res.data.data.map((item) => {
          return {
            cmNm: item.cmNm,
            cmOrd: item.cmOrd,
            description: item.description,
            editId: item.editId,
            editDt: item.editDt,
            regId: item.regId,
          };
        });
      }
    },
    /** @description 교환기 이름 한글 변환 메서드
     *  @param {String} cm Name
     *  @returns {String} 한글명
     */
    getCmNm(cm) {
      return this.cmList?.find(d => d.cmNm === cm)?.description || '';
    },
    /**@description 전화기 동기화 메서드*/
    async onSyncDevice() {
      const confirm = this.$_lang('UC.MESSAGE.START_SYNC', {defaultValue: 'DB데이터를 동기화 하시겠습니까?'});

      if (!(await this.$_Confirm(confirm))) {
        return;
      }

      const requests = this.cmList.map(async (item) => {
        const payload = {
          actionName: 'EUC_DEVICE_CISCO_SYNC',
          data: {
            cmNm: item.cmNm,
            loginId: this.$store.getters.getLoginId
          },
          loading: true,
        };

        const res = await this.CALL_EUC_API(payload);
        if (!isSuccess(res)) {
          const errorMessage = res.data.data?.length
              ? `교환기 : ${item.cmNm} ${res.data.header.resMsg}`
              : res.data.header.resMsg;
          await this.$_Msg(errorMessage);
        }
        return res;
      });

      const results = await Promise.all(requests);
      if (results.some(isSuccess)) {
        this.$refs.deviceGrid.getInstance.refresh();
      }
    },
  },
  created() {
    this.selectCmList();
  },
};
</script>