import EUCApprovalList from '@/pages/euc/phone/approval/list.vue';
import EUCApprovalRequest from '@/pages/euc/phone/approval/request.vue';

export default function ApprovalRoutes(commonComponents) {
    return [
        /**
         * @description 전화 신청에 대한 승인 목록 페이지
         * */
        {
            path: '/euc/phone/approval/list',
            name: 'EUCApprovalList',
            components: { default: EUCApprovalList, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 전화 신청에 대한 승인 상세 페이지
             * */
            path: '/euc/phone/approval/request',
            name: 'EUCApprovalRequest',
            components: { default: EUCApprovalRequest, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ]
}