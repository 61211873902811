import ESPMenuUsed from '@/pages/esp/summary/menu-used.vue';

export default function SummaryRoutes(commonComponents) {
  return [
    {
      path: '/esp/summary/menu-used',
      name: 'ESPMenuUsed',
      components: { default: ESPMenuUsed, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
