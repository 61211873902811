import EUCAvayaDeviceDetail from '@/pages/euc/device/avaya/detail.vue';
import EUCAvayaDevice from '@/pages/euc/device/avaya/index.vue';

export default function AvayaRoutes(commonComponents) {
    return [
        {
            path: '/euc/device/avaya',
            name: 'EUCAvayaDevice',
            components: { default: EUCAvayaDevice, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/device/avaya/detail',
            name: 'EUCAvayaDeviceDetail',
            components: { default: EUCAvayaDeviceDetail, ...commonComponents },
            meta: { requiresAuth: true, isDetailPage: true },
        },
    ];
}