import EWMOpBoard from '@/pages/ewm/op/board/index.vue';
import EWMOpBoardConfig from '@/pages/ewm/op/board/config.vue';
import EWMOpBoardDetail from '@/pages/ewm/op/board/detail.vue';

export default function OpRoutes(commonComponents) {
  return [
    {
      path: '/ewm/op/board',
      name: 'EWMOpBoard',
      components: { default: EWMOpBoard, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/op/board/config',
      name: 'EWMOpBoardConfig',
      components: { default: EWMOpBoardConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/ewm/op/board/detail',
      name: 'EWMOpBoardDetail',
      components: { default: EWMOpBoardDetail, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}