<!--
  PACKAGE_NAME : src/pages/ui/components/calendar-demo.vue
  FILE_NAME : calendar-demo
  AUTHOR : hjsim
  DATE : 2025-02-06
  DESCRIPTION : 코드 에디터와 미리보기를 통한 실시간 토스트 캘린더 렌더링
-->
<template>
  <div class="page-sub-box">
    <div class="real-time-editor">
      <div class="editor sub_title_txt">
        <h2>코드 에디터</h2>
        <code-editor v-model="code" beautyType="html" />
      </div>
      <div class="preview sub_title_txt">
        <h2>미리보기</h2>
        <div ref="previewContainer" class="preview-container" />
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import CodeEditor from "@/pages/ui/components/code-editor.vue";
import EspToastCalendar from "@/components/toast/esp-toast-calendar.vue";

const demoTemplate = `
    <template>
      <div class="page-sub-box">
        <esp-toast-calendar/>
      </div>
    <\/template>

    <script>
    import EspToastCalendar from "@/components/toast/esp-toast-calendar.vue";

    export default {
      components: {
        EspToastCalendar,
      },
      data() {
        return {
        };
      },
    };
    <\/script>
  `;

export default {
  name: 'ESPCOMPCalendarDemo',
  components: {
    CodeEditor,
    EspToastCalendar
  },
  watch: {
  },
  data() {
    return {
      // 초기 코드 설정
      code: demoTemplate,
    };
  },

  methods: {
    /**
     * @description 코드 에디터의 내용을 파싱하여 프리뷰 영역에 컴포넌트를 렌더링
     * @throws {Error} 컴포넌트 생성 또는 마운트 중 발생하는 에러
     */
    handleApplyCode() {
      try {
        // 기존 컴포넌트 초기화
        if (this.currentComponent) {
          this.currentComponent.$destroy();
          this.$refs.previewContainer.innerHTML = '';
        }

        // 코드 파싱 및 컴포넌트 생성
        const { template, data } = this.parseVueComponent(this.code);
        const ComponentClass = this.createNewComponent(template, data?.treeList);

        // 새로운 마운트 포인트 생성
        const mountPoint = document.createElement('div');
        mountPoint.className = 'preview-mount-point';
        this.$refs.previewContainer.appendChild(mountPoint);

        // Vue 컴포넌트의 새 인스턴스 생성
        this.currentComponent = new ComponentClass({
          parent: this, // 현재 컴포넌트를 부모로 설정
        });

        this.currentComponent.$mount(mountPoint);

      } catch (error) {
        this.$log.error('Error:', error);
        this.$refs.previewContainer.innerHTML = `
          <div class="error">
            <h3>Error:</h3>
            <pre>${error.message}</pre>
          </div>
        `;
      }
    },
    /**
     * @description 컴포넌트 문자열을 파싱하여 template과 data 객체로 분리
     * @param {string} code - Vue 컴포넌트 전체 코드 문자열
     * @returns {Object} 파싱된 컴포넌트 구조
     *         - template: 컴포넌트의 template 부분
     *         - data: 실행된 data 객체
     * @throws {Error} 스크립트나 data 함수를 찾을 수 없는 경우
     */
    parseVueComponent(code) {
      try {
        const templateMatch = code.match(/<template>([\s\S]*?)<\/template>/);
        const template = templateMatch ? templateMatch[1].trim() : '';

        const scriptMatch = code.match(/<script>([\s\S]*?)<\/script>/);
        if (!scriptMatch) {
          throw new Error('스크립트를 찾을 수 없습니다.');
        }

        const dataMatch = scriptMatch[1].match(/data\s*\(\s*\)\s*{\s*return\s*({[\s\S]*?});?\s*}/);
        if (!dataMatch) {
          throw new Error('data 함수를 찾을 수 없습니다.');
        }

        const dataStr = dataMatch[1].replace(/this\./g, 'context.'); // this를 context로 변경
        const dataObj = this.executeComponentData(dataStr); // data 함수 실행

        return {
          template,
          data: dataObj
        };
      } catch (error) {
        this.$log.error('컴포넌트 파싱 에러:', error);
        throw error;
      }
    },
    /**
     * @description Vue 컴포넌트의 data 문자열을 실행하여 실제 data 객체로 변환
     * @param {string} dataStr - Vue 컴포넌트의 data 부분 문자열
     * @param {Object} context - Vue 컴포넌트의 this
     * @returns {Object} 실제 data 객체
     */
    executeComponentData(dataStr, context = this) {
      const evalFn = new Function('context', `
        with(context) {
          return ${dataStr};
        }
      `);

      try {
        return evalFn(context);
      } catch (error) {
        this.$log.error('data 실행 중 에러::', error);
        return {};
      }
    },
    /**
     * @description Vue 컴포넌트를 동적으로 생성하는 메서드
     *              template, components, data를 영역으 컴포넌트 생성
     * @param {string} template - 컴포넌트의 템플릿 문자열
     * @param {Object} data - 컴포넌트의 data 객체
     * @returns {VueComponent} Vue.extend로 생성된 컴포넌트 클래스
     */
    createNewComponent(template, demoTemplate) {
      const baseOptions = {
        template,
        components: { EspToastCalendar },
        data() {
          return {
            calendar: demoTemplate,
          };
        },
      };

      return Vue.extend(baseOptions);
    },
  },
  created() {},
  async mounted() {
    // 초기 코드로 컴포넌트 생성
    this.handleApplyCode(this.code);
    this.$store.commit('setPagingHists',  null);
  },
};
</script>
<style scoped>
.real-time-editor {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editor, .preview {
  width: 100%;
  padding: 15px 5px;
}

.preview-container {
  min-height: 400px;
}

@media (min-width: 768px) {
  .real-time-editor {
    flex-direction: row;
  }
  .editor {
    width: 35%;
  }
  .preview {
    width: 65%;
  }
}

.error {
  color: red;
  padding: 10px;
  border: 1px solid red;
  border-radius: 4px;
  background-color: #fff5f5;
}

.error pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  margin: 0;
}
</style>

