import CCAgentConfig from '@/pages/cc/cti/config/agt';
import CCAttributeConfig from '@/pages/cc/cti/config/att';
import CCContactTypeConfig from '@/pages/cc/cti/config/contact-type';
import CCInboundGroupConfig from '@/pages/cc/cti/config/ibg';
import CCNotReadyReasonConfig from '@/pages/cc/cti/config/nreason';
import CCSkillGroupConfig from '@/pages/cc/cti/config/skl';
import CCTeamConfig from '@/pages/cc/cti/config/team';
import CCInboundGroupCategory from '@/pages/cc/cti/ibg-category/index';
import CCSkillGroupCategory from '@/pages/cc/cti/skl-category/index';
import CCTeamGroupCategory from '@/pages/cc/cti/team-category/index';

export default function CTIConfigRoutes(commonComponents) {
  return [
    {
      path: '/cc/cti/config/ibg',
      name: 'CCInboundGroupConfig',
      components: { default: CCInboundGroupConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/agt',
      name: 'CCAgentConfig',
      components: { default: CCAgentConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/skl',
      name: 'CCSkillGroupConfig',
      components: { default: CCSkillGroupConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/team',
      name: 'CCTeamConfig',
      components: { default: CCTeamConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/att',
      name: 'CCAttributeConfig',
      components: { default: CCAttributeConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/contact-type',
      name: 'CCContactTypeConfig',
      components: { default: CCContactTypeConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/config/nreason',
      name: 'CCNotReadyReasonConfig',
      components: { default: CCNotReadyReasonConfig, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/ibg-category/index',
      name: 'CCInboundGroupCategory',
      components: { default: CCInboundGroupCategory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/team-category/index',
      name: 'CCTeamGroupCategory',
      components: { default: CCTeamGroupCategory, ...commonComponents },
      meta: { requiresAuth: true },
    },
    {
      path: '/cc/cti/skl-category/index',
      name: 'CCSkillGroupCategory',
      components: { default: CCSkillGroupCategory, ...commonComponents },
      meta: { requiresAuth: true },
    },

  ];
}
