<template>
  <div>
    <div class="ewm page-sub-box">
      <esp-dx-data-grid
        :data-grid="dataGrid"
        :ref="dataGrid.refName"
        @init-new-row="onInitNewRow"
        @saving="e => onSave(e)"
        @selection-changed="onSelectionChanged"
      />
    </div>
  </div>
</template>

<script>
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxDropDownBox } from 'devextreme-vue/drop-down-box';
  import { DxTreeList } from 'devextreme-vue/tree-list';
  import { DxSwitch } from 'devextreme-vue/switch';
  import {formatDate, getPastFromToday, isEmpty, isSuccess} from '@/utils/common-lib';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  const gridData = {
    columnDataSource: {
      viewFl: [
        { cd: 'Y', nm: '표시' },
        { cd: 'N', nm: '미표시' },
      ],
    },
    deptCd: {
      dataSource: [],
      selectedRowsData: [],
      selectedRowKeys: [],
      expandedRowKeys: [],
      rootItem: [],
      displayExpr: 'codeNm',
      valueExpr: 'codeId',
    },
  };
  
  export default {
    name: 'EWMVacationType',
    components: { EspDxDataGrid },
    watch: {},
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            frScheYmd: getPastFromToday(11, 'months'),
            toScheYmd: getPastFromToday(0, 'days'),
          },
          paramsData: {},
        },
        codes: {
          vacationTypePtCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          vacationTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          vacationPaidTypeCd: {
            dataSource: [],
            displayExpr: 'codeNm',
            valueExpr: 'codeId',
          },
          vacationTypeCdAll: [],
          dept0: {
            dataSource: [],
          },
          dept1: {
            dataSource: [],
          },
          dept2: {
            dataSource: [],
          },
          dept3: {
            dataSource: [],
          },
        },
        deptSelectionChanged: false,
        dataGrid: {
          //keyExpr: 'id',
          refName: 'vacationTypeGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {
            selectionChanged: true,
            initNewRow: true,
            optionChanged: false,
            rowInserted: false,
            rowInserting: false,
            saving: true,
            rowPrepared: true,
            rowUpdating: true,
            rowUpdated: true,
          },
          showActionButtons: {
            copy: false,
            select: false,
            update: false,
            delete: false,
            excel: false,
            customButtons: [
              {
                widget: 'dxButton',
                sortIndex: 10,
                options: {
                  icon: '',
                  text: '조회',
                  elementAttr: { class: 'btn_XS default filled search' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.selectDataList();
                  },
                },
                location: 'before',
              },
              {
                widget: 'dxButton',
                options: {
                  icon: '',
                  text: '삭제',
                  elementAttr: { class: 'btn_XS white light_filled trash' },
                  width: 60,
                  height: 30,
                  onClick() {
                    vm.onDeleteData();
                  },
                },
                location: 'before',
              },
            ],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: true, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: true, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'multiple', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: '휴가구분',
              dataField: 'vacationTypePtCd',
              width: 160,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'vacationTypePtCd'),
              setCellValue: (newData, value, currentRowData) => {
                vm.changesChildCd(newData, value, currentRowData);
              },
              lookup: {},
              requiredRule: {
                message: '필수 항목입니다.',
              },
              sortIndex: 0,
              sortOrder: 'asc',
            },
            {
              caption: '휴가구분상세',
              dataField: 'vacationTypeCd',
              width: 180,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'vacationTypeCd'),
              setCellValue: (newData, value, currentRowData) => {
                vm.changesParentCd(newData, value, currentRowData);
              },
              //lookup: {},
              requiredRule: {
                message: '필수 항목입니다.',
              },
              customRule: {
                message: '휴가구분상세가 중복 됩니다.',
                callback: options => {
                  return vm.checkVacationTypeCdDuplicated(options);
                },
              },
              sortIndex: 0,
              sortOrder: 'asc',
            },
            {
              caption: '유급여부',
              dataField: 'vacationPaidTypeCd',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'vacationPaidTypeCd'),
              setCellValue: (newData, value, currentRowData) => {
                newData.vacationPaidTypeCd = value;
              },
              lookup: {},
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '적용부서',
              dataField: 'deptCd',
              height: 40,
              alignment: 'center',
              visible: false,
              allowSorting: false,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              //lookup: {},
              calculateDisplayCellValue: rowData => {
                if (!rowData.deptCd || !Array.isArray(rowData.deptCd)) return '';

                if (rowData.deptCd === gridData.deptCd.expandedRowKeys || rowData.deptCd === gridData.rootItem) {
                  return '전체';
                } else {
                  const selectedValues = rowData.deptCd.map(o => {
                    const foundItem = gridData.deptCd.dataSource.find(e => o === e.codeId);
                    return foundItem ? foundItem.codeNm : '';
                  });
                  return `${selectedValues.join(', ')}`;
                }
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '적용부서',
              dataField: 'deptCd2',
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowFiltering: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              editCellTemplate: (container, editOptions) => {
                const dropDownBox = new DxDropDownBox({
                  propsData: {
                    dataSource: gridData.deptCd.dataSource,
                    deferRendering: false,
                    showClearButton: false,
                    displayExpr: 'codeNm',
                    valueExpr: 'codeId',
                    value: editOptions.data.deptCd,
                    stylingMode: this.stylingMode,
                    width: '100%',
                    height: '34',
                    dropDownOptions: {
                      minWidth: 500,
                    },
                    opened: false,
                    onDisposing: () => {
                      if (vm.deptSelectionChanged) {
                        editOptions.component.cellValue(editOptions.rowIndex, 'deptCd', editOptions.value);
                        editOptions.component.cellValue(editOptions.rowIndex, 'deptCd2', vm.calculateDept2CellValue(editOptions.value));
                        vm.deptSelectionChanged = false;
                      }
                    },
                    contentTemplate: (dropBox, container) => {
                      const treeGrid = new DxTreeList({
                        propsData: {
                          keyExpr: 'codeId',
                          parentIdExpr: 'parentId',
                          dataSource: gridData.deptCd.dataSource,
                          rootValue: 1,
                          columns: [
                            {
                              alignment: 'center',
                              caption: '부서정보',
                              dataField: 'codeNm',
                              allowSorting: false,
                            },
                          ],
                          selection: {
                            mode: 'multiple',
                            recursive: 'recursive',
                            allowSelectAll: true,
                          },
                          selectedRowKeys: dropBox.value,
                          expandedRowKeys: gridData.deptCd.expandedRowKeys,
                          width: '100%',
                          height: '100%',
                          onSelectionChanged: e => {
                            dropBox.component.option('value', e.selectedRowKeys);

                            let selectedDept = [];
                            selectedDept.push(e.selectedRowKeys);
                            if (selectedDept[0].length < 1 || selectedDept[0].length === gridData.deptCd.expandedRowKeys.length) {
                              editOptions.value = gridData.rootItem;
                              this.$set(editOptions, 'value', gridData.rootItem);
                            } else {
                              editOptions.value = selectedDept[0];
                              this.$set(editOptions, 'value', selectedDept[0]);
                            }
                            vm.deptSelectionChanged = true;
                          },
                        },
                      });
                      treeGrid.$mount();
                      container.append(treeGrid.$el);
                    },
                  },
                });
                dropDownBox.$mount();
                container.append(dropDownBox.$el);
              },
              customRule: {
                message: '필수 항목입니다.',
                callback: options => {
                  return !isEmpty(options.data.deptCd);
                },
              },
            },
            {
              caption: '시간옵션',
              dataField: 'vacationTimeTypeCd',
              width: 150,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateSortValue: data => vm.calculateSortValue(data, 'vacationTimeTypeCd'),
              setCellValue: (newData, value, currentRowData) => {
                // vm.changesTimeType(newData, value, currentRowData);
                newData.vacationTimeTypeCd = value;
                newData.vacationStartTime = null;
                newData.vacationEndTime = null;
              },
              lookup: {
                dataSource: this.$_enums.ewm.hrVacationTimeType.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '시작시간',
              dataField: 'vacationStartTime',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                return rowData.vacationStartTime
                  ? formatDate(rowData.vacationStartTime, 'HHmm', 'HH:mm')
                  : rowData.vacationStartTime;
              },
              editCellTemplate: (container, options) => {
                if (options.data.vacationTimeTypeCd === this.$_enums.ewm.hrVacationTimeType.ALL.value) {
                  return;
                } else if (options.data.vacationTimeTypeCd === this.$_enums.ewm.hrVacationTimeType.DIVISION.value) {
                  let parsedDate = new Date();
                  if (options.value) {
                    parsedDate.setHours(parseInt(options.value.substring(0, 2)));
                    parsedDate.setMinutes(parseInt(options.value.substring(2)));
                  } else {
                    parsedDate = null;
                  }

                  const dateBox = new DxDateBox({
                    propsData: {
                      value: parsedDate,
                      type: 'time',
                      width: '100%',
                      dateSerializationFormat: 'HHmm',
                      displayFormat: 'HHmm',
                      interval: 15,
                      onValueChanged: e => {
                        const regex = /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/;
                        if (regex.test(e.value)) {
                          if (options.data.vacationEndTime && options.data.vacationEndTime < e.value) {
                            this.$_Toast('시작시간은 종료시간 이전이여야 합니다.');
                          } else {
                            options.component.cellValue(options.rowIndex, 'vacationStartTime', e.value);
                          }
                        } else {
                          this.$_Toast('24시간제 시간으로 입력하세요(0000~2359)');
                        }
                      },
                    },
                  });
                  dateBox.$mount();
                  container.append(dateBox.$el);
                }
              },
              customRule: {
                message: '필수 항목입니다. 24시간제 시간으로 입력하세요(0000~2359)',
                callback: options => {
                  if (options.data.vacationTimeTypeCd !== this.$_enums.ewm.hrVacationTimeType.DIVISION.value) {
                    return true;
                  }

                  if (isEmpty(options.value)) {
                    return false;
                  }

                  return !isEmpty(options.value);
                },
              },
            },
            {
              caption: '종료시간',
              dataField: 'vacationEndTime',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateDisplayCellValue: rowData => {
                return rowData.vacationEndTime
                  ? formatDate(rowData.vacationEndTime, 'HHmm', 'HH:mm')
                  : rowData.vacationEndTime;
              },
              editCellTemplate: (container, options) => {
                if (options.data.vacationTimeTypeCd === this.$_enums.ewm.hrVacationTimeType.ALL.value) {
                  return;
                } else if (options.data.vacationTimeTypeCd === this.$_enums.ewm.hrVacationTimeType.DIVISION.value) {
                  let parsedDate = new Date();
                  if (options.value) {
                    parsedDate.setHours(parseInt(options.value.substring(0, 2)));
                    parsedDate.setMinutes(parseInt(options.value.substring(2)));
                  } else {
                    parsedDate = null;
                  }

                  const dateBox = new DxDateBox({
                    propsData: {
                      value: parsedDate,
                      type: 'time',
                      width: '100%',
                      dateSerializationFormat: 'HHmm',
                      displayFormat: 'HHmm',
                      interval: 15,
                      onValueChanged: e => {
                        const regex = /^(0[0-9]|1[0-9]|2[0-3])[0-5][0-9]$/;
                        if (regex.test(e.value)) {
                          if (options.data.vacationStartTime && options.data.vacationStartTime > e.value) {
                            this.$_Toast('종료시간은 시작시간 이후여야 합니다.');
                          } else {
                            options.component.cellValue(options.rowIndex, 'vacationEndTime', e.value);
                          }
                        } else {
                          this.$_Toast('24시간제 시간으로 입력하세요(0000~2359)');
                        }
                      },
                    },
                  });
                  dateBox.$mount();
                  container.append(dateBox.$el);
                }
              },
              customRule: {
                message: '필수 항목입니다. 24시간제 시간으로 입력하세요(0000~2359)',
                callback: options => {
                  if (options.data.vacationTimeTypeCd !== this.$_enums.ewm.hrVacationTimeType.DIVISION.value) {
                    return true;
                  }

                  if (isEmpty(options.value)) {
                    return false;
                  }

                  return !isEmpty(options.value);
                },
              },
            },
            {
              caption: '차감일수',
              dataField: 'vacationDayoff',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: true,
              allowHeaderFiltering: true,
              allowGrouping: false,
              dataType: 'number',
              patternRule: {
                pattern: /^(0|0\.5|1)$/,
                message: '0, 0.5, 1의 숫자를 입력하세요',
              },
              requiredRule: {
                message: '필수 항목입니다.',
              },
            },
            {
              caption: '표시여부',
              dataField: 'viewFl',
              width: 110,
              height: 40,
              alignment: 'center',
              visible: true,
              allowSorting: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              allowGrouping: false,
              calculateSortValue: data => {
                return this.$_enums.common.stringUsedFlag.values.find(e => data.viewFl === e.value).label;
              },
              cellTemplate: (container, options) => {
                const switchBtn = new DxSwitch({
                  propsData: {
                    value: options.data.viewFl === this.$_enums.common.stringUsedFlag.YES.value,
                    disabled: options.data.useFl === this.$_enums.common.stringUsedFlag.YES.value,
                    onValueChanged: e => {
                      const viewFlVal = e.value
                        ? this.$_enums.common.stringUsedFlag.YES.value
                        : this.$_enums.common.stringUsedFlag.NO.value;

                      this.$refs.vacationTypeGrid.getInstance.cellValue(options.rowIndex, 'viewFl', viewFlVal);
                    },
                  },
                });
                switchBtn.$mount();
                container.append(switchBtn.$el);
              },
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
    computed: {},
    methods: {
      /** @description : 휴가구분상세 중복 체크 */
      checkVacationTypeCdDuplicated(options) {
        const findIdx = this.$refs.vacationTypeGrid.getInstance
          .getVisibleRows()
          .findIndex(row => row.data.id !== options.data.id && row.data.vacationTypeCd === options.data.vacationTypeCd);
        return findIdx <= -1;
      },
      /** @description : 부서값 */
      calculateDept2CellValue(deptCd) {
        if (!deptCd || !Array.isArray(deptCd)) {
          return '';
        } else {
          if (deptCd === gridData.deptCd.expandedRowKeys || deptCd === gridData.rootItem) {
            return '전체';
          } else {
            const selectedValues = deptCd.map(o => {
              const foundItem = gridData.deptCd.dataSource.find(e => o === e.codeId);
              return foundItem ? foundItem.codeNm : '';
            });

            return `${selectedValues.join(', ')}`;
          }
        }
      },
      /** @description : 소트설정 */
      calculateSortValue(data, clsCd) {
        if (clsCd === 'vacationTypePtCd') {
          return this.codes.vacationTypePtCd.dataSource.find(e => data.vacationTypePtCd === e.codeId).codeNm;
        } else if (clsCd === 'vacationTypeCd') {
          return this.codes.vacationTypeCdAll.find(e => data.vacationTypeCd === e.codeId).codeNm;
        } else if (clsCd === 'vacationTimeTypeCd') {
          return this.$_enums.ewm.hrVacationTimeType.values.find(e => data.vacationTimeTypeCd === e.value).label;
        }
      },
      /** @description : 데이터 추가 시 기본 값 적용하기 위함 */
      onInitNewRow(e) {
        e.data.vacationTypePtCd = null;
        e.data.vacationTypeCd = null;
        e.data.deptCd = gridData.rootItem;
        e.data.vacationPaidTypeCd = this.codes.vacationPaidTypeCd.dataSource.find(p => p.codeNm == '유급').codeId;
        e.data.vacationTimeTypeCd = this.$_enums.ewm.hrVacationTimeType.ALL.value;
        e.data.vacationStartTime = null;
        e.data.vacationEndTime = null;
        e.data.vacationDayoff = 0;
        e.data.viewFl = 'Y';
      },
      changesChildCd(newData, value, currentRowData) {
        newData.vacationTypePtCd = value;
        newData.vacationTypeCd = null;
      },
      changesParentCd(newData, value, currentRowData) {
        newData.vacationTypeCd = value;
      },
      changesTimeType(newData, value, currentRowData) {
        newData.vacationTimeTypeCd = value;
        newData.vacationStartTime = null;
        newData.vacationEndTime = null;
      },
      async onSave(e) {
        e.cancel = true;

        if (!e.changes.length) {
          return;
        }

        if (
          !(await this.$_Confirm(this.$_lang('COMMON.MESSAGE.CMN_CFM_SAVE', { defaultValue: '저장하시겠습니까?' }), {
            title: `<h2>알림</h2>`,
          }))
        ) {
          return;
        }

        let data = [];

        if (e.changes && e.changes.length > 0) {
          e.changes.forEach(d => {
            if (d.type === 'update') {
              let rowIndex = e.component.getRowIndexByKey(d.key);
              let row = e.component.getVisibleRows()[rowIndex];
              row.data.deptMultiCd = row.data.deptCd.join('|');
              let updateData = row.data;

              data.push(updateData);
            } else if (d.type === 'insert') {
              if (Object.keys(d.data).length !== 0) {
                d.data.deptMultiCd = d.data.deptCd.join('|');
                let updateData = { ...d.data };
                updateData['id'] = null;

                data.push(updateData);
              }
            }
          });
        }
        data.forEach(d => {
          if (d.vacationTimeTypeCd === this.$_enums.ewm.hrVacationTimeType.ALL.value) {
            d.vacationStartTime = null;
            d.vacationEndTime = null;
          }
          if (d.deptCd[0] === gridData.rootItem[0]) {
            d.deptMultiCd = '전체';
          }
        });

        const payload = {
          actionName: 'EWM_HR_VACATION_TYPE_SAVE',
          data: { data: data },
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_SAVE', { defaultValue: '정상적으로 저장되었습니다.' }));
          this.$refs.vacationTypeGrid.getInstance.refresh();
          e.component.cancelEditData();
          await this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      onSelectionChanged(e) {
        this.$refs.vacationTypeGrid.selectedRowsData = e.selectedRowsData;
      }, 
      async onDeleteData() {
        const selectedRowsData = this.$refs.vacationTypeGrid.selectedRowsData;

        if (selectedRowsData.length < 1) {
          this.$_Msg(this.$_lang('COMMON.MESSAGE.CMN_NOT_SELECTED', { defaultValue: '대상이 선택되어 있지 않습니다.' }));
          return;
        }

        if (!(await this.$_Confirm(this.$_lang('CMN_CFM_DELETE_SELECTED'), { title: `<h2>알림</h2>` }))) {
          return;
        }

        const sendData = selectedRowsData.map(x => x.id);

        const payload = {
          actionName: 'EWM_HR_VACATION_TYPE_DELETE',
          data: sendData,
          loading: true,
        };

        const res = await this.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          this.$_Toast(this.$_lang('CMN_SUC_DELETE'));
          this.selectDataList();
        } else {
          this.$_Toast(this.$_lang('CMN_ERROR', { defaultValue: '데이터 처리 중 오류가 발생하였습니다.' }));
        }
      },
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        const payload = {
          actionName: 'EWM_HR_VACATION_TYPE',
          loading: false,
        };

        const res = await vm.CALL_EWM_API(payload);

        if (isSuccess(res)) {
          res.data.data.forEach(d => {
            d.vacationTypePtCd = this.codes.vacationTypeCdAll.find(v => v.codeId === d.vacationTypeCd).parentId;
            
            if (d.deptMultiCd === '전체') {
              d.deptCd = gridData.rootItem;
            } else {
              d.deptCd = d.deptMultiCd.split('|').map(item => parseInt(item.trim()));
            }

            d.deptCd2 = this.calculateDept2CellValue(d.deptCd);
          });

          this.dataGrid.dataSource = res.data.data;
        }
      },
      /** @description : 코드 로드 */
      async initCodeMap() {
        this.codeMap = await this.$_getCodeMapList('root_ewm_hr_vacation_division,root_ewm_hr_dept,root_ewm_hr_vacation_time_type,root_ewm_hr_vacation_paid_type');
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      async createdData() {
        vm = this;

        await this.initCodeMap().then(() => {
          this.codes.vacationTypePtCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 2);
          this.codes.vacationTypeCdAll = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_division'], 3);
          this.codes.vacationTypeCd.dataSource = options => {
            return {
              store: this.$_fetchCodesByMaxDepth(this.codeMap['root_ewm_hr_vacation_division']),
              filter: options.data ? ['parentId', '=', options.data.vacationTypePtCd] : null,
            };
          };

          this.codes.dept1.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 2);
          this.codes.dept2.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 3);
          this.codes.dept3.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_dept'], 4);

          this.codes.dept0.dataSource = [
            {
              codeId: this.codes.dept1.dataSource.length > 0 ? this.codes.dept1.dataSource[0].parentId : -1,
              codeKey: 'ewm_hr_dept',
              codeNm: '전체',
              codeValue: 'ewm_hr_dept',
              depth: 1,
              parentId: null,
              path: '',
              pathNmFull: '전체',
            },
          ];

          gridData.deptCd.dataSource = [
            ...this.codes.dept3.dataSource,
            ...this.codes.dept2.dataSource,
            ...this.codes.dept1.dataSource,
            ...this.codes.dept0.dataSource,
          ];
          gridData.deptCd.expandedRowKeys = gridData.deptCd.dataSource.map(d => d.codeId);
          gridData.rootItem = this.codes.dept0.dataSource.map(d => d.codeId);

          this.codes.vacationPaidTypeCd.dataSource = this.$_fetchCodesByDepth(this.codeMap['root_ewm_hr_vacation_paid_type'], 2);

          this.$refs.vacationTypeGrid.getInstance.columnOption('vacationTypePtCd', 'lookup', this.codes.vacationTypePtCd);
          this.$refs.vacationTypeGrid.getInstance.columnOption('vacationTypeCd', 'lookup', this.codes.vacationTypeCd);
          this.$refs.vacationTypeGrid.getInstance.columnOption('vacationPaidTypeCd', 'lookup', this.codes.vacationPaidTypeCd);
        });
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
