import EUCWorkerList from '@/pages/euc/phone/worker/list.vue';
import EUCWorkerRequest from '@/pages/euc/phone/worker/request.vue';

export default function WorkerRoutes(commonComponents) {
    return [
        /**
         * @description 전화 신청에 대한 작업 목록 페이지
         * */
        {
            path: '/euc/phone/worker/list',
            name: 'EUCWorkerList',
            components: { default: EUCWorkerList, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            /**
             * @description 전화 신청에 대한 작업 상세 페이지
             * */
            path: '/euc/phone/worker/request',
            name: 'EUCWorkerRequest',
            components: { default: EUCWorkerRequest, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ]
}