import EUCInfoPushSite from '@/pages/euc/info-push/site.vue';

export default function InfoPushSiteRoutes(commonComponents) {
    return [
        {
            path: '/euc/info-push/site',
            name: 'EUCInfoPushSite',
            components: { default: EUCInfoPushSite, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}