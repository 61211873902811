import EUCContact from '@/pages/euc/contact/index.vue';

export default function EUCContactRoutes(commonComponents) {
    return [
        {
            path: '/euc/contact',
            name: 'EUCContact',
            components: { default: EUCContact, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}