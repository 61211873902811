<!--
  PACKAGE_NAME : src\pages\op\ivr\ivrsvr-config.vue
  FILE_NAME : ivrsvr-config
  AUTHOR : hmlee
  DATE : 2024-05-22
  DESCRIPTION : IVR 서버 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    name: 'CCIVRServerConfig',
    components: {
      EspDxDataGrid,
    },
    props: {},
    data() {
      return {
        dataGrid: {
          callApi: 'CALL_CC_API',
          refName: 'ivrServerRefName',
          dataSource: [], //dataSource 설정 / 커스텀 조회를 위해 필요
          dataSourceDefaultSortColumn: '+svrOrd', // 주석처리하면 keyExpr 컬럼으로 sorting됨 + 오름차순 - 내림차순1
          apiActionNm: {
            select: 'CC_IVR_SVR_LIST',
            update: 'CC_IVR_SVR_MERGE',
            delete: 'CC_IVR_SVR_DELETE',
          },
          showActionButtons: {
            select: true,
          },
          columns: [
            {
              caption: 'ID',
              dataField: 'id',
              visible: false,
            },
            {
              caption: '사이트명', // 타이틀
              i18n: 'COMMON.WORD.SITE_NM', // 다국어 처리
              dataField: 'siteId', // 컬럼명
              lookup: { // SelectBox
                dataSource: this.$store.getters.getSiteList,
                displayExpr: 'siteNm',
                valueExpr: 'id',
              },
              requiredRule: {}, //필수값 옵션 / 필수값 메시지 디폴트 설정
            },
            {
              caption: '센터명',
              i18n: 'COMMON.WORD.TENANT_NM',
              dataField: 'tenantId',
              lookup: {
                dataSource: this.$store.getters.getTenantList,
                displayExpr: 'tenantNm',
                valueExpr: 'id',
              },
              requiredRule: {},
            },
            {
              caption: 'IVR명',
              i18n: 'CC.WORD.IVR_NAME',
              dataField: 'svrNm',
              requiredRule: {},
            },
            {
              caption: 'IVR No',
              dataField: 'sysNo',
              requiredRule: {},
            },
            {
              caption: 'IP',
              dataField: 'ip',
              requiredRule: {},
            },
            {
              caption: 'svrUser',
              dataField: 'svrUser',
            },
            {
              caption: 'svrPasswd',
              dataField: 'svrPasswd',
            },
            {
              caption: 'svrMethod',
              dataField: 'svrMethod',
            },
            {
              caption: 'Port',
              dataField: 'svrPort',
            },
            {
              caption: '순서',
              i18n: 'COMPONENTS.ORDER',
              dataField: 'svrOrd',
            },
            {
              caption: '사용여부',
              i18n: 'COMPONENTS.USE_STATUS',
              dataField: 'viewFl',
              lookup: {
                dataSource: this.$_enums.common.stringUsedFlag.values,
                displayExpr: 'label',
                valueExpr: 'value',
              },
            },
          ],
        },
      };
    },
  };
</script>