<!--
  PACKAGE_NAME : src\pages\esp\hr\user\modal-add-user.vue
  FILE_NAME : modal-add-user
  AUTHOR : hmlee
  DATE : 2025-04-10
  DESCRIPTION : 사용자 등록/수정 모달
-->
<template>
  <esp-dx-modal-popup
    :option="{
      title: modal.title,
      width: modal.width,
      height: modal.height,
      minWidth: modal.minWidth,
      minHeight: modal.minHeight,
      resizeEnabled: modal.resizeEnabled,
      closeOnOutsideClick: modal.hideOnOutsideClick,
      validationGroup: modal.validationGroup,
      useSaveBtnDisabled: modal.useSaveBtnDisabled,
    }"
    :is-open="isOpen"
    @saveModal="handleSaveModal"
    @closeModal="handleCloseModal"
  >
    <template #content>
      <div class="add-form-container">
        <div class="dx-fieldset">
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.USER_ID', '사용자 ID') }}*</div>
            <div class="dx-field-value">
              <dx-text-box
                v-model="formData.userNo"
                :width="textBox.width"
                :styling-mode="stylingMode"
              >
                <dx-validator :validation-group="modal.validationGroup">
                  <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: getI18n('COMMON.WORD.USER_ID', '사용자 ID') })" />
                </dx-validator>
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.USER_NAME', '사용자명') }}*</div>
            <div class="dx-field-value">
              <DxTextBox
                v-model="formData.userNm"
                :width="textBox.width"
                :styling-mode="stylingMode"
              >
                <dx-validator :validation-group="modal.validationGroup">
                  <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: getI18n('COMMON.WORD.USER_NM', '사용자명') })" />
                </dx-validator>
              </DxTextBox>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.SITE', '사이트') }}* / {{ getI18n('COMMON.WORD.TENANT', '센터') }}* / {{ getI18n('COMMON.WORD.DEPT', '부서') }}</div>
            <div class="dx-field-value">
              <dx-select-box
                v-model="formData.siteId"
                :items="getSiteList"
                display-expr="siteNm"
                value-expr="id"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
                <dx-validator :validation-group="modal.validationGroup">
                  <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '사이트' })" />
                </dx-validator>
              </dx-select-box>

              <dx-select-box
                v-model="formData.tenantId"
                :items="getTenantList"
                display-expr="tenantNm"
                value-expr="id"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
                <dx-validator :validation-group="modal.validationGroup">
                  <dx-required-rule :message="$_lang('COMMON.MESSAGE.REQUIRED_VALUE_IS', { value: '센터' })" />
                </dx-validator>
              </dx-select-box>

              <dx-select-box
                v-model="formData.deptId"
                :items="getTenantList"
                display-expr="deptNm"
                value-expr="id"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.COMPANY', '도급사') }}</div>
            <div class="dx-field-value">
              <dx-select-box
                v-model="formData.companyCd"
                :items="$_getCode('user_company')"
                display-expr="codeNm"
                value-expr="codeValue"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.GRADE', '직급') }}</div>
            <div class="dx-field-value">
              <dx-select-box
                v-model="formData.gradeCd"
                :items="$_getCode('user_grade')"
                display-expr="codeNm"
                value-expr="codeValue"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.CLASS', '직위') }}</div>
            <div class="dx-field-value">
              <dx-select-box
                v-model="formData.classCd"
                :items="$_getCode('user_class')"
                display-expr="codeNm"
                value-expr="codeValue"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.POSITION', '직책') }}</div>
            <div class="dx-field-value">
              <dx-select-box
                v-model="formData.positionCd"
                :items="$_getCode('user_position')"
                display-expr="codeNm"
                value-expr="codeValue"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.WORKGROUP', '업무그룹') }}</div>
            <div class="dx-field-value">
              <dx-select-box
                v-model="formData.workgroupCd"
                :items="$_getCode('user_workgroup')"
                display-expr="codeNm"
                value-expr="codeValue"
                :placeholder="selectBox.placeholder"
                :width="selectBox.width"
                :styling-mode="stylingMode"
              >
              </dx-select-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.DN', '내선번호') }}</div>
            <div class="dx-field-value">
              <dx-text-box
                v-model="formData.dn"
                :width="textBox.width"
                :styling-mode="stylingMode"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.MOBILE', '휴대폰번호') }}</div>
            <div class="dx-field-value">
              <dx-text-box
                v-model="formData.mobile"
                :width="textBox.width"
                :styling-mode="stylingMode"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.LOGIN_FL', '로그인 연동 여부') }}*</div>
            <div class="dx-field-value">
              <dx-switch
                :value="getLoginFl"
                @value-changed="handleChangedLoginFl"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMMON.WORD.CTI_FL', 'CTI 등록 여부') }}*</div>
            <div class="dx-field-value">
              <dx-switch
                :value="getCtiFl"
                @value-changed="handleChangedCtiFl"
              />
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label"> CTI ID </div>
            <div class="dx-field-value">
              <dx-text-box
                v-model="formData.ctiId"
                :width="textBox.width"
                :styling-mode="stylingMode"
                :disabled="isCtiIdDisabled"
              >
              </dx-text-box>
            </div>
          </div>
          <div class="dx-field">
            <div class="dx-field-label">{{ getI18n('COMPONENTS.USE_STATUS', '사용 여부') }}*</div>
            <div class="dx-field-value">
              <dx-switch
                :value="getUsedFl"
                @value-changed="handleChangedUsedFl"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
  </esp-dx-modal-popup>
</template>

<script>
import EspDxModalPopup from "@/components/devextreme/esp-dx-modal-popup.vue";
import { DxSelectBox } from 'devextreme-vue/select-box';
import { DxRequiredRule, DxValidator } from 'devextreme-vue/validator';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxSwitch from "devextreme-vue/switch";
import { isSuccess } from '@/utils/common-lib';

export default {
  components: {
    EspDxModalPopup,
    DxSelectBox,
    DxRequiredRule,
    DxValidator,
    DxTextBox,
    DxSwitch,
  },
  props: {
    isOpen: {
      default: false,
      type: Boolean,
    },
    userData : {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      stylingMode: 'outlined',
      textBox: {
        width: '60%',
      },
      selectBox: {
        width: '60%',
        placeholder: '선택',
      },
      modal: {
        title: this.getI18n('COMPONENTS.USER', '사용자')
            +' '+this.getI18n('COMPONENTS.REGISTER', '등록'),
        minWidth: '300',
        width: '600',
        minHeight: '300',
        height: '700',
        resizeEnabled: true,
        dragEnabled: true,
        showCloseButton: true,
        hideOnOutsideClick: false,
        validationGroup: 'validationGroupModal',
      },
      isCtiIdDisabled: true,
      formData: {
        id: null,
        userNo: null,
        userNm: null,
        siteId: null,
        tenantId: null,
        deptId: null,
        companyCd: null,
        gradeCd: null,
        classCd: null,
        positionCd: null,
        workgroupCd: null,
        dn: null,
        mobile: null,
        loginFl: 'Y',
        ctiFl: 'Y',
        ctiId: null,
        usedFl: 'Y',
      },
      // showCti: null,
      // showWfm: false,
      // showIpt: false,
      // showCtiDetail: false,
      // showWfmDetail: false,
      // showIptDetail: false,
      // propsData: {
      //   userNo: '',
      //   userNm: '',
      //   ctiFl: 'Y',
      //   viewFl: 'Y',
      // },
      // agtTeamList: [],
    };
  },
  watch: {},
  computed: {
    /** @description: 로그인 연동 여부 */
    getLoginFl() {
      return this.formData.loginFl === 'N';
    },
    /** @description: CTI 연동 여부 */
    getCtiFl() {
      return this.formData.ctiFl === 'N';
    },
    /** @description: 사용여부 */
    getUsedFl() {
      return this.formData.usedFl === 'Y';
    },
    /** @description: U_CODE svr_type 가져오기 */
    getSiteList(){
      const result = this.$store.getters.getSiteList;
      return result;
    },
    getTenantList(){
      const result = this.$store.getters.getTenantList;
      return result;
    },
  },
  methods: {
    /**
     * @description 다국어 메시지 반환
     * @param key {string}
     * @param defaultValue {string}
     * @param params {Object}
     * @return {string}
     */
    getI18n(key, defaultValue, params) {
      return this.$_lang(key, { defaultValue: defaultValue, ...params });
    },
    /** @description: 사용여부 이벤트 */
    handleChangedUsedFl(e) {
      if (e.value) {
        this.formData.usedFl = 'Y';
      } else {
        this.formData.usedFl = 'N';
      }
    },
    /** @description: 로그인 연동 여부 이벤트 */
    handleChangedLoginFl(e) {
      if (e.value) {
        this.formData.loginFl = 'Y';
      } else {
        this.formData.loginFl = 'N';
      }
    },
    /** @description: CTI 연동 여부 이벤트 */
    handleChangedCtiFl(e) {
      this.formData.ctiFl = e.value ? 'Y' : 'N'; // CTI 연동 여부
      this.isCtiIdDisabled = !e.value; // CTI 연동 여부에 따라 CTI ID 입력 비활성화
    },
    // onChangedCtiFl(e) {
    //   if (e.value) {
    //     this.formData.ctiFl = 'Y';
    //   } else {
    //     this.formData.ctiFl = 'N';
    //     this.formData.ctiId = null;
    //     this.formData.loginId = null;
    //   }
    // },
    async onSaveModal(e) {
      const validationGroup = this.$refs.validationGroup.instance;
      const validationResult = validationGroup.validate();
      if (!validationResult.isValid) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.REQUIRED_VALUE_VALIDATION_ERROR', { defaultValue: '필수값을 입력해주세요.' }));
        return;
      }

      let selectedSite = this.$store.getters.getSiteList.filter((v) => v.id === this.formData.siteId)[0];
      let selectedTenant = this.$store.getters.getTenantList.filter((v) => v.id === this.formData.tenantId)[0];
      this.formData.site = selectedSite.id;
      this.formData.siteNm = selectedSite.siteNm;
      this.formData.tenant = selectedTenant.id;
      this.formData.tenantNm = selectedTenant.tenantNm;
      // 시간 형식 변환
      const d = new Date();
      const date = new Date(d.getTime()).toISOString().split('T')[0];
      const time = d.toTimeString().split(' ')[0];
      this.formData.joinDt = date + ' ' + time;
      this.formData.loginFl = 'Y';
      this.formData.recFl = 'N';
      const payload = {
        actionName: 'USER_SAVE',
        data: {
          from: "core",
          data : [this.formData]
        },
        loading: true,
      };
      const res = await this.CALL_API(payload);
      if (res.status === 200) {
        this.$_Msg('성공');
      } else {
        this.$_Msg('실패');
      }

      this.setInitModal();
    },
    /* @desc: 모달창이 close될 때, modal 객체 설정 값 초기화 */
    setInitModal() {
      this.$emit('saveModal');
      this.propsData = {};
    },
    /** @description: 모달 저장 */
    async handleSaveModal(e) {
      let userData = this.userData;

      return false;

      const payload = {
        actionName: 'USER_SAVE',
        data: {
          from: "core",
          data : [userData]
        },
        loading: true,
      };
      const res = await this.CALL_API(payload);
      if (isSuccess(res)) {
        this.$_Toast(this.$_lang('COMMON.MESSAGE.CMN_SUC_SAVE', {defaultValue: '정상적으로 저장되었습니다.'}));
        this.$emit('saveModal');
      }
    },
    /** @description : 팝업 취소 버튼 액션  */
    handleCloseModal() {
      this.$emit('closeModal');
    },
    onToggle(type) {
      if (type === 'CTI') {
        this.showCtiDetail = this.showCtiDetail ? false : true;
      } else if (type === 'WFM') {
        this.showWfmDetail = this.showWfmDetail ? false : true;
      } else if (type === 'IPT') {
        this.showIptDetail = this.showIptDetail ? false : true;
      }
    },
    onValueChanged(e) {
      if (e.value === true) {
        if (e.element.__vue__.text === 'CTI') {
          this.showCti = true;
        } else if (e.element.__vue__.text === 'WFM') {
          this.showWfm = true;
        } else if (e.element.__vue__.text === 'IPT') {
          this.showIpt = true;
        }
      } else {
        if (e.element.__vue__.text === 'CTI') {
          this.showCti = false;
        } else if (e.element.__vue__.text === 'WFM') {
          this.showWfm = false;
        } else if (e.element.__vue__.text === 'IPT') {
          this.showIpt = false;
        }
      }
    },
    onTeamChanged(e) {
      const selectedTeam = this.agtTeamList.find(team => String(team.teamId) === String(e.value));
      if (selectedTeam) {
        this.formData.teamNm = selectedTeam.teamNm;
        this.formData.teamId = selectedTeam.teamId;
        this.formData.team = selectedTeam.team;
      } else {
        this.formData.teamNm = null;
        this.formData.teamId = null;
        this.formData.team = null;
      }
    },
    async getAgtTeamList(){
      const yesFlag = this.$_enums.common.stringViewFlag.YES.value;
      const payload = {
        actionName: 'CC_AGTTEAM_LIST',
        data: {
          sort: '+agtteamNm',
          viewFl: yesFlag,
        },
      }
      const res = await this.CALL_CC_API(payload);
      this.agtTeamList = res.data.data
        .filter(item => item.agtteam && item.agtteamid && item.agtteamNm)
        .map(item => ({
          team: item.agtteam,
          teamId: item.agtteamid,
          teamNm: item.agtteamNm
        }));
    },
    async initializeData() {
      this.formData = this.userData?.rowData ? { ...this.userData.rowData[0] } : {};
      if (this.formData.teamId) {
        const selectedTeam = this.agtTeamList.find(team => String(team.teamId) === String(this.formData.teamId));
        if (selectedTeam) {
          this.formData.teamNm = selectedTeam.teamNm;
          this.formData.teamId = selectedTeam.teamId;
          this.formData.team = selectedTeam.team;
        }
      }

      this.$nextTick(() => {
        const validationGroup = this.$refs.validationGroup;
        if (validationGroup && validationGroup.instance) {
          //validationGroup.instance.reset();
        }
      });
    },
    /** @description: 데이터 초기 설정 */
    initData() {
      this.formData = this.userData ? JSON.parse(JSON.stringify(this.userData)) : {};
    },
  },
  async created() {
    //await this.getAgtTeamList();
    this.initData()
  },
  mounted() {
  }
};
</script>

<style lang="scss" scoped>
.add-form-container {
  display: flex;
  flex-direction: column; /* 위에서 아래로 정렬 */
  height: 100%; /* 컨테이너가 부모에 꽉 차도록 설정 */
}

::v-deep {
  .dx-popup-content {
    //overflow-y: auto !important;
  }


  .clearfix {
    margin: 0 auto; // 가운데정렬
    //width: 88%;
  }


}
</style>
