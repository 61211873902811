<template>
	<div class="container">
		<Tabs ref="tabs" @selectedIndex="tabSelectedIndex" :tabType="1">
			<Tab title="상위작업">
				<div class="locker_setting_list sub_new_style01 sub_ui_box1" v-if="tabIndex === 0">
					<div class="cusmain-table-wrap">
						<Task />
					</div>
				</div>
			</Tab>
			<Tab title="하위작업">
				<div class="locker_setting_list sub_new_style01 sub_ui_box1" v-if="tabIndex === 1">
					<div class="cusmain-table-wrap">
						<SubTask />
					</div>
				</div>
			</Tab>

			<Tab title="시간설정">
				<div class="locker_setting_list sub_new_style01 sub_ui_box1" v-if="tabIndex === 2">
					<div class="cusmain-table-wrap">
						<Time />
					</div>
				</div>
			</Tab>
			<Tab title="배치설정">
				<div class="locker_setting_list sub_new_style01 sub_ui_box1" v-if="tabIndex === 3">
					<div class="cusmain-table-wrap">
						<Mapping />
					</div>
				</div>
			</Tab>
		</Tabs>
	</div>
</template>

<script>
import Tabs from '@/components/common/tabs.vue';
import Tab from '@/components/common/tab.vue';
import Task from './task.vue';
import SubTask from './sub-task.vue';
import Time from './time.vue';
import Mapping from './mapping.vue';

export default {
  name: 'EUCScheduling',
	components: {
		Tabs,
		Tab,
		Task,
		SubTask,
		Time,
		Mapping
	},
	props: {},
	watch: {},
	data() {
		return {
			tabIndex: 0,
			cmList: [],
			tabs: {
				selectedIndex: this.$store.getters.getTabHists[this.$router.currentRoute.path],
			},
			config: {
				stylingMode: 'outlined',    //outlined, underlined, filled
				pageSetting: {                  //pageData pageSetting 관련
					config: {},
					formData: {},
				},
			},
		}
	},
	computed: {},
	methods: {
		async tabSelectedIndex(index) {
			// cm data 변경 시 aes, svr에서 반영할 수 있도록 하기 위해 v-if 사용
			this.tabIndex = index;
		},
	},
	created() {},
	mounted() {},
	destroyed() {}
}
</script>

<style scoped>
</style>