<!--
  PACKAGE_NAME : src\pages\esp\system\security\allowed-ip.vue
  FILE_NAME : allowed-ip
  AUTHOR : supark
  DATE : 2021-07-14
  DESCRIPTION : 접속 허용 IP 관리
-->
<template>
  <div class="page-sub-box">
    <esp-dx-data-grid :data-grid="dataGrid" :ref="dataGrid.refName" />
  </div>
</template>

<script>
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid-v2.vue';

  export default {
    name: 'ESPAllowedIPList',
    components: { EspDxDataGrid },
    data() {
      return {
        dataGrid: {
          refName: 'allowedIPRefName',
          keyExpr: 'allowedIPId',
          dataSource: [],
          apiActionNm: {
            select: 'ALLOWED_IP_LIST',
            update: 'ALLOWED_IP_SAVE',
            delete: 'ALLOWED_IP_DELETE',
          },
          showActionButtons: {
            customButtons: [], // 그리드 커스텀 버튼 생성
          },
          columns: [
            {
              caption: 'IP',
              dataField: 'allowedIP',
              requiredRule: {},
            },
            {
              caption: '설명',
              dataField: 'description',
              alignment: 'left',
              minWidth: 300,
            },
            {
              caption: '등록자',
              dataField: 'regId',
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '등록일시',
              dataField: 'regDt',
              dataType: 'date',
              format: 'yyyy-MM-dd HH:mm:ss',
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '수정자',
              dataField: 'editId',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '수정 일시',
              dataType: 'date',
              dataField: 'editDt',
              format: 'yyyy-MM-dd HH:mm:ss',
              allowHeaderFiltering: false,
            },
          ],
        },
      };
    },
    computed: {},
    methods: {},
    mounted() {},
  };
</script>
