import BulkWorkRoutes from "@/router/euc/bulk-work";
import ChineseWallRoutes from "@/router/euc/chinese-wall";
import ContactRoutes from "@/router/euc/contact";
import DeviceRoutes from "@/router/euc/device";
import HerRoutes from "@/router/euc/her";
import InfoPushRoutes from "@/router/euc/info-push";
import PhoneRoutes from "@/router/euc/phone";
import SchedulingRoutes from "@/router/euc/scheduling";
import SpIptRoutes from "@/router/euc/sp-ipt";
import IptFileRoutes from "@/router/euc/ipt-file";

export default function EucRoutes(commonComponents) {
    return [
        ...BulkWorkRoutes(commonComponents),
        ...ChineseWallRoutes(commonComponents),
        ...ContactRoutes(commonComponents),
        ...DeviceRoutes(commonComponents),
        ...HerRoutes(commonComponents),
        ...InfoPushRoutes(commonComponents),
        ...PhoneRoutes(commonComponents),
        ...SchedulingRoutes(commonComponents),
        ...SpIptRoutes(commonComponents),
        ...IptFileRoutes(commonComponents),
    ];
}