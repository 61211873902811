import EUCBulkWorkTimer from '@/pages/euc/bulk-work/timer.vue';
import EUCBulkWorkHistory from '@/pages/euc/bulk-work/history.vue';

export default function BulkWorkRoutes(commonComponents) {
    return [
        {
            path: '/euc/bulk-work/timer',
            name: 'EUCBulkWorkTimer',
            components: { default: EUCBulkWorkTimer, ...commonComponents },
            meta: { requiresAuth: true },
        },
        {
            path: '/euc/bulk-work/history',
            name: 'EUCBulkWorkHistory',
            components: { default: EUCBulkWorkHistory, ...commonComponents },
            meta: { requiresAuth: true },
        },
    ];
}