import ESPRedisList from '@/pages/esp/monitoring/redis/index.vue';

export default function MonitoringRoutes(commonComponents) {
  return [
    {
      path: '/esp/monitoring/redis/index',
      name: 'ESPRedisList',
      components: { default: ESPRedisList, ...commonComponents },
      meta: { requiresAuth: true },
    },
  ];
}
